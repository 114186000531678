import React from 'react';

import '../../Styles/Module.css';

const ModuleContent = ({ children }) => {
	
	return (
		<div className="module-content">
			{children}
		</div>
	);
}

export default ModuleContent;