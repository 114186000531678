
import { useState } from 'react';

import UserAccountSummary,
{
    UserAccountSummaryButton,
    UserAccountSummaryPopup,
    UserAccountSummaryHeader,
    UserAccountSummaryUsername,
    UserAccountSummaryDescription,
    UserAccountSummaryFooter
} from '@nokia-csf-uxr/ccfk/UserAccountSummary';
import Avatar from '@nokia-csf-uxr/ccfk/Avatar'
import { CircularLoading } from '../Common/Loading';
import Button from '@nokia-csf-uxr/ccfk/Button';
import ButtonsRow from '@nokia-csf-uxr/ccfk/ButtonsRow';
import { useMsalProps } from "../../CustomHooks/Msal";

import { useUser, useAvatar } from '../../CustomHooks/Global';

import '../../Styles/UserAccount.css';

const UserAccount = () => {

    const [isUserAccountSummaryPopupVisible, setIsUserAccountSummaryPopupVisible] = useState(false);

    const user = useUser();
    const avatar = useAvatar();
    const { instance } = useMsalProps();

    const handleClick = () => {
        setIsUserAccountSummaryPopupVisible(!isUserAccountSummaryPopupVisible);
    }

    const handleClose = () => {
        setIsUserAccountSummaryPopupVisible(false);
    }

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    const userIsInvalid =
            user === undefined ||
            !user.name ||
            !user.firstName ||
            !user.lastName ||
            !user.email;

    if (userIsInvalid) {
        return (
            <div className="user-menu">
                <CircularLoading size={22} overlay />
            </div>
        );
    }

    const userInitials = user.firstName[0] + user.lastName[0];

    return (
        <UserAccountSummary variant='default-custom' onEscFocusOut={handleClose} onClickOutside={handleClose} >
            <UserAccountSummaryButton onClick={handleClick}>
                <div className="user-menu">
                    {user.name}
                    <Avatar size="small">
                        { avatar ? <img src={avatar} alt=""></img> : userInitials }
                    </Avatar>
                </div>
            </UserAccountSummaryButton>
            <UserAccountSummaryPopup open={isUserAccountSummaryPopupVisible} >
                <UserAccountSummaryHeader>
                    <div className="user-menu">
                        <Avatar size="xlarge">
                            {avatar ? <img src={avatar} alt=""></img> : userInitials}
                        </Avatar>
                        <div>
                            <UserAccountSummaryUsername className="account-text">{user.name}</UserAccountSummaryUsername>
                            <UserAccountSummaryDescription className="account-text">
                                <span title={user.email}>
                                    <a href={"mailto:" + user.email}>
                                        {user.email}
                                    </a>
                                </span>
                            </UserAccountSummaryDescription>
                        </div>
                    </div>
                </UserAccountSummaryHeader>
                <UserAccountSummaryFooter>
                    <ButtonsRow>
                        <Button variant="call-to-action-bigger-font" onClick={ handleLogout }>Sign Out</Button>
                    </ButtonsRow>
                </UserAccountSummaryFooter>
            </UserAccountSummaryPopup>
        </UserAccountSummary>
    );
}

export default UserAccount;