import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import '../../Styles/JsonViewer.css';

const JsonViewer = (props) => {
    return (
        <JsonView {...props} />
    );
}

export default JsonViewer;