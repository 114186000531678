import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import ClipboardIcon from '@nokia-csf-uxr/ccfk-assets/latest/ClipboardIcon';

import '../../Styles/MarkdownStyled.css';

const MarkdownStyled = ({ children }) => {

    return (
        <Markdown
            remarkPlugins={[remarkGfm]} className='markdown-styled-container'
            components={{
                code(props) {
                    const { children, className, node, ...rest } = props
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <>
                            <div className='code-container'>
                                <div className='code-options'>
                                    <div>
                                        {match[1]}
                                    </div>
                                    <IconButton size='small' disabledRipple onClick={() => navigator.clipboard.writeText(children)}>
                                        <ClipboardIcon size={20} color={'var(--ff-color-tone-700)'} />
                                    </IconButton>
                                </div>
                                <SyntaxHighlighter
                                    {...rest}
                                    PreTag="div"
                                    children={String(children).replace(/\n$/, '')}
                                    language={match[1]}
                                    style={oneDark}
                                />
                            </div>
                        </>
                    ) : (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                    )
                }
            }}
        >
            {children}
        </Markdown>
    );
}

export default MarkdownStyled;