import { useEffect, useState } from 'react';

import { TextArea } from '../../Components/Common';

const SystemSection = ({ configurationWasLoaded, getConfiguration, updateConfiguration }) => {

    const [systemPrompt, setSystemPrompt] = useState("");
    const [prefix, setPrefix] = useState("");
    const [postfix, setPostfix] = useState("");
    const [summarySystemPrompt, setSummarySystemPrompt] = useState("");
    const [summaryUserPrompt, setSummaryUserPrompt] = useState("");
    const [rollingSummarySystemPrompt, setRollingSummarySystemPrompt] = useState("");
    const [queryReformulationUserPrompt, setQueryReformulationUserPrompt] = useState("");
    const [configurationAvailable, setConfigurationAvailable] = useState(null);

    const onUpdateTextArea = (type, parameter, value, setStateCallback) => {
        updateConfiguration(type, parameter, value);
        setStateCallback(value);
    }

    useEffect(() => {
        let configuration = getConfiguration();

        if (configurationWasLoaded && configuration) {
            setSystemPrompt(configuration.ps_mb.system_prompt ?? '');
            setPrefix(configuration.ps_mb.prefix ?? '');
            setPostfix(configuration.ps_mb.postfix ?? '');
            setSummarySystemPrompt(configuration.ps_mb.summary_system_prompt ?? '');
            setSummaryUserPrompt(configuration.ps_mb.summary_user_prompt ?? '');
            setRollingSummarySystemPrompt(configuration.ps_mb.summary_rolling_user_prompt ?? '');
            setQueryReformulationUserPrompt(configuration.ps_mb.query_reformulation_user_prompt ?? '');

            setConfigurationAvailable(configuration);
        }
        // eslint-disable-next-line
    }, [configurationWasLoaded]);

    return (
        <>
            {configurationAvailable &&
            <TextArea
                label="System Prompt"
                value={systemPrompt}
                setValue={(newValue) => onUpdateTextArea('ps_mb', 'system_prompt', newValue, setSystemPrompt)}
                placeholder="System Prompt"
                maxChars={5000}
                showCharacterCount={true}
                rows={8}
                />}
            {configurationAvailable &&
            <TextArea
                label="Prefix"
                value={prefix}
                setValue={(newValue) => onUpdateTextArea('ps_mb', 'prefix', newValue, setPrefix)}
                placeholder="Prefix"
                maxChars={500}
                showCharacterCount={true}
                rows={1}
                />}
            {configurationAvailable &&
            <TextArea
                label="Postfix"
                value={postfix}
                setValue={(newValue) => onUpdateTextArea('ps_mb', 'postfix', newValue, setPostfix)}
                placeholder="Postfix"
                maxChars={500}
                showCharacterCount={true}
                rows={1}
                />}
            {configurationAvailable &&
            <TextArea
                label="Summary System Prompt"
                value={summarySystemPrompt}
                setValue={(newValue) => onUpdateTextArea('ps_mb', 'summary_system_prompt', newValue, setSummarySystemPrompt)}
                placeholder="Summary System Prompt"
                maxChars={1000}
                showCharacterCount={true}
                rows={5}
                />}
            {configurationAvailable &&
            <TextArea
                label="Summary User Prompt"
                value={summaryUserPrompt}
                setValue={(newValue) => onUpdateTextArea('ps_mb', 'summary_user_prompt', newValue, setSummaryUserPrompt)}
                placeholder="Summary User Prompt"
                maxChars={1000}
                showCharacterCount={true}
                rows={5}
                />}
            {configurationAvailable &&
                <TextArea
                    label="Rolling Summary User Prompt"
                    value={rollingSummarySystemPrompt}
                    setValue={(newValue) => onUpdateTextArea('ps_mb', 'summary_rolling_user_prompt', newValue, setRollingSummarySystemPrompt)}
                    placeholder="Rolling Summary User Prompt"
                    maxChars={1000}
                    showCharacterCount={true}
                    rows={5}
                />}
            {configurationAvailable &&
                <TextArea
                    label="Query Reformulation User Prompt"
                    value={queryReformulationUserPrompt}
                    setValue={(newValue) => onUpdateTextArea('ps_mb', 'query_reformulation_user_prompt', newValue, setQueryReformulationUserPrompt)}
                    placeholder="Query Reformulation User Prompt"
                    maxChars={1000}
                    showCharacterCount={true}
                    rows={5}
                />}
        </>
    );
}

export default SystemSection