import { useCallback, useState, useEffect } from "react";
import DataTableOptions from '../Components/Datatable/DataTableOptions';

import '../Styles/DataTable.css';

export const usePaging = (gridRef) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('?');
    const [totalRows, setTotalRows] = useState('?');

    const onFirstPage = useCallback(() => {
        if (gridRef.current)
            gridRef.current.api.paginationGoToFirstPage();
    }, [gridRef]);

    const onPreviousPage = useCallback(() => {
        if (gridRef.current)
            gridRef.current.api.paginationGoToPreviousPage();
    }, [gridRef]);

    const onNextPage = useCallback(() => {
        if (gridRef.current)
            gridRef.current.api.paginationGoToNextPage();
    }, [gridRef]);

    const onLastPage = useCallback(() => {
        if (gridRef.current)
            gridRef.current.api.paginationGoToLastPage();
    }, [gridRef]);

    const onGoToPage = useCallback((page) => {
        if (gridRef.current)
            gridRef.current.api.paginationGoToPage(page);
    }, [gridRef]);

    const onPaginationChanged = useCallback(() => {
        if (gridRef.current) {
            setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
            setTotalPages(gridRef.current.api.paginationGetTotalPages());
            setTotalRows(gridRef.current.api.paginationGetRowCount());
        }
    }, [gridRef]);

    return [currentPage, totalPages, totalRows, onFirstPage, onPreviousPage, onNextPage, onLastPage, onGoToPage, onPaginationChanged];
}

export const useClientSearch = (gridApi, searchRef) => {

    const isExternalFilterPresent = useCallback(() => {
        if (searchRef.current)
            return searchRef.current.getChips().length > 0

        return false;
    }, [searchRef]);

    const doesExternalFilterPass = useCallback(({ data }) => {
        const itemsToSearch = searchRef.current.getChips()
            .map(chipsItem => chipsItem.toLowerCase());
        const rowValues = Object.values(data).map(value => value && value.toString().toLowerCase());

        return itemsToSearch.every(item => rowValues.join('').includes(item));
    }, [searchRef]);

    const onKeywordChange = useCallback(() => {
        if (gridApi) {
            gridApi.onFilterChanged();
        }
    }, [gridApi]);

    return [isExternalFilterPresent, doesExternalFilterPass, onKeywordChange]
}

export const useColumnDefinitions = (dataRows, columnDefs, columnsIgnore, withRowOptions, withFilters) => {
    const [columnDefinitions, setColumnDefinitions] = useState([]);

    const handleInitialDefinitions = useCallback(() => {
        const isInIgnoreList = (column) => {
            return columnsIgnore.some((ignore) =>
                ignore.replace(/\s+/g, '').toLowerCase() === column.replace(/\s+/g, '').toLowerCase()
            )
        }

        const colDefs = Object.keys(dataRows[0]).map((column) => {
            var fieldObject = {
                field: column,
                type: 'text',
                editable: true,
                tooltipField: column,
                hide: isInIgnoreList(column),
                flex: 1
            };

            if (columnDefs && column in columnDefs)
                fieldObject = { ...fieldObject, ...columnDefs[column] }

            return fieldObject;
        });

        return colDefs;
    }, [columnDefs, columnsIgnore, dataRows]);

    const handleCellRendererDefinitions = useCallback((colDefs) => {

        const lastColumnRenderer = (props, currentValue) => {
            if (props.value === undefined)
                return currentValue;

            if (withRowOptions && withRowOptions.length > 0) {
                return (
                    <div key='options' className="last-column-container">
                        <div className="last-column-content">{currentValue}</div>
                        <DataTableOptions className="last-column-options stop-row-action" withRowOptions={withRowOptions} props={props} />
                    </div>
                );
            }

            return currentValue;
        };
        
        const lastVisibleIndex = colDefs.length - 1 - colDefs.slice().reverse().findIndex(colDef => colDef.hide === false);

        colDefs.forEach((colDef, index) => {
            var renderers = [];
            if (columnDefs && colDef.field in columnDefs && columnDefs[colDef.field].cellRenderer)
                renderers.push(columnDefs[colDef.field].cellRenderer);
            if (index === lastVisibleIndex && withRowOptions)
                renderers.push(lastColumnRenderer);

            colDef.cellRenderer = (props) => {
                var valueToBeRendered = props.value;
                renderers.forEach(renderer => valueToBeRendered = renderer(props, valueToBeRendered));
                
                return valueToBeRendered;
            };

        });

        return colDefs;
    }, [columnDefs, withRowOptions]);

    const handleFilterDefinitions = useCallback((colDefs) => {

        const textFilterParams = {
            buttons: ['apply', 'clear'],
            filterOptions: ['equals', 'startsWith', 'contains', 'endsWith', 'blank', 'notBlank'],
            closeOnApply: true,
            maxNumConditions: 4,
            defaultJoinOperator: 'OR'
        };

        const numberFilterParams = {
            buttons: ['apply', 'clear'],
            closeOnApply: true,
            maxNumConditions: 4,
            defaultJoinOperator: 'OR'
        };

        const dateFilterParams = {
            buttons: ['apply', 'clear'],
            filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
            closeOnApply: true,
            maxNumConditions: 2,
            defaultJoinOperator: 'OR',
            comparator: (selectedDate, cellValue) => {
                if (!cellValue)
                    return 0;
                const cellDate = new Date(cellValue);

                if (cellDate.toLocaleDateString() === selectedDate.toLocaleDateString())
                    return 0;
                else if (cellDate < selectedDate)
                    return -1;
                else if (cellDate > selectedDate)
                    return 1;
                return 0;
            }
        };

        const filterParamsByType = {
            date: dateFilterParams,
            text: textFilterParams,
            number: numberFilterParams
        };

        const columnTypesAndFilters = {
            date: 'agDateColumnFilter',
            text: 'agTextColumnFilter',
            number: 'agNumberColumnFilter'
        };

        colDefs = colDefs.map(colDef => {
            const filterParams = filterParamsByType[colDef.type];
            const filterType = columnTypesAndFilters[colDef.type];

            return {
                ...colDef,
                filter: filterType,
                filterParams: filterParams
            }
        });

        return colDefs;
    }, []);

    const handleFinalDefinitions = useCallback((colDefs) => {
        const lastVisibleIndex = colDefs.length - 1 - colDefs.slice().reverse().findIndex(colDef => colDef.hide === false);

        colDefs[lastVisibleIndex] = {
            ...colDefs[lastVisibleIndex],
            resizable: false,
        }

        return colDefs;
    }, []);

    useEffect(() => {
        if (!dataRows || dataRows.length === 0)
            return;

        columnsIgnore.push('RowNum');
        var colDefs = handleInitialDefinitions();
        colDefs = handleCellRendererDefinitions(colDefs);
        if (withFilters)
            colDefs = handleFilterDefinitions(colDefs);
        colDefs = handleFinalDefinitions(colDefs);

        setColumnDefinitions(colDefs);
    }, [
        dataRows,
        columnsIgnore,
        withRowOptions,
        withFilters,
        handleInitialDefinitions,
        handleCellRendererDefinitions,
        handleFilterDefinitions,
        handleFinalDefinitions
    ]);

    return columnDefinitions;
}

export const useTableOptions = (gridApi, searchRef, withTableOptions, withServerInfiniteRow, withServerPagination) => {

    const onFilterRemove = useCallback(() => {
        gridApi.setFilterModel(null);
        gridApi.resetColumnState();
        if (searchRef.current)
            searchRef.current.resetChips();

        if (withTableOptions.onFilterRemove?.callback)
            withTableOptions.onFilterRemove.callback();

    }, [gridApi, searchRef, withTableOptions]);

    const onExcelFile = useCallback(() => {
        const fileName = withTableOptions.onExcelFile?.fileName ?? 'Export.csv';
        const csvColumns = withTableOptions.onExcelFile?.columns ?? [];

        if (withTableOptions.onExcelFile && withTableOptions.onExcelFile.messageCallback) {
            withTableOptions.onExcelFile.messageCallback();
        }

        if ((!withServerInfiniteRow || !withServerInfiniteRow.onExcelFile) && (!withServerPagination || !withServerPagination.onExcelFile)) {
            gridApi.exportDataAsCsv({
                allColumns: true,
                columnKeys: csvColumns,
                fileName: fileName
            });
            return;
        }

        const filterModels = gridApi.getFilterModel();
        const keywords = searchRef.current.getChips();
        const sortModels = gridApi.getColumnState().filter(col => col.sort);

        if (withServerInfiniteRow && withServerInfiniteRow.onExcelFile)
            withServerInfiniteRow.onExcelFile(fileName, sortModels, keywords, filterModels, csvColumns);
        else if (withServerPagination && withServerPagination.onExcelFile)
            withServerPagination.onExcelFile(fileName, sortModels, keywords, filterModels, csvColumns);

    }, [gridApi, searchRef, withTableOptions, withServerInfiniteRow, withServerPagination]);

    const onAdjustColumns = useCallback(() => {
        gridApi.sizeColumnsToFit();
    }, [gridApi]);

    return [onFilterRemove, onExcelFile , onAdjustColumns];
}

export const tableColumnTypes = {
    date: {},
    text: {},
    number: {},
    status: {},
};
