import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLeftMenu } from '../../CustomHooks/Global';
import { default as LaunchPadDefault, LaunchPadContent } from '@nokia-csf-uxr/ccfk/LaunchPad';
import LaunchPadContainer from './LaunchPadContainer';

import AppIcons from '../../CustomObjects/AppIcons';

import {
    LEGACY_COLOR_NOKIA_BLUE_500,
    LEGACY_COLOR_SEA_GREEN_500,
    LEGACY_COLOR_BLUSH_PINK_500,
    LEGACY_COLOR_AMETHYST_PURPLE_500,
    LEGACY_COLOR_PUMPKIN_ORANGE_500,
    LEGACY_COLOR_KINGFISHER_BLUE_500,
    LEGACY_COLOR_CERULEAN_BLUE_500,
    LEGACY_COLOR_SCIENCE_BLUE_400,
    LEGACY_COLOR_POPPY_RED_500
} from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';

const LaunchPad = (props) => {

    const navigate = useNavigate();
    const leftMenu = useLeftMenu();

    const ICON_SIZE = 50;

    const launchPadProperties = {
        'schedulel4reviewsession': {
            ICON: AppIcons('certifications', { size: ICON_SIZE, color: LEGACY_COLOR_NOKIA_BLUE_500 }),
        },
        'ktsessions': {
            ICON: AppIcons('ktsessions', { size: ICON_SIZE, color: LEGACY_COLOR_SEA_GREEN_500 }),
            TITLE: 'Schedule/View KT Sessions'
        },
        'schedulelab': {
            ICON: AppIcons('booklab', { size: ICON_SIZE, color: LEGACY_COLOR_SCIENCE_BLUE_400 }),
        },
        'l3workassignments': {
            ICON: AppIcons('documentrepository', { size: ICON_SIZE, color: LEGACY_COLOR_BLUSH_PINK_500 }),
        },
        'reports': {
            ICON: AppIcons('reports', { size: ICON_SIZE, color: LEGACY_COLOR_AMETHYST_PURPLE_500 }),
        },
        'nkcnotifications': {
            ICON: AppIcons('message', { size: ICON_SIZE, color: LEGACY_COLOR_PUMPKIN_ORANGE_500 }),
        },
        'userroles': {
            ICON: AppIcons('userroles', { size: ICON_SIZE, color: LEGACY_COLOR_KINGFISHER_BLUE_500 }),
        },
        'powersearchhistory': {
            ICON: AppIcons('powersearchhistory', { size: ICON_SIZE, color: LEGACY_COLOR_CERULEAN_BLUE_500 }),
        },
        'powersearchxconfiguration': {
            ICON: AppIcons('powersearchxconfiguration', { size: ICON_SIZE, color: LEGACY_COLOR_POPPY_RED_500 }),
        }
    };

    const userLaunchPadRoutes = leftMenu.filter(leftMenuRoute => leftMenuRoute.displayInHome);
    
    const tiles = userLaunchPadRoutes.map((userRoute, index) => {
        const keyValue = `tile-${index}`;
        return (
            <LaunchPadContainer
                id={keyValue}
                key={keyValue}
                onClick={() => navigate(userRoute.path)}
                title={launchPadProperties[userRoute.element].TITLE == null ? userRoute.label : launchPadProperties[userRoute.element].TITLE}
                icon={launchPadProperties[userRoute.element].ICON}
            />
        );
    });

    return (
        <LaunchPadDefault {...props} >
            <LaunchPadContent>
                {tiles}
            </LaunchPadContent>
        </LaunchPadDefault>
    );

}

export default LaunchPad;