import React, { useState, useEffect } from 'react';

import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DeleteIcon';
import RefreshIcon from '@nokia-csf-uxr/ccfk-assets/legacy/RefreshIcon';
import ArrowBoldDownCircleOutlineIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowBoldDownCircleOutlineIcon';
import { getContext, getEasing, getDuration, isAnimationDisabled } from '@nokia-csf-uxr/ccfk/common';
import { ListItemBasic, ListItemText, ListItemTextContainer, ListItemSubText, ListItemActions } from '@nokia-csf-uxr/ccfk/List';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import LinearProgressIndicatorDeterminate from '@nokia-csf-uxr/ccfk/LinearProgressIndicatorDeterminate';
import { useTransition, animated } from '@nokia-csf-uxr/ccfk/node_modules/@react-spring/web';
import { useTokens } from '../../CustomHooks/Global';

import toStyle from 'css-to-style';
import { getAdvancedThemeContext, getComponentAdvancedTheme, CustomVariant, mergeVariantsCSS, getValueFromVar } from '@nokia-csf-uxr/ccfk/AdvancedTheme';

const FileUploadListItem = (props) => {

    const tokens = useTokens();

    const LISTITEM_TEXT_STYLES = {
        padding: '0 0 0.0625rem 0'
    };

    const LISTITEM_SECONDARY_STYLES = {
        padding: `0 ${/*tokens.SPACING.SPACING_6*/tokens.SPACING_MEDIUM} 0.0625rem ${/*tokens.SPACING.SPACING_6*/tokens.SPACING_MEDIUM}`,
    }

    const TOOLTIP_PROPS = {
        placement: 'bottom',
        trigger: ['hover'],
        fallbackPlacements: ['top'],
        modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
        tooltip: ''
    };

    const {
        fileName,
        status,
        secondaryContent,
        disabled,
        progress,
        errorMessage,
        onFocus,
        onBlur,
        onPointerEnter,
        onPointerLeave,
        onRetry,
        onDelete,
        onDownload,
        allowDownLoad,
        index,
        disableAnimation: propsdisableAnimation,
        ...otherProps
    } = props;
    
    const isRTL = getContext(({ RTL }) => RTL);
    const $AT = getAdvancedThemeContext(({ advancedTheme }) => advancedTheme);
    const disableAnimation = getContext(({ disableAnimation }) => disableAnimation);
    const [hideProgress, setHideProgress] = useState(progress >= 100);
    const [fadeOut, setFadeOut] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleFocus = (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    const handlePointerEnter = (e) => {
        setIsHovered(true);
        onPointerEnter && onPointerEnter(e);
    };

    const handlePointerLeave = (e) => {
        setIsHovered(false);
        onPointerLeave && onPointerLeave(e);
    };

    const resetHoverAndFocus = () => {
        setIsFocused(false);
        setIsHovered(false);
    }

    const handleRetry = (e) => {
        resetHoverAndFocus();
        onRetry && onRetry(e);
    };

    const handleDownload = (e) => {
        onDownload && onDownload(e);
    };

    const handleDelete = (e) => {
        setIsDeleting(true);
        /** wait for the end of animation, then call onDelete callback. */
        setTimeout(() => {
            resetHoverAndFocus();
            onDelete && onDelete(e);
        }, getDuration(tokens.DURATION_MODERATE));
    };

    const getProgress = () => Math.round((progress / 100) * 100);

    useEffect(() => {
        if (progress >= 100) {
            setFadeOut(true);
            setTimeout(() => setHideProgress(true), 500);
        }
        if (fadeOut && progress < 100) {
            setFadeOut(false);
            setHideProgress(false);
        }
    }, [fadeOut, progress]);

    // Theming.
    const resolvedListAT = getComponentAdvancedTheme($AT, 'List', 'default');
    const resolvedFileUploadAT = getComponentAdvancedTheme($AT, 'FileUpload', 'default');
    const variantTheme = mergeVariantsCSS(resolvedListAT, resolvedFileUploadAT);
    const resolvedListItemCss = variantTheme['fileUploadListItem || [List]-default'].listItem['-basic'][':selected'].css;
    const resolvedListItemErrorCss = variantTheme['fileUploadListItem || [List]-default'].listItem['-error'][':selected'].css;
    const listItemHeight = toStyle(resolvedListItemCss).height;
    const listItemHeightError = toStyle(resolvedListItemErrorCss).height;
    const resolvedListItemTextErrorColor = resolvedFileUploadAT.text.error;
    //const listItemHeight = '64px';
    //const listItemHeightError = '64px';
    //const resolvedListItemTextErrorColor = THEME.TEXT_INPUT.ERROR_MSG;

    const LIST_ITEM_STYLE = {
        minHeight: errorMessage ? listItemHeightError : listItemHeight,
        position: 'relative',
        scrollbars: 'none',
        "::webkitScrollbar": { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    };

    const LIST_ITEM_ERROR_TEXT_STYLES = {
        paddingBottom: '0.0625rem',
        //color: resolvedListItemTextErrorColor
        color: getValueFromVar({ ...$AT['GLOBAL-VARIABLES'], ...tokens }, resolvedListItemTextErrorColor),
    };

    const actionButtonStyle = isFocused || isHovered || errorMessage ? { opacity: 1, width: 'auto', height: 'auto' } : { opacity: 0 };

    /** Animation when trying to delete this item by clicking the delete button. It requires react-spring version 8. If you don't need this animation. Feel free to delete this part of code. */
    const defaultAnimationConfig = {
        config: {
            //duration: getDuration(tokens.SPEED.SPEED_NORMAL),
            //easing: getEasing(tokens.TRANSITION.TRANSITION_ACCELERATE),
            duration: getDuration(tokens.DURATION_MODERATE),
            easing: getEasing(tokens.TRANSITION_ACCELERATE),
        },
        from: { opacity: 1, minHeight: LIST_ITEM_STYLE.minHeight },
        enter: { opacity: 1, minHeight: LIST_ITEM_STYLE.minHeight },
        leave: { opacity: 0, height: '0rem', minHeight: '0rem' },
        immediate: isAnimationDisabled(propsdisableAnimation, disableAnimation),
    };

    const transitions = useTransition(!isDeleting, defaultAnimationConfig);

    return transitions((transitionsStyles, item, _state, key) =>
        item && (
            <animated.div style={{ ...LIST_ITEM_STYLE, ...transitionsStyles }} key={key}>
                {/* This is a hack for ListIteBasic. When it is the first child, hasBottomBorder will add both top and bottom border.
      As it will always be the first child of animated.div, add this empty div to avoid this behaviour */}
                <CustomVariant
                    variantName="fileUploadListItem || [List]-default"
                    componentName="List"
                    variantTheme={variantTheme}
                >
                    {index !== 0 && <div></div>}
                    <ListItemBasic
                        variant="fileUploadListItem || [List]-default"
                        disabled={disabled}
                        onPointerEnter={handlePointerEnter}
                        onPointerLeave={handlePointerLeave}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        {...otherProps}
                        style={LIST_ITEM_STYLE}
                    >
                        {/* Section for File name */}
                        <ListItemTextContainer style={{ padding: 0 }}>
                            <ListItemText style={LISTITEM_TEXT_STYLES} >{fileName}</ListItemText>
                            {errorMessage && <ListItemSubText style={LIST_ITEM_ERROR_TEXT_STYLES} >{errorMessage}</ListItemSubText>}
                        </ListItemTextContainer>
                        {/* Section for secondary content like size or uploaded Date */}
                        {!isFocused && !isHovered && !errorMessage && (
                            <>
                                {/* Transparent div added to give spacing for the secondaryContent so it will not be overlapped by filename as position:absolute is needed for proper RTL LTR */}
                                <div style={{ color: `${tokens.LEGACY_COLOR_TRANSPARENT}`, position: 'relative', overflow: 'unset', padding: 0, right: !isRTL ? '0rem' : 'auto', left: !isRTL ? 'auto' : '0rem', marginLeft: `${tokens.SPACING_X_SMALL}`, marginRight: `${tokens.SPACING_X_SMALL}` }}>{/*secondaryContent*/}</div>
                                <ListItemTextContainer style={{ position: 'absolute', overflow: 'unset', padding: 0, right: !isRTL ? '0rem' : 'auto', left: !isRTL ? 'auto' : '0rem', marginLeft: `${tokens.SPACING_X_SMALL}`, marginRight: `${tokens.SPACING_X_SMALL}` }}>
                                    <ListItemSubText style={LISTITEM_SECONDARY_STYLES}>{secondaryContent}</ListItemSubText>
                                </ListItemTextContainer>
                            </>
                        )}
                        {/* Section for action buttons */}
                        {(
                            <ListItemActions style={{ overflow: 'visible', ...actionButtonStyle }}>
                                {errorMessage ? (
                                    <IconButton aria-label='Retry' disabled={disabled} onClick={handleRetry}>
                                        <Tooltip {...TOOLTIP_PROPS} tooltip="Retry">
                                            <RefreshIcon />
                                        </Tooltip>
                                    </IconButton>
                                ) : (allowDownLoad &&
                                    <IconButton aria-label='Download' disabled={disabled} onClick={handleDownload}>
                                        <Tooltip {...TOOLTIP_PROPS} tooltip="Download">
                                            <ArrowBoldDownCircleOutlineIcon />
                                        </Tooltip>
                                    </IconButton>
                                )}
                                <IconButton
                                    disabled={disabled}
                                    aria-label='Delete'
                                    onClick={handleDelete}
                                    rippleStyle={{ margin: isRTL ? `0 ${tokens.SPACING_SMALL} 0 0` : `0 0 0 ${tokens.SPACING_SMALL}`, zIndex: 0 }}
                                >
                                    <Tooltip {...TOOLTIP_PROPS} tooltip="Delete">
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </ListItemActions>
                        )}
                        {/* Section for progress indicator */}
                        {progress != null && progress <= 100 && !hideProgress && (
                            <LinearProgressIndicatorDeterminate
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%'
                                }}
                                progress={getProgress()}
                                fade={fadeOut}
                            />
                        )}
                    </ListItemBasic>
                </CustomVariant>
            </animated.div>
        ));
}

export default FileUploadListItem;