import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import ReactHtmlParser from 'html-react-parser';

import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import {
	OverlayPanel,
	RowSeparator,
	Column,
	Row,
	SelectAutoFill,
	FileUpload,
	TextArea,
	ButtonLink,
	FloatingPanel,
	DataTable,
	Message,
	Toggle,
	ToggleButtonGroup,
	Slider,
	ExpansionPanel,
} from "../Common";

import { useUserEmailsList } from '../../CustomHooks/Global';

import UserSettingsIcon from '@nokia-csf-uxr/ccfk-assets/latest/UserSettingsIcon';
import UserAssignIcon from '@nokia-csf-uxr/ccfk-assets/latest/UserAssignIcon';
import ActiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/ActiveIcon';
import InactiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/InactiveIcon';
import AddCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddCircleFillIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';
import SpeakIcon from '@nokia-csf-uxr/ccfk-assets/latest/SpeakIcon';
import CopyIcon from '@nokia-csf-uxr/ccfk-assets/latest/CopyIcon';
import TaskIcon from '@mui/icons-material/Task';
import ErrorCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/ErrorCircleFillIcon';
import VerifiedIcon from '@nokia-csf-uxr/ccfk-assets/latest/VerifiedIcon';
import CloudUploadIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloudUploadIcon';

import {
	MENTOR_MANAGEMENT_MODES,
	MODES,
	useL3PlusCertificationPanelFields,
	useL3PlusRoles,
	useL3PlusCertifications,
    EVALUATION_STATUS,
    useL3PlusMentors,
    APPROVER_VALIDATION,
    OJT_PHASES_MARKS,
    OJT_PHASES,
    ASSIGNMENT_STATUS
} from '../../CustomHooks/L3PlusCertification';

import { iconRenderer } from '../../Renderers/DataTable';

export const MentorAssignmentOverlayPanel = forwardRef(({ fields, reUploadOJTDocument }, ref) => {

	const panelFields = useL3PlusCertificationPanelFields();
	const { roles } = useL3PlusRoles();
	const { certifications, setCertifications, updateCertifications, getCertificationDetails } = useL3PlusCertifications();

	useImperativeHandle(ref, () => ({
		panelFields,
		roles,
		certifications,
		updateCertifications,
		getCertificationDetails
	}));

	const getActionButtonLabel = () => {
		if (fields.mode === MODES.REQUEST_MENTOR)
			return 'Submit';
		else if (fields.mode === MODES.IN_PROGRESS)
			return 'Upload';
		else if (fields.mode === MODES.EVALUATE)
			return 'Update';
		else if (fields.mode === MODES.GO_BACK)
			return 'Restart OJT';
		else if (fields.mode === MODES.TERMINATED)
			return 'Close';
		return 'CHECK THIS!';
	}

	const getActionButtonDisabled = () => {
		if (fields.mode === MODES.IN_PROGRESS && !fields.isMentee)
			return true;

		if (fields.mode === MODES.EVALUATE && fields.isMentor && fields.isApprover)
			return false;

		if (fields.mode === MODES.EVALUATE && fields.isMentor && !fields.currentAssignmentData.isMentorPhase)
			return true;

		if (fields.mode === MODES.EVALUATE && fields.isApprover && fields.currentAssignmentData.isMentorPhase)
			return true;

		if (fields.mode === MODES.EVALUATE && fields.isMentee)
			return true;

		if (fields.mode === MODES.GO_BACK && !fields.isMentee)
			return true;

		return false;
	}

	const onResubmitOJT = () => {
		if (!panelFields.filesToUpload.length) {
			fields.messageRef.current.errorSnack('Please select at least <b>one</b> file to upload.');
			return;
		}

		reUploadOJTDocument();
	}

	const overlayProps = {
		title: fields.mode === MODES.REQUEST_MENTOR ? 'L3+ Mentor Assignment' : fields.currentAssignmentData.courseDisplayName,
		visible: fields.panelVisible,
		setVisible: fields.setPanelVisible,
		elevationIndex: 4,
		width: '35vw',
		actionButtonProps: {
			label: getActionButtonLabel(),
			variant: fields.mode !== MODES.GO_BACK || !fields.isMentee ? 'call-to-action-bigger-font' : 'critical-bigger-font',
			onClick: () => {
				if (fields.mode === MODES.REQUEST_MENTOR && (!panelFields.role || !panelFields.certification)) {
					fields.messageRef.current.errorMessage('Please choose a <b>role</b> and a <b>certification</b> in order to proceed..');
					return;
				}
				else if (fields.mode === MODES.IN_PROGRESS && !panelFields.filesToUpload.length) {
					fields.messageRef.current.errorMessage('Please select at least <b>one</b> file to upload.');
					return;
				}
				else if (fields.mode === MODES.EVALUATE && fields.currentAssignmentData.isMentorPhase && !panelFields.mentorEvaluation) {
					fields.messageRef.current.errorMessage('Please provide an <b>\'Evaluation\'</b> before submitting...');
					return;
				}
				else if (fields.mode === MODES.EVALUATE && fields.currentAssignmentData.isMentorPhase && (!panelFields.mentorFeedback || panelFields.mentorFeedback === '(no feedback)')) {
					fields.messageRef.current.errorMessage('Please provide <b>\'Feedback\'</b> before submitting...');
					return;
				}
				else if (fields.mode === MODES.EVALUATE && !fields.currentAssignmentData.isMentorPhase && !panelFields.approverValidation) {
					fields.messageRef.current.errorMessage('Please provide a <b>\'Validation\'</b> before submitting...');
					return;
				}

				if (fields.mode === MODES.REQUEST_MENTOR) {
					fields.messageRef.current.showAreYouSureMessage(`
						<b>Are you sure you want to apply for a new mentor:</b>
						</br>
						</br>
						<b>Role</b>: ${panelFields.role}
						</br>
						<b>Certification</b>: ${panelFields.certification}
					`);
				}
				else if (fields.mode === MODES.IN_PROGRESS) {
					fields.messageRef.current.showAreYouSureMessage(`
                        <b>Are you sure you finished your work and want to upload the OJT document:</b>
                        </br>
                        </br>
                        <b>Mentee</b>: ${fields.currentAssignmentData.userEmail}
                        </br>
                        <b>Mentor</b>: ${fields.currentAssignmentData.mentor}
                        </br>
                        <b>Certification</b>: ${fields.currentAssignmentData.courseDisplayName}
                    `);
				}
				else if (fields.mode === MODES.EVALUATE) {
					fields.messageRef.current.showAreYouSureMessage(`
                        <b>Are you sure you want to update this OJT:</b>
                        </br>
                        </br>
                        <b>Mentee</b>: ${fields.currentAssignmentData.userEmail}
                        </br>
                        <b>Mentor</b>: ${fields.currentAssignmentData.mentor}
                        </br>
                        <b>Certification</b>: ${fields.currentAssignmentData.courseDisplayName}
                    `);
				}
				else if (fields.mode === MODES.GO_BACK) {
					fields.messageRef.current.showAreYouSureMessage(`
						Are you sure you want to go back and restart the process for certification: <b>${fields.currentAssignmentData.courseDisplayName}</b>
					`);
				}
				else if (fields.mode === MODES.TERMINATED) {
					fields.setPanelVisible(false);
				}
				else {
					console.error('Operation not supported!!!');
				}

			},
			disabled: getActionButtonDisabled(),
		},
		cancelButtonProps: {
			label: 'Cancel'
		}
	}

	const roleProps = {
		values: panelFields.role,
		setValues: panelFields.setRole,
		data: {
			values: roles,
			mapping: {
				value: "courseRole"
			}
		},
		label: 'Role*',
		placeholder: 'Choose the role...',
		onChange: () => {
			if (fields.mode !== MODES.REQUEST_MENTOR)
				return;

			setCertifications([]);
			panelFields.setCertification(null);
			if (panelFields.role) {
				updateCertifications(panelFields.role);
			}
		},
		disabled: fields.mode !== MODES.REQUEST_MENTOR
	}

	const certificationProps = {
		values: panelFields.certification,
		setValues: panelFields.setCertification,
		data: {
			values: certifications,
			mapping: {
				value: "courseDisplayName"
			}
		},
		label: 'Certification*',
		placeholder: 'Choose the certification...',
		disabled: fields.mode !== MODES.REQUEST_MENTOR
	}

	const fileUploadProps = {
		values: panelFields.filesToUpload,
		setValues: panelFields.setFilesToUpload,
		width: '100%',
		margin: '0rem',
		unit: 'MB'
	}

	const mentorEvaluationProps = {
		label: 'Mentor Evaluation',
		buttonProps: [
			{ value: EVALUATION_STATUS.PASSED, label: 'Pass', selectedColor: 'var(--ff-color-green-500)' },
			{ value: EVALUATION_STATUS.FAILED, label: 'Fail', selectedColor: 'var(--ff-color-red-500)' }
		],
		value: panelFields.mentorEvaluation,
		setValue: panelFields.setMentorEvaluation,
		disabled:
			!fields.isMentor ||
			!fields.currentAssignmentData.isMentorPhase ||
			fields.mode === MODES.GO_BACK ||
			fields.mode === MODES.TERMINATED
	}

	const approverValidationProps = {
		label: 'Approver Validation',
		buttonProps: [
			{ value: APPROVER_VALIDATION.AGREE, label: 'Agree', selectedColor: 'var(--ff-color-green-500)' },
			{ value: APPROVER_VALIDATION.DISAGREE, label: 'Disagree', selectedColor: 'var(--ff-color-red-500)' }
		],
		value: panelFields.approverValidation,
		setValue: panelFields.setApproverValidation,
		disabled:
			!fields.isApprover ||
			fields.currentAssignmentData.isMentorPhase ||
			fields.mode === MODES.GO_BACK ||
			fields.mode === MODES.TERMINATED
	}

	const mentorFeedbackProps = {
		label: 'Mentor Feedback',
		value: panelFields.mentorFeedback,
		setValue: panelFields.setMentorFeedback,
		maxChars: 8000,
		cols: 83,
		rows: 2,
		disabled:
			!fields.isMentor ||
			!fields.currentAssignmentData.isMentorPhase ||
			fields.mode === MODES.GO_BACK ||
			fields.mode === MODES.TERMINATED
	}

	const approverFeedbackProps = {
		label: 'Approver Feedback',
		value: panelFields.approverFeedback ?? '',
		setValue: panelFields.setApproverFeedback,
		maxChars: 8000,
		cols: 83,
		rows: 2,
		disabled:
			!fields.isApprover ||
			fields.currentAssignmentData.isMentorPhase ||
			fields.mode === MODES.GO_BACK ||
			fields.mode === MODES.TERMINATED
	}

	return (
		<OverlayPanel {...overlayProps}>
			<Column>
				{
					fields.mode === MODES.REQUEST_MENTOR &&
					<>
						<Row>
							<UserAssignIcon size={30} color='var(--ff-color-purple-500)' />
						</Row>
						<RowSeparator />
						<Row>
							<Typography>
								<b>Welcome to the L3+ Certification program!</b>
							</Typography>
						</Row>
						<RowSeparator />
						<Row>
							<Typography>
								Please select the certification <b>role</b> and <b>title</b> in order to request a new mentor for your training.
							</Typography>
						</Row>
					</>
				}
				{
					fields.mode === MODES.IN_PROGRESS &&
					<>
						<Row>
							<SpeakIcon size={30} color='var(--ff-color-purple-500)' />
						</Row>
						{
							fields.isMentee &&
							<>
								<RowSeparator />
								<Row>
									<Typography>
										<b>Congratulations on participating in the L3+ Certification program!</b>
									</Typography>
								</Row>
								<RowSeparator />
								<Row>
									<Typography>
										When your training is complete, please upload the OJT template so that your mentor can evaluate it.
									</Typography>
								</Row>
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<>
								<RowSeparator />
								<Row>
									<Typography>
										<b>Mentor/Approver View</b>
									</Typography>
								</Row>
								<RowSeparator />
								<Row>
									<Typography>
										OJT currently in progress... After completion student must upload their work into sharepoint in order to be evaluated.
									</Typography>
								</Row>
							</>
						}
					</>
				}
				{
					fields.mode === MODES.EVALUATE &&
					<>
						<Row>
							<TaskIcon style={{ fontSize: 30, color:'var(--ff-color-nokia-blue-500)'}} />
						</Row>
						<RowSeparator />
						{
							fields.isMentee &&
							<>
								<Row>
									<Typography>
										<b>OJT Document has been uploaded. Pending evaluation from mentor and KMCD Approver...</b>
									</Typography>
								</Row>
								<RowSeparator />
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<>
								<Row>
									<Typography>
										<b>OJT Document has been uploaded. Please evaluate {fields.currentAssignmentData.firstName} {fields.currentAssignmentData.lastName}</b>
									</Typography>
								</Row>
								<RowSeparator />
								<Row>
									<Typography>
										{
											fields.currentAssignmentData.isMentorPhase ?
												<b>Pending Mentor evaluation...</b> :
												<b>Pending Approver validation...</b>
										}
									</Typography>
								</Row>
								{
									fields.isMentor && fields.currentAssignmentData.isMentorPhase && fields.currentAssignmentData.approverValidation === APPROVER_VALIDATION.DISAGREE &&
									<>
										<RowSeparator />
										<Row>
											<Typography>
												Please check <b>Approver</b> feedback and make the necessary changes
											</Typography>
										</Row>
									</>
								}
							</>
						}
					</>
				}
				{
					fields.mode === MODES.GO_BACK &&
					<>
						<Row>
							<ErrorCircleFillIcon size={30} color={'var(--ff-color-red-500)'} />
						</Row>
						<RowSeparator />
						{
							fields.isMentee &&
							<>
								<Row>
									<Typography>
										<b>OJT has been evaluated as 'FAILED'.</b>
									</Typography>
								</Row>
								<RowSeparator />
								<Row>
									<Typography>
										Please consider the feedback provided by the mentor and when ready press the '<b>Restart OJT</b>' button in order to restart the process.
									</Typography>
								</Row>
								<RowSeparator />
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<Row>
								<Typography>
									<b>OJT marked as failed. Pending for mentee to restart the process.</b>
								</Typography>
							</Row>
						}
					</>
				}
				{
					fields.mode === MODES.TERMINATED &&
					<>
						<Row>
							<VerifiedIcon size={30} color={'var(--ff-color-green-500)'} />
						</Row>
						<RowSeparator />
						{
							fields.isMentee &&
							<>
								<Row>
									<Typography>
										<b>Congratulations. You have successfully completed your OTJ!</b>
									</Typography>
								</Row>
								<RowSeparator />
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<Row>
								<Typography>
									<b>OJT successfully completed!</b>
								</Typography>
							</Row>
						}
					</>

				}
				<RowSeparator />
				<RowSeparator />
				<Row>
					<Column>
						<Slider
							value={panelFields.ojtPhase}
							setValue={panelFields.setOjtPhase}
							min={OJT_PHASES.REQUEST_MENTOR}
							max={OJT_PHASES.COMPLETE}
							marks={OJT_PHASES_MARKS}
							disabledWithColor={{
								track:
									fields.currentAssignmentData && fields.currentAssignmentData.status === ASSIGNMENT_STATUS.FAILED ? 'var(--ff-color-red-500)' :
										fields.currentAssignmentData && fields.currentAssignmentData.status === ASSIGNMENT_STATUS.SUCCESS ? 'var(--ff-color-green-500)' :
											'var(--ff-color-nokia-blue-500)',
								thumb:
									fields.currentAssignmentData && fields.currentAssignmentData.status === ASSIGNMENT_STATUS.FAILED ? 'var(--ff-color-red-500)' :
										fields.currentAssignmentData && fields.currentAssignmentData.status === ASSIGNMENT_STATUS.SUCCESS ? 'var(--ff-color-green-500)' :
										'var(--ff-color-nokia-blue-500)',
							}}
						/>
					</Column>
				</Row>
				<RowSeparator />
				<RowSeparator />
				<RowSeparator />
				<RowSeparator />
				<Row>
					<Column flex={0.3}>
						<SelectAutoFill {...roleProps} />
					</Column>
					<Column flex={0.7}>
						<SelectAutoFill {...certificationProps} />
					</Column>
				</Row>
				{
					fields.mode === MODES.IN_PROGRESS &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowTextWithCopy label='Mentee' value={fields.currentAssignmentData.userEmail} />
						<RowTextWithCopy label='Mentor' value={fields.currentAssignmentData.mentor} />
						{
							fields.isMentee &&
							<>
								<RowSeparator />
								<ButtonLink url={fields.currentAssignmentData.OJTMaterialsURL} text='OJT Template' />
								<RowSeparator />
								<RowSeparator />
								<FileUpload {...fileUploadProps} />
							</>
						}
					</>
				}
				{
					fields.mode === MODES.EVALUATE &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowTextWithCopy label='Mentee' value={fields.currentAssignmentData.userEmail} />
						<RowTextWithCopy label='Mentor' value={fields.currentAssignmentData.mentor} />
						<RowSeparator />
						{
							fields.isMentee && fields.currentAssignmentData.isMentorPhase &&
							<>
								<RowSeparator />
								<ExpansionPanel header={ReactHtmlParser('<b>Use this section if you want to Resubmit your OJT</b>')}>
									<Row>
										<Column flex={0.8}>
											<FileUpload {...fileUploadProps} />
										</Column>
										<Column flex={0.2}>
											<ButtonLink url={fields.currentAssignmentData.OJTMaterialsURL} text='OJT Template' />
											<RowSeparator />
											<ResubmitOJTButton onClick={onResubmitOJT} />
										</Column>
									</Row>
								</ExpansionPanel>
								<RowSeparator />
								<RowSeparator />
								<RowSeparator />
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<>
								<ButtonLink url={fields.currentAssignmentData.sharepointPath} text='Go To Assignment' />
								<RowSeparator />
							</>
						}
						{
							fields.isMentee && fields.currentAssignmentData.approverValidation === APPROVER_VALIDATION.AGREE &&
							<>
								<Row>
									<ToggleButtonGroup {...mentorEvaluationProps} />
								</Row>
								<RowSeparator />
								<RowSeparator />
								<TextArea {...mentorFeedbackProps} />
							</>
						}
						{
							(fields.isMentor || fields.isApprover) &&
							<>
								<Row>
									<Column>
										<ToggleButtonGroup {...mentorEvaluationProps} />
									</Column>
									<Column>
										<ToggleButtonGroup {...approverValidationProps} />
									</Column>
								</Row>
								<RowSeparator />
								<RowSeparator />
								<TextArea {...mentorFeedbackProps} />
								<TextArea {...approverFeedbackProps} />
							</>
						}

					</>
				}
				{
					fields.mode === MODES.GO_BACK &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowTextWithCopy label='Mentee' value={fields.currentAssignmentData.userEmail} />
						<RowTextWithCopy label='Mentor' value={fields.currentAssignmentData.mentor} />
						<RowSeparator />
						<RowSeparator />
						<Row>
							<Column>
								<ToggleButtonGroup {...mentorEvaluationProps} />
							</Column>
							{
								(fields.isMentor || fields.isApprover) &&
								<Column>
									<ToggleButtonGroup {...approverValidationProps} />
								</Column>
							}
						</Row>
						<RowSeparator />
						<RowSeparator />
						<TextArea {...mentorFeedbackProps} />
						{
							(fields.isMentor || fields.isApprover) &&
							<TextArea {...approverFeedbackProps} />
						}
					</>
				}
				{
					fields.mode === MODES.TERMINATED &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowTextWithCopy label='Mentee' value={fields.currentAssignmentData.userEmail} />
						<RowTextWithCopy label='Mentor' value={fields.currentAssignmentData.mentor} />
						<RowSeparator />
						<RowSeparator />
						<Row>
							<Column>
								<ToggleButtonGroup {...mentorEvaluationProps} />
							</Column>
							{
								(fields.isMentor || fields.isApprover) &&
								<Column>
									<ToggleButtonGroup {...approverValidationProps} />
								</Column>
							}
						</Row>
						<RowSeparator />
						<RowSeparator />
						<TextArea {...mentorFeedbackProps} />
						{
							(fields.isMentor || fields.isApprover) &&
							<TextArea {...approverFeedbackProps} />
						}
					</>
				}
			</Column>
		</OverlayPanel>
	);
});

export const ManageMentorsButton = ({ onClick }) => {
	return (
		<Button variant='secondary-bigger-font' onClick={onClick}>
			<ButtonText>Manage Mentors</ButtonText>
			<ButtonIcon>
				<UserSettingsIcon />
			</ButtonIcon>
		</Button>
	);
}

export const CreateMentorAssignmentButton = ({ onClick }) => {
	return (
		<Button variant='call-to-action-bigger-font' onClick={onClick}>
			<ButtonText>Request Mentor Assignment</ButtonText>
			<ButtonIcon>
				<UserAssignIcon />
			</ButtonIcon>
		</Button>
	);
}

export const MentorManagementFloatingPanel = ({ fields, eligibleCoursesToManage, getManagableCourseDetails }) => {

	const {
	    mentors,
	    getMentors,
	    setMentors,
	    insertMentor,
	    updateMentor,
	    deleteMentor,
	    insertResponse,
	    insertError,
	    updateResponse,
	    updateError,
	    deleteResponse,
	    deleteError
	} = useL3PlusMentors(fields);

	const [certification, setCertification] = useState(null);
	const [showMentorMessage, setShowMentorMessage] = useState(false);
	const [courseNumber, setCourseNumber] = useState('');
	const [mentorEmail, setMentorEmail] = useState('');
	const [mentorActive, setMentorActive] = useState(true);
	const [mode, setMode] = useState(MENTOR_MANAGEMENT_MODES.CREATE);
	const [showDelete, setShowDelete] = useState(false);
	const [certificationError, setCertificationError] = useState(false);
	const [mentorError, setMentorError] = useState(false);

	const currentCourseNumber = useRef(null);

	const onCertificationChange = () => {
		if (!certification) {
			setMentors([]);
			return;
		}
		const newCertification = getManagableCourseDetails(certification);
		if (newCertification) {
			getMentors(newCertification.courseNumber);
		}
	}

	const onAddMentor = () => {
		if (!certification) {
			fields.messageRef.current.errorMessage('Please select a <b>Certification</b> before adding a new mentor.');
			setCertificationError(true);
			return;
		}
		const theCertification = getManagableCourseDetails(certification);

		setMode(MENTOR_MANAGEMENT_MODES.CREATE);
		setMentorEmail('');
		setCourseNumber(theCertification.courseNumber);
		setMentorActive(true);
		setShowMentorMessage(true);

		currentCourseNumber.current = theCertification.courseNumber;
	}

	const onEditMentor = ({ data }) => {
		setMode(MENTOR_MANAGEMENT_MODES.EDIT);
		setMentorEmail(data.mentor);
		setCourseNumber(data.courseNumber);
		setMentorActive(data.status === 'active');
		setShowMentorMessage(true);

		currentCourseNumber.current = data.courseNumber;
	}

	const onDeleteMentor = ({ data }) => {
		setMode(MENTOR_MANAGEMENT_MODES.DELETE);
		setMentorEmail(data.mentor);
		setCourseNumber(data.courseNumber);
		setMentorActive(data.status === 'active');
		setShowDelete(true);

		currentCourseNumber.current = data.courseNumber;
	}

	const onSubmitMentor = () => {
		if (!mentorEmail) {
			fields.messageRef.current.errorSnack('Please select a <b>Mentor</b> before submitting.');
			setMentorError(true);
			return;
		}

		if (mode === MENTOR_MANAGEMENT_MODES.CREATE) {
			insertMentor(mentorEmail, courseNumber, mentorActive ? 'active' : 'inactive');
		}
		else if (mode === MENTOR_MANAGEMENT_MODES.EDIT) {
			updateMentor(mentorEmail, courseNumber, mentorActive ? 'active' : 'inactive');
		}
		else if (mode === MENTOR_MANAGEMENT_MODES.DELETE) {
			deleteMentor(mentorEmail, courseNumber);
		}
		else {
			console.error('Invalid state when submitting a new mentor!!');
		}
	}

	useEffect(() => {
		if (insertResponse && currentCourseNumber.current) {
			getMentors(currentCourseNumber.current);
			fields.dataTableMentorsRef.current.setClientDataNullAndShowLoadingOverlay();
			setShowMentorMessage(false);
		}
		//eslint-disable-next-line
	}, [insertResponse]);

	useEffect(() => {
		if (updateResponse && currentCourseNumber.current) {
			getMentors(currentCourseNumber.current);
			fields.dataTableMentorsRef.current.setClientDataNullAndShowLoadingOverlay();
			setShowMentorMessage(false);
		}
		//eslint-disable-next-line
	}, [updateResponse]);

	useEffect(() => {
		if (deleteResponse && currentCourseNumber.current) {
			getMentors(currentCourseNumber.current);
			fields.dataTableMentorsRef.current.setClientDataNullAndShowLoadingOverlay();
			setShowMentorMessage(false);
			setShowDelete(false);
		}
		//eslint-disable-next-line
	}, [deleteResponse]);

	useEffect(() => {
		if (insertError) {
			fields.messageRef.current.errorMessage(insertError);
			setShowMentorMessage(false);
		}
		//eslint-disable-next-line
	}, [insertError]);

	useEffect(() => {
		if (updateError) {
			fields.messageRef.current.errorMessage(updateError);
			setShowMentorMessage(false);
		}
		//eslint-disable-next-line
	}, [updateError]);

	useEffect(() => {
		if (deleteError) {
			fields.messageRef.current.errorMessage(deleteError);
			setShowMentorMessage(false);
		}
		//eslint-disable-next-line
	}, [deleteError]);

	return (
		<>
			<FloatingPanel
				title='Manage Certification Mentors'
				visible={fields.panelManageVisible}
				setVisible={fields.setPanelManageVisible}
				initialSize={{ width: 600, height: 800 }}
			>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexFlow: 'column',
						rowGap: '1em'
					}}
				>
					<div>
						<RowSeparator />
						<RowSeparator />
						<SelectAutoFill
							label='Select Certification *'
							values={certification}
							setValues={setCertification}
							data={{
								values: eligibleCoursesToManage,
								mapping: {
									value: 'courseDisplayName'
								}
							}}
							placeholder='Choose a certification to manage mentors...'
							onChange={onCertificationChange}
							error={certificationError}
							setError={setCertificationError}
						/>
					</div>
					<div style={{ flex: 0.8 }}>
						<RowSeparator />
						<RowSeparator />
						<DataTable
							ref={fields.dataTableMentorsRef}
							data={mentors}
							columnDefinitions={{
								status: {
									type: 'status',
									flex: 0.4,
									width: 120,
									maxWidth: 120,
									resizable: false,
									cellRenderer: iconRenderer,
									cellRendererParams: {
										valueColorMap: {
											active: 'var(--ff-color-green-500)',
											inactive: 'var(--ff-color-red-500)',
										},
										valueIconMap: {
											active: ActiveIcon,
											inactive: InactiveIcon,
										}
									}
								}
							}}
							withAdditionalComponents={{
								searchbar: <AddMentorButton onAddMentor={onAddMentor} />
							}}
							withRowOptions={[
								{
									icon: <EditIcon />,
									tooltip: 'Edit Mentor',
									callback: onEditMentor
								},
								{
									icon: <DeleteIcon />,
									tooltip: 'Delete Mentor',
									callback: onDeleteMentor
								}
							]}
							withSearch
							withRowClick={onEditMentor}
							noRowsMessage='No Mentors are set for current certification...'
						/>
					</div>
				</div>
			</FloatingPanel>
			<MentorManagementDetails
				mode={mode}
				showMentorMessage={showMentorMessage}
				setShowMentorMessage={setShowMentorMessage}
				mentorEmail={mentorEmail}
				setMentorEmail={setMentorEmail}
				mentorActive={mentorActive}
				setMentorActive={setMentorActive}
				onSubmit={onSubmitMentor}
				mentorError={mentorError}
				setMentorError={setMentorError}
			/>
			<Message
				title='L3+ On the Job Training'
				message={`Are you sure you want to delete <b>${mentorEmail}</b> from <b>${certification}</b>?`}
				actionButtonLabel='Yes'
				cancelButtonLabel='No'
				open={showDelete}
				setOpen={setShowDelete}
				variant='WARNING'
				onSubmit={onSubmitMentor}
			/>
		</>
	);
};


const AddMentorButton = ({ onAddMentor }) => {
	return (
		<div style={{ marginLeft: '2em' }}>
			<Tooltip tooltip='Add new Mentor' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
				<IconButton data-prevent-drag onClick={onAddMentor}>
					<AddCircleFillIcon color='var(--ff-color-nokia-blue-500)' />
				</IconButton>
			</Tooltip>
		</div>
	);
}

const MentorManagementDetails = ({
	mode,
	showMentorMessage,
	setShowMentorMessage,
	mentorEmail,
	setMentorEmail,
	mentorActive,
	setMentorActive,
	onSubmit,
	mentorError,
	setMentorError
}) => {

	const userEmailsList = useUserEmailsList();

	return (
		<Message
			title={mode === MENTOR_MANAGEMENT_MODES.CREATE ? 'Add new Mentor' : 'Edit Mentor'}
			actionButtonLabel='Submit'
			cancelButtonLabel='Cancel'
			open={showMentorMessage}
			setOpen={setShowMentorMessage}
			withComponent={
				<>
					<RowSeparator />
					<div style={{ display: 'flex', direction: 'row', gap: '1em' }}>
						<SelectAutoFill
							label='Email'
							placeholder="Choose the mentor's email"
							values={mentorEmail}
							setValues={setMentorEmail}
							data={{
								values: userEmailsList.map(u => ({ email: u })),
								mapping: {
									value: 'email'
								}
							}}
							disabled={mode === MENTOR_MANAGEMENT_MODES.EDIT}
							maxNumberOfOptions={50}
							error={mentorError}
							setError={setMentorError}
						/>
						<Toggle
							checked={mentorActive}
							setChecked={setMentorActive}
							checkedText='Active'
							uncheckedText='Active'
						/>
					</div>
				</>
			}
			onSubmit={onSubmit}
		>
		</Message>
	);
}

const RowTextWithCopy = ({ label, value }) => {
	return (
		<Row>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.2em' }}>
				<Typography>
					<b>{label}</b>: {value}
				</Typography>
				<IconButton onClick={() => navigator.clipboard.writeText(value)}>
					<Tooltip placement='top' tooltip="Copy">
						<CopyIcon size={18} />
					</Tooltip>
				</IconButton>
			</div>
		</Row>
	);
}

const ResubmitOJTButton = ({ onClick }) => {
	return (
		<Button variant='secondary-bigger-font' onClick={onClick}>
			<ButtonText>Upload</ButtonText>
			<ButtonIcon>
				<CloudUploadIcon />
			</ButtonIcon>
		</Button>
	);
}