import { useMemo, useState } from 'react';

import ReactHtmlParser from 'html-react-parser';

import { dateCellRendererLocalizedDayOnly } from '../../../Renderers/DataTable';

import { uppercaseFirstLetter } from '../../../CustomObjects/Utils';

import { RESERVATION_STATUS, getValuePlaceholder } from '../../../CustomHooks/LabScheduler';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import {
    Row,
    RowSeparator,
    ItemsList,
    Message,
} from "../../Common";

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import UserCheckIcon from '@nokia-csf-uxr/ccfk-assets/latest/UserCheckIcon';
import ViewIcon from '@nokia-csf-uxr/ccfk-assets/latest/ViewIcon';
import CalendarScheduleIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarScheduleIcon';
import ActiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/ActiveIcon';
import DeleteForeverIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteForeverIcon';
import InactiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/InactiveIcon';
import CopyIcon from '@nokia-csf-uxr/ccfk-assets/latest/CopyIcon';

const CredentialsDetails = ({ credentialData, credentialsDetailsVisible, setCredentialsDetailsVisible }) => {
    
    return (
        <Message
            title='Credential Details'
            actionButtonLabel='Close'
            open={credentialsDetailsVisible}
            setOpen={setCredentialsDetailsVisible}
            onSubmit={() => setCredentialsDetailsVisible(false)}
            withComponent={
                <>
                    {
                        credentialData &&
                        Object.entries(credentialData).map(([field, value]) => (
                            value &&
                            <div key={field}>
                                <Row >
                                    <Typography>
                                        {ReactHtmlParser(`<b>${uppercaseFirstLetter(field)}:</b> ${getValuePlaceholder(value)}`)}
                                    </Typography>
                                </Row>
                                <RowSeparator />
                            </div>
                        ))
                    }
                </>
            }
        />
    );
}

const ScheduledSlotDetails = ({ data }) => {

    const [credentialData, setCredentialData] = useState([]);
    const [credentialsDetailsVisible, setCredentialsDetailsVisible] = useState(false);
    
    const credentialsItems = useMemo(() => {
        const credentials = JSON.parse(data.labCredentialsJSON);
           
        if (!Array.isArray(credentials) || credentials.length === 0)
            return null;
            
        return credentials.map((cred) => ({
            icon: <UserCheckIcon color='var(--ff-color-purple-500)' />,
            text: '<b>' + cred.type + '</b>',
            subText: Object.entries(cred)
                .filter(([field, value]) => field !== 'type' && value)
                .map(([field, value]) => `<b>${uppercaseFirstLetter(field)}:</b> ${getValuePlaceholder(value) }`)
                .join(' '),
            data: cred
        }));
        
    }, [data]);
    
    return (
        <>
            <Row>
                {
                    data.status === RESERVATION_STATUS.BOOKED &&
                    <CalendarScheduleIcon size={30} color='var(--ff-color-nokia-blue-500)' />
                }
                {
                    data.status === RESERVATION_STATUS.ACTIVE &&
                    <ActiveIcon size={30} color='var(--ff-color-green-500)' />
                }
                {
                    data.status === RESERVATION_STATUS.CANCELED &&
                    <DeleteForeverIcon size={30} color='var(--ff-color-red-500)' />
                }
                {
                    data.status === RESERVATION_STATUS.INACTIVE &&
                    <InactiveIcon size={30} color='var(--ff-color-purple-500)' />
                }
            </Row>
            <RowSeparator />
            <RowSeparator />
            <Row>
                <Typography style={{ fontWeight: '300' }}>
                    {ReactHtmlParser(`<b>User:</b> ${data.firstName} ${data.lastName}`)}
                </Typography>
            </Row>
            <RowSeparator />
            <Row>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.2em' }}>
                    <Typography style={{ fontWeight: '300' }}>
                        {ReactHtmlParser(`<b>Email:</b> ${data.userEmail}`)}
                    </Typography>
                    <IconButton onClick={() => navigator.clipboard.writeText(data.userEmail)}>
                        <Tooltip placement='top' tooltip="Copy">
                            <CopyIcon size={18} />
                        </Tooltip>
                    </IconButton>
                </div>
            </Row>
            <RowSeparator />
            <Row>
                <Typography style={{ fontWeight: '300' }}>
                    {ReactHtmlParser(`<b>Lab:</b> ${data.labName}`)}
                </Typography>
            </Row>
            <RowSeparator />
            <Row>
                <Typography style={{ fontWeight: '300' }}>
                    {ReactHtmlParser(`<b>Type:</b> ${data.labType}`)}
                </Typography>
            </Row>
            <RowSeparator />
            <RowSeparator />
            <Row>
                <Typography style={{ fontWeight: '300' }}>
                    {ReactHtmlParser(`<b>Start:</b> ${dateCellRendererLocalizedDayOnly({ value: data.startDate })}`)}
                </Typography>
            </Row>
            <RowSeparator />
            <Row>
                <Typography style={{ fontWeight: '300' }}>
                    {ReactHtmlParser(`<b>End:</b> ${dateCellRendererLocalizedDayOnly({ value: data.endDate, defaultValue: 'Ongoing Reservation...' })}`)}
                </Typography>
            </Row>
            <RowSeparator />
            <RowSeparator />
            {
                (data.status === RESERVATION_STATUS.BOOKED || data.status === RESERVATION_STATUS.ACTIVE) && data.waTemplate &&
                <>
                    <Row>
                        <Typography style={{ fontWeight: '300' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {ReactHtmlParser(`<b>Lab Assignment Template:</b> <a href="${data.waTemplate}" target="_blank">${data.waTemplate}</a>`)}
                            </div>
                        </Typography>
                    </Row>
                    <RowSeparator />
                    <RowSeparator />
                </>
            }
            {
                (data.status === RESERVATION_STATUS.BOOKED || data.status === RESERVATION_STATUS.ACTIVE) && credentialsItems &&
                <>
                    <Row>
                        <ItemsList
                            title='Credentials'
                            items={credentialsItems}
                            withActions={[
                                {
                                    callback: (cred) => {
                                        setCredentialData(cred.data);
                                        setCredentialsDetailsVisible(true);
                                    },
                                    icon: <ViewIcon />,
                                    tooltip: 'View Details'
                                }
                            ]}
                        />
                    </Row>
                    <RowSeparator />
                    <RowSeparator />
                </>
            }
            <CredentialsDetails
                credentialData={credentialData}
                credentialsDetailsVisible={credentialsDetailsVisible}
                setCredentialsDetailsVisible={setCredentialsDetailsVisible}
            />
        </>
    )
}

export default ScheduledSlotDetails;