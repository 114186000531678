import Env from "../CustomObjects/Environment";

const msalConfig = {
	auth: {
		clientId: Env.CLIENT_ID,
		authority: Env.AUTHORITY,
		redirectUri: Env.REDIRECT_URI, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
		postLogoutRedirectUri: Env.REDIRECT_URI, // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: Env.AUTHENTICATION_STORAGE, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
		secureCookies: true
	}
};

export default msalConfig;