import React, { useEffect, useRef, useState } from 'react';
import Env from "../../CustomObjects/Environment";

import { useURLParamsAction } from '../../CustomHooks/URLParams';

import { useFetch } from '../../CustomHooks/Fetch';

import {
	ModuleContainer,
	ModuleHeader,
	ModuleContent,
	Rating,
	TextArea,
	ToggleButtonGroup,
	MessageBundle,
} from '../../Components/Common';

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Typography from '@nokia-csf-uxr/ccfk/Typography'
import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import ArrowBoldDownCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowBoldDownCircleIcon';
import FeedbackIcon from '@nokia-csf-uxr/ccfk-assets/latest/FeedbackIcon';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';

const useCertificationCourse = (setLinkIsValid) => {
	const [course, setFetchCourse, , setCourseHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'CertificationRating/Course');
	const [courseData, setCourseData] = useState(null);

	const getCourse = (id) => {
		setCourseHeaders({
			id
		});
		setFetchCourse(true);
	}

	useEffect(() => {
		if (course && course.data && course.data.length > 0) {
			setLinkIsValid(true);
			setCourseData(course.data[0]);
		}
		else if (course && course.data && course.data.length === 0) {
			setLinkIsValid(false);
		}
		// eslint-disable-next-line
    }, [course]);

	return {
		courseData,
		getCourse,
    };
}

const useCertificationQuestions = () => {

	const [questionsData, setFetchQuestionsData] = useFetch(Env.BACKEND_SERVER_URL + 'CertificationRating/Questions');

	const getQuestions = () => {
		setFetchQuestionsData(true);
    }

	const getCategoriesFor = (questionLabel) => {

		const questionData =
				questionsData &&
				questionsData.data &&
				questionsData.data.questions &&
				questionsData.data.categories
				? questionsData.data.questions.find(q => q.questionLabel === questionLabel) : null;

		if (!questionData || !questionData.questionCategoryLabels)
			return null;

		const categories = questionData.questionCategoryLabels
			.split(':')
			.map(c => {
				const questionCat = questionsData.data.categories.find(cat => cat.categoryLabel === c);
				if (questionCat) {
                    return {
                        value: questionCat.categoryLabel,
                        label: questionCat.categoryText
                    }
				}
				return null;
			});

		return categories;
	}

    return {
		questionsData,
		getQuestions,
		getCategoriesFor,
    };
}

const useFeedbackLoad = () => {
	const [userFeedback, setFetchUserFeedback, , setUserFeedbackHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'CertificationRating/Feedback');

    const getUserFeedback = (courseNumber) => {
        setUserFeedbackHeaders({
            courseNumber
        });
        setFetchUserFeedback(true);
    }

    return {
        userFeedback,
        getUserFeedback,
    };
}

const useFeedbackSubmit = (messageRef) => {
	const [submissionSuccess, setSubmitFeedback, submissionError, , setSubmitBody] = useFetch(Env.BACKEND_SERVER_URL + 'CertificationRating/Feedback', false, {}, 'POST');

	const submitFeedback = (courseNumber, feedbacks) => {
		const normalizedFeedbacks = Object.fromEntries(
			Object.entries(feedbacks).map(([q, ans]) => {
				return [q, { ...ans, value: ans.value ? ans.value.toString() : null }]
			})
		);

		messageRef.current.hideAreYouSureMessage();
		setSubmitBody({
			courseNumber,
			feedbacks: normalizedFeedbacks
		});
        setSubmitFeedback(true);
	}

	useEffect(() => {
		if (submissionSuccess) {
			//console.log('Feedback submitted successfully!.');
			messageRef.current.successMessage('Feedback submitted successfully!.');
		}
		// eslint-disable-next-line
	}, [submissionSuccess]);

	useEffect(() => {
		if (submissionError) {
			messageRef.current.errorMessage('There was an error submitting your feedback: ' + submissionError.data);
		}
		// eslint-disable-next-line
	}, [submissionError]);

    return {
        submitFeedback
    };
}

const CertificationRating = () => {

	const contentRef = useRef(null);
	const messageRef = useRef(null);

	const [feedbacks, setFeedbacks] = useState({});
	const [linkIsValid, setLinkIsValid] = useState(null);

	const { courseData, getCourse } = useCertificationCourse(setLinkIsValid);
	const { questionsData, getQuestions, getCategoriesFor } = useCertificationQuestions();
	const { userFeedback, getUserFeedback } = useFeedbackLoad();
	const { submitFeedback } = useFeedbackSubmit(messageRef);

	const scrollToBottomSmooth = () => {
		const div = contentRef.current;
		div.scrollTo({ top: div.scrollHeight, behavior: 'smooth' });
	}

	const updateFeedbacks = (feedbacks) => {
		setFeedbacks(feedbacks);
    }

	const updateFeedbackValue = (questionLabel, value) => {
		setFeedbacks({ ...feedbacks, [questionLabel]: { ...feedbacks[questionLabel], value } });
	}

	const updateFeedbackCategory = (questionLabel, category) => {
		setFeedbacks({ ...feedbacks, [questionLabel]: { ...feedbacks[questionLabel], category } });
	}

	const getCategoryOptions = (questionLabel, category, setCategory) => {
		const categories = getCategoriesFor(questionLabel);
		if (categories && categories.length > 0) {
			if (!category)
				updateFeedbackCategory(questionLabel, categories[categories.length-1].value)

			return (
				<ToggleButtonGroup
					buttonProps={categories}
					value={category ?? categories[categories.length-1].value}
					setValue={setCategory}
				/>
			);
		}
		return null;
	}

	const validateFeebackAnswers = () => {
		const invalidAnswers = questionsData.data.questions.filter(q => q.isMandatory && (!feedbacks[q.questionLabel] || !feedbacks[q.questionLabel].value));

		if (invalidAnswers.length > 0) {
			messageRef.current.errorMessage('Please fill all mandatory questions.');
            return;
		}

		messageRef.current.showAreYouSureMessage('Are you sure you want to submit your feedback?');
	}

    const getCertificationTitle = (courseData) => {
		const words = courseData.courseDisplayName.split(' ');
		const lastWord = words.pop();
		const title = words.join(' ');
		return `${title} <br/> ${lastWord} Survey`;
    }

	useEffect(() => {
		if (userFeedback && userFeedback.data )
			updateFeedbacks(userFeedback.data.feedbacks);
	}, [userFeedback]);

	useURLParamsAction(
		'all',
		courseData,
		({ courseManagementId, recSolution, recProduct }) => {
			if (courseManagementId)
				getCourse(courseManagementId);
			else if (recSolution)
				getCourse(recSolution);
			else if (recProduct)
				getCourse(recProduct);
			else
				setLinkIsValid(false);
		},
		() => {
			if (courseData) {
				getQuestions();
				getUserFeedback(courseData.courseNumber);
			}
		}
	);

	return (
		<ModuleContainer>
			{
				linkIsValid === false &&
				<div style={{ height: '100%', alignContent: 'center', alignSelf: 'center' }}>
					<NoEncryptionGmailerrorredIcon style={{ fontSize: '10em', color: 'var(--ff-color-nokia-blue-100)', width: '100%' }} />
					<Typography typography="TITLE_24" style={{ marginTop: '2em', fontSize: '2rem' }}>
						Invalid Certification Feedback Link.
					</Typography>
				</div>
			}
			{
				linkIsValid === true &&
				<>
					<ModuleHeader
						backgroundPath={require('../../Images/BannerBackgrounds/banner-background-1.jpg')}
						backgroundPosition='50% 50%'
						titleColor='var(--ff-color-nokia-blue-100)'
					>
						{getCertificationTitle(courseData)}
					</ModuleHeader>
					<ModuleContent>
						<div ref={contentRef} style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '1.5em', paddingRight: '3em', position: 'relative'}}>
							<div>
								<Typography style={{ color: 'var(--ff-color-grey-800)', fontSize: '1rem', fontWeight: '500' }}>
									Please provide your feedback on the curriculum you completed recently. It will take just 5 min of your time and will help us a lot to
									improve our processes.
									<br />
									Your answers will be treated anonymously. Thank you for your support.
								</Typography>
							</div>
							{
								questionsData && questionsData.data &&
								questionsData.data.questions.map((question, i) => {
									return (
										<div key={question.questionLabel} style={{ display: 'flex', flexDirection: 'column', gap: '0.7em', padding: '1em', background: 'var(--ff-color-nokia-blue-100)', borderRadius: '0.5em'}}>
											<Typography
												style={{ color: 'var(--ff-color-grey-800)' }}>
												{i + 1}. {question.questionText} 
												{question.isMandatory && <span style={{ color: 'var(--ff-color-red-500)', fontSize: '1.3em', fontWeight: '600' }}>*</span>}
											</Typography>
											{
												getCategoryOptions(
													question.questionLabel,
													feedbacks[question.questionLabel]?.category ?? null,
													(newCategory) => {
														updateFeedbackCategory(question.questionLabel, newCategory);
													}
												)
											}
											{
												question.questionType === 'Rating' &&
												<Rating
													value={parseFloat(feedbacks[question.questionLabel]?.value ?? 0)}
													setValue={(newValue) => {
														updateFeedbackValue(question.questionLabel, newValue);
													}}
												/>
											}
											{
												question.questionType === 'Text' &&
												<TextArea
													placeholder='Answer here...'
													value={feedbacks[question.questionLabel]?.value ?? ''}
													setValue={(newValue) => {
														updateFeedbackValue(question.questionLabel, newValue);
													}}
												/>
											}
										</div>
									)
								})
							}
						</div>
						<div style={{ position: 'fixed', bottom: '1.2em', right: '5.1em', display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '1em' }}>
							<IconButton onClick={() => scrollToBottomSmooth()}>
								<ArrowBoldDownCircleIcon size={28} color={'var(--ff-color-nokia-blue-500)'} />
							</IconButton>
							<Button
								variant='call-to-action-bigger-font'
								onClick={validateFeebackAnswers}
							>
								<ButtonText>Submit</ButtonText>
								<ButtonIcon>
									<FeedbackIcon />
								</ButtonIcon>
							</Button>
						</div>
						<MessageBundle
							ref={messageRef}
							title={'Certification Rating'}
							onSubmitAreYouSure={() => submitFeedback(courseData.courseNumber, feedbacks)}
						/>
					</ModuleContent>
				</>
			}
		</ModuleContainer>
	);


}

export default CertificationRating;