
import dayjs from 'dayjs';

import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import LanguageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LanguageIcon';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChartBarIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChartBarIcon';

import { LEGACY_COLOR_NEUTRAL_GREY_10, LEGACY_COLOR_NEUTRAL_GREY_200 } from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';

import '../../Styles/BottomSection.css';

const BottomSection = (props) => {

    const linksData = [
        {
            link: 'https://app.powerbi.com/groups/me/apps/da10106d-c535-4662-9d5c-7e742f2f9e21/reports/79027f1b-4958-4b25-89a1-b54c54479ec1/ReportSection?ctid=5d471751-9675-428d-917b-70f44f9630b0&experience=power-bi',
            text: 'Power BI',
            icon: <ChartBarIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: true
        },
        {
            link: 'https://nokia.sharepoint.com/sites/mkmcd/SitePages/06%20-%20AIM/AIM.aspx',
            text: 'AIM',
            icon: <LanguageIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: false
        },
        {
            link: 'https://learningstore.nokia.com/employee/item/n.1584469263499',
            text: 'CNS Learning Store',
            icon: <LanguageIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: false
        },
        {
            link: 'https://nokia.sharepoint.com/sites/GSLL/SitePages/LessonsLearned.aspx',
            text: 'Lessons Learned',
            icon: <LanguageIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: false
        },
        {
            link: 'https://digital-assistant.nokia.com/conversation?botId=16',
            text: 'NDA',
            icon: <LanguageIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: false
        },
        {
            link: 'https://doc.networks.nokia.com/',
            text: 'Discovery Center',
            icon: <LanguageIcon color={LEGACY_COLOR_NEUTRAL_GREY_200} size={24} />,
            highlight: false
        },
        {
            link: 'https://kmcd-kbot-chatbot.nokia.com',
            text: 'KMCD Chatbot',
            icon: <SmartToyIcon style={{ fontSize: '24px', color: LEGACY_COLOR_NEUTRAL_GREY_200, marginRight: '0.3rem' }} />,
            highlight: true
        },
    ];

    const links = linksData.map((linkData, index) => {
        return (
            <Tooltip key={`bottom-section-link-${index}`} placement="top" tooltip={ linkData.link }>
                <a href={linkData.link} className={ linkData.highlight ? 'highlight' : '' } target="_blank" rel="noreferrer">
                    { linkData.icon }
                    { linkData.text }
                </a>
            </Tooltip>
        );
    });

    return (
        <div {...props} style={{ backgroundColor: LEGACY_COLOR_NEUTRAL_GREY_10 }}>
            <div className="links-container">
                { links }
            </div>
            <Typography className="links-text" variant="default">&copy; {dayjs().year()} Nokia. CNS KMCD Development Team</Typography>
        </div>
    );
}

export default BottomSection;