import React, { useState } from 'react';

import { ClickAwayListener } from '@mui/base';

import Label from '@nokia-csf-uxr/ccfk/Label';
import { SelectItemLabelContent } from '@nokia-csf-uxr/ccfk/SelectItem';
import List, { ListItemBasic, ListItemText } from '@nokia-csf-uxr/ccfk/List';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/SettingsIcon';

import { SelectAutoFill } from "../Common";

const SelectButton = (props) => {

	const [open, setOpen] = useState(false);

	const {
		label = '',
		value,
		setValue,
		data,
		disabled = false,
		width = '20rem',
		Icon = SettingsIcon,
		defaultIconColor,
		id = null,
		error = false,
		setError = () => { },
		withAutoFill = false,
		withLabel = true,
		onChange = () => { },
		tooltipOverride = null,
		itemsCssClasses = null
	} = props;

	const handleButtonClick = () => {
		setOpen(!open);
	}

	const getIconColor = () => {
		if (error)
			return 'var(--g-color-status-critical)';
		else if (defaultIconColor)
			return defaultIconColor;
		else
			return 'var(--g-color-global-secondary-content)';
	}

	const getToolTipText = () => {
		if (tooltipOverride)
			return tooltipOverride;
		else if (value)
			return value;
		else
			return 'Select Value';
	}

	const getItemCssClasses = (item) => {
		if (itemsCssClasses && itemsCssClasses[item])
			return itemsCssClasses[item];
        else
            return '';
	}

	if (withAutoFill)
		return (
			<div style={{ position: "relative" }}>
				{
					withLabel &&
					<Label verticalLayout>
						<SelectItemLabelContent>{label}</SelectItemLabelContent>
					</Label>
				}
				<Tooltip closeOnOutOfBoundaries={false} modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]} placement="top" trigger="hover" tooltip={getToolTipText()}>
					<IconButton id={id} aria-label="settings" onClick={handleButtonClick} disabled={disabled}>
						<Icon color={getIconColor()} />
					</IconButton>
				</Tooltip>
				{
					open &&
					<ClickAwayListener onClickAway={() => { setOpen(false); }}>
						<div style={{ width: width, zIndex: 10, position: "absolute", boxShadow: "0 0 3px 0 rgba(0,0,0,0.12), 0 3px 3px 0 rgba(0,0,0,0.24)" }}>
							<SelectAutoFill
								values={value}
								setValues={setValue}
								itemsCssClasses={itemsCssClasses}
								data={data}
								onChange={() => {
									setError(false);
									onChange(value);
								}}
							/>
						</div>
					</ClickAwayListener>
				}
			</div>
		);

	const values = data === undefined || data.values === undefined ? [] : data.values;
	const valueColumn = data.mapping === undefined ? "value" : data.mapping.value;
	const textColumn = data.mapping === undefined ? "text" : (data.mapping.text === undefined ? data.mapping.value : data.mapping.text);

	return (
		<div>
			{
				withLabel &&
				<Label verticalLayout>
					<SelectItemLabelContent>{label}</SelectItemLabelContent>
				</Label>
			}
			<Tooltip closeOnOutOfBoundaries={false} placement="top" modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]} trigger="hover" tooltip={getToolTipText()}>
				<IconButton id={id} aria-label="settings" onClick={handleButtonClick} disabled={disabled}>
					<Icon color={getIconColor()} />
				</IconButton>
			</Tooltip>
			{open &&
				<ClickAwayListener onClickAway={() => { setOpen(false); }}>
					<List style={{ width: width, zIndex: 10, position: "absolute" }} onBlur={() => { }}>
						{values.map((entry, index) => {
							return (
								<ListItemBasic
									key={'sli-' + index}
									className={getItemCssClasses(entry[valueColumn])}
									selected={entry[valueColumn] === value}
									focusItem={entry[valueColumn] === value}
									onClick={() => {
										setValue(entry[valueColumn]);
										setOpen(false);
										onChange(entry[valueColumn]);
									}}
									aria-label={entry[valueColumn]}
									role="option">
									<ListItemText>{entry[textColumn]}</ListItemText>
								</ListItemBasic>
							);
						})}
					</List>
				</ClickAwayListener>
			}
		</div>
	);

}

export default SelectButton;