import React from 'react';
import Env from "../CustomObjects/Environment";
import { useUser } from "../CustomHooks/Global";
import { LegacyModule } from '../Components/Common';
//import { ModuleContainer, ModuleHeader, ModuleContent } from "../Components/Common";

import '../Styles/Home.css';

const PowerSearch = () => {

    const user = useUser();

    return (
        <LegacyModule
            url={Env.LEGACY_NKC_SERVER_URL + "/PowerSearch.aspx?login=" + user.username + "&email=" + user.email + "&access=not_direct_access"}
            height="2300px"
        />
    );

    //return (
    //    <ModuleContainer>
    //        <ModuleHeader>Power Search</ModuleHeader>
    //        <ModuleContent></ModuleContent>
    //    </ModuleContainer>
    //);
}

export default PowerSearch;
