import React, { useMemo, useRef, useState, useEffect } from 'react';
import Env from '../../CustomObjects/Environment';

import { useUserEmailsList } from "../../CustomHooks/Global";

import {
    ModuleContainer,
    ModuleHeader,
    ModuleContent,
	TextInput,
	SelectAutoFill,
	Message,
	DataTable,
	Toggle,
	TextArea,
	FloatingPanel,
	TextWithChips,
    RowSeparator,
} from "../../Components/Common";

import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

import MessageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MessageIcon';
import InfoIcon from '@nokia-csf-uxr/ccfk-assets/legacy/InfoIcon';
import CloseCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseCircleIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import AddCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddCircleFillIcon';
import CloudUploadIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloudUploadIcon';
import EraseIcon from '@nokia-csf-uxr/ccfk-assets/latest/EraseIcon';
import StatusAvailableIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusAvailableIcon';
import StatusBusyIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusBusyIcon';

import { statusBarRenderer, iconRenderer, dateCellRendererLocalTime } from '../../Renderers/DataTable';
import { useFetch } from '../../CustomHooks/Fetch';
import { validateEmail } from '../../CustomObjects/Utils';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ReactHtmlParser from 'html-react-parser';

import dayjs from 'dayjs';

import '../../Styles/NKCNotifications.css';

const MODES = {
	CREATE: 'Create',
	EDIT: 'Update'
}

const TYPES = {
	INFO: 'Info',
	WARNING: 'Warning',
	ERROR: 'Error'
}

const EMAIL_SEPARATOR = ':';

const CreateNotificationButton = ({ onClick }) => {
	return (
		<div style={{ display: 'flex' }}>
			<IconButton onClick={onClick}>
				<AddCircleFillIcon color='var(--ff-color-nokia-blue-500)' />
			</IconButton>
		</div>
	);
}

const notificationEmailRenderer = ({ value }) => {
	if (value && value.includes(EMAIL_SEPARATOR))
		return ReactHtmlParser(`<span><b>A list of users</b> will be notified.</span>`);
	else if (value)
		return ReactHtmlParser(`<span><b>${value}</b> will be notified.</span>`);
	return ReactHtmlParser('<span><b>All users</b> will be notified.</span>');
}

const ExpirationDateComponent = ({ value, setValue }) => {

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MobileDatePicker
				className='datagrid-date-filter'
				value={value}
				disablePast
				onOpen={() => {
					setTimeout(() => {
						const dateElement = document.querySelector('.MuiDialogContent-root');
						dateElement.classList.add('ag-custom-component-popup');
					}, 100);
				}}
				onChange={(newDate) => {
					setValue(newDate);
				}}
				closeOnSelect
			/>
		</LocalizationProvider>
	);
};

const NotificationFloatingPanel = (
	{
		dialogMessage, openNotificationDialog, setOpenNotificationDialog,
		title, setTitle, titleError, setTitleError,
		text, setText, textError, setTextError,
		type, setType, typeError, setTypeError,
		notificationEmailsRef, notificationEmails, setNotificationEmails,
		active, setActive,
		expirationDate, setExpirationDate,
		onSubmitNotification, mode
	}) => {

	const userEmailsList = useUserEmailsList();

	return (
		<FloatingPanel
			title={dialogMessage}
			visible={openNotificationDialog}
			setVisible={setOpenNotificationDialog}
			initialSize={{ width: 520, height: 700 }}
		>
			<div className='notification-dialog'>
				<div>
					<SelectAutoFill
						label='Type *'
						values={type}
						setValues={setType}
						data={{
							values: [{ type: TYPES.INFO }, { type: TYPES.WARNING }, { type: TYPES.ERROR }],
							mapping: { value: 'type' }
						}}
						placeholder='Choose the notification type'
						itemsCssClasses={{
							[TYPES.INFO]: 'notification-info',
							[TYPES.WARNING]: 'notification-warning',
							[TYPES.ERROR]: 'notification-error'
						}}
						error={typeError}
						setError={setTypeError}
					/>
					{
						type === TYPES.INFO &&
						<MessageIcon color='var(--color-info)' size={32} />
					}
					{
						type === TYPES.WARNING &&
						<InfoIcon color='var(--color-warning)' size={32} />
					}
					{
						type === TYPES.ERROR &&
						<CloseCircleIcon color='var(--color-danger)' size={32} />
					}
				</div>
				<TextInput
					label='Title'
					value={title}
					setValue={setTitle}
					placeholder='Set the notification title'
					required={true}
					error={titleError}
					setError={setTitleError}
				/>
				<RowSeparator />
				<RowSeparator />
				<TextWithChips
					ref={notificationEmailsRef}
					values={notificationEmails}
					setValues={setNotificationEmails}
					label='Users who will view this notification'
					placeholder='Email'
					helper='Users who can view this notification (please specify their emails, or leave blank to make it visible to everyone). Press "enter" to submit.'
					chipErrorMessage='Invalid email format'
					separators={[',', ';']}
					validator={validateEmail}
					maxHeight='6rem'
					withCopy
					withDropdownHelperMessage
					options={userEmailsList}
					itemRegExp={new RegExp("\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}\\b", "g")}

				/>
				<div>
					<TextArea
						label='Text'
						value={text}
						setValue={setText}
						maxChars={1500}
						placeholder='Set the notification text. HTML can be used.'
						required={true}
						error={textError}
						setError={setTextError}
					/>
				</div>
				<div>
					<div>
						<span>Expiration Date</span>
						<div>
							<ExpirationDateComponent
								value={expirationDate}
								setValue={setExpirationDate}
							/>
							<div style={{ display: 'inline-block', marginLeft: '0.2em' }}>
								<IconButton onClick={() => setExpirationDate(null)}>
									<EraseIcon color='var(--ff-color-tone-600)' />
								</IconButton>
							</div>
						</div>
					</div>
					<Toggle
						checked={active}
						setChecked={setActive}
						checkedText='Active'
						uncheckedText='Active'
					/>
				</div>
			</div>
			<div className='notification-submit'>
				<Button
					variant='secondary-bigger-font'
					onClick={() => setOpenNotificationDialog(false)}
				>
					<ButtonText>Cancel</ButtonText>
				</Button>
				<Button
					variant='call-to-action-bigger-font'
					onClick={onSubmitNotification}
				>
					<ButtonText>{mode}</ButtonText>
					<ButtonIcon>
						<CloudUploadIcon />
					</ButtonIcon>
				</Button>
			</div>
		</FloatingPanel>
	);
};

const NKCNotifications = () => {

	const [messages, setFetchMessages, , setMessagesHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/messages', true, { isManageMessages: true, showExpiredOrInactive: false });
	const [createNotificationSuccess, setCreateNotification, createNotificationError, , setCreateNotificationBody] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/message', false, {}, 'POST');
	const [deleteNotificationSuccess, setDeleteNotification, deleteNotificationError, , setDeleteNotificationBody] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/deletemessage', false, {}, 'POST');

	const [mode, setMode] = useState(MODES.CREATE);
	const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

	const [id, setId] = useState(null);
	const [showExpiredOrInactive, setShowExpiredOrInactive] = useState(false);

	const [title, setTitle] = useState(null);
	const [titleError, setTitleError] = useState(false);
	const [text, setText] = useState(null);
	const [textError, setTextError] = useState(false);
	const [type, setType] = useState(null);
	const [typeError, setTypeError] = useState(false);
	const [notificationEmails, setNotificationEmails] = useState([]);
	const [active, setActive] = useState(true);
	const [expirationDate, setExpirationDate] = useState(null);

	const [resultVariant, setResultVariant] = useState(null);
	const [resultMessage, setResultMessage] = useState('');
	const [openResult, setOpenResult] = useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const dataTableRef = useRef(null);
	const notificationEmailsRef = useRef(null);

	useEffect(() => {
		if (!createNotificationSuccess)
			return;

		setResultVariant('success');
		setResultMessage(createNotificationSuccess.data);
		setOpenResult(true);
		setOpenNotificationDialog(false);
		updateNotifications();
	// eslint-disable-next-line
	}, [createNotificationSuccess]);

	useEffect(() => {
		if (!createNotificationError)
			return;

		setResultVariant('error');
		setResultMessage(createNotificationError.data);
		setOpenResult(true);
	}, [createNotificationError]);

	useEffect(() => {
		if (!deleteNotificationSuccess)
			return;

		setResultVariant('success');
		setResultMessage(deleteNotificationSuccess.data);
		setOpenResult(true);
		setOpenNotificationDialog(false);
		updateNotifications();
	// eslint-disable-next-line
	}, [deleteNotificationSuccess]);

	useEffect(() => {
		if (!deleteNotificationError)
			return;

		setResultVariant('error');
		setResultMessage(deleteNotificationError);
		setOpenResult(true);
	}, [deleteNotificationError]);

	useEffect(() => {
		if (messages)
			updateNotifications();
	// eslint-disable-next-line
	}, [showExpiredOrInactive]);

	const dialogMessage = useMemo(() => {
		if (mode === MODES.CREATE)
			return 'Create a new Notification...';
		return 'Edit the Notification...';
	}, [mode]);

	const updateNotifications = () => {
		dataTableRef.current.setClientDataNullAndShowLoadingOverlay();
		setMessagesHeaders({ isManageMessages: true, showExpiredOrInactive: showExpiredOrInactive })
		setFetchMessages(true);
	}

	const onNewNotification = () => {
		setMode(MODES.CREATE);
		setId('');
		setTitle('');
		setTitleError(false);
		setText('');
		setTextError(false);
		setType('');
		setTypeError(false);
		setNotificationEmails([]);
		setActive(true);
		setExpirationDate(null);
		setOpenNotificationDialog(true);
	}

	const onEditNotification = ({ data }) => {
		setMode(MODES.EDIT);
		setId(data.id.toString());
		setTitle(data.title);
		setTitleError(false);
		setText(data.text);
		setTextError(false);
		setType(data.type);
		setTypeError(false);
		setNotificationEmails(data.notificationEmail ? data.notificationEmail.split(EMAIL_SEPARATOR) : []);
		setActive(data.isActive);
		setExpirationDate(data.expirationDate ? dayjs(data.expirationDate) : null);
		setOpenNotificationDialog(true);
	}

	const onDeleteNotification = ({ data }) => {
		setDeleteNotificationBody({
			id: data.id.toString()
		});
		setOpenDeleteDialog(true);
	}

	const onSubmitNotification = () => {
		var thereIsError = false;

		if (!title) {
			setTitleError(true);
			thereIsError = true;
		}
		if (!text) {
			setTextError(true);
			thereIsError = true;
		}
		if (!type) {
			setTypeError(true);
			thereIsError = true;
		}
		if (notificationEmailsRef.current.hasChipsError()) {
			thereIsError = true;
		}

		if (thereIsError)
			return;

		setCreateNotificationBody({
			id: id,
			title: title,
			text: text,
			type: type,
			notificationEmail: notificationEmails.join(EMAIL_SEPARATOR) ,
			active: active,
			expirationDate: expirationDate ?? '',
		});
		setCreateNotification(true);
	}

	const onSubmitDelete = () => {
		setDeleteNotification(true);
		setOpenDeleteDialog(false);
	}

	return (
		<ModuleContainer>
			<ModuleHeader
				backgroundPath={require('../../Images/BannerBackgrounds/banner-background-2.jpg')}
				backgroundPosition='50% 84%'
			>
				Notifications
			</ModuleHeader>
			<ModuleContent>
				<DataTable
					ref={dataTableRef}
					title="NKC Notifications"
					data={messages}
					columnDefinitions={{
						type: {
							type: 'status',
							headerName: '',
							width: 44,
							maxWidth: 44,
							resizable: false,
							cellRenderer: statusBarRenderer,
							cellRendererParams: {
								valueColorMap: {
									[TYPES.INFO]: 'var(--ff-color-nokia-blue-400)',
									[TYPES.WARNING]: 'var(--ff-color-yellow-400)',
									[TYPES.ERROR]: 'var(--ff-color-red-400)',
								},
								valueIconMap: {
									[TYPES.INFO]: MessageIcon,
									[TYPES.WARNING]: InfoIcon,
									[TYPES.ERROR]: CloseCircleIcon,
								}
							}
						},
						notificationEmail: {
							cellRenderer: notificationEmailRenderer,
							flex: 0.5
						},
						messageTitle: {
							headerName: 'Title',
							flex: 0.5
						},
						text: {
							flex: 1
						},
						isActive: {
							type: 'status',
							headerName: 'Active',
							width: 70,
							maxWidth: 70,
							resizable: false,
							cellRenderer: iconRenderer,
							cellRendererParams: {
								valueColorMap: {
									true: 'var(--ff-color-green-500)',
									false: 'var(--ff-color-red-500)',
								},
								valueIconMap: {
									true : StatusAvailableIcon,
									false : StatusBusyIcon,
								}
							}
						},
						expirationDate: {
							flex: 0.5,
							cellRenderer: dateCellRendererLocalTime,
							cellRendererParams: {
								defaultValue: 'Notification will not expire'
							},
						}
					}}
					columnsIgnore={['id', 'creatoremail', 'active']}
					withTableOptions={{
						onRefresh: updateNotifications,
						onFilterRemove: true,
						onExcelFile: { fileName: 'Notifications.csv'},
						onAdjustColumns: false
					}}
					withRowClick={onEditNotification}
					withRowOptions={[
						{
							icon: <EditIcon />,
							tooltip: 'Edit',
							callback: onEditNotification,
						},
						{
							icon: <DeleteIcon />,
							tooltip: 'Delete',
							callback: onDeleteNotification,
							renderCondition: ({ data }) => data.isActive
						}
					]}
					withSearch
					withFilters
					withAdditionalComponents={{
						searchbar: <CreateNotificationButton onClick={onNewNotification} />,
						toolbar: <Toggle
							checked={showExpiredOrInactive}
							setChecked={setShowExpiredOrInactive}
							checkedText='Currently Inactive or Expired'
							uncheckedText='Currently Active'
						/>
					}}
					noRowsMessage='There are currently no Notifications created'
				/>
				
				<Message
					title="NKC Notifications"
					variant={resultVariant}
					message={resultMessage}
					open={openResult}
					setOpen={setOpenResult}
					actionButtonLabel="OK"
					onSubmit={() => setOpenResult(false)}
				/>
				<Message
					title="Delete Notification"
					message="Are you sure you want to deactivate this notification?"
					actionButtonLabel="Yes"
					cancelButtonLabel="No"
					variant='error'
					open={openDeleteDialog}
					setOpen={setOpenDeleteDialog}
					onSubmit={onSubmitDelete}
				/>
			</ModuleContent>
			<NotificationFloatingPanel
				className='notification-dialog'
				dialogMessage={dialogMessage}
				openNotificationDialog={openNotificationDialog}
				setOpenNotificationDialog={setOpenNotificationDialog}
				title={title}
				setTitle={setTitle}
				titleError={titleError}
				setTitleError={setTitleError}
				text={text}
				setText={setText}
				textError={textError}
				setTextError={setTextError}
				type={type}
				setType={setType}
				setTypeError={setTypeError}
				typeError={typeError}
				notificationEmailsRef={notificationEmailsRef}
				notificationEmails={notificationEmails}
				setNotificationEmails={setNotificationEmails}
				active={active}
				setActive={setActive}
				expirationDate={expirationDate}
				setExpirationDate={setExpirationDate}
				onSubmitNotification={onSubmitNotification}
				mode={mode}
			/>
		</ModuleContainer>
	);
	
}

export default NKCNotifications;
