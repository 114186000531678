import React, { useState } from 'react';

import { ClickAwayListener } from '@mui/base';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Label from '@nokia-csf-uxr/ccfk/Label';
import { SelectItemLabelContent } from '@nokia-csf-uxr/ccfk/SelectItem';
import List from '@nokia-csf-uxr/ccfk/List';
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/SettingsIcon';

const SelectMultipleButton = (props) => {

	const [open, setOpen] = useState(false);

	const {
		label = '',
		values,
		setValues,
		data,
		disabled = false,
		width = '20rem',
		Icon = SettingsIcon,
		defaultIconColor,
		id = null,
		error = false,
		withLabel = true,
		tooltip = "",
		onChange = () => { }
	} = props;
	
	const handleButtonClick = () => {
		setOpen(!open);
	}

	const getIconColor = () => {
		if (error)
			return 'var(--g-color-status-critical)';
		else if (defaultIconColor)
			return defaultIconColor;
		else
			return 'var(--g-color-global-secondary-content)';
	}

	const valueColumn = data.mapping === undefined ? "value" : data.mapping.value;

	const isTooltipDisabled = tooltip === "" || tooltip === undefined || tooltip === null;

	const allValues = data.values.map((entry, index) => entry.value);

	const isChecked = (v) => values && values.includes(v);

	const isAllChecked = () => {
		var result = true;

		allValues.forEach(v => result &= values.includes(v));

		return result;
	}

	const handleOnChange = (e) => {
		var newValues = values.includes(e.target.name) ? values.filter(v => v !== e.target.name) : [...values, e.target.name];

		setValues(newValues);
		onChange(newValues);
	}

	const handleOnChangeAll = () => {
		var newValues;

		if (isAllChecked()) {
			newValues = [];
		} else {
			newValues = allValues;
		}

		setValues(newValues);
		onChange(newValues);
	}

	return (
		<div>
			{
				withLabel &&
				<Label verticalLayout>
					<SelectItemLabelContent>{label}</SelectItemLabelContent>
				</Label>
			}
			<Tooltip disable={isTooltipDisabled} closeOnOutOfBoundaries={false} placement="top" modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]} trigger="hover" tooltip={tooltip} >
				<IconButton id={id} aria-label="settings" onClick={handleButtonClick} disabled={disabled}>
					<Icon color={getIconColor()} />
				</IconButton>
			</Tooltip>
			{open &&
				<ClickAwayListener onClickAway={() => { setOpen(false); }}>
					<List style={{ width: width, zIndex: 10, position: "absolute" }} onBlur={() => { }}>
						{data.values && data.values.map((entry, index) => {
							return (
								<li key={'li-'+entry[valueColumn]}>
									{
										<Checkbox
											name={entry[valueColumn]}
											size='small'
											icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
											checkedIcon={<CheckBoxIcon fontSize="small" />}
											style={{ marginRight: 8 }}
											checked={isChecked(entry[valueColumn])}
											onChange={handleOnChange}
										/>
									}
									{
										entry[valueColumn]
									}
								</li>
							);
						})}
						<HorizontalDivider />
						<li key={'li-all'}>
							{
								<Checkbox
									name={'All'}
									size='small'
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={isAllChecked()}
									onChange={handleOnChangeAll}
								/>
							}
							{
								"All"
							}
						</li>
					</List>
				</ClickAwayListener>
			}
		</div>
	);

}

export default SelectMultipleButton;