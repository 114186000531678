import React from 'react';
import { useCoachmarks } from '../CustomHooks/Coachmarks';
import { LaunchPad, Carousel, BottomSection } from '../Components';
import TipCoachmark from '../Components/Common/TipCoachmark';

import background1 from '../Images/ModulesBackgrounds/Multi-coloured_Cable_Twist_Cut-out.jpg';
import background2 from '../Images/ModulesBackgrounds/Robot_Frog_Cut-out.png';
import background3 from '../Images/ModulesBackgrounds/Background-1.jpg';

import '../Styles/Home.css';

const images = [
    {
        image: background1,
        backgroundSize: 'contain',
        backgroundPosition: '0em 15em'
    },
    {
        image: background2,
        backgroundSize: 'cover',
        backgroundPosition: '0em 6em'
    },
    {
        image: background3,
        backgroundSize: 'cover',
        backgroundPosition: '10em 0em'
    },
];

const imageSelected = images[Math.floor(Math.random() * images.length)];

export const Home = () => {
    const { coachmarks } = useCoachmarks('home');

    return (
        <div
            className="home-container"
            style={
                {
                    backgroundImage: `url(${imageSelected.image})`,
                    backgroundSize: imageSelected.backgroundSize,
                    backgroundPosition: imageSelected.backgroundPosition
                }
            }
        >
            {coachmarks && coachmarks.length > 0 && <TipCoachmark identifier={coachmarks[0].id} steps={coachmarks} />}
            <Carousel variant="default"/>
            <LaunchPad variant="default-custom"/>
            <BottomSection className="home-bottom-section"/>
        </div>
    );
}
