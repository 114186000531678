import React, { useCallback, useMemo } from 'react';

import { ModuleBase, ToggleButtonGroup } from "../../Components/Common";
import { ScheduleLabButton, ScheduleLabOverlayPanel } from '../../Components/Labs';

import { useURLParamsAction } from '../../CustomHooks/URLParams';
import { useUser, useIsAdmin, useIsRole } from '../../CustomHooks/Global';
import {
	MODES,
	RESERVATION_STATUS,
	useLabReservations,
	useScheduleLabFields,
	useLabRoles,
	useLabTrainings,
	useUserAvailableSlots,
	useLabOwners,
	useLabKPMs,
	useSechuleLabActions,
	useLabSingleReservation
} from '../../CustomHooks/LabScheduler';
import { useCoachmarks } from '../../CustomHooks/Coachmarks';

import ModuleBaseObj, { Coachmarks } from '../../CustomObjects/Module';
import { evalExpression } from '../../CustomObjects/Utils';

import { statusBarRenderer, dateCellRendererLocalizedDayOnly } from '../../Renderers/DataTable';

import CalendarScheduleIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarScheduleIcon';
import ActiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/ActiveIcon';
import DeleteForeverIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteForeverIcon';
import InactiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/InactiveIcon';
import ViewIcon from '@nokia-csf-uxr/ccfk-assets/latest/ViewIcon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const ScheduleLab = () => {

	const user = useUser();
	const isAdmin = useIsAdmin();
	const isLabLead = useIsRole('labLead');
	
	const fields = useScheduleLabFields();

	const {
		userLabReservations,
		updateLabReservations,
		onGridReady,
		onKeywordChange,
		fetchDataForCSV
	} = useLabReservations(fields);
	const { isALabOwner } = useLabOwners();
	const { isALabKPM } = useLabKPMs();
	const { labRoles } = useLabRoles(isALabOwner, isALabKPM);
	const { labTrainings, updateLabTrainings, getTrainingDetails, resetLabTrainings } = useLabTrainings(isALabOwner, isALabKPM);
	const { getUserSlots } = useUserAvailableSlots(getTrainingDetails, fields);
	const { onSubmitLabAction } = useSechuleLabActions(fields, updateLabReservations);
	const { labReservation, getLabReservation } = useLabSingleReservation();
	const { groupedCoachmarks } = useCoachmarks('schedulelab');

	const onScheduleLabClick = useCallback(() => {
		resetLabTrainings();

		fields.reset();
		fields.setMode(MODES.SCHEDULE);
		fields.setCurrentLabData({});
		fields.setPanelVisible(true);
	}, [fields, resetLabTrainings]);

	const onEditClicked = useCallback(({ data }) => {
		fields.reset();
		fields.setMode(MODES.EDIT);
		fields.setCurrentLabData(data);
		fields.setPanelVisible(true);
	}, [fields]);

	useURLParamsAction(
		['id'],
		labReservation,
		({ id }) => {
			if (!id) return;
			fields.messageRef.current.showProcessingDiag();
			getLabReservation(id);
		},
		() => {
			fields.messageRef.current.hideProcessingDiag();
			if (labReservation &&
				labReservation.length > 0 &&
				(
					labReservation[0].userEmail === user.email ||
					(labReservation[0].labOwners && labReservation[0].labOwners.includes(user.email)) ||
					isAdmin ||
					isLabLead
				)
			)
				onEditClicked({ data: labReservation[0] });
		}
	);

	useURLParamsAction(
		['create'],
		null,
		onScheduleLabClick
	);
	
	const moduleBaseObj = useMemo(() => {

		const newModuleBaseObj = new ModuleBaseObj(fields.messageRef);

		newModuleBaseObj.moduleHeader.bannerPath = require('../../Images/BannerBackgrounds/banner-background-8.jpg');
		newModuleBaseObj.moduleHeader.bannerPosition = "50% 35%";
		newModuleBaseObj.moduleHeader.title = "My Bookings";
		newModuleBaseObj.dataTable.ref = fields.datatableRef;
		newModuleBaseObj.dataTable.data = userLabReservations;
		
		newModuleBaseObj.dataTable.columnDefinitions = {
			status: {
				headerName: '',
				type: 'status',
				width: 44,
				maxWidth: 44,
				resizable: false,
				cellRenderer: statusBarRenderer,
				cellRendererParams: {
					valueColorMap: {
						[RESERVATION_STATUS.BOOKED]: 'var(--ff-color-nokia-blue-500)',
						[RESERVATION_STATUS.ACTIVE]: 'var(--ff-color-green-500)',
						[RESERVATION_STATUS.CANCELED]: 'var(--ff-color-red-500)',
						[RESERVATION_STATUS.INACTIVE]: 'var(--ff-color-purple-500)',
					},
					valueIconMap: {
						[RESERVATION_STATUS.BOOKED]: CalendarScheduleIcon,
						[RESERVATION_STATUS.ACTIVE]: ActiveIcon,
						[RESERVATION_STATUS.CANCELED]: DeleteForeverIcon,
						[RESERVATION_STATUS.INACTIVE]: InactiveIcon,
                    },
				}
			},
			displayName: {
				headerName: 'Certification Name',
			},
			labAccessType: {
				flex: 0.5
			},
			startDate: {
				type: 'date',
				cellRenderer: dateCellRendererLocalizedDayOnly,
				flex: 0.6
			},
			endDate: {
				type: 'date',
				cellRenderer: dateCellRendererLocalizedDayOnly,
				cellRendererParams: { defaultValue: 'Ongoing' },
				flex: 0.6
			}
		};
		newModuleBaseObj.dataTable.tableOptions.onRefresh = updateLabReservations;
		newModuleBaseObj.dataTable.tableOptions.onExcelFile.fileName = 'LabReservations.csv';
		newModuleBaseObj.dataTable.tableOptions.onExcelFile.columns = [
			"id", "status", "userId", "userEmail", "startDate", "endDate", "firstName", "lastName", "courseNumber",
			"courseDisplayName", "courseRole", "labOwners", "labName", "labType", "labMaterialsURL",
			"awsCatalogUUID", "awsRegionUUID", "userLimit", "duration", "isOpenAccess", "isBookableByTrainees",
			"isBookableByLabOwners", "waTemplate", "isDeleted", "createDate",
			"modDate", "deleteDate", "modNokiaUserId"
		];
		newModuleBaseObj.dataTable.rowOptions = [
			{
				icon: <ViewIcon />,
				tooltip: 'Details',
				callback: onEditClicked
			}
		];
		newModuleBaseObj.dataTable.columnsIgnore = [
			'id', 'userid', 'firstname', 'lastname', 'coursenumber', 'labowners', 'labmaterialsurl', 'watemplatesjson',
			'awscataloguuid', 'awsregionuuid', 'userLimit', 'duration', 'isOpenAccess',
			'isbookablebytrainees', 'isbookablebylabowners', 'labcredentialsjson', 'waTemplate', 'propertiesjson',
			'isdeleted', 'createDate', 'moddate', 'deletedate', 'modnokiauserid'
		];
		newModuleBaseObj.dataTable.noRowsMessage = `${user.firstName} ${user.lastName} has no lab reservations...`;

		newModuleBaseObj.dataTable.serverInfiniteRow.onGridReady = onGridReady;
		newModuleBaseObj.dataTable.serverInfiniteRow.onKeywordChange = onKeywordChange;
		newModuleBaseObj.dataTable.serverInfiniteRow.onExcelFile = fetchDataForCSV;

		newModuleBaseObj.dataTable.additionalComponents.searchbar = <ScheduleLabButton
			onScheduleLabClick={onScheduleLabClick}
		/>;
		newModuleBaseObj.dataTable.additionalComponents.toolbar = <ToggleButtonGroup
			value={fields.showActive}
			setValue={fields.setShowActive}
			buttonProps={[
				{
					value: true,
					icon: <EventAvailableIcon style={{ color: 'var(--ff-color-green-600)', fontSize: '22' }} />,
					selectedColor: 'var(--ff-color-green-600)',
					tooltip: 'Booked and Active Reservations',
				},
				{
					value: false,
					icon: <EventBusyIcon style={{ color: 'var(--ff-color-red-500)', fontSize: '22' }} />,
					selectedColor: 'var(--ff-color-red-500)',
					tooltip: 'Expired or Cancelled Reservations',
				}
			]}
			buttonColorProps={{
				selected: 'green'
			}}
		/>;

		newModuleBaseObj.dataTable.rowClick = onEditClicked;
		newModuleBaseObj.overlayPanel.component = <ScheduleLabOverlayPanel
			fields={fields}
			labRoles={labRoles}
			labTrainings={labTrainings}
			updateLabTrainings={updateLabTrainings}
			getUserSlots={getUserSlots}
			resetLabTrainings={resetLabTrainings}
		/>

		newModuleBaseObj.messages.title = 'Lab Reservations';
		newModuleBaseObj.messages.submitAreYouSureMessage = onSubmitLabAction;
		
		groupedCoachmarks.forEach(coachmarks => {
			newModuleBaseObj.addCoachmarks(new Coachmarks(
				null,
				coachmarks[0].id,
				coachmarks,
				parseInt(coachmarks[0].delay),
				evalExpression(coachmarks[0].condition, { fields, user, RESERVATION_STATUS })
			))
		});
		
		return newModuleBaseObj;
	}, [
		user,
		fields,
		userLabReservations,
		updateLabReservations,
		onGridReady,
		onKeywordChange,
		fetchDataForCSV,
		onScheduleLabClick,
		labRoles,
		labTrainings,
		updateLabTrainings,
		getUserSlots,
		onEditClicked,
		onSubmitLabAction,
		resetLabTrainings,
		groupedCoachmarks,
	]);

	return (
		<ModuleBase
			messageRef={fields.messageRef}
			moduleBaseObj={moduleBaseObj}
		/>
	);

}

export default ScheduleLab;
