import React from 'react';

import CircularProgressIndicatorIndeterminate from '@nokia-csf-uxr/ccfk/CircularProgressIndicatorIndeterminate';
import LinearProgressIndicatorIndeterminate from '@nokia-csf-uxr/ccfk/LinearProgressIndicatorIndeterminate';

import '../../Styles/Loading.css';

export const CircularLoading = ({ size, overlay, ...remainProps }) => {

    if (overlay)
        return (
            <div className='loading-progress-container-overlay' style={{ height: size, width: size, margin: 'auto' }} {...remainProps}>
                <CircularProgressIndicatorIndeterminate strokeWidth={5} />
            </div>
        );

    return (
        <div style={{ height: size, width: size, margin: 'auto' }} {...remainProps}>
            <CircularProgressIndicatorIndeterminate variant='default-custom' strokeWidth={5} />
        </div>
    );
}
CircularLoading.defaultProps = {
    size: 32,
    overlay: false,
}

export const LinearLoading = (props) => {

    return (
        <div {...props}>
            <LinearProgressIndicatorIndeterminate thickness="max" />
        </div>
    );
}