export const GetOpts = (params = {}, method = 'GET', body = null, isJson = true, credentials = false) => {

	var headers = {
		"Accept": "application/json"
	};
	if (isJson) headers["Content-Type"] = "application/json";

	for (const key in params) {
		headers[key] = params[key];
	}

	var options = {
		method: method,
		mode: 'cors',
		headers: new Headers(headers),
		credentials: credentials ? 'include' : 'omit'
	}

	if (body && isJson) options.body = JSON.stringify(body);
	else if (body) options.body = body;

	return options;
};

export const FetchRequest = async (url, options) => {
	var fetchResponse;

	return fetch(url, options)
		.then(response => {
			fetchResponse = response;
			if (fetchResponse.status === 401) {
				return {
					data: null,
					status: 401,
					error: "Unauthorized"
				}
			}
			return response.json();
		})
		.then(data => {
			return {
				data: fetchResponse.ok ? data : null,
				status: fetchResponse.status,
				error: !fetchResponse.ok ? data : null
			}
		}).catch(error => {
			return {
				data: null,
				status: 500,
				error: error
			}
		});
}