import React, { forwardRef, useState, useRef, useImperativeHandle } from "react";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import dayjs from 'dayjs';

import '../../Styles/DateFilter.css';

const DateFilter = forwardRef((props, ref) => {

    const [filterDate, setFilterDate] = useState(null);

    const dateRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getDate() {
            return dateRef.current;
        },
        setDate(date) {
            dateRef.current = date;
            if (!date)
                setFilterDate(null);
            else
                setFilterDate(dayjs(date));
        },
    }));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                className='datagrid-date-filter'
                value={filterDate}
                onOpen={() => {
                    setTimeout(() => {
                        const dateElement = document.querySelector('.MuiDialogContent-root');
                        dateElement.classList.add('ag-custom-component-popup');
                    }, 100);
                }}
                onChange={(newDate) => {
                    dateRef.current = new Date(newDate);
                    setFilterDate(newDate);
                    props.onDateChanged();
                }}
                closeOnSelect
            />
        </LocalizationProvider>
    );
});

export default DateFilter;