import React, { useState, useRef, useEffect } from 'react';

import _uniqueId from 'lodash/uniqueId';

import Label from '@nokia-csf-uxr/ccfk/Label';
import SelectItem, { SelectItemLabelContent, SelectItemButton, SelectItemClearButton, SelectListItem, SelectItemText } from '@nokia-csf-uxr/ccfk/SelectItem';
import Chip, { ChipLabel, ChipIconButton } from '@nokia-csf-uxr/ccfk/Chip';
import KEY_CODES from '@nokia-csf-uxr/ccfk/common/KeyCodes';
import ArrowTriangleDownIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowTriangleDownIcon';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';

import '../../Styles/Select.css';

const SelectComponent = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);
	const buttonRef = useRef(null);

	const { label = '', values, setValues, placeholder = '', multiSelectTextSize = 3, disabled = false, required = false, error = false, change = () => { }, id = null } = props;

	const data = props.data == null || props.data.values == null ? [] : props.data.values;
	const valueColumn = props.data.mapping === undefined ? 'value' : props.data.mapping.value;
	const textColumn = props.data.mapping === undefined ? 'text' : (props.data.mapping.text === undefined ? props.data.mapping.value : props.data.mapping.text);

	const labelId = `select-label-${_uniqueId()}`;

	const CHIP_STYLE = {
		style: {
			margin: '0.1875rem 0.125rem 0.125rem 0.125rem',
		}
	};

	const useMultiSelectText = values.length > multiSelectTextSize;

	const ariaString = () => values.map((item) => { return item }).toString();
	const getIndex = (v) => values.findIndex(value => value === v);
	const haveValues = values.length > 0;

	const isSelectionKeyPressed = (key) => {
		return key && (key === KEY_CODES.ENTER_KEY || key === KEY_CODES.SPACE_KEY);;
	};

	const renderClearButton = () => {
		return (
			<SelectItemClearButton
				aria-label="Clear selected values"
				onClick={() => { setValues([]); buttonRef.current && buttonRef.current.focus(); }}
			/>
		);
	};

	useEffect(() => {
		change();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	// handle list item selection and close dropdown list after item is selected
	const handleEvent = (value) => (event) => {
		const { type } = event;
		if ((type === 'keydown' && isSelectionKeyPressed(event.key)) || type === 'click') {
			if (values.includes(value)) {
				removeChip(value);
			} else {
				setValues([...values, value]);
			}

			setIsOpen(false);
		}
	};

	// remove closed chip from selected values
	const handleChipClose = (value) => () => {
		removeChip(value);
	};

	const removeChip = (value) => {
		const valueIndex = getIndex(value);
		if (valueIndex > -1) {
			const clonedValues = [...values];
			clonedValues.splice(valueIndex, 1);
			setValues(clonedValues);
			buttonRef.current && buttonRef.current.focus();
		}
	}

	const renderSelect = () => {

		return (
			<SelectItemButton
				id={id}
				ref={buttonRef}
				placeholder={placeholder}
				dropdownIcon={<ArrowTriangleDownIcon />}
				inputProps={{ value: haveValues ? ariaString() : placeholder }}
				renderClearButton={!disabled && haveValues && isOpen ? renderClearButton : undefined} // only show ClearButton when there is at least 1 item selected and the menu is opened
				role="combobox"
				aria-label={haveValues ? ariaString() : placeholder}
				aria-expanded={isOpen}
				{...props}
			>
				{haveValues &&
					<>
						{useMultiSelectText && `(${values.length} selected)`}
						{!useMultiSelectText && values.map((item, x) => {
							return (
								<Chip
									key={`chip-${item}-${x}`}
									tabIndex={0}
									role="comment"
									size="small"
									aria-label={item}
									{...CHIP_STYLE}
									disabled={disabled}
									onClick={(event) => { event.stopPropagation(); }}
									onKeyDown={(event) => {
										if (event.key === KEY_CODES.BACKSPACE || event.key === KEY_CODES.DELETE) {
											handleChipClose(item)();
										}
									}}
								>
									<ChipLabel label={item} />
									{
										!disabled && <ChipIconButton tabIndex={0} aria-label={`remove ${item}`} onClick={handleChipClose(item)}><CloseIcon /></ChipIconButton>
									}
								</Chip>
							);
						})}
					</>
				}
			</SelectItemButton>
		)
	}

	return (
		<div className='sc-div'>
			<Label id={labelId} verticalLayout>
				<SelectItemLabelContent requiredSymbol='*' required={required}>{label}</SelectItemLabelContent>
			</Label>
			<SelectItem
				aria-labelledby={labelId}
				ref={ref}
				error={error}
				disabled={disabled}
				isOpen={isOpen}
				onOpen={() => { setIsOpen(true && !disabled) }}
				onClose={() => { setIsOpen(false) }}
				truncateListText={true}
				listProps={{
					ulProps: { role: "listbox", id: "selectitem-dropdown-list" },
					elevationProps: { elevationIndex: 4 }
				}}
				menuProps={{ positioningOptions: { modifiers: [{ name: 'offset', options: { offset: [0, 4] } }] } }}
				renderSelectItemBase={renderSelect}
			>
				{data.map((entry, index) => {
					return (
						<SelectListItem
							key={"sli-" + index}
							id={"sli-" + index}
							selected={getIndex(entry[valueColumn]) > -1}
							onClick={handleEvent(entry[valueColumn])}
							onKeyDown={handleEvent(entry[valueColumn])}
							role="option"
						>
							<SelectItemText>{entry[textColumn] ? entry[textColumn] : entry[valueColumn]}</SelectItemText>
						</SelectListItem>
					);
				})}
			</SelectItem>
		</div>
	);
}

export default SelectComponent;
