import React, { useState, useEffect } from 'react';
import MarkdownStyled from './MarkdownStyled';

//Even though delay can be lowered to 0, browsers effectively set the minimum display rate at 4ms.
//Adding the step parameter, allows us a faster display rate.
const TypingAnimationMarkdown = ({ text, delay, step=1, onAnimationComplete }) => {
    const [displayedText, setDisplayedText] = useState('');
    const useStep = step < 1 ? 1 : step;

    useEffect(() => {
        setDisplayedText('');
        let index = 0;
        const intervalId = setInterval(() => {
            if (index >= text.length) {
                clearInterval(intervalId);
                onAnimationComplete();
                return;
            }
            const newText = text.substring(index, index + useStep);
            setDisplayedText((prevText) => prevText + newText);
            index += useStep;
        }, delay);
        return () => clearInterval(intervalId);
    // eslint-disable-next-line
    }, [text]);

    return (
        <MarkdownStyled>
            {displayedText}
        </MarkdownStyled>
    );
}
TypingAnimationMarkdown.defaultProps = {
    delay: 100,
    onAnimationComplete: () => { }
};

export default TypingAnimationMarkdown;
