
import React from "react";

import {
    ModuleContainer,
    ModuleHeader,
    TreeView,
    MessageBundle,
    TextWithChips,
} from "../Components/Common";

import KPIPlanningOverlayPanel from "../Components/KPIPlanning/KPIPlanningOverlayPanel";
import OrgTreeLabel from "../Components/KPIPlanning/OrgTreeLabel";
import KPIPlanningToolBar from "../Components/KPIPlanning/KPIPlanningToolBar";

import {
    useKPIPlanningFields,
    useOrgTreeJSON,
    useKPIsSummary,
    useOrgTreeEnrichmentWithKPIs,
    useHeadCountTypes,
    useKPIs,
    useKPIPlanningSubmission,
    useKPIReport,
    useKPIConfig,
} from "../CustomHooks/KPIPlanning";

import SearchCircleIcon from '@nokia-csf-uxr/ccfk-assets/latest/SearchCircleIcon';

import '../Styles/KPIPlanning.css';

const KPIPlanning = () => {

    const fields = useKPIPlanningFields();
    const { orgTreeJSONData } = useOrgTreeJSON();
    const { kpisSummaryData, getKpisSummaryData } = useKPIsSummary();
    const { filteredOrgTreeEnriched, filterOrgTree } = useOrgTreeEnrichmentWithKPIs(fields, orgTreeJSONData, kpisSummaryData);
    const { headCountTypesData, availableHeadCountTypes, getHeadCountTypeByLongName, getHeadCountTypeByName } = useHeadCountTypes(fields);
    const { kpisData, getKpiByName, getKpiByKpiId } = useKPIs();
    const { submitKpiPlanning } = useKPIPlanningSubmission(fields, getKpiByName, getHeadCountTypeByLongName, getKpisSummaryData);
    const { kpiReport, getKPIReport } = useKPIReport();
    useKPIConfig(fields);
    
    return (
        <ModuleContainer>
            <ModuleHeader
                backgroundPath={require('../Images/BannerBackgrounds/banner-background-10.jpg')}
                backgroundPosition='50% 69%'
                //titleColor='var(--ff-color-green-900)'
            >
                Training & Certification KPI Planning
            </ModuleHeader>
            <div className='kpi-planning-content'>
                <div className= 'kpi-planning-search-content'>
                    <TextWithChips
                        values={fields.searchTerms}
                        setValues={fields.setSearchTerms}
                        onChange={filterOrgTree}
                        inputIcon={<SearchCircleIcon color='var(--ff-color-tone-500)' />}
                        placeholder='Search...'
                        underlined
                    />
                </div>
                <KPIPlanningToolBar
                    fields={fields}
                    onCollapseTeams={() => fields.treeRef.current.collapseTree()}
                    filterOrgTree={filterOrgTree}
                    kpiReport={kpiReport}
                    getKPIReport={getKPIReport}
                />
                <div className='kpi-planning-tree-content'>
                    <TreeView
                        ref={fields.treeRef}
                        data={filteredOrgTreeEnriched}
                        nameKey='T'
                        childrenKey='C'
                        value={fields.kpiTeam}
                        setValue={fields.setKpiTeam}
                        label={{
                            component: OrgTreeLabel,
                            componentParams: { fields, getKpiByKpiId, getHeadCountTypeByName }
                        }}
                        noDataMessage='No organizations are available...'
                    />
                </div>
                <KPIPlanningOverlayPanel
                    fields={fields}
                    headCountTypesData={headCountTypesData}
                    kpisData={kpisData}
                    headCountTypesAvailable={availableHeadCountTypes}
                    getHeadCountTypeByLongName={getHeadCountTypeByLongName}
                />
                <MessageBundle
                    ref={fields.messageRef}
                    title='KPI Planning'
                    onSubmitAreYouSure={submitKpiPlanning}
                />
            </div>
        </ModuleContainer>
    );
};

export default KPIPlanning;