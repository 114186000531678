import React from 'react';
import { DEFAULT_DURATION } from '../../CustomHooks/KTSessions';
import { rangeArray } from '../../CustomObjects/Utils';

import { TextInput, Slider, RowSeparator } from "../Common";

const durationLabels = [
	{
		value: 15,
		label: '15 Minutes'
	},
	...rangeArray(30, 60, 15).map(m => ({
		value: m,
	})),
	...rangeArray(90, 420, 60).map(m => ({
		value: m,
	})),
	{
		value: 480,
		label: '8 Hours'
	},
];

const PresenterDialog = ({ inputFields }) => {

	return (
		<>
			<TextInput
				autoFocus
				label={'Email:'}
				value={inputFields.newPresenter}
				setValue={inputFields.setNewPresenter}
				showClearButton={false}
			/>
			<RowSeparator />
			<Slider
				label='Duration:'
				value={inputFields.newPresenterDuration}
				setValue={inputFields.setNewPresenterDuration}
				min={DEFAULT_DURATION}
				max={480}
				marks={durationLabels}
			/>
		</>
	)
}

export default PresenterDialog;