import React from "react";

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import ReactHtmlParser from 'html-react-parser';

import List, {
    ListItemBasic,
    ListItemTextContainer,
    ListItemText,
    ListItemSubText,
    ListItemActions,
} from '@nokia-csf-uxr/ccfk/List';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton'; 

import '../../Styles/ItemsList.css';

const ItemsList = ({ title, items, selectedItem, setSelectedItem, withActions, onClick }) => {

    const listItemStyleProps = {
        style: {
            height: '3.75rem',
            //minHeight: '3.75rem',
            //maxHeight: '3.75rem',
        }
    };

    const listItemTextStyleProps = {
        style: {
            paddingLeft: '1rem',
            paddingRight: 0,
            paddingBottom: '0.0625rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    };

    const listItemSubTextStyleProps = {
        style: {
            ...listItemTextStyleProps.style,
        }
    };

    const getListItemCssClass = (item) => {
        if (item.id === selectedItem.id)
            return 'selected';
    }

    return (
        <List variant="selectable" className='items-list'>
            {
                title &&
                <Typography className="title">{title}</Typography>
            }
            {
                items && items.length > 0 &&
                items.map((item, i) => {
                    return (
                        <ListItemBasic
                            key={i}
                            {...listItemStyleProps}
                            onClick={() => {
                                onClick(item);
                                setSelectedItem({ ...item, id: i })
                            }}
                            className={getListItemCssClass({ ...item, id: i })}
                            disabled={item.disabled ?? false}
                        >
                            {item.icon}
                            <ListItemTextContainer>
                                <ListItemText {...listItemTextStyleProps}>{item.text ? ReactHtmlParser(item.text) : ''}</ListItemText>
                                <ListItemSubText {...listItemSubTextStyleProps}>{item.subText ? ReactHtmlParser(item.subText) : ''}</ListItemSubText>
                            </ListItemTextContainer>
                            {
                                withActions &&
                                <ListItemActions>
                                    {
                                        withActions.map((action, index) =>
                                            <Tooltip key={index} placement="top" tooltip={action.tooltip}>
                                                <IconButton onClick={() => action.callback(item)}>
                                                    {action.icon}
                                                </IconButton>
                                            </Tooltip>
                                        )  
                                    }
                                </ListItemActions>
                            }
                        </ListItemBasic>
                    )
                })
            }
        </List>
    );

}
ItemsList.defaultProps = {
    selectedItem: {},
    setSelectedItem: () => { },
    onClick: () => { },
}

export default ItemsList;