const Env = {
    BACKEND_SERVER_URL: process.env.REACT_APP_BACKEND_SERVER_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    AUTHORITY: process.env.REACT_APP_AUTHORITY,
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
    FRONTEND_SERVER_URL: process.env.REACT_APP_FRONTEND_SERVER_URL,
    NKC_SERVER_URL: process.env.REACT_APP_NKC_SERVER_URL,
    LEGACY_NKC_SERVER_URL: process.env.REACT_APP_LEGACY_NKC_SERVER_URL,
    NKC_TEST_SERVER_URL: process.env.REACT_APP_NKC_TEST_SERVER_URL,
    MOCK_AUTHENTICATION: process.env.REACT_APP_MOCK_AUTHENTICATION === 'true',
    MOCK_USER_EMAIL: process.env.REACT_APP_MOCK_USER_EMAIL ? process.env.REACT_APP_MOCK_USER_EMAIL : null,
    MAX_NUMBER_FETCH_RETRIES: process.env.REACT_APP_MAX_NUMBER_FETCH_RETRIES,
    AUTHENTICATION_STORAGE: process.env.REACT_APP_AUTHENTICATION_STORAGE,
};

export default Env;