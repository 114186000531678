import React, { useMemo, useEffect, useCallback } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { CircularLoading } from './Loading';

import '../../Styles/MuiAutoComplete.css';

const filter = createFilterOptions();

const SelectAutoFill = (
    {
        id,
        values,
        setValues,
        label,
        placeholder,
        data,
        limitTags,
        disabled,
        error,
        setError,
        onChange,
        hideClearButton,
        itemsCssClasses,
        withFreeText,
        maxNumberOfOptions
    }
) => {

    const isMultiple = useMemo(() => Array.isArray(values), [values]);

    const internalValues = useMemo(() => {
        if (!data || !data.mapping || !data.mapping.value || !values)
            return null;

        if (isMultiple)
            return values.map(val => ({ [data.mapping.value]: val }));
        return { [data.mapping.value]: values };
    }, [values, data, isMultiple]);

    const getCssClasses = useCallback(() => {
        var classes = 'select-auto-fill-container min-height-smaller';
        if (disabled) classes = classes + ' disabled';
        if (error) classes = classes + ' external-error';
        if (isMultiple) classes = classes + ' multiple';

        return classes;
    }, [error, isMultiple, disabled]);

    const getItemCssClass = useCallback((value) => {
        if (itemsCssClasses && value in itemsCssClasses)
            return ' ' + itemsCssClasses[value];
        return '';
    }, [itemsCssClasses]);

    const handleOnChange = (_, value) => {
        setError(false);

        if (!data || !data.mapping || !data.mapping.value || !value) {
            setValues(null);
            return;
        }

        if (isMultiple)
            setValues(value.map(opt => opt[data.mapping.value]));
        else
            setValues(value[data.mapping.value]);
    }

    const valueIsValid = (option, value) => {
        return option[data.mapping.value] === value[data.mapping.value];
    }
    
    useEffect(() => {
        onChange();
        // eslint-disable-next-line
    }, [values]);

    return (
        <Autocomplete
            id={id}
            fullWidth
            multiple={isMultiple}
            limitTags={!disabled ? limitTags: -1}
            size="small"
            value={internalValues}
            disabled={disabled}
            className={getCssClasses()}
            disableClearable={hideClearButton}
            options={data && data.values ? data.values : []}
            freeSolo={withFreeText && !isMultiple}
            disableCloseOnSelect={isMultiple}
            getOptionLabel={(option) => {
                if (data && data.mapping && data.mapping.value)
                    return option[data.mapping.value];
                else
                    return null;
            }}
            renderOption={(props, option, { selected }) => {
                if (data && data.mapping && data.mapping.value && option[data.mapping.value]) {
                    return (
                        <li {...props} className={props.className + getItemCssClass(option[data.mapping.value])}>
                            {
                                isMultiple &&
                                <Checkbox
                                    size='small'
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                            }
                            {
                                option[data.mapping.value]
                            }
                        </li>
                    )
                }

                return null;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={!disabled ? (!data.values ? 'Loading...' : placeholder) : null}
                    onChange={(e) => {
                        if (withFreeText && !isMultiple) {
                            setError(false);
                            setValues(e.target.value);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {!data.values ? <CircularLoading size={28} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            filterOptions={(options, params) => {
                var filtered = filter(options, params);
                if (maxNumberOfOptions)
                    filtered = filtered.slice(0, maxNumberOfOptions);

                return filtered;
            }}
            onChange={handleOnChange}
            isOptionEqualToValue={valueIsValid}
        />
    );

}
SelectAutoFill.defaultProps = {
    label: '',
    placeholder: '',
    limitTags: -1,
    onChange: () => { },
    hideClearButton: false,
    withFreeText: false,
    setError: () => { }
}

export default SelectAutoFill;