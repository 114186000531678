import { useCallback } from 'react';
import { useMsalProps } from "./Msal";
import { FetchRequest, GetOpts } from "../CustomObjects/Fetch";

import Env from "../CustomObjects/Environment";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useAuthentication = () => {

	const { instance } = useMsalProps();

	const getUserAuthentication = useCallback(async () => {

		if (!instance)
			return null;

		try {
			const loginRequest = { scopes: ["User.Read"] };
			const account = instance.getAllAccounts()[0];
			if (account) {
				const userInfo = await instance.acquireTokenSilentAsync(loginRequest, account);
				await FetchRequest(
					Env.BACKEND_SERVER_URL + 'Admin/authenticationToken',
					GetOpts(
						{
							Username: userInfo.account.username,
							Role: 'Viewer',
							Authorization: `Bearer ${userInfo.idToken}`
						},
						'POST',
						{
							email: userInfo.account.username,
							token: userInfo.idToken,
							expireDate: dayjs.utc(userInfo.expiresOn).format()
						}
					),
				);

				return userInfo;
			}
			else
				return null;
		} catch (error) {
			console.error("Error in authentication: ", error);
			instance.logoutRedirect();
			return null;
		}
	}, [instance]);

	return getUserAuthentication;
}
