import React, { useMemo } from "react";

import { styled } from '@mui/material/styles';
import { default as SliderMUI } from '@mui/material/Slider';

import Label from '@nokia-csf-uxr/ccfk/Label';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';

import '../../Styles/Slider.css';

const Slider = ({ label, value, setValue, step, min, max, marks, sideMargin, disabled, disabledWithColor, color }) => {

    const StyledSliderMUI = useMemo(() => styled(SliderMUI)({
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: 'var(--ff-color-nokia-blue-400)',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
        '&.Mui-disabled': {
            '& .MuiSlider-rail': {
                color: disabledWithColor && disabledWithColor.track ? disabledWithColor.track : 'var(--ff-color-grey-400)'
            },
            '& .MuiSlider-track': {
                color: disabledWithColor && disabledWithColor.track ? disabledWithColor.track : 'var(--ff-color-grey-400)'
            },
            '& .MuiSlider-thumb': {
                color: disabledWithColor && disabledWithColor.thumb ? disabledWithColor.thumb : 'var(--ff-color-grey-400)'
            },
            '& .MuiSlider-mark': {
                backgroundColor: disabledWithColor ? 'white' : 'var(--ff-color-grey-400)'
            },
            '& .MuiSlider-markActive': {
                backgroundColor: disabledWithColor ? 'white' : 'var(--ff-color-grey-400)'
            }
        }
    }), [disabledWithColor]);

    const onChange = (_, newValue) => {
        setValue(newValue);
    }

    return (
        <div>
            {
                label && (
                <Label>
                    <TextInputLabelContent>{label}</TextInputLabelContent>
                </Label>
                )}
            <div style={{ margin: `0 ${sideMargin}`}}>
                <StyledSliderMUI
                    value={value}
                    onChange={onChange}
                    step={step}
                    min={min}
                    max={max}
                    marks={marks}
                    valueLabelDisplay="auto"
                    disabled={(disabled || disabledWithColor) ? true : false}
                    color={color}
                />
            </div>
        </div>
    );
}
Slider.defaultProps = {
    setValue: () => { },
    step: null,
    min: null,
    max: null,
    marks: null,
    sideMargin: '1.5em',
    disabled: false,
}

export default Slider;