import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from './CustomHooks/Global';
import { PageNavigationProvider } from './CustomHooks/PageNavigation';
import { AppMsalProvider, AuthenticatedComponents, UnAuthenticatedComponents } from './CustomHooks/Msal';

import './Styles/index.css';
import Main from './Modules/Main';
import Login from './Modules/Login';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	//<React.StrictMode>
		<BrowserRouter basename={baseUrl}>
			<AppMsalProvider>
				<UnAuthenticatedComponents>
					<Login />
				</UnAuthenticatedComponents>
				<AuthenticatedComponents>
					<GlobalProvider>
						<PageNavigationProvider>
							<Main/>
						</PageNavigationProvider>
					</GlobalProvider>
				</AuthenticatedComponents>
			</AppMsalProvider>
		</BrowserRouter>
	//</React.StrictMode>
);
