import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const NotifySMEButton = ({ onClick }) => {

	return (
		<Button variant='call-to-action-bigger-font' onClick={onClick}>
			<ButtonText>Notify SME</ButtonText>
			<ButtonIcon>
				<NotificationsActiveIcon />
			</ButtonIcon>
		</Button>
	);
}

export default NotifySMEButton;