import React, { useRef } from 'react';

import _uniqueId from 'lodash/uniqueId';

import Label from '@nokia-csf-uxr/ccfk/Label';
import { default as DefaultTextInput, TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';

import '../../Styles/TextInput.css';

const TextInput = (props) => {

	const ref = useRef(null);

	const {
		label = '', value, setValue, maxChars = 80,
		required = false, disabled = false, placeholder = '',
		error = false, setError = undefined, showClearButton = true,
		id = null
	} = props;

	const handleChangeValue = (value) => {
		if (setError !== undefined) setError(false);
		setValue(value);
	}

	return (
		<div className='tic-div'>
			<Label variant="vertical-layout">
				<TextInputLabelContent className='tic-label' requiredSymbol='*' required={required}>{label}</TextInputLabelContent>
				<DefaultTextInput
					id={id}
					className='tic-ti'
					value={value}
					disabled={disabled}
					onChange={(event) => { handleChangeValue(event.target.value); }}
					placeholder={placeholder}
					inputProps={{
						id: `textinput-${_uniqueId()}`,
						autoComplete: 'false',
						ref: ref,
						maxLength: maxChars
					}}
					showClearButton={showClearButton}
					clearButtonProps={{ onClick: () => { handleChangeValue(''); } }}
					error={error}
				/>
			</Label>
		</div>
	);
};

export default TextInput;