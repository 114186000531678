import React, { useMemo, useState, useRef } from 'react';

import { ModuleBase, SelectButton } from "../Components/Common"; 

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import ModuleBaseObj from '../CustomObjects/Module';

import {
    DATA_TYPES,
    userDataRenderer,
    reportsStartDateCellRenderer,
    reportsEndDateCellRenderer,
    useReportsData,
    useGenerateDiploma,
    useQueueDiploma
} from '../CustomHooks/Reports';
import { useEmailsNotifications } from '../CustomHooks/Emails';
import { useIsRole } from '../CustomHooks/Global';

import EmailIcon from '@nokia-csf-uxr/ccfk-assets/latest/EmailIcon';
import MenuSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/MenuSmallIcon';

const ReportType = ({ dataType, setDataType, onChange }) => {
    return (
        <>
            <Typography style={{ textAlign: 'end' }}><b>{dataType}</b></Typography>
            <SelectButton
                withLabel={false}
                value={dataType}
                setValue={setDataType}
                data={{
                    values: Object.values(DATA_TYPES).map(type => ({ dType: type })),
                    mapping: { value: 'dType' }
                }}
                width='10em'
                onChange={onChange}
                Icon={MenuSmallIcon}
            />
        </>
    );
}

const Reports = () => {
    const messageRef = useRef(null);
    const dataTableRef = useRef(null);

    const [dataType, setDataType] = useState(DATA_TYPES.ALL);
    const [currentReportData, setCurrentReportData] = useState({});

    const userIsReportLead = useIsRole('reportLead');
    const userIsAdmin = useIsRole('admin');

    const {
        reports,
        onGridReady,
        onKeywordChange,
        updateReports,
        fetchDataForCSV
    } = useReportsData(dataType, dataTableRef);
    const {
        generateDiploma,
        successWithDiplomaFileName,
    } = useGenerateDiploma(messageRef);
    const {
        queueEmailWithFileNames,
        queueEmailWithFileNamesSuccess,
        queueEmailWithFileNamesError
    } = useEmailsNotifications();
    useQueueDiploma(
        messageRef,
        currentReportData,
        successWithDiplomaFileName,
        queueEmailWithFileNames,
        queueEmailWithFileNamesSuccess,
        queueEmailWithFileNamesError
    );

    const moduleBaseObj = useMemo(() => {

        const newModuleBaseObj = new ModuleBaseObj(messageRef);

        newModuleBaseObj.moduleHeader.bannerPath = require('../Images/BannerBackgrounds/banner-background-6.jpg');
        newModuleBaseObj.moduleHeader.bannerPosition = "50% 80%";
        newModuleBaseObj.moduleHeader.title = "NLDH Reports";
        newModuleBaseObj.moduleHeader.titleTextColor = "var(--ff-color-orange-800)";

        newModuleBaseObj.dataTable.data = reports;
        newModuleBaseObj.dataTable.ref = dataTableRef;
        newModuleBaseObj.dataTable.columnDefinitions = {
            userId: {
                flex: 0.6
            },
            userEmail: {
                flex: 1.0,
                headerName: 'Email',
                cellRenderer: userDataRenderer
            },
            country: {
                flex: 0.6,
                cellRenderer: userDataRenderer
            },
            trainingTitle: {
                flex: 1.4,
            },
            courseRole: {
                headerName: 'Role',
                flex: 0.7
            },
            transcriptStatus: {
                flex: 0.5
            },
            transcriptStartDate: {
                flex: 0.7,
                type: 'date',
                headerName: 'Registration',
                cellRenderer: reportsStartDateCellRenderer,
            },
            transcriptCompletionDate: {
                flex: 0.7,
                type: 'date',
                headerName: 'Completion',
                cellRenderer: reportsEndDateCellRenderer,
            },
            lmEmail: {
                headerName: 'Line Manager'
            }
        };

        const columnsToHideInUI = [
            'firstname', 'lastname', 'region', 'courseNumber', 'coursetype', 'courseobjecttype',
            'courselevel', 'solutionshortname', 'solutionlongname', 'courserelease', 'statuschangedate',
            'usertype', 'userrole', 'userstatus', 'orglevel1', 'orglevel2', 'orglevel3', 'orglevel4', 'orglevel5',
            'orglevel6', 'orglevel7', 'orglevel8', 'orglevel9', 'coursediplomaname', 'rownum'
        ];

        const columnsToExportInCSV = [
            'userId', 'region', 'courseNumber', 'trainingTitle', 'transcriptStatus', 'courseType',
            'courseObjectType', 'courseLevel', 'solutionShortName', 'solutionLongName', 'courseRole', 'courseRelease',
            'transcriptStartDate', 'transcriptCompletionDate', 'statusChangeDate', 'userType', 'userRole',
            'userStatus', 'lmEmail', 'team', 'orgLevel1', 'orgLevel2', 'orgLevel3', 'orgLevel4', 'orgLevel5',
            'orgLevel6', 'orgLevel7', 'orgLevel8', 'orgLevel9', 'courseDiplomaName'
        ];

        if (!userIsReportLead && !userIsAdmin)
            columnsToHideInUI.push('userEmail', 'country');
        if (userIsReportLead || userIsAdmin)
            columnsToExportInCSV.push('firstname', 'lastname', 'userEmail', 'country');
        
        newModuleBaseObj.dataTable.columnsIgnore = columnsToHideInUI;
        newModuleBaseObj.dataTable.tableOptions.onRefresh = () => updateReports(dataType);
        newModuleBaseObj.dataTable.tableOptions.onFilterRemove.callback = () => {
            setDataType(DATA_TYPES.ALL);
            updateReports(DATA_TYPES.ALL);
        };
        newModuleBaseObj.dataTable.tableOptions.onExcelFile.fileName = 'Reports.csv';
        newModuleBaseObj.dataTable.tableOptions.onExcelFile.columns = columnsToExportInCSV;

        newModuleBaseObj.dataTable.additionalComponents.toolbar = <ReportType
            dataType={dataType}
            setDataType={setDataType}
            onChange={(newDataType) => updateReports(newDataType)}
        />;

        newModuleBaseObj.dataTable.rowOptions = [
            {
                icon: <EmailIcon />,
                tooltip: 'Send Diploma',
                callback: ({ data }) => {
                    setCurrentReportData(data);
                    messageRef.current.showAreYouSureMessage(
                        `Are you sure you want to generate and send the diploma <b>'${data.courseDiplomaName}'</b> to ${data.firstName} ${data.lastName}`
                    );
                },
                renderCondition: ({ data }) => {
                    return (
                        (data.courseLevel === 'L3' && data.courseObjectType === 'Certification' && data.transcriptStatus === 'Completed' && data.courseType === 'KMCD') ||
                        (data.courseLevel === 'L4' && data.courseObjectType === 'Certification' && data.transcriptStatus === 'Completed')
                    );
                }
            }
        ];

        newModuleBaseObj.dataTable.serverInfiniteRow.onGridReady = onGridReady;
        newModuleBaseObj.dataTable.serverInfiniteRow.onKeywordChange = onKeywordChange;
        newModuleBaseObj.dataTable.serverInfiniteRow.onExcelFile = fetchDataForCSV;

        newModuleBaseObj.messages.title = 'NLDH Reports';
        newModuleBaseObj.messages.submitAreYouSureMessage = () => {
            messageRef.current.hideAreYouSureMessage();
            generateDiploma(currentReportData);
        }

        return newModuleBaseObj;
    }, [
        fetchDataForCSV,
        onGridReady,
        onKeywordChange,
        reports,
        updateReports,
        dataType,
        generateDiploma,
        currentReportData,
        userIsReportLead,
        userIsAdmin
    ]);

    return (
        <ModuleBase
            moduleBaseObj={moduleBaseObj}
            messageRef={messageRef}
        />
    );
}

export default Reports;
