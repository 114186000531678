import Button, { ButtonText } from '@nokia-csf-uxr/ccfk/Button';

const ButtonLink = ({ url, text }) => {

	const handleOnClick = () => {
		window.open(url, '_blank');
	}

	return (
		<Button variant='call-to-action-bigger-font' onClick={handleOnClick}>
			<ButtonText>{text}</ButtonText>
		</Button>
	);
}

export default ButtonLink;