import React from 'react';

import { RowSeparator, Hyperlink } from "../Common";

import Button from '@nokia-csf-uxr/ccfk/Button';

import '../../Styles/KTSessionsOverlayPanel.css';

const MaterialLinks = ({ fields }) => {
	const materialsAndRecordingsLinkExists = fields.materialsAndRecordingsLink && fields.materialsAndRecordingsLink.length > 0;

	return (
		<>
			<RowSeparator />
			<div className='op-row op-row-space-between'>
				{
					materialsAndRecordingsLinkExists && fields.materialsAndRecordingsLink.length === 1 &&
					<Button style={{ width: '10em' }}>
						<Hyperlink style={{ textDecoration: 'none', color: '#124191', fontWeight: 700 }} text='Materials Folder' link={fields.materialsAndRecordingsLink[0]} />
					</Button>
				}
				{
					materialsAndRecordingsLinkExists && fields.materialsAndRecordingsLink.length === 2 &&
					<>
						<Button style={{ width: '10em' }}>
							<Hyperlink style={{ textDecoration: 'none', color: '#124191', fontWeight: 700 }} text='Materials Folder' link={fields.materialsAndRecordingsLink[0]} />
						</Button>
						<Button style={{ width: '10em' }}>
							<Hyperlink style={{ textDecoration: 'none', color: '#124191', fontWeight: 700 }} text='Recordings Link' link={fields.materialsAndRecordingsLink[1]} />
						</Button>
					</>
				}
				<Button onClick={() => {
					fields.setPanelVisible(false);
					fields.setFeedbackPanelVisible(true);
				}}>
					Feedback And Learning Points
				</Button>
			</div>
		</>
	);
}

export default MaterialLinks;