import { useEffect, useRef, useState } from 'react';

import Env from '../CustomObjects/Environment';

import { useUser, useIsAdmin, useIsRole } from './Global';
import { useFetch } from './Fetch';
import { useSharepoint } from './Sharepoint';
import { EmailInstance, useEmailsNotifications } from './Emails';

export const ASSIGNMENT_STATUS = {
    ASSIGNED: 'ASSIGNED',
    UPLOADED: 'UPLOADED',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
}

export const EVALUATION_STATUS = {
    PASSED: 'PASSED',
    FAILED: 'FAILED'
}

export const APPROVER_VALIDATION = {
    AGREE: 'AGREE',
    DISAGREE: 'DISAGREE'
}

export const MODES = {
    REQUEST_MENTOR: 'REQUEST_MENTOR',
    IN_PROGRESS: 'IN_PROGRESS',
    EVALUATE: 'EVALUATE',
    GO_BACK: 'GO_BACK',
    TERMINATED: 'TERMINATED'
}

export const MENTOR_MANAGEMENT_MODES = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
}

export const OJT_PHASES = {
    REQUEST_MENTOR: 0,
    ASSIGNED: 1,
    MENTOR_EVALUATION: 2,
    APPROVER_VALIDATION: 3,
    COMPLETE: 4
}

export const OJT_PHASES_MARKS = [
    {
        value: 0,
        label: 'Request',
    },
    {
        value: 1,
        label: 'Assigned',
    },
    {
        value: 2,
        label: 'Evaluation',
    },
    {
        value: 3,
        label: 'Approval',
    },
    {
        value: 4,
        label: 'Complete',
    }
]

export const useL3PlusCertificationFields = () => {
    const dataTableRef = useRef(null);
    const dataTableMentorsRef = useRef(null);
    const messageRef = useRef(null);
    const overlayPanelRef = useRef(null);

    const [toggleDisabled, setToggleDisabled] = useState(false);
    const [showEvaluated, setShowEvaluated] = useState(false);

    const [currentAssignmentData, setCurrentAssignmentData] = useState({});
    const [panelVisible, setPanelVisible] = useState(false);
    const [panelManageVisible, setPanelManageVisible] = useState(false);

    const [mode, setMode] = useState(MODES.REQUEST_MENTOR);

    const [isMentee, setIsMentee] = useState(false);
    const [isMentor, setIsMentor] = useState(false);
    const [isApprover, setIsApprover] = useState(false);

    const defaultColsToHide = [
        'id', 'userId', 'firstname', 'lastname', 'coursenumber', 'mentorEvaluation',
        'approverValidation', 'approverFeedback', 'ismentorphase', 'kmcdTechnicalOwner', 'sharepointpath',
        'createdate', 'moddate', 'moduseremail'
    ];

    return {
        dataTableRef,
        dataTableMentorsRef,
        messageRef,
        overlayPanelRef,

        toggleDisabled,
        setToggleDisabled,
        showEvaluated,
        setShowEvaluated,

        currentAssignmentData,
        setCurrentAssignmentData,
        panelVisible,
        setPanelVisible,
        panelManageVisible,
        setPanelManageVisible,

        mode,
        setMode,

        isMentee,
        setIsMentee,
        isMentor,
        setIsMentor,
        isApprover,
        setIsApprover,

        defaultColsToHide
    }
}

export const useL3PlusCertificationPanelFields = () => {

    const [role, setRole] = useState(null);
    const [certification, setCertification] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [mentorEvaluation, setMentorEvaluation] = useState(null);
    const [mentorFeedback, setMentorFeedback] = useState('');
    const [approverValidation, setApproverValidation] = useState(null);
    const [approverFeedback, setApproverFeedback] = useState('');
    const [ojtPhase, setOjtPhase] = useState(OJT_PHASES.REQUEST_MENTOR);

    return {
        role,
        setRole,
        certification,
        setCertification,
        filesToUpload,
        setFilesToUpload,
        mentorEvaluation,
        setMentorEvaluation,
        approverValidation,
        setApproverValidation,
        mentorFeedback,
        setMentorFeedback,
        approverFeedback,
        setApproverFeedback,
        ojtPhase,
        setOjtPhase
    }
}

export const useL3PlusAssignments = (fields) => {
    const user = useUser();
    const userIsAdmin = useIsAdmin();
    const userIsL3PlusLead = useIsRole('l3PlusCertificationLead');

    const [assignments, setFetchAssignments, , setAssignmentsHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification', false);
    const [singleAssignment, setFetchSingleAssignment, , setSingleAssignmentHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification', false);

    const getSingeAssignment = (id) => {
        setSingleAssignmentHeaders({
            id: id,
            userEmail: (userIsAdmin || userIsL3PlusLead) ? '' : user.email,
        });
        setFetchSingleAssignment(true);
    }

    const onToggleChange = (checked) => {
        updateAssignments(checked);
        fields.dataTableRef.current.setClientDataNullAndShowLoadingOverlay();
    }

    const updateAssignments = (showEvaluated) => {
        setAssignmentsHeaders({
            userEmail: (userIsAdmin || userIsL3PlusLead) ? '' : user.email,
            showEvaluated: showEvaluated
        });
        setFetchAssignments(true);
        fields.setToggleDisabled(true);
    }

    const refreshAssignments = () => {
        updateAssignments(fields.showEvaluated);
        fields.dataTableRef.current.setClientDataNullAndShowLoadingOverlay();
    };

    const createAssignment = () => {
        fields.setCurrentAssignmentData({});
        fields.setIsMentee(true);
        fields.setIsMentor(false);
        fields.setIsApprover(false);
        fields.overlayPanelRef.current.panelFields.setRole(null);
        fields.overlayPanelRef.current.panelFields.setCertification(null);
        fields.overlayPanelRef.current.panelFields.setFilesToUpload([]);
        fields.overlayPanelRef.current.panelFields.setMentorEvaluation(null);
        fields.overlayPanelRef.current.panelFields.setMentorFeedback('');
        fields.overlayPanelRef.current.panelFields.setApproverValidation(null);
        fields.overlayPanelRef.current.panelFields.setApproverFeedback('');
        fields.overlayPanelRef.current.panelFields.setOjtPhase(OJT_PHASES.REQUEST_MENTOR);
        fields.setMode(MODES.REQUEST_MENTOR);
        fields.setPanelVisible(true);
    }

    const editAssignment = ({ data }) => {
        var newMode;
        if (data.status === ASSIGNMENT_STATUS.ASSIGNED)
            newMode = MODES.IN_PROGRESS;
        else if (data.status === ASSIGNMENT_STATUS.UPLOADED)
            newMode = MODES.EVALUATE;
        else if (data.status === ASSIGNMENT_STATUS.FAILED)
            newMode = MODES.GO_BACK;
        else if (data.status === ASSIGNMENT_STATUS.SUCCESS )
            newMode = MODES.TERMINATED;
        else {
            console.error('Invalid status!!');
            return;
        }

        var newOjtPhase;
        if (newMode === MODES.IN_PROGRESS)
            newOjtPhase = OJT_PHASES.ASSIGNED;
        else if (newMode === MODES.EVALUATE && data.isMentorPhase)
            newOjtPhase = OJT_PHASES.MENTOR_EVALUATION;
        else if (newMode === MODES.EVALUATE && !data.isMentorPhase)
            newOjtPhase = OJT_PHASES.APPROVER_VALIDATION;
        else if (newMode === MODES.GO_BACK || newMode === MODES.TERMINATED)
            newOjtPhase = OJT_PHASES.COMPLETE;

        fields.setCurrentAssignmentData(data);
        fields.setIsMentee(data.userEmail === user.email);
        fields.setIsMentor(data.mentor === user.email || userIsAdmin || userIsL3PlusLead);
        fields.setIsApprover(data.kmcdTechnicalOwner === user.email || userIsAdmin || userIsL3PlusLead);
        fields.overlayPanelRef.current.panelFields.setRole(data.courseRole);
        fields.overlayPanelRef.current.panelFields.setCertification(data.courseDisplayName);
        fields.overlayPanelRef.current.panelFields.setFilesToUpload([]);
        fields.overlayPanelRef.current.panelFields.setMentorEvaluation(data.mentorEvaluation);
        fields.overlayPanelRef.current.panelFields.setMentorFeedback(data.mentorFeedback ?? '');
        fields.overlayPanelRef.current.panelFields.setApproverValidation(data.approverValidation);
        fields.overlayPanelRef.current.panelFields.setApproverFeedback(data.approverFeedback);
        fields.overlayPanelRef.current.panelFields.setOjtPhase(newOjtPhase);
        fields.setMode(newMode);
        fields.setPanelVisible(true);
    }

    useEffect(() => {
        updateAssignments(false);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!assignments)
            return;
        fields.setToggleDisabled(false);
    // eslint-disable-next-line
    }, [assignments]);

    return {
        assignments,
        refreshAssignments,
        onToggleChange,
        createAssignment,
        editAssignment,
        singleAssignment,
        getSingeAssignment
    }

}

export const useL3PlusRoles = () => {
    const [roles] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/Roles', true);

    return { roles };
}

export const useL3PlusCertifications = () => {
    const [certifications, setFetchCertifications, , setCertificationsHeaders, , setCertifications] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/Certifications', false);

    const updateCertifications = (certRole) => {
        setCertificationsHeaders({ certRole: certRole });
        setFetchCertifications(true);
    }

    const getCertificationDetails = (courseDisplayName) => {
        const theCourse = certifications.filter(course => course.courseDisplayName === courseDisplayName);

        if (theCourse.length > 0)
            return theCourse[0];
        return null;
    }

    useEffect(() => {
        setCertifications([]);
        // eslint-disable-next-line
    }, []);

    return { certifications, setCertifications, updateCertifications, getCertificationDetails };
}

export const useL3PlusMentorManagers = () => {
    const user = useUser();
    const userIsAdmin = useIsAdmin();
    const userIsL3PlusLead = useIsRole('l3PlusCertificationLead');
    const [eligibleCoursesToManage, setFetchEligibleCoursesToManage,,setEligibleCoursesToManageHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/Approvers');
    const [isCourseManager, setIsCourseManager] = useState(false);

    const getManagableCourseDetails = (courseDisplayName) => {
        const theCourse = eligibleCoursesToManage.filter(course => course.courseDisplayName === courseDisplayName);

        if (theCourse.length > 0)
            return theCourse[0];
        return null;
    }

    useEffect(() => {
        setEligibleCoursesToManageHeaders({
            approverEmail: (userIsAdmin || userIsL3PlusLead) ? '' : user.email
        });
        setFetchEligibleCoursesToManage(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!eligibleCoursesToManage)
            return;

        if (eligibleCoursesToManage.length > 0)
            setIsCourseManager(true);
        // eslint-disable-next-line
    }, [eligibleCoursesToManage]);

    return {
        isCourseManager,
        eligibleCoursesToManage,
        getManagableCourseDetails
    }
}

export const useL3PlusMentors = (fields) => {
    const [mentors, setFetchMentors,, setMentorsHeaders,, setMentors] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/Mentors');
    const [insertResponse, setInsertMentor, insertError,, setInsertMentorBody] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/InsertMentor', false, {}, 'POST');
    const [updateResponse, setUpdateMentor, updateError,, setUpdateMentorBody] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/UpdateMentor', false, {}, 'POST');
    const [deleteResponse, setDeleteMentor, deleteError,, setDeleteMentorBody] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/DeleteMentor', false, {}, 'POST');

    const getMentors = (courseNumber) => {
        setMentorsHeaders({
            courseNumber
        });
        setFetchMentors(true);
    }

    const insertMentor = (mentor, courseNumber, status) => {
        setInsertMentorBody({
            mentor, courseNumber, status
        });
        setInsertMentor(true);
    }

    const updateMentor = (mentor, courseNumber, status) => {
        setUpdateMentorBody({
            mentor, courseNumber, status
        });
        setUpdateMentor(true);        
    }

    const deleteMentor = (mentor, courseNumber) => {
        setDeleteMentorBody({
            mentor, courseNumber
        });
        setDeleteMentor(true);
    }

    useEffect(() => {
        if (fields.panelManageVisible) {
            setMentors([]);
        }
    //eslint-disable-next-line
    }, [fields.panelManageVisible]);

    return {
        mentors,
        getMentors,
        setMentors,
        insertMentor,
        updateMentor,
        deleteMentor,
        insertResponse,
        insertError,
        updateResponse,
        updateError,
        deleteResponse,
        deleteError
    }
}

export const useL3PlusAssignmentSubmission = (fields, refreshAssignments) => {

    const EVALUATION_SCENARIOS = {
        MENTOR_FAILED: 'MENTOR_FAILED',
        MENTOR_PASSED: 'MENTOR_PASSED',
        APPROVER_DISAGREES: 'APPROVER_DISAGREES',
        APPROVER_AGREES: 'APPROVER_AGREES',
    }

    const user = useUser();
    const [mentorResponse, setValidateUser, validateUserError, setValidateUserHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/ValidateUser', false, {}, 'POST');
    const [updateAssignmentResponse, setUpdateAssignment, updateAssignmentError,, setUpdateAssignmentBody] = useFetch(Env.BACKEND_SERVER_URL + 'L3PlusCertification/InsertUpdate', false, {}, 'POST');
    const [createFolderSuccess, createFolderError, uploadOJTSuccess, uploadOJTError, createFolder, uploadOJT] = useSharepoint("sharepointDocumentFolderL3Plus");
    const [,, reUploadOJTSuccess, reUploadOJTError,, reUploadOJT] = useSharepoint("sharepointDocumentFolderL3Plus");
    const { queueEmail, queueEmailError, queueEmailSuccess } = useEmailsNotifications();

    const courseSelected = useRef(null);
    const mentorSelected = useRef(null);
    const approverSelected = useRef(null);
    const newEvaluationScenario = useRef(null);

    const getSharepointPath = () => {
        return `${courseSelected.current.courseNumber}/${user.username}`;
    }

    const getNewMentorEmailBody = () => {
        return `
            <h1>L3+ Mentor Assignment</h1>
            <p>Congratulations ${user.firstName} ${user.lastName}!</p>
            <br/>
            <p>You have successfully been assigned a mentor for the course <b>${courseSelected.current.courseDisplayName}</b></p>
            <br/>
            <p>Mentor: <b>${mentorSelected.current}</b></p>
            <br/>
            <p><b>As a Mentee:</b></p>
            <ul>
                <li>Please initiate your OJT by scheduling the kick-off meeting with your mentor.</li>
                <li>Schedule any necessary further meetings agreed in the kick-off meeting.</li>
                <li>After your OJT is complete please upload the provided <a href="${courseSelected.current.OJTMaterialsURL}" target="_blank" rel="noopener noreferrer">OJT Template</a> into NKC so that your mentor can evaluate it.</li>
            </ul>
            <br/>
            <p><b>As a Mentor:</b></p>
            <ul>
                <li>During kick-off meeting please explain your role as a guide and counselor for the mentee.</li>
                <li>Review and evaluate the OJT document and also provide feedback.</li>
            </ul>
            <br/>
            <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            <p>Good luck!</p>
            <br/>
        `
    }

    const getOJTUploadEmailBody = () => {
        var body = `
            <h1>L3+ OJT Document Uploaded</h1>
            <p>${user.firstName} ${user.lastName} has uploaded the OJT document for the course <b>${courseSelected.current.courseDisplayName}</b></p>
            <br/>
            <p>Mentor: <b>${mentorSelected.current}</b></p>
            <p>Files:</p>
            <ul>
        `;

        fields.overlayPanelRef.current.panelFields.filesToUpload.forEach(f => {
            body += `<li>${f.file.name}</li>`
        });

        body += `
            </ul>
            <p>Please review the document and provide feedback.</p>
            <br/>
            <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification?id=${courseSelected.current.id}" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            <br/>
        `
        return body;
    }

    const getEvaluationNotificationEmailBody = () => {

        if (newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_FAILED) {

            return `
                <h1>L3+ OJT Document FAILED Evaluation</h1>
                <p>Dear <b>${courseSelected.current.firstName} ${courseSelected.current.lastName}</b>,</p>
                <br/>
                <p>Unfortunately your OTJ has been considered as 'FAILED' for certification: <b>${courseSelected.current.courseDisplayName}</b>.</p>
                <p>Please review your mentor's feeback and when ready go back to NKC and restart your OTJ</p>
                <br/>
                <p>Feedback:</p>
                <br/>
                <p style="background-color: #c7cdd6; padding: 0.5em">${fields.overlayPanelRef.current.panelFields.mentorFeedback ?? 'No Feedback'}</p>
                <br/>
                <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification?id=${courseSelected.current.id}" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            `
        }

        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_PASSED) {
            return `
                <h1>L3+ OJT Document Evaluation</h1>
                <p>Dear KMCD Approver <b>${approverSelected.current}</b>,</p>
                <br/>
                <p>An evaluation as 'PASSED' has been provided from the mentor: <b>${mentorSelected.current}</b> to the mentee: <b>${courseSelected.current.firstName} ${courseSelected.current.lastName}</b>.
                <br/>
                <p>Course: <b>${courseSelected.current.courseDisplayName}</b>.</p>
                <br/>
                <p>Feedback:</p>
                <br/>
                <p style="background-color: #c7cdd6; padding: 0.5em">${fields.overlayPanelRef.current.panelFields.mentorFeedback ?? 'No Feedback'}</p>
                <br/>
                <p>Please go to NKC and approve/reject the evaluation.</p>
                <br/>
                <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification?id=${courseSelected.current.id}" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            `
        }

        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.APPROVER_DISAGREES) {
            return `
                <h1>L3+ OJT Document Evaluation 'FAILED' Approval</h1>
                <p>Dear Mentor <b>${mentorSelected.current}</b>,</p>
                <br/>
                <p>The KMCD Approver <b>${approverSelected.current}</b> has reviewed your evaluation regarding the certification '<b>${courseSelected.current.courseDisplayName}</b>' and is not in agreement with your review.</p>
                <br/>
                <p><b>Mentee: </b> ${courseSelected.current.firstName} ${courseSelected.current.lastName}</p>
                <br/>
                <p>Please go back to NKC to make the necessary corrections.</p>
                <br/>
                <p>Feedback:</p>
                <br/>
                <p style="background-color: #c7cdd6; padding: 0.5em">${fields.overlayPanelRef.current.panelFields.approverFeedback ?? 'No Feedback'}</p>
                <br/>
                <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification?id=${courseSelected.current.id}" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            `
        }

        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.APPROVER_AGREES) {

            return `
                <h1>L3+ OJT Document PASSED Evaluation</h1>
                <p>Dear <b>${courseSelected.current.firstName} ${courseSelected.current.lastName}</b>,</p>
                <br/>
                <p>Congratulations! You have successfully completed your OTJ for the certification: <b>${courseSelected.current.courseDisplayName}</b>.</p>
                <br/>
                <p>Feedback:</p>
                <br/>
                <p style="background-color: #c7cdd6; padding: 0.5em">${fields.overlayPanelRef.current.panelFields.mentorFeedback ?? 'No Feedback'}</p>
                <br/>
                <p>You can find more details in <a href="${Env.NKC_SERVER_URL}certifications/l3pluscertification?id=${courseSelected.current.id}" target="_blank" rel="noopener noreferrer">NKC L3+ OJT Certification</a></p>
            `
        }

        else {
            console.error('INVALID evaluation scenario!!');
            return '';
        }

    }

    const getEvaluationToAndCCList = () => {
        if (newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_FAILED) {
            return [[courseSelected.current.userEmail], [mentorSelected.current]];
        }
        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_PASSED) {
            return [[approverSelected.current], [mentorSelected.current]];
        }
        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.APPROVER_DISAGREES) {
            return [[mentorSelected.current], [approverSelected.current]];
        }
        else if (newEvaluationScenario.current === EVALUATION_SCENARIOS.APPROVER_AGREES) {
            return [[courseSelected.current.userEmail], [mentorSelected.current, approverSelected.current]];
        }
        else {
            console.error('INVALID evaluation scenario!!');
            return [[],[]];
        }
    }

    const requestMentor = () => {
        courseSelected.current = fields.overlayPanelRef.current.getCertificationDetails(fields.overlayPanelRef.current.panelFields.certification);
        if (!courseSelected.current) {
            fields.messageRef.current.errorMessage('Invalid course selected!');
            return;
        }

        setValidateUserHeaders({
            courseNumber: courseSelected.current.courseNumber
        });
        setValidateUser(true);
        fields.messageRef.current.showProcessingDiag();
    }

    const uploadOJTDocument = () => {
        courseSelected.current = fields.currentAssignmentData;
        mentorSelected.current = fields.currentAssignmentData.mentor;

        uploadOJT(getSharepointPath(), fields.overlayPanelRef.current.panelFields.filesToUpload);
        fields.messageRef.current.showProcessingDiag();
    }

    const reUploadOJTDocument = () => {
        courseSelected.current = fields.currentAssignmentData;
        mentorSelected.current = fields.currentAssignmentData.mentor;

        reUploadOJT(getSharepointPath(), fields.overlayPanelRef.current.panelFields.filesToUpload);
        fields.messageRef.current.showProcessingDiag();
    } 

    const evaluateAssignment = () => {
        courseSelected.current = fields.currentAssignmentData;
        mentorSelected.current = fields.currentAssignmentData.mentor;
        approverSelected.current = fields.currentAssignmentData.kmcdTechnicalOwner;

        const isMentorPhase = fields.currentAssignmentData.isMentorPhase;

        var mentorEvaluation = fields.overlayPanelRef.current.panelFields.mentorEvaluation;
        var mentorFeedback = fields.overlayPanelRef.current.panelFields.mentorFeedback;
        var approverValidation = fields.overlayPanelRef.current.panelFields.approverValidation;
        var approverFeedback = fields.overlayPanelRef.current.panelFields.approverFeedback;

        var status = ASSIGNMENT_STATUS.UPLOADED;
        if (mentorEvaluation === EVALUATION_STATUS.PASSED && approverValidation === APPROVER_VALIDATION.AGREE)
            status = ASSIGNMENT_STATUS.SUCCESS;
        else if (mentorEvaluation === EVALUATION_STATUS.FAILED)
            status = ASSIGNMENT_STATUS.FAILED;
        
        if (isMentorPhase && mentorEvaluation === EVALUATION_STATUS.FAILED)
            newEvaluationScenario.current = EVALUATION_SCENARIOS.MENTOR_FAILED;
        else if (isMentorPhase && mentorEvaluation === EVALUATION_STATUS.PASSED)
            newEvaluationScenario.current = EVALUATION_SCENARIOS.MENTOR_PASSED;
        else if (!isMentorPhase && approverValidation === APPROVER_VALIDATION.DISAGREE)
            newEvaluationScenario.current = EVALUATION_SCENARIOS.APPROVER_DISAGREES;
        else if (!isMentorPhase && approverValidation === APPROVER_VALIDATION.AGREE)
            newEvaluationScenario.current = EVALUATION_SCENARIOS.APPROVER_AGREES;

        if (newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_PASSED || newEvaluationScenario.current === EVALUATION_SCENARIOS.MENTOR_FAILED) {
            approverValidation = '';
            approverFeedback = '';
        }

        setUpdateAssignmentBody({
            nokiaUserId: courseSelected.current.userId,
            courseNumber: courseSelected.current.courseNumber,
            status: status,
            mentorEvaluation: mentorEvaluation ?? '',
            mentorFeedback: mentorFeedback ?? '',
            approverValidation: approverValidation ?? '',
            approverFeedback: approverFeedback ?? '',
            isMentorPhase: !isMentorPhase,
            modNokiaUserId: user.userId,
        });
        setUpdateAssignment(true);
        fields.messageRef.current.showProcessingDiag();
    }

    const restartAssignment = () => {
        courseSelected.current = fields.currentAssignmentData;
        mentorSelected.current = fields.currentAssignmentData.mentor;

        setUpdateAssignmentBody({
            nokiaUserId: courseSelected.current.userId,
            courseNumber: courseSelected.current.courseNumber,
            status: ASSIGNMENT_STATUS.ASSIGNED,
            mentorEvaluation: '',
            mentorfeedback: '',
            approverValidation: '',
            approverfeedback: '',
            isMentorPhase: true,
            modNokiaUserId: user.userId,
        });
        setUpdateAssignment(true);
        fields.messageRef.current.showProcessingDiag();
    }

    const onSubmitAssignment = () => {
        if (fields.mode === MODES.REQUEST_MENTOR)
            requestMentor();
        else if (fields.mode === MODES.IN_PROGRESS)
            uploadOJTDocument();
        else if (fields.mode === MODES.EVALUATE)
            evaluateAssignment();
        else if (fields.mode === MODES.GO_BACK)
            restartAssignment();
        else
            console.error('INVALID submission mode!!');
        fields.messageRef.current.hideAreYouSureMessage();
    }

    // USER IS NOT VALID -> STOP
    useEffect(() => {
        if (validateUserError) {
            fields.messageRef.current.hideAreYouSureMessage();
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(validateUserError.data);
        }
        //eslint-disable-next-line
    }, [validateUserError]);

    // USER IS VALID (WITH MENTOR SELECTED) -> CREATE SHAREPOINT FOLDER
    useEffect(() => {
        if (mentorResponse) {
            console.log('User is valid and mentor has been selected. Proceeding to create a sharepoint folder...');
            mentorSelected.current = mentorResponse.mentor;
            createFolder(getSharepointPath());
        }
        //eslint-disable-next-line
    }, [mentorResponse]);

    // SHAREPOINT FOLDER ERROR -> STOP
    useEffect(() => {
        if (createFolderError) {
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(createFolderError);
        }
        //eslint-disable-next-line
    }, [createFolderError]);

    // SHAREPOINT FOLDER CREATED -> CREATE ASSIGNMENT IN DB
    useEffect(() => {
        if (createFolderSuccess) {
            console.log('Sharepoint folder created successfully!. Updating DB with the new assignment...');
            setUpdateAssignmentBody({
                nokiaUserId: user.userId,
                courseNumber: courseSelected.current.courseNumber,
                status: ASSIGNMENT_STATUS.ASSIGNED,
                sharepointPath: getSharepointPath(),
                mentor: mentorSelected.current
            });
            setUpdateAssignment(true);
        }
        // eslint-disable-next-line
    }, [createFolderSuccess]);

    // DB ASSIGNMENT UPDATE ERROR -> STOP
    useEffect(() => {
        if (updateAssignmentError) {
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(updateAssignmentError.data);
        }
        //eslint-disable-next-line
    }, [updateAssignmentError]);

    // DB ASSIGNMENT UPDATED -> SEND NOTIFICATION
    useEffect(() => {
        if (!updateAssignmentResponse)
            return;

        console.log('Assignment updated successfully!. Sending notification...');
        const email = new EmailInstance();
        if (fields.mode === MODES.REQUEST_MENTOR || fields.mode === MODES.GO_BACK) {
            email.from = 'do_not_reply@nokia.com';
            email.to = [user.email];
            email.cc = [mentorSelected.current];
            email.bcc = ['I_NKC_ADMINS@nokia.com'];
            email.subject = 'L3+ Mentor Assignment';
            email.body = getNewMentorEmailBody();
            queueEmail(email);
        }
        else if (fields.mode === MODES.IN_PROGRESS) {
            email.from = 'do_not_reply@nokia.com';
            email.to = [mentorSelected.current];
            email.cc = [user.email];
            email.bcc = ['I_NKC_ADMINS@nokia.com'];
            email.subject = 'L3+ OJT Document Uploaded';
            email.body = getOJTUploadEmailBody();
            queueEmail(email);
        }
        else if (fields.mode === MODES.EVALUATE) {
            const [emailToList, emailCCList] = getEvaluationToAndCCList();
            email.from = 'do_not_reply@nokia.com';
            email.to = emailToList;
            email.cc = emailCCList;
            email.bcc = ['I_NKC_ADMINS@nokia.com'];
            email.subject = 'L3+ OJT Document Evaluation';
            email.body = getEvaluationNotificationEmailBody();
            queueEmail(email);
        }
        else {
            console.error('INVALID submission mode!!');
        }
        //eslint-disable-next-line
    }, [updateAssignmentResponse]);

    // EMAIL NOTIFICATION ERROR -> STOP
    useEffect(() => {
        if (queueEmailError) {
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(queueEmailError.data);
        }
        //eslint-disable-next-line
    }, [queueEmailError]);

    // EMAIL NOTIFICATION SENT -> SUCCESS
    useEffect(() => {
        if (queueEmailSuccess) {
            console.log('Email notification queued successfully!');
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.successMessage('Successfully updated certification OJT!');
            fields.setPanelVisible(false);

            refreshAssignments();
        }
        //eslint-disable-next-line
    }, [queueEmailSuccess]);

    // OJT DOCUMENT UPLOAD ERROR -> STOP
    useEffect(() => {
        if (uploadOJTError) {
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(uploadOJTError);
        }
        //eslint-disable-next-line
    }, [uploadOJTError]);

    // OJT DOCUMENT UPLOADED SUCCESS -> UPDATE ASSIGNMENT IN DB
    useEffect(() => {
        if (uploadOJTSuccess) {
            console.log('OJT document uploaded successfully!. Updating current assignment in DB...');
            setUpdateAssignmentBody({
                nokiaUserId: user.userId,
                courseNumber: courseSelected.current.courseNumber,
                status: ASSIGNMENT_STATUS.UPLOADED,
                modNokiaUserId: user.userId,
            });
            setUpdateAssignment(true);
        }
        //eslint-disable-next-line
    }, [uploadOJTSuccess]);

    // OJT DOCUMENT RE-UPLOAD ERROR -> STOP
    useEffect(() => {
        if (reUploadOJTError) {
            fields.messageRef.current.hideProcessingDiag();
            fields.messageRef.current.errorMessage(reUploadOJTError.data);
        }
        //eslint-disable-next-line
    }, [reUploadOJTError]);

    // OJT DOCUMENT RE-UPLOADED SUCCESS -> SEND NOTIFICATION
    useEffect(() => {
        if (reUploadOJTSuccess) {
            console.log('OJT document re-uploaded successfully!. Sending notification...');
            const email = new EmailInstance();
            email.from = 'do_not_reply@nokia.com';
            email.to = [mentorSelected.current];
            email.cc = [user.email];
            email.bcc = ['I_NKC_ADMINS@nokia.com'];
            email.subject = 'L3+ OJT Document Uploaded';
            email.body = getOJTUploadEmailBody();
            queueEmail(email);
        }
        //eslint-disable-next-line
    }, [reUploadOJTSuccess]);

    return {
        onSubmitAssignment,
        reUploadOJTDocument
    }

}