import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppModules from './AppModules';
import { Home } from '../Modules/Home';

const AppRoutes = ({ routes }) => {

	return (
		<Routes>
			{routes.map((route, index) => {
				const { element, path } = route;

				return (
					<Route
						key={index}
						path={path}
						element={
							<Suspense fallback={<div>Loading...</div>}>
								{AppModules[element]}
							</Suspense>
						}
					/>
				);
			})}
			<Route key={999999} path="*" element={<Home />} />
		</Routes >	
	);
};

export default AppRoutes;
