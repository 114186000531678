import React, { useState, useEffect } from 'react';
import { useAuthentication } from '../../CustomHooks/Authentication';
import { usePageHits } from '../../CustomHooks/Monitoring';
import { CircularLoading } from './Loading';
import Frame from './Frame';

import '../../Styles/Frame.css';

const LegacyModule = ({ url, height, width, ...otherProps }) => {

	const getUserAuthentication = useAuthentication();
	usePageHits(url);

	const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
		const getUserInfo = async () => {
			const newUserInfo = await getUserAuthentication();
			setUserInfo(newUserInfo);
		}
		getUserInfo();
		// eslint-disable-next-line
	}, []);

	if (!userInfo) {
		console.log("Loading legacy module. Waiting for user info...");
		return (
			<div className="loading-container">
				<CircularLoading size={64} overlay />
			</div>
		)
	}

	console.log("User info retrieved! Loading frame... ");
	return (
		<Frame url={url} width={width} height={height} {...otherProps} />
	);
}
LegacyModule.defaultProps = {
	height: "900px",
	width: "100%"
}

export default LegacyModule;