import React from 'react';

import { MODES } from "../../CustomHooks/KTSessions";
import { TextInput } from "../Common";

import { useIsAdminOrKTLeader } from '../../CustomHooks/Global';

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import HelpCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/HelpCircleFillIcon';

const OverlayPanelTitle = ({ fields, textFields, errorFields }) => {

	const isAdminOrKtLeader = useIsAdminOrKTLeader();

	const handleShowTips = () => {
		fields.coachmarksRef.current.showTips();
	}

	const titleProps = {
		id: 'kt-session-title',
		label: '',
		value: textFields.title,
		setValue: textFields.setTitle,
		maxChars: 90,
		required: false,
		disabled: fields.isUpdate || (!fields.isOwner && !isAdminOrKtLeader),
		placeholder: "Give your KT Session a title...",
		autoComplete: 'off',
		showClearButton: !fields.isUpdate,
		error: errorFields.titleError,
		setError: errorFields.setTitleError
	};

	return (
		<div>
			{
				fields.panelMode && fields.panelMode.includes(MODES.EDIT) &&
				textFields.title
			}
			{
				fields.panelMode && fields.panelMode.includes(MODES.CREATE) &&
				<div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
					<TextInput {...titleProps} />
					<Tooltip placement="bottom" tooltip='Help' >
						<IconButton size='small' onClick={handleShowTips}>
							<HelpCircleFillIcon color='var(--ff-color-nokia-blue-400)' size={18} />
						</IconButton>
					</Tooltip>
				</div>
			}
		</div>
	);
};

export default OverlayPanelTitle;