import { forwardRef, useImperativeHandle, useState } from "react";

import { Message, SnackBar } from "../../Components/Common";

const MessageBundle = forwardRef(({ title, onSubmitAreYouSure }, ref) => {

    const [areYouSureMessage, setAreYouSureMessage] = useState('Are you sure?');
    const [showAreYouSureMessage, setShowAreYouSureMessage] = useState(false);
    const [infoMessageVariant, setInfoMessageVariant] = useState('SUCCESS');
    const [infoMessage, setInfoMessage] = useState('Sample Info Message');
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [showProcessingDiag, setShowProcessingDiag] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('Sample Snack Bar Message');
    const [snackBarVariant, setSnackBarVariant] = useState('INFO');
    
    useImperativeHandle(ref, () => ({
        infoMessage: (message) => {
            setInfoMessageVariant('INFO');
            setInfoMessage(message);
            setShowInfoMessage(true);
        },
        successMessage: (message) => {
            setInfoMessageVariant('SUCCESS');
            setInfoMessage(message);
            setShowInfoMessage(true);
        },
        warningMessage: (message) => {
            setInfoMessageVariant('WARNING');
            setInfoMessage(message);
            setShowInfoMessage(true);
        },
        errorMessage: (message) => {
            setInfoMessageVariant('ERROR');
            setInfoMessage(message);
            setShowInfoMessage(true);
        },
        showAreYouSureMessage: (message) => {
            setAreYouSureMessage(message);
            setShowAreYouSureMessage(true);
        },
        hideAreYouSureMessage: () => {
            setShowAreYouSureMessage(false);
        },        

        showProcessingDiag: () => {
            setShowProcessingDiag(true);
        },
        hideProcessingDiag: () => {
            setShowProcessingDiag(false);
        },

        infoSnack: (message) => {
            setSnackBarVariant('INFO');
            setSnackBarMessage(message);
            setShowSnackBar(true);
        },
        successSnack: (message) => {
            setSnackBarVariant('SUCCESS');
            setSnackBarMessage(message);
            setShowSnackBar(true);
        },
        warningSnack: (message) => {
            setSnackBarVariant('WARNING');
            setSnackBarMessage(message);
            setShowSnackBar(true);
        },
        errorSnack: (message) => {
            setSnackBarVariant('ERROR');
            setSnackBarMessage(message);
            setShowSnackBar(true);
        },
    }));

    return (
        <>
            <Message
                title={title}
                variant='WARNING'
                message={areYouSureMessage}
                open={showAreYouSureMessage}
                setOpen={setShowAreYouSureMessage}
                onSubmit={onSubmitAreYouSure}
                actionButtonLabel='Yes'
                cancelButtonLabel='No'
            />
            <Message
                title={title}
                variant={infoMessageVariant}
                message={infoMessage}
                open={showInfoMessage}
                setOpen={setShowInfoMessage}
                actionButtonLabel="OK"
                onSubmit={() => setShowInfoMessage(false)}
            />
            <Message
                title={title}
                message='Please wait while request is processed...'
                open={showProcessingDiag}
                withLoading
            />
            <SnackBar
                variant={snackBarVariant}
                open={showSnackBar}
                setOpen={setShowSnackBar}
                message={snackBarMessage}
            />
        </>
                
    );

})
MessageBundle.defaultProps = {
    title: '',
    onSubmitAreYouSure: () => { }
}

export default MessageBundle;