import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import Button from '@mui/material/Button';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';

import "../../Styles/Dialog.css";

import { CircularLoading } from ".";

const Dialog = forwardRef(({ title, text, withInputField, withLoading, actionButtonLabel, cancelButtonLabel, variant, open, setOpen, onSubmit }, ref) => {

	const setError = Object.keys(withInputField).length > 0 ? withInputField.setError ?? null : null;
	const defaultValue = Object.keys(withInputField).length > 0 ? withInputField.defaultValue ?? '' : '';
	const formValue = useRef(defaultValue);

	useImperativeHandle(ref, () => ({
		value: () => formValue.current,
	}));

	useEffect(() => {
		if (open && defaultValue) {
			formValue.current = defaultValue;
			setError && setError(false);
		}
	}, [open, defaultValue, setError]);

	const handleOnChange = (e) => {
		if (withInputField.setError)
			withInputField.setError(false);
		formValue.current = e.target.value;
	};

	return (
		<MuiDialog open={open}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Alert severity={variant}>
					<DialogContentText>{text}</DialogContentText>
				</Alert>
				{
					withLoading && <CircularLoading style={{ width: '48px', height: '48px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1.5em' }} />
				}
				{
					Object.keys(withInputField).length > 0 &&
					<TextField
						autoFocus
						fullWidth
						margin="dense"
						label={withInputField.inputLabel ?? ''}
						variant="standard"
						required={withInputField.required ?? false}
						error={withInputField.error ?? false}
						disabled={withInputField.disabled ?? false}
						defaultValue={withInputField.defaultValue ?? ''}
						helperText={withInputField.error && withInputField.errorMessage ? withInputField.errorMessage : ''}
						onChange={handleOnChange}
					/>
				}
			</DialogContent>
			<DialogActions>
				{cancelButtonLabel && <Button variant='outlined' onClick={() => setOpen(false)}>{cancelButtonLabel}</Button>}
				{actionButtonLabel && <Button variant='contained' onClick={onSubmit}>{actionButtonLabel}</Button>}
			</DialogActions>
		</MuiDialog>
	);
});

Dialog.defaultProps = {
	title: '',
	text: '',
	withInputField: {},
	actionButtonLabel: '',
	cancelButtonLabel: '',
	variant: 'info', // error, warning, info, success
	onSubmit: () => { }
}

export default Dialog;