import { useCallback, useMemo } from "react";
import Env from "../CustomObjects/Environment";

import { useFetch } from "./Fetch";

export const useCoachmarks = (module) => {

    const [coachmarks] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/coachmarks', true, { module });

    const getGroupedCoachmarks = useCallback(() => {
        const groupedCoachmarks = coachmarks.reduce((acc, curr) => {
            const existingGroup = acc.find(group => group.length && group[0].id === curr.id);
            if (existingGroup)
                existingGroup.push(curr);
            else
                acc.push([curr]);
            return acc;
        }, []);

        return groupedCoachmarks;
    }, [coachmarks]);

    const groupedCoachmarks = useMemo(() => {
        if (!coachmarks || coachmarks.length === 0)
            return [];
        return getGroupedCoachmarks()
    }, [coachmarks, getGroupedCoachmarks]);
 
    return {
        coachmarks,
        groupedCoachmarks
    }
}