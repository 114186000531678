
import React from 'react';
import { useNavigate } from 'react-router-dom';

import LaunchPad, {
    LaunchPadProductName,
    LaunchPadHeader,
    LaunchPadContent,
    LaunchPadTile,
    LaunchPadTileTitle,
    LaunchPadTileIcon,
    LaunchPadHeaderInner
} from '@nokia-csf-uxr/ccfk/LaunchPad';

import Typography from '@nokia-csf-uxr/ccfk/Typography';
//import Icon from '@nokia-csf-uxr/ccfk/Icon';
//import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';

import LaunchpadAdministrationIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LaunchpadAdministrationIcon';
import HistoryIcon from '@nokia-csf-uxr/ccfk-assets/legacy/HistoryIcon';
import GpsFixedIcon from '@nokia-csf-uxr/ccfk-assets/legacy/GpsFixedIcon';
import EnterpriseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/EnterpriseIcon';
import DocumentSettingsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DocumentSettingsIcon';
import DocumentLinkMultipleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DocumentLinkMultipleIcon';
import MessageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MessageIcon';
import CellClusterIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CellClusterIcon';

import {LEGACY_COLOR_NOKIA_BLUE_300 as DEFAULT_ADMINISTRATION} from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';

import { useLeftMenu } from '../../CustomHooks/Global';

const Admin = (props) => {

    const ICON_SIZE = 50;

    const navigate = useNavigate();
    const adminMenu = useLeftMenu().filter(e => e.groupLabel === 'admin');

    const isActive = (element) => adminMenu.some(e => e.element === element)

    const handleClick = (element) => {
        navigate(adminMenu.filter(e => e.element === element)[0].path);
    }

    const adminItems = [
        {
            label: "User and Role Management",
            element: "userroles",
            icon: LaunchpadAdministrationIcon
        },
        //{
        //    label: "Yammer Groups & CoP",
        //    element: "yammergroupsandcop",
        //    icon: Icon,
        //    source: "assets/images/group.svg"
        //},
        {
            label: "Focus Areas",
            element: "focusareas",
            icon: GpsFixedIcon
        },
        {
            label: "Customers",
            element: "customers",
            icon: EnterpriseIcon
        },
        {
            label: "Document Type and Subtypes",
            element: "documenttypeandsubtypes",
            icon: DocumentSettingsIcon
        },
        {
            label: "Document Infrastructure",
            element: "documentinfrastructure",
            icon: DocumentLinkMultipleIcon
        },
        {
            label: "NKC Notifications",
            element: "nkcnotifications",
            icon: MessageIcon
        },
        {
            label: "Core Engineering",
            element: "coreengineering",
            icon: CellClusterIcon
        },
        {
            label: "Power Search X History",
            element: "powersearchhistory",
            icon: HistoryIcon
        },
        {
            label: "Power Search X Configuration",
            element: "powersearchxconfiguration",
            icon: CellClusterIcon
        }
    ];

    const adminSlides = adminItems.map((item, index) => {
        if (isActive(item.element)) {
            return (
                <LaunchPadTile key={index} aria-label={item.label} onClick={() => handleClick(item.element)} >
                    <LaunchPadTileIcon>
                        <item.icon src={item.source} size={ICON_SIZE} color={DEFAULT_ADMINISTRATION} />
                    </LaunchPadTileIcon>
                    <LaunchPadTileTitle>{item.label}</LaunchPadTileTitle>
                </LaunchPadTile>
            );
        } else {
            return undefined;
        }
    });

    return (
        <LaunchPad variant='default-custom' {...props}>
            <LaunchPadHeader>
                <LaunchPadHeaderInner>
                    {/*<HorizontalDivider />*/}
                    <LaunchPadProductName>
                        <Typography variant="default" typography="HEADLINE_ALPHA">Admin</Typography>
                    </LaunchPadProductName>
                </LaunchPadHeaderInner>
            </LaunchPadHeader>
            <LaunchPadContent>
                { adminSlides }
            </LaunchPadContent>
        </LaunchPad>
    );
}

export default Admin;