
import InputEditIcon from '@nokia-csf-uxr/ccfk-assets/legacy/InputEditIcon';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import QuickHelpContainer from '@nokia-csf-uxr/ccfk/QuickHelp';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

const ContactUs = () => {
    
    return (
        <QuickHelpContainer id='contact-us' >
            <Tooltip tooltip="JSM Customer Portal" placement="bottom" >
                <IconButton variant={"app-banner"}>
                    <a href="https://jiradc2.ext.net.nokia.com/servicedesk/customer/portal/30" target="_blank" rel="noreferrer">
                        <InputEditIcon color="white" />
                    </a>
                </IconButton>
            </Tooltip>
        </QuickHelpContainer>
    );
}

export default ContactUs;