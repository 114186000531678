import { useState, useEffect, useRef, useMemo, useCallback } from "react";

import Env from "../CustomObjects/Environment";
import { isBlankText, validateEmail, timezones } from '../CustomObjects/Utils';
import { useFetch } from './Fetch';
import { useUser, useIsAdminOrKTLeader } from "./Global";
import { useSharepoint } from './Sharepoint';
import { useActions } from "./Actions";

import dayjs from 'dayjs';

export const MODES = {
	CREATE: 'create',
	EDIT: 'edit',
	CREATE_FINISHED: 'createFinished',
	EDIT_FINISHED: 'editFinished'
}

export const DEFAULT_DURATION = 15;

const descriptionIsInvalid = (desc) => /teams\.microsoft\.com/.test(desc);

export const useKTSessionsFields = (updateKTSessionsTable) => {
	const user = useUser();
	const isAdminOrKtLeader = useIsAdminOrKTLeader();
	
	const [panelVisible, setPanelVisible] = useState(false);
	const [currentKTData, setCurrentKTData] = useState({});
	const [panelMode, setPanelMode] = useState(undefined);
	const [message, setMessage] = useState('');
	const [messageVariant, setMessageVariant] = useState('info');
	const [showMessage, setShowMessage] = useState(false);
	const [openForwardDiag, setOpenForwardDiag] = useState(false);
	const [forwardEmail, setForwardEmail] = useState(user.email);
	const [forwardEmailError, setForwardEmailError] = useState(false);
	const [openDeleteDiag, setOpenDeleteDiag] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [openProcessingDiag, setOpenProcessingDiag] = useState(false);
	const [feedbackPanelVisible, setFeedbackPanelVisible] = useState(false);

	const [overlayPanelTitle, setOverlayPanelTitle] = useState('');
	const [overlayPanelActionButtonLabel, setOverlayPanelActionButtonLabel] = useState('');
	const [overlayPanelCancelButtonLabel, setOverlayPanelCancelButtonLabel] = useState('');
	const [overlayPanelCancelButtonVariant, setOverlayPanelCancelButtonVariant] = useState('secondary-bigger-font');
	const [disableCreateButton, setDisableCreateButton] = useState(false);
	const [disableCancelButton, setDisableCancelButton] = useState(false);
	const [recNum, setRecNum] = useState('-1');
	
	const [timezone, setTimezone] = useState(dayjs.tz.guess());
	const [startDateTime, setStartDateTime] = useState(dayjs());
	const [endDateTime, setEndDateTime] = useState(dayjs().add(30, 'minute'));
	const [targetAudience, setTargetAudience] = useState([]);
	const [solutions, setSolutions] = useState([]);
	const [inviteesAndParticipants, setInviteesAndParticipants] = useState([]);
	const [presenters, setPresenters] = useState([]);

	const [onlyPresentersCanShare, setOnlyPresentersCanShare] = useState(false);
	const [postToYammer, setPostToYammer] = useState(true);
	
	const [allInviteesAndParticipants, setAllInviteesAndParticipants] = useState([]);
	const [initialInviteesAndParticipants, setInitialInviteesAndParticipants] = useState([]);
	const [errorOcurredInParticipants, setErrorOcurredInParticipants] = useState(false);
	const currentMailList = useRef([]);

	const [materialsAndRecordingsLink, setMaterialsAndRecordingsLink] = useState([]);
	const [filesUploaded, setFilesUploaded] = useState([]);

	const [isUpdate, setIsUpdate] = useState(false);
	const [isRecent, setIsRecent] = useState(false);
	const [isOld, setIsOld] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [isPresenter, setIsPresenter] = useState(false);

	const [invitedNoResponse, setInvitedNoResponse] = useState([]);
	const [invitedAccepted, setInvitedAccepted] = useState([]);
	const [invitedDeclined, setInvitedDeclined] = useState([]);
	const [invitedTentative, setInvitedTentative] = useState([]);
	const [invitedParticipated, setInvitedParticipated] = useState([]);
	const [notInvitedParticipated, setNotInvitedParticipated] = useState([]);

	const [showChipLegend, setShowChipLegend] = useState(false);
	const [chipDefaultClass, setChipDefaultClass] = useState(null);

	const participantsRef = useRef(null);
	const presentersRef = useRef(null);
	const coachmarksRef = useRef(null);

	const finishProcessingKTSession = () => {
		setOpenProcessingDiag(false);

		if (error) {
			setMessage(error);
			setMessageVariant('error');
			setShowMessage(true);
		}
		else if (success) {
			console.log('Success message: ', success);
			setMessage(success);
			setMessageVariant('success');
			setShowMessage(true);
			updateKTSessionsTable();
			setPanelVisible(false);
		}
		setError('');
		setSuccess('');
	};

	const [setNumberOfActions] = useActions(finishProcessingKTSession);
	const createNewKTSession = useCreateKTSession(setNumberOfActions, setError, setSuccess);
	const updateKTSession = useUpdateKTSession(setNumberOfActions, setError, setSuccess);
	const deleteKTSession = useDeleteKTSession(setNumberOfActions, setError, setSuccess);
	const forwardKTSession = useForwardKTSession(setNumberOfActions, setError, setSuccess);

	const reset = () => {
		setRecNum('-1');
		setIsOwner(true);
		setIsPresenter(false);
		setIsRecent(false);
		setIsOld(false);
		setIsUpdate(false);
		setDisableCreateButton(false);
		setDisableCancelButton(false);
		setOverlayPanelTitle('');
		setOverlayPanelActionButtonLabel("Create");
		setOverlayPanelCancelButtonLabel("Cancel");
		setOverlayPanelCancelButtonVariant('secondary-bigger-font');
		setTimezone(dayjs.tz.guess());
		setStartDateTime(dayjs().add(60, 'minute'));
		setEndDateTime(dayjs().add(120, 'minute'));
		setTargetAudience([]);
		setSolutions([]);
		setInviteesAndParticipants([]);
		setErrorOcurredInParticipants(false);
		setInvitedNoResponse([]);
		setInvitedAccepted([]);
		setInvitedDeclined([]);
		setInvitedTentative([]);
		setInvitedParticipated([]);
		setNotInvitedParticipated([]);
		setOnlyPresentersCanShare(false);
		setPostToYammer(true);
		setFilesUploaded([]);
		setMaterialsAndRecordingsLink([]);
		setPresenters([]);
		setShowChipLegend(false);
		setChipDefaultClass('');
	}

	const onAddSessionClick = () => {
		setPanelMode(MODES.CREATE);
		setCurrentKTData({});
		setFeedbackPanelVisible(false);
	}

	const onEditClicked = ({ data }) => {
		setPanelMode(MODES.EDIT);
		setCurrentKTData(data);
		setFeedbackPanelVisible(false);
		setOpenProcessingDiag(true);
	};

	const onDeleteClicked = ({ data }) => {
		setCurrentKTData(data);
		setFeedbackPanelVisible(false);
		setOpenDeleteDiag(true);
	};

	const onDeleteSubmit = () => {
		setOpenDeleteDiag(false);
		setOpenProcessingDiag(true);

		const ktSessionBody = {
			recNum: currentKTData.sessionId.toString(),
			eventId: currentKTData.eventID ?? '',
			title: currentKTData.sessionName,
			status: currentKTData.sessionStatus,
			statusDetail: currentKTData.sessionStatusDetail,
			timezone: currentKTData.timeZone,
			startDateTime: currentKTData.startDate,
			endDateTime: currentKTData.endDate,
		}

		setNumberOfActions(1);
		deleteKTSession(ktSessionBody);
	};

	const onForwardClicked = ({ data }) => {
		setCurrentKTData(data);
		setFeedbackPanelVisible(false);
		setOpenForwardDiag(true);
	};

	const onForwardSubmit = () => {
		if (!forwardEmail || !validateEmail(forwardEmail)) {
			setForwardEmailError(true);
			return;
		}

		setOpenForwardDiag(false);
		setOpenProcessingDiag(true);

		const ktSessionBody = {
			recNum: currentKTData.sessionId.toString(),
			eventId: currentKTData.eventID,
			title: currentKTData.sessionName,
			timezone: currentKTData.timeZone,
			startDateTime: currentKTData.startDate,
			endDateTime: currentKTData.endDate,
			description: currentKTData.description,
			userEmail: currentKTData.userEmail,
			ratingLink: currentKTData.ratingLink,
			status: currentKTData.sessionStatus,
			statusDetail: currentKTData.sessionStatusDetail,
		}

		setNumberOfActions(1);
		forwardKTSession(ktSessionBody, { forwardEmail: forwardEmail });
	};

	const ignoreColumnList = useMemo(() => {
		var ignoreList = [
			'sessionid', 'sessionstatusdetail', 'timezone', 'enddate', 'startdateutc', 'enddateutc',
			'description', 'userid', 'ratingcount', 'legacysolutionshortnames', 'targetaudiencematch',
			'presenters', 'eventid', 'meetingid', 'ratinglink', 'participantlist', 'onlypresenterscanshare', 'postedtoyammer',
			'startyear', 'startyearandquarter', 'startyearandperiod', 'lmemail', 'team',
			'orglevel1', 'orglevel2', 'orglevel3', 'orglevel4', 'orglevel5', 'orglevel6', 'useremail'
		];
		if (!isAdminOrKtLeader)
			ignoreList.push('learningpoints', 'filesuploaded');
		return ignoreList;
	}, [isAdminOrKtLeader]);

	const timezoneList = timezones().map(entry => {
		const timezone = entry; // formatTimezone(entry);
		return { value: timezone, text: timezone };
	});
	
	return {
		DEFAULT_DURATION,
		panelVisible,
		setPanelVisible,
		currentKTData,
		setCurrentKTData,
		panelMode,
		setPanelMode,

		message,
		setMessage,
		messageVariant,
		setMessageVariant,
		showMessage,
		setShowMessage,

		openForwardDiag,
		setOpenForwardDiag,
		forwardEmail,
		setForwardEmail,
		forwardEmailError,
		setForwardEmailError,

		openDeleteDiag,
		setOpenDeleteDiag,

		error,
		setError,
		success,
		setSuccess,

		openProcessingDiag,
		setOpenProcessingDiag,

		feedbackPanelVisible,
		setFeedbackPanelVisible,

		overlayPanelTitle,
		setOverlayPanelTitle,

		overlayPanelActionButtonLabel,
		setOverlayPanelActionButtonLabel,
		disableCreateButton,
		setDisableCreateButton,

		overlayPanelCancelButtonLabel,
		setOverlayPanelCancelButtonLabel,
		overlayPanelCancelButtonVariant,
		setOverlayPanelCancelButtonVariant,
		disableCancelButton,
		setDisableCancelButton,

		recNum,
		setRecNum,
		timezone,
		setTimezone,
		startDateTime,
		setStartDateTime,
		endDateTime,
		setEndDateTime,
		targetAudience,
		setTargetAudience,
		solutions,
		setSolutions,
		inviteesAndParticipants,
		setInviteesAndParticipants,
		presenters,
		setPresenters,
		onlyPresentersCanShare,
		setOnlyPresentersCanShare,
		postToYammer,
		setPostToYammer,

		allInviteesAndParticipants,
		setAllInviteesAndParticipants,
		initialInviteesAndParticipants,
		setInitialInviteesAndParticipants,
		errorOcurredInParticipants,
		setErrorOcurredInParticipants,
		currentMailList,

		materialsAndRecordingsLink,
		setMaterialsAndRecordingsLink,
		filesUploaded,
		setFilesUploaded,

		isUpdate,
		setIsUpdate,
		isRecent,
		setIsRecent,
		isOld,
		setIsOld,
		isOwner,
		setIsOwner,
		isPresenter,
		setIsPresenter,

		invitedNoResponse,
		setInvitedNoResponse,
		invitedAccepted,
		setInvitedAccepted,
		invitedDeclined,
		setInvitedDeclined,
		invitedTentative,
		setInvitedTentative,
		invitedParticipated,
		setInvitedParticipated,
		notInvitedParticipated,
		setNotInvitedParticipated,

		showChipLegend,
		setShowChipLegend,
		chipDefaultClass,
		setChipDefaultClass,

		participantsRef,
		presentersRef,
		coachmarksRef,

		setNumberOfActions,

		reset,
		createNewKTSession,
		updateKTSession,
		onAddSessionClick,
		onEditClicked,
		onDeleteClicked,
		onDeleteSubmit,
		onForwardClicked,
		onForwardSubmit,
		ignoreColumnList,
		timezoneList,
	}

}

export const useInviteesAndParticipants = (fields) => {

	const [newInviteesAndParticipants, setNewInviteesAndParticipants, newInviteesAndParticipantsError, setNewInviteesAndParticipantsHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions/InviteesAndParticipants');

	const updateInviteesAndParticipants = (eventId, participantList) => {
		setNewInviteesAndParticipantsHeaders({
			participantList: participantList,
			eventId: eventId
		});
		setNewInviteesAndParticipants(true);
	}

	useEffect(() => {
		const updateParticipants = async () => {
			if (newInviteesAndParticipantsError) {
				console.error('Error when fetching KT Session participants: ', newInviteesAndParticipantsError);
				fields.setErrorOcurredInParticipants(true);
				fields.setMessage('Error when fetching KT Session participants. No updates will be allowed.')
				fields.setMessageVariant('error');
				fields.setShowMessage(true);
				fields.setOpenProcessingDiag(false);
			}

			if (newInviteesAndParticipants) {
				const invitedOrParticipated = newInviteesAndParticipants && newInviteesAndParticipants.length >= 0 ? newInviteesAndParticipants.filter(part => validateEmail(part.email) && (part.invited || part.participated)) : [];

				const invitedNoResponse = invitedOrParticipated.filter(person => (person.invitationResponse === 'NotResponded' || person.invitationResponse === 'None') && !person.participated).map(person => person.email);
				const invitedAccepted = invitedOrParticipated.filter(person => (person.invitationResponse === 'Accepted' || person.invitationResponse === 'Organizer') && !person.participated).map(person => person.email);
				const invitedDeclined = invitedOrParticipated.filter(person => person.invitationResponse === 'Declined' && !person.participated).map(person => person.email);
				const invitedTentative = invitedOrParticipated.filter(person => person.invitationResponse === 'TentativelyAccepted' && !person.participated).map(person => person.email);
				const invitedParticipated = invitedOrParticipated.filter(person => person.invited && person.participated).map(person => person.email);
				const notInvitedParticipated = invitedOrParticipated.filter(person => !person.invited && person.participated).map(person => person.email);

				fields.setInitialInviteesAndParticipants(invitedOrParticipated.map(person => person.email));
				fields.setInvitedNoResponse(invitedNoResponse);
				fields.setInvitedAccepted(invitedAccepted);
				fields.setInvitedDeclined(invitedDeclined);
				fields.setInvitedTentative(invitedTentative);
				fields.setInvitedParticipated(invitedParticipated);
				fields.setNotInvitedParticipated(notInvitedParticipated);

				const allInviteesAndParticipants = [
					...new Set([
						...invitedParticipated,
						...notInvitedParticipated,
						...invitedAccepted,
						...invitedTentative,
						...invitedDeclined,
						...invitedNoResponse,
					])
				];

				fields.setAllInviteesAndParticipants(allInviteesAndParticipants);
				fields.setInviteesAndParticipants(allInviteesAndParticipants);
				fields.setOpenProcessingDiag(false);
			}
		}

		updateParticipants();
	// eslint-disable-next-line
	}, [newInviteesAndParticipants, newInviteesAndParticipantsError]);

	return updateInviteesAndParticipants;
}

export const useKTInputFields = () => {
	const user = useUser();

	const [title, setTitle] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [description, setDescription] = useState('');
	const [newPresenter, setNewPresenter] = useState('');
	const [newPresenterDuration, setNewPresenterDuration] = useState(DEFAULT_DURATION);

	const reset = () => {
		setTitle('');
		setUserEmail(user.email);
		setDescription('');
	}

	return {
		title,
		setTitle,
		userEmail,
		setUserEmail,
		description,
		setDescription,
		newPresenter,
		setNewPresenter,
		newPresenterDuration,
		setNewPresenterDuration,
		reset
	}
}

export const useKTSessionsPanelErrors = (fields, inputFields) => {

	const [titleError, setTitleError] = useState(false);
	const [ownerError, setOwnerError] = useState(false);
	const [timezoneError, setTimezoneError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [audienceError, setAudienceError] = useState(false);
	const [solutionsError, setSolutionsError] = useState(false);
	const [inviteesAndParticipantsError, setInviteesAndParticipantsError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	const [presenterEmailError, setPresenterEmailError] = useState(false);

	const reset = () => {
		setTitleError(false);
		setOwnerError(false);
		setTimezoneError(false);
		setStartDateError(false);
		setEndDateError(false);
		setAudienceError(false);
		setSolutionsError(false);
		setInviteesAndParticipantsError(false);
		setDescriptionError(false);
		setPresenterEmailError(false);
	}

	useEffect(() => {
		if (fields.panelMode === MODES.CREATE || fields.panelMode === MODES.EDIT)
			reset();
	}, [fields.panelMode]);

	const validateForm = () => {

		const isInThePast = fields.isRecent || fields.isOld;

		reset();

		if (isBlankText(inputFields.title)) {
			setTitleError(true);
			return 'Please set a KT Session <b>title</b>.';
		}

		if (isBlankText(inputFields.userEmail) || !validateEmail(inputFields.userEmail)) {
			setOwnerError(true);
			return 'Please define an <b>owner</b>.';
		}

		if (!fields.timezone) {
			setTimezoneError(true);
			return '<b>Timezone</b> is not set. Please chose one.';
		}

		const startDateTimeTZ = dayjs.tz(fields.startDateTime.format('YYYY-MM-DD HH:mm:ss'), fields.timezone);
		const currentTimeTZ = dayjs.utc().tz(fields.timezone);

		if (!isInThePast && startDateTimeTZ.isBefore(currentTimeTZ)) {
			setStartDateError(true);
			return '<b>Start date</b> must be after the current time.';
		}

		if (fields.endDateTime.isBefore(fields.startDateTime)) {
			setEndDateError(true);
			return '<b>End date</b> must be after the start date.';
		}

		if (isBlankText(fields.targetAudience)) {
			setAudienceError(true);
			return 'Please select at least on <b>target audience</b>.';
		}

		if (fields.solutions.length === 0) {
			setSolutionsError(true);
			return 'Please select at least on <b>solution</b>.';
		}

		if (fields.inviteesAndParticipants.length === 0 || fields.participantsRef.current.hasChipsError()) {
			setInviteesAndParticipantsError(true);
			return 'Please add at least one <b>participant</b> in correct format.';
		}

		if (isBlankText(inputFields.description) || descriptionIsInvalid(inputFields.description)) {
			setDescriptionError(true);
			return 'Please make sure <b>description</b> is not empty and <b>does not contain "Teams" links</b> (Teams links are automatically generated).';
		}

		if (fields.presentersRef.current.hasNoChips() || fields.presentersRef.current.hasChipsError()) {
			setPresenterEmailError(true);
			return 'Please set at least one <b>presenter</b> with respective duration in correct format.';
		}

		return null;
	}

	return {
		validateForm,
		titleError,
		setTitleError,
		ownerError,
		setOwnerError,
		timezoneError,
		setTimezoneError,
		startDateError,
		setStartDateError,
		endDateError,
		setEndDateError,
		audienceError,
		setAudienceError,
		solutionsError,
		setSolutionsError,
		inviteesAndParticipantsError,
		setInviteesAndParticipantsError,
		descriptionError,
		setDescriptionError,
		presenterEmailError,
		setPresenterEmailError
	}
}

export const useMailingList = (fields) => {

	const [newMailingList, setNewMailingList, , setNewMailingListHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions/MailingList');

	const updateMailingList = () => {
		console.log('UPDATING MAILING LIST');

		setNewMailingListHeaders({
			products: fields.solutions,
			targetAudience: fields.targetAudience,
		});
		setNewMailingList(true);
	}

	useEffect(() => {
		const mailList = newMailingList && newMailingList.length > 0 ? newMailingList : [];
		const manualEmails = fields.participantsRef.current ? fields.participantsRef.current.getChips().filter(inv => !fields.currentMailList.current.includes(inv)) : [];
		const allInviteesAndParticipants = [...new Set([...mailList, ...manualEmails])];

		fields.setAllInviteesAndParticipants(allInviteesAndParticipants);
		fields.setInviteesAndParticipants(allInviteesAndParticipants);
		fields.currentMailList.current = mailList;
	// eslint-disable-next-line
	}, [newMailingList]);

	return updateMailingList;
}

export const usePanelModes = (fields, inputFields, updateInviteesAndParticipants) => {
	const user = useUser();
	const isAdminOrKtLeader = useIsAdminOrKTLeader();

	const updateFieldsWithCurrentData = () => {
		const isOwner = fields.currentKTData.userEmail === user.email;
		const isPresenter = fields.currentKTData.presenters ? fields.currentKTData.presenters.split(',').some(p => p.split(' ~ ')[0] === user.email) : false;
		const isRecent = fields.currentKTData.sessionStatus === 'COMPLETE' && fields.currentKTData.sessionStatusDetail === 'RECENT';
		const isOld = fields.currentKTData.sessionStatus === 'COMPLETE' && fields.currentKTData.sessionStatusDetail === 'NONE';

		fields.setRecNum(fields.currentKTData.sessionId.toString());
		fields.setIsOwner(isOwner);
		fields.setIsPresenter(isPresenter);
		fields.setIsRecent(isRecent);
		fields.setIsOld(isOld);
		inputFields.setUserEmail(fields.currentKTData.userEmail ?? '');
		fields.setIsUpdate(true);
		fields.setDisableCreateButton(!isOwner && !isAdminOrKtLeader);
		fields.setDisableCancelButton((!isOwner || isRecent || isOld) && !isAdminOrKtLeader);
		fields.setOverlayPanelActionButtonLabel("Update");
		inputFields.setTitle(fields.currentKTData.sessionName);
		fields.setOverlayPanelTitle(fields.currentKTData.sessionName);
		fields.setOverlayPanelCancelButtonLabel("Delete");
		fields.setOverlayPanelCancelButtonVariant('critical-bigger-font');

		const localTimezone = dayjs.tz.guess();
		fields.setTimezone(localTimezone);
		fields.setStartDateTime(dayjs.tz(fields.currentKTData.startDate, fields.currentKTData.timeZone).tz(localTimezone));
		fields.setEndDateTime(dayjs.tz(fields.currentKTData.endDate, fields.currentKTData.timeZone).tz(localTimezone));

		fields.setTargetAudience(fields.currentKTData.targetAudience.split(','));
		fields.setSolutions(fields.currentKTData.solutionShortNames.split(','));
		inputFields.setDescription(fields.currentKTData.description);
		fields.setFilesUploaded([]);
		fields.setMaterialsAndRecordingsLink(fields.currentKTData.materialsLink ? fields.currentKTData.materialsLink.split('|') : []);
		fields.setInviteesAndParticipants([]);
		fields.setErrorOcurredInParticipants(false);
		updateInviteesAndParticipants(
			fields.currentKTData.eventID,
			fields.currentKTData.participantList ? fields.currentKTData.participantList.split(';') : [],
		);
		fields.setPresenters(fields.currentKTData.presenters ? fields.currentKTData.presenters.split(',') : []);
		fields.setOnlyPresentersCanShare(fields.currentKTData.onlyPresentersCanShare);
		fields.setPostToYammer(fields.currentKTData.postedToYammer);
		fields.setShowChipLegend(true);
		fields.setChipDefaultClass(isRecent || isOld ? 'invited-participated' : null);
	}

	useEffect(() => {
		if (fields.panelMode === MODES.CREATE) {
			fields.reset();
			inputFields.reset();
			fields.setPanelMode(MODES.CREATE_FINISHED);
			fields.setPanelVisible(true);
		}
		else if (fields.panelMode === MODES.EDIT) {
			updateFieldsWithCurrentData();
			fields.setPanelMode(MODES.EDIT_FINISHED);
			fields.setPanelVisible(true);
		}
	// eslint-disable-next-line
	}, [fields.panelMode]);
}

const useKTSessionOperation = (
	type, successMessage, failureMessage, actionName = null,
	setNumberOfActions, setError, setSuccess, 
	createSharepointFolder = false, uploadFilesIntoSharepoint = false
) => {

	const ktSessionBodyRef = useRef(null);
	const ktSessionHeadersRef = useRef(null);
	const ktSessionHasChangedRef = useRef(true);

	const url = Env.BACKEND_SERVER_URL + 'KTSessions' + (actionName ? '/' + actionName : '');
	const [actionResponse, setDoAction, actionError, setActionHeaders, setActionBody] = useFetch(url, false, {}, type);
	const [
		successInSharepointFolderCreation, errorInSharepointFolderCreation,
		successInSharepointFileUpload, errorInSharepointFileUpload, 
		createKTSessionSharepointFolder, uploadKTSessionFilesToSharepoint
	] = useSharepoint("sharepointDocumentFolderKT", false, true);

	const doActionInDB = useCallback(() => {
		if (ktSessionHasChangedRef.current) {
			if (ktSessionBodyRef.current) setActionBody(ktSessionBodyRef.current);
			if (ktSessionHeadersRef.current) setActionHeaders(ktSessionHeadersRef.current);
			setDoAction(true);
		}
		else {
			console.log(successMessage);
			setSuccess(successMessage);
			setNumberOfActions((prev) => prev - 1);
		}
	// eslint-disable-next-line
	}, []);

	// SHAREPOINT ACTION RESPONSE
	useEffect(() => {
		if (successInSharepointFolderCreation || successInSharepointFileUpload) {
			console.log(successInSharepointFolderCreation ? successInSharepointFolderCreation.message : successInSharepointFileUpload.message);
			console.log(`Doing a ${type} for KT Session in DB...`);
			doActionInDB();
		}
		else if (errorInSharepointFolderCreation || errorInSharepointFileUpload) {
			console.error(`Error in sharepoint operation: ${errorInSharepointFolderCreation ? errorInSharepointFolderCreation : errorInSharepointFileUpload}`);
			setError(`${failureMessage}: ${errorInSharepointFolderCreation ? errorInSharepointFolderCreation : errorInSharepointFileUpload}`);
			setNumberOfActions((prev) => prev - 1);
		}
	// eslint-disable-next-line
	}, [successInSharepointFolderCreation, errorInSharepointFolderCreation, successInSharepointFileUpload, errorInSharepointFileUpload]);

	// DB ACTION RESPONSE
	useEffect(() => {
		if (actionResponse) {
			console.log(successMessage);
			setSuccess(successMessage);
		}
		else if (actionError) {
			console.error(`${failureMessage}: ${actionError}`);
			setError(`${failureMessage}: ${actionError}`);
		}
		if (actionResponse || actionError)
			setNumberOfActions((prev) => prev - 1);
	// eslint-disable-next-line
	}, [actionResponse, actionError]);

	const doAction = (ktSessionBody = null, ktSessionHeaders = null, sessionName = null, sharepointFiles = null, sessionHasChanged = true) => {
		ktSessionBodyRef.current = ktSessionBody;
		ktSessionHeadersRef.current = ktSessionHeaders;
		ktSessionHasChangedRef.current = sessionHasChanged;

		if (createSharepointFolder && sessionName) createKTSessionSharepointFolder(sessionName);
		else if (uploadFilesIntoSharepoint && sessionName && sharepointFiles && sharepointFiles.length > 0) uploadKTSessionFilesToSharepoint(sessionName, sharepointFiles);
		else doActionInDB();
	}

	return doAction;
}

export const useCreateKTSession = (setNumberOfActions, setError, setSuccess) => {

	const createNewKTSession = useKTSessionOperation(
		'POST',
		'KT Session created successfully!<br /><br /><b>NOTE:</b> The meeting recording will automatically start and will be accessible through the session\'s materials link a few hours after the session ends.',
		'KT Session creation error!.',
		null,
		setNumberOfActions,
		setError,
		setSuccess,
		true
	);

	return createNewKTSession;
}

export const useUpdateKTSession = (setNumberOfActions, setError, setSuccess) => {

	const updateKTSession = useKTSessionOperation(
		'PATCH',
		'KT Session updated successfully!',
		'KT Session update error!.',
		null,
		setNumberOfActions,
		setError,
		setSuccess,
		false,
		true
	);

	return updateKTSession;
}

export const useDeleteKTSession = (setNumberOfActions, setError, setSuccess) => {

	const deleteKTSession = useKTSessionOperation(
		'PATCH',
		'KT Session deleted/cancelled successfully!',
		'KT Session deleted/cancelled error!.',
		'Delete',
		setNumberOfActions,
		setError,
		setSuccess
	);

	return deleteKTSession;
}

export const useForwardKTSession = (setNumberOfActions, setError, setSuccess) => {

	const forwardKTSession = useKTSessionOperation(
		'POST',
		'KT Session forwarded successfully!',
		'KT Session forwarded error!.',
		'Forward',
		setNumberOfActions,
		setError,
		setSuccess
	);

	return forwardKTSession;
}
