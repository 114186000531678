import { forwardRef, useImperativeHandle, useState } from 'react';

import { styled } from '@mui/material/styles';
import { TreeView as TreeViewMUI } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CollapseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CollapseIcon';
import TriangleArrowRightFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/TriangleArrowRightFillIcon';
import TriangleArrowDownFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/TriangleArrowDownFillIcon';

import '../../Styles/TreeView.css';

const StyledTreeItem = styled(TreeItem)(() => ({
    '& .MuiTreeItem-group': {
        paddingLeft: 18,
        marginLeft: 15,
    },

    '& .MuiTreeItem-content': {
        borderRadius: '1em',
        marginTop: 2,
        marginBottom: 2,
        '&:hover': {
            backgroundColor: 'var(--ff-color-nokia-blue-100)',
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: 'var(--ff-color-purple-100)',
        },
        '&.Mui-selected.Mui-focused, &.Mui-selected.Mui-focused:hover': {
            backgroundColor: 'var(--ff-color-purple-200)',
        },
    },
}));

const TreeView = forwardRef(({ data, label, value, setValue, nameKey, childrenKey, withCollapseButton, noDataMessage }, ref) => {

    const [expanded, setExpanded] = useState([]);

    useImperativeHandle(ref, () => ({
        collapseTree: () => {
            setExpanded([]);
        },
    }));

    const renderTree = (node) => {
        const nodeId = node[nameKey];

        return (
            <StyledTreeItem
                key={nodeId}
                nodeId={nodeId}
                label={label && label.component ? <label.component node={node} {...label.componentParams} /> : nodeId}
            >
                {
                    node[childrenKey] && Array.isArray(node[childrenKey])
                        ? node[childrenKey].map((node) => renderTree(node))
                        : null
                }
            </StyledTreeItem>
        );
    }

    const onNodeSelect = (_, nodeId) => {
        setValue(nodeId);
    }

    const onNodeToggle = (_, nodeId) => {
        setExpanded(nodeId);
    };

    const onCollapseAll = () => {
        setExpanded([]);
    }

    return (
        <div className='tree-view-container'>
            {
                data && Array.isArray(data) && data.length > 0 && withCollapseButton &&
                <div>
                    <Tooltip placement="top" tooltip="Collapse" modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]} >
                        <IconButton onClick={onCollapseAll}>
                            <CollapseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {
                data && Array.isArray(data) && data.length > 0 &&
                <TreeViewMUI
                    defaultCollapseIcon={<TriangleArrowDownFillIcon />}
                    defaultExpandIcon={<TriangleArrowRightFillIcon />}
                    selected={value}
                    onNodeSelect={onNodeSelect}
                    expanded={expanded}
                    onNodeToggle={onNodeToggle}
                >
                    {
                        data.map((node) => renderTree(node))
                    }
                </TreeViewMUI>
            }
            {
                (!data || (Array.isArray(data) && data.length === 0)) &&
                <div className='tree-view-no-data'>
                    <Typography>{noDataMessage}</Typography>
                </div>
            }
        </div>
    );
});
TreeView.defaultProps = {
    value: '',
    setValue: () => {},
    nameKey: 'name',
    childrenKey: 'children',
    withCollapseButton: false,
    noDataMessage: 'Tree data has no elements...',
}

export default TreeView;