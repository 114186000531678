import { useEffect } from 'react';

export const useBlurOnPopState = () => {
    useEffect(() => {
        const handlePopstate = () => {
            document.activeElement.blur();
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);
}