import React, { useEffect, useState } from 'react';

import StatusBar, {
    StatusBarSection,
    StatusBarPaginationIcon,
    StatusBarPagination,
    StatusBarText,
    StatusBarTextTitle,
    StatusBarPaginationInput
} from '@nokia-csf-uxr/ccfk/StatusBar';

import ChevronLeftIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronLeftIcon';
import ChevronRightIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronRightIcon';
import FirstPageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FirstPageIcon';
import LastPageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LastPageIcon';

const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';

const Pagination = ({ onFirstPage, onPreviousPage, onNextPage, onLastPage, onGoToPage, currentPage, totalRows, totalPages }) => {

    const [pageValue, setPageValue] = useState(currentPage);

    useEffect(() => {
        setPageValue(currentPage);
    }, [currentPage]);

    const handleKeyDown = (event) => {
        if (event.shiftKey)
            return;

        if (event.key === KEY_DOWN || event.key === KEY_LEFT) {
            if (pageValue > 1) {
                setPageValue(parseInt(pageValue) - 1);
                onPreviousPage();
            }
        }
        else if (event.key === KEY_UP || event.key === KEY_RIGHT) {
            if (pageValue < totalPages) {
                setPageValue(parseInt(pageValue) + 1);
                onNextPage();
            }
        }
        else if (event.key === KEY_ENTER) {
            onGoToPage(pageValue - 1);
        }
    }

    const handleOnChange = (event) => {
        const value = event.target.value;
        if (!value)
            return;
        if (value <= 0)
            return;
        if (value > totalPages)
            return;

        setPageValue(event.target.value);
    }

    return (
        <StatusBar>
            <StatusBarPagination>
                <StatusBarPaginationIcon
                    iconButtonProps={{
                        disabled: currentPage === 1,
                        onClick: onFirstPage
                    }}
                >
                    <FirstPageIcon />
                </StatusBarPaginationIcon>
                <StatusBarPaginationIcon
                    iconButtonProps={{
                        disabled: currentPage === 1,
                        onClick: onPreviousPage
                    }}
                >
                    <ChevronLeftIcon />
                </StatusBarPaginationIcon>
                <StatusBarTextTitle>Page</StatusBarTextTitle>
                <StatusBarPaginationInput
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    value={pageValue}
                />
                <StatusBarText>/ {totalPages}</StatusBarText>
                <StatusBarPaginationIcon
                    iconButtonProps={{
                        disabled: currentPage === totalPages,
                        onClick: onNextPage
                    }}
                >
                    <ChevronRightIcon />
                </StatusBarPaginationIcon>
                <StatusBarPaginationIcon
                    iconButtonProps={{
                        disabled: currentPage === totalPages,
                        onClick: onLastPage
                    }}
                >
                    <LastPageIcon />
                </StatusBarPaginationIcon>
            </StatusBarPagination>
            <StatusBarSection>
                <StatusBarTextTitle>Count:</StatusBarTextTitle>
                <StatusBarText>{totalRows}</StatusBarText>
            </StatusBarSection>
        </StatusBar>
    );
}

export default Pagination;