import DataTableStatusColumn from '../Components/Datatable/DataTableStatusColumn';

import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import FileIcon from '@nokia-csf-uxr/ccfk-assets/latest/FileIcon';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);

export const dateCellRendererCalendar = ({ value }) => {
	if (value) 
		return dayjs(value).calendar();
	else
		return value;
};

export const dateCellRendererLocalizedDayOnly = ({ value, defaultValue = '' }) => {
	if (value)
		return dayjs(value).format('ll');
	else
		return defaultValue;
};

export const dateCellRendererLocalized = ({ value }) => {
	if (value)
		return dayjs(value).format('lll');
	else
		return value;
};

export const dateCellRendererLocalTime = ({ value, data, defaultValue }) => {
	if (value)
		return dayjs.tz(value, data.timeZone).tz(dayjs.tz.guess()).format('lll');
	else if (defaultValue)
		return defaultValue;
	else
		return value;
};

export const statusBarRenderer = ({ value, valueColorMap, valueIconMap }) => {

	var color = '#FFFFFF';
	if (valueColorMap && value in valueColorMap)
		color = valueColorMap[value];

	var icon = '';
	if (valueIconMap && value in valueIconMap)
		icon = valueIconMap[value];

	return (
		<DataTableStatusColumn color={color} Icon={icon} />
	);
}

export const iconRenderer = ({ value, valueColorMap, valueIconMap }) => {

	if (!value)
        return <></>;

	var color = '#FFFFFF';
	if (valueColorMap && value in valueColorMap)
		color = valueColorMap[value];

	var Icon = '';
	if (valueIconMap && value in valueIconMap)
		Icon = valueIconMap[value];

	return (
		<div style={{ height: '100%', display: 'grid', alignItems: 'center' }}>
			<Icon color={color} style={{ color: color }} />
		</div>
	);
}

export const smileFaceRenderer = ({ value, minValue, maxValue }) => {

	if (!value)
		return (
			<div style={{height: '100%', display: 'grid', alignItems: 'center'}}>
				<SentimentNeutralIcon style={{ color: '#CCCCCC' }} />
			</div>
		);

	const valueToRender = value.toFixed(1);

	if (minValue === undefined || maxValue === undefined)
		return valueToRender;

	const styleProps = {
		display: 'flex',
		alignItems: 'center',
		gap: '0.4em'
	}

	const interval = ((maxValue - minValue) / 3).toFixed(0);

	if (valueToRender < Number(minValue + interval))
		return (
			<div style={styleProps}>
				<SentimentNeutralIcon style={{ color: '#FF7900' }} />
				<div style={{ color: '#FF7900' }}>{valueToRender}</div>
			</div>
		);

	else if (valueToRender >= interval && valueToRender <= Number(minValue + (2 * interval)))
		return (
			<div style={styleProps}>
				<InsertEmoticonOutlinedIcon style={{ color: '#FFBF02' }} />
				<div style={{ color: '#FFBF02' }}>{valueToRender}</div>
			</div>
		);

	else if (valueToRender > Number(minValue + (2 * interval)))
		return (
			<div style={styleProps}>
				<SentimentVerySatisfiedOutlinedIcon style={{ color: '#62AC00' }} />
				<div style={{ color: '#62AC00' }}>{valueToRender}</div>
			</div>
		);
}

export const commaSeparatedValue = ({ value }) => {
	if (value)
		return value.split(',').join(', ');
	else
		return value;
}

export const materialsLink = ({ value }) => {
	if (value) {
		const links = value.split('|');
		if (links.length === 1)
			return (
				<div className="stop-row-action">
					<a href={links[0]} target="_blank" rel="noreferrer">Link</a>
				</div>
			);
		else if (links.length === 2)
			return (
				<div className="stop-row-action">
					<a href={links[0]} target="_blank" rel="noreferrer">Link</a>, &nbsp;
					<a href={links[1]} target="_blank" rel="noreferrer">Recording</a>
				</div>
			);
	}
	return '';
}

export const link = ({ value, text }) => {
	return (
		<div className="stop-row-action">
			<a href={value} target="_blank" rel="noreferrer">{ text }</a>
		</div>
	);
}

export const hasFiles = ({ value }) => {
	if (value)
		return (
			<div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
				<FileIcon />
			</div>
		);
    else
        return '';
}