
import React, { useState } from 'react';
import Iframe from 'react-iframe';

import { CircularLoading } from './Loading';

import '../../Styles/Frame.css';

const Frame = ({ url, width, height, scrolling, className }) => {
    const [isLoaded, setIsLoaded] = useState(false);

	return (
		<>
			{
				!isLoaded &&
				<div className="loading-container">
					<CircularLoading size={64} overlay />
				</div>
			}
			<div style={{ width: !isLoaded ? '0' : '100%' }}>
				<Iframe url={url}
					width={width}
					height={height}
					id=""
					className={className}
					display="block"
					position="relative"
					scrolling={scrolling}
					onLoad={() => setIsLoaded(true)}
				/>
			</div>
		</>
	);
}

Frame.defaultProps = {
	scrolling: 'no',
	className: ''
}

export default Frame;