import { useEffect } from 'react';

import StatusBar, {
    StatusBarSection,
    StatusBarTextTitle
} from '@nokia-csf-uxr/ccfk/StatusBar';
import VerticalDivider from '@nokia-csf-uxr/ccfk/VerticalDivider';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import { Toggle } from "../../Components/Common";

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CollapseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CollapseIcon';
import FileCsvIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FileCsvIcon';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import { useFileExport } from '../../CustomHooks/FileExport';

import { STANDARD_CSV_MESSAGE } from '../../CustomObjects/Utils';

const KPIPlanningToolBar = ({ fields, onCollapseTeams, filterOrgTree, kpiReport, getKPIReport }) => {

    const { downloadCsv } = useFileExport();

    useEffect(() => {
        if (kpiReport)
            downloadCsv('KPIPlanningReport.csv', kpiReport);
    // eslint-disable-next-line
    }, [kpiReport]);

    return (
        <StatusBar className="toolbar-container">
            <StatusBarSection>
                <StatusBarTextTitle>
                    <Typography className="toolbar-title">Organization Tree {new Date().getFullYear()}</Typography>
                </StatusBarTextTitle>
            </StatusBarSection>
            <StatusBarSection className="toolbar-content">
                <Toggle
                    checked={fields.filterOrgsWithKPIs}
                    setChecked={fields.setFilterOrgsWithKPIs}
                    checkedText='Teams with data only'
                    uncheckedText='Teams with data only'
                    onChange={filterOrgTree}
                />
            </StatusBarSection>
            <StatusBarSection className="toolbar-buttons">
                <VerticalDivider />
                <Tooltip placement="top" tooltip="Collapse" modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]} >
                    <IconButton onClick={onCollapseTeams}>
                        <CollapseIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip placement="top" tooltip="Download KPI Report" modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]} >
                    <IconButton onClick={() => {
                        fields.messageRef.current.infoMessage(STANDARD_CSV_MESSAGE);
                        getKPIReport();
                    }}>
                        <FileCsvIcon />
                    </IconButton>
                </Tooltip>
            </StatusBarSection>
        </StatusBar>
    );

}

export default KPIPlanningToolBar;