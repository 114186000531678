import { useState, useEffect, useRef } from "react";

export const useURLParamsAction = (params = 'all', varAfterAction = null, callbackWhenParamsExist, callbackAfterAction) => {

	const [operationHasExecuted, setOperationHasExecuted] = useState(false);
	const paramsObj = useRef({});

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		const paramsAreValid =
			params === 'all' ||
			(params instanceof Array &&
				Array.from(queryParams).length === params.length &&
				Array.from(queryParams.keys()).every(p => params.includes(p)));

		if (!paramsAreValid)
			return;

		if (params === 'all') {
			Array.from(queryParams).forEach(([param, paramValue]) => {
				paramsObj.current[param] = paramValue;
			});
		} else {
			params.forEach(param => {
				const paramValue = queryParams.get(param);
				paramsObj.current[param] = paramValue;
			});
		}

		if (callbackWhenParamsExist) {
			setOperationHasExecuted(true);
			callbackWhenParamsExist(paramsObj.current);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (operationHasExecuted && callbackAfterAction)
			callbackAfterAction(paramsObj.current);
		// eslint-disable-next-line
	}, [varAfterAction]);


}