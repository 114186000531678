import { createContext, useContext, useState, useEffect, useRef } from "react";
import { FetchRequest, GetOpts } from "../CustomObjects/Fetch";
import { useMsalProps } from "../CustomHooks/Msal";
import { useAuthentication } from "./Authentication";
import { InteractionStatus } from "@azure/msal-browser";

import '@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css';
import tokens from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';

import { merge } from "lodash";

import Env from "../CustomObjects/Environment";

import baseTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-light-theme.json";
//import baseTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-AAA-dark-theme.json";
//import baseTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-AAA-light-theme.json";
//import baseTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-dark-theme.json";
import deltaTheme from "../Themes/NKC-custom-light-theme.json";

const theme = merge(baseTheme, deltaTheme);

const GlobalContext = createContext(undefined);

export const GlobalProvider = ({ children }) => {

	const { inProgress } = useMsalProps();
	const getUserAuthentication = useAuthentication();

	const [global, setGlobal] = useState({});
	const isRefreshingToken = useRef(false);

	const backendHeaders = (global) => {
		return {
			Username: global.authorization.email,
			Role: global.user.role,
			Authorization: `Bearer ${global.authorization.token}`
		};
	};

	const avatarHeaders = (global) => {
		return {
			Authorization: `Bearer ${global.authorization.accessToken}`
		}
	};

	useEffect(() => {

		if (!(inProgress === InteractionStatus.None))
			return;

		const globalVars = {
			authorization: {
				execute: async function () {
					return await getUserAuthentication();
				},
				process: function (global, response) {
					if (response) {
						return {
							email: response.account.username,
							token: response.idToken,
							accessToken: response.accessToken
						}
					}

					return global.authorization;
				}
			},
			avatar: {
				execute: async function (global) {
					if (!global.authorization.accessToken)
						return null;

					const response = await fetch('https://graph.microsoft.com/v1.0/me/photos/120x120/$value', { headers: avatarHeaders(global) });
					const imageBlob = await response.blob();

					if (response.status === 200)
						return URL.createObjectURL(imageBlob);

					return null;
				},
				process: function (_, response) {
					return response;
				}
			},
			user: {
				execute: async function (global) {
					if (!global.authorization.email)
						return null;

					const responseObj = await FetchRequest(Env.BACKEND_SERVER_URL + 'Global/authenticate', GetOpts(backendHeaders(global)));
					return responseObj.data;
				},
				process: function (global, response) {
					if (response) {
						return {
							firstName: response.user.firstName,
							lastName: response.user.lastName,
							name: response.user.fullName,
							email: response.user.email,
							username: response.user.login,
							userId: response.user.userId,
							organization: response.user.org,
							role: response.role
						};
					}

					return global.user;
				}
			},
			environment: {
				execute: function () { },
				process: function () {
					return {
						backend: Env.BACKEND_SERVER_URL,
						frontend: Env.FRONTEND_SERVER_URL,
						nkc: Env.NKC_SERVER_URL
					};
				}
			},
			leftMenu: {
				execute: async function (global) {
					if (!global.authorization.email)
						return null;

					const responseObj = await FetchRequest(Env.BACKEND_SERVER_URL + 'Global/leftmenu', GetOpts(backendHeaders(global)));
					return responseObj.data;
				},
				process: function (global, response) {
					if (response)
						return response;

					return global.leftMenu;
				}
			},
			theme: {
				execute: function () {
				},
				process: function (global) {
					return global.theme ? global.theme : theme;
				}
			},
			tokens: {
				execute: function () {
				},
				process: function (global) {
					return global.tokens ? global.tokens : tokens;
				}
			},
			userEmailsList: {
                execute: async function (global) {
                    if (!global.authorization.email)
                        return null;

					const responseObj = await FetchRequest(Env.BACKEND_SERVER_URL + 'Global/useremailslist', GetOpts(backendHeaders(global)));
                    return responseObj.data;
                },
                process: function (global, response) {
                    if (response)
                        return JSON.parse(response);

                    return global.userEmailsList;
                }
            }
		};

		async function loadGlobalVars() {
			var newGlobal = {
				authorization: {
					email: null,
					token: null,
					accessToken: null
				},
				avatar: null,
				user: {
					name: "",
					email: "",
					username: "",
					role: "viewer"
				},
				environment: {},
				leftMenu: [],
				userEmailsList: []
			};

			for (const key in globalVars) {
				const response = await globalVars[key].execute(newGlobal);
				newGlobal[key] = globalVars[key].process(newGlobal, response);
			}
			setGlobal(newGlobal);
		}

		/*console.log('THEME: ', theme);*/

		loadGlobalVars();
	}, [inProgress, getUserAuthentication]);

	return <GlobalContext.Provider value={{ global, setGlobal, isRefreshingToken }}>{children}</GlobalContext.Provider>;
}

export const useGlobal = () => useContext(GlobalContext);
export const useAuthorization = () => { const { global } = useGlobal(); return global.authorization; };
export const useAvatar = () => { const { global } = useGlobal(); return global.avatar; };
export const useUser = () => { const { global } = useGlobal(); return global.user; };
export const useLeftMenu = () => { const { global } = useGlobal(); return global.leftMenu; };
export const useEnvironment = () => { const { global } = useGlobal(); return global.environment; };
export const useTheme = () => { const { global } = useGlobal(); return global.theme; };
export const useTokens = () => { const { global } = useGlobal(); return global.tokens; };
export const useUserEmailsList = () => { const { global } = useGlobal(); return global.userEmailsList; };
export const useIsAdminOrKTLeader = () => { const user = useUser(); return user.role.toLowerCase().includes('admin') || user.role.toLowerCase().includes('ktleader'); };
export const useIsAdmin = () => { const user = useUser(); return user?.role.toLowerCase().includes('admin'); };
export const useIsTester = () => { const user = useUser(); return user?.role.toLowerCase().includes('nkctester'); };
export const useIsRole = (role) => { const user = useUser(); return user?.role.toLowerCase().includes(role.toLowerCase()); };
