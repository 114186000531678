import { useEffect, useRef, useState } from "react";
import Env from "../CustomObjects/Environment";

import { useFetch } from "./Fetch";

export const PARAM_TYPES = {
    STRING: 'string',
    ARRAY: 'array',
    SELECT: 'select',
    BOOLEAN: 'boolean',
    RANGE: 'range'
}

export const CONFIG_LABEL_MAP = {
    'llm_gw': 'LLM Gateway',
    'ps_cm': 'Conversational Module',
    'ps_ctx_mgr': 'Context Manager',
    'ps_ks': 'Knowledge Search',
    'ps_mb': 'Message Builder',
}

export const MODELS = {
    'GPT35': {
        context: '4K'
    },
    'GPT35_16K': {
        context: '16K'
    },
    'GPT35_TURBO': {
        context: '4K'
    },
    'GPT4': {
        context: '8K'
    },
    'GPT4_32K': {
        context: '32K'
    },
    'GPT4_TURBO': {
        context: '8K'
    },
    'Llama_2_7b_chat_hf': {
        context: '4K'
    },
    'Mixtral_8_7b_instruct': {
        context: '32K'
    },
    'gemini_10_pro_002': {
        context: '32K'
    },
    'gemini_15_pro_001': {
        context: '32K'
    }
}

export const CONFIG_MODES = {
    USER: 'User',
    GLOBAL: 'Global'
}

export const SUBMIT_MODES = {
    COMMIT_GENERAL: 'CommitGeneral',
    COMMIT_TO_GLOBAL: 'CommitToGlobal',
    DELETE_USER_CONFIG: 'DeleteConfig',
    COPY_FROM_GLOBAL: 'CopyFromGlobal'
}

export const removeConfigParam = (obj, path) => {
    if (!obj || !path || !Array.isArray(path) || path.length === 0)
        return obj;
    let lastPath = path.pop();
    let currentParam = obj;
    path.forEach((pathPart) => {
        currentParam = currentParam[pathPart] ?? currentParam;
    });
    if (currentParam)
        delete currentParam[lastPath];
}

export const usePowerSearchConfiguration = (messageRef, configMode, setConfigMode, configActive, setConfigActive, submitMode, setSubmitMode, configUserTarget, setConfigUserTarget) => {

    const [currentConfiguration, setFetchConfiguration, , setFetchConfigurationHeaders] = useFetch(
        Env.BACKEND_SERVER_URL + 'PowerSearch/CurrentConfiguration',
        true,
        { psxUserEmail: configUserTarget, mode: configMode },
        'GET'
    );

    const [currentConfigurationUserEmails, setFetchConfigurationUserEmails] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/ConfigurationPSXUserEmails');

    const [
        submitConfigurationSuccess,
        setSubmitConfiguration,
        submitConfigurationError,
        setSubmitConfigurationHeaders,
        setSubmitConfigurationBody
    ] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/Configuration', false, {}, 'POST');

    const [
        commitConfigurationSuccess,
        setCommitConfiguration,
        commitConfigurationError,
        setCommitConfigurationHeaders,
        setCommitConfigurationBody
    ] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/CommitConfigToGlobal', false, {}, 'POST');

    const [
        deleteConfigurationSuccess,
        setDeleteConfiguration,
        deleteConfigurationError,
        setDeleteConfigurationHeaders
    ] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/DeleteConfiguration', false, {}, 'POST');
    
    const [configurationWasLoaded, setConfigurationWasLoaded] = useState(false);
    const [configurationUserEmails, setConfigurationUserEmails] = useState(null);
    
    const configuration = useRef(null);
    const configTypes = useRef(null);

    const getConfigurationTypeData = (type, parameter) => {
        if (configTypes.current) {
            let configTypeData = configTypes.current.filter((configType) => configType.type === type && configType.parameter === parameter);
            if (configTypeData && configTypeData.length > 0)
                return configTypeData[0];
        }

        return null;
    }

    const getDefaultParamValue = (configTypeData) => {
        if (configTypeData && configTypeData.valueType === PARAM_TYPES.SELECT)
            return '';
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.ARRAY)
            return [];
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.BOOLEAN)
            return false;
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.RANGE)
            return 0;
        return '';
    }

    const getParamValue = (value, configTypeData) => {
        if (!value)
            return getDefaultParamValue(configTypeData);

        value = value.replace(/\\n/g, '\n');
        if (configTypeData && configTypeData.valueType === PARAM_TYPES.SELECT)
            return value;
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.ARRAY)
            return value.split(',');
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.BOOLEAN)
            return Boolean(value.toLowerCase() === 'true');
        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.RANGE)
            return value;
        return value;
    }

    const getDBParamValue = (value) => {
        if (value === '' || value === null || value === undefined)
            return null;
        else if (Array.isArray(value) && value.length === 0)
            return null;
        else if (Array.isArray(value) && value.length > 0)
            return value.join(',').replace(/\n/g, '\\n');
        else if (typeof value === PARAM_TYPES.BOOLEAN)
            return value.toString();
        else if (typeof value === 'number')
            return value.toString();
        else
            return value.replace(/\n/g, '\\n');
    }

    const normalizeConfigurationForUI = () => {
        if (!configuration.current)
            return;

        configuration.current = Object.keys(configuration.current).reduce((typeAcc, type) => {
            typeAcc[type] = Object.keys(configuration.current[type]).reduce((paramAcc, parameter) => {
                const configTypeData = getConfigurationTypeData(type, parameter);
                paramAcc[parameter] = getParamValue(configuration.current[type][parameter], configTypeData);
                return paramAcc;
            }, {});
            return typeAcc;
        }, {});
    }

    const normalizeConfigurationForDB = () => {
        if (!configuration.current)
            return;
        
        configuration.current = Object.keys(configuration.current).reduce((typeAcc, type) => {
            typeAcc[type] = Object.keys(configuration.current[type]).reduce((paramAcc, parameter) => {
                paramAcc[parameter] = getDBParamValue(configuration.current[type][parameter]);
                return paramAcc;
            }, {});
            return typeAcc;
        }, {});

    }

    const getConfiguration = () => {
        return configuration.current;
    }

    const getConfigurationFromDB = (newConfigMode) => {
        setConfigurationWasLoaded(false);
        setFetchConfigurationHeaders({ psxUserEmail: configUserTarget, mode: newConfigMode });
        setFetchConfiguration(true);
    }

    const updateConfiguration = (type, parameter, newValue) => {
        if (configuration.current) {
            configuration.current = {
                ...configuration.current,
                [type]: {
                    ...configuration.current[type],
                    [parameter]: newValue
                }
            };
        }
    }

    const getConfigTypes = () => {
        return configTypes.current;
    }

    const submitConfiguration = () => {
        messageRef.current.hideAreYouSureMessage();
        messageRef.current.showProcessingDiag();

        normalizeConfigurationForDB();

        if (submitMode === SUBMIT_MODES.COMMIT_GENERAL) {
            setSubmitConfigurationHeaders({ psxUserEmail: configUserTarget, mode: configMode, status: configActive ? 'active' : 'inactive' });
            setSubmitConfigurationBody(configuration.current);
            setSubmitConfiguration(true);
        }
        else if (submitMode === SUBMIT_MODES.COMMIT_TO_GLOBAL) {
            setCommitConfigurationHeaders({ psxUserEmail: configUserTarget });
            setCommitConfigurationBody(configuration.current);
            setCommitConfiguration(true);
        }
        else if (submitMode === SUBMIT_MODES.DELETE_USER_CONFIG) {
            setDeleteConfigurationHeaders({ psxUserEmail: configUserTarget });
            setDeleteConfiguration(true);
        }
        else if (submitMode === SUBMIT_MODES.COPY_FROM_GLOBAL) {
            getConfigurationFromDB(CONFIG_MODES.GLOBAL);
        }
    }

    const submitConfigurationWithDetails = (psxUserEmail, mode, status) => {
        messageRef.current.showProcessingDiag();
        normalizeConfigurationForDB();
        setSubmitConfigurationHeaders({ psxUserEmail, mode, status });
        setSubmitConfigurationBody(configuration.current);
        setSubmitConfiguration(true);
    }

    // Initial load
    useEffect(() => {
        messageRef.current.showProcessingDiag();
        // eslint-disable-next-line
    }, []);

    // Current Configuration is updated
    useEffect(() => {
        if (currentConfiguration && currentConfiguration.data) {
            if (currentConfiguration.data.config)
                configuration.current = currentConfiguration.data.config;
            if (currentConfiguration.data.configTypes)
                configTypes.current = currentConfiguration.data.configTypes;

            setConfigActive(currentConfiguration.data.isActive);

            messageRef.current.hideProcessingDiag();

            normalizeConfigurationForUI();

            if (submitMode === SUBMIT_MODES.COPY_FROM_GLOBAL) {
                submitConfigurationWithDetails(configUserTarget, CONFIG_MODES.USER, 'active');
                setSubmitMode(null);
                return;
            } else {
                setConfigUserTarget(currentConfiguration.data.username);
                setConfigMode(currentConfiguration.data.username === CONFIG_MODES.GLOBAL ? CONFIG_MODES.GLOBAL : CONFIG_MODES.USER)
            }

            setConfigurationWasLoaded(true);
            setFetchConfigurationUserEmails(true);
        }
        // eslint-disable-next-line
    }, [currentConfiguration]);

    // Current Configuration User Emails is updated
    useEffect(() => {
        if (currentConfigurationUserEmails && currentConfigurationUserEmails.data)
            setConfigurationUserEmails(currentConfigurationUserEmails.data);
    }, [currentConfigurationUserEmails]);

    // Configuration was submitted
    useEffect(() => {
        if (submitConfigurationSuccess || submitConfigurationError) {
            messageRef.current.hideProcessingDiag();
            getConfigurationFromDB(configMode);
        }

        if (submitConfigurationSuccess)
            messageRef.current.successMessage(submitConfigurationSuccess.data);
        else if (submitConfigurationError)
            messageRef.current.errorMessage(submitConfigurationError.data);
        // eslint-disable-next-line
    }, [submitConfigurationSuccess, submitConfigurationError, commitConfigurationSuccess, commitConfigurationError]);

    // Configuration was committed to global
    useEffect(() => {
        if (commitConfigurationSuccess || commitConfigurationError) {
            messageRef.current.hideProcessingDiag();
            getConfigurationFromDB(CONFIG_MODES.GLOBAL);
        }

        if (commitConfigurationSuccess)
            messageRef.current.successMessage(commitConfigurationSuccess.data);
        else if (commitConfigurationError)
            messageRef.current.errorMessage(commitConfigurationError.data);
        // eslint-disable-next-line
    }, [commitConfigurationSuccess, commitConfigurationError]);

    // Configuration was deleted
    useEffect(() => {
        if (deleteConfigurationSuccess || deleteConfigurationError) {
            messageRef.current.hideProcessingDiag();
            setConfigUserTarget(CONFIG_MODES.GLOBAL);
            getConfigurationFromDB(CONFIG_MODES.GLOBAL);
        }

        if (deleteConfigurationSuccess)
            messageRef.current.successMessage(deleteConfigurationSuccess.data);
        else if (deleteConfigurationError)
            messageRef.current.errorMessage(deleteConfigurationError.data);
        // eslint-disable-next-line
    }, [deleteConfigurationSuccess, deleteConfigurationError]);
    
    return {
        configurationWasLoaded,
        getConfiguration,
        getConfigurationFromDB,
        updateConfiguration,
        getConfigTypes,
        submitConfiguration,
        submitConfigurationWithDetails,
        getConfigurationTypeData,
        configurationUserEmails,
        setConfigurationUserEmails
    };
}

