import React, { useRef } from 'react';

import Label from '@nokia-csf-uxr/ccfk//Label';
import { default as DefaultTextArea, TextAreaLabelContent } from '@nokia-csf-uxr/ccfk//TextArea';
import { KEY_CODES } from '@nokia-csf-uxr/ccfk/common/index.js';

import '../../Styles/TextArea.css';

const TextArea = (props) => {

    const inputRef = useRef();

    const {
        label = '', value, setValue, required = false, placeholder = '',
        maxChars = 300, cols = 80, rows = 5,
        disabled = false, showCharacterCount = true,
        error = false, setError = null, errorMessage = '',
    } = props;

    const handleClear = () => {
        setValue('');
        inputRef.current.focus();
    };

    const handleClearKeyDown = (e) => {
        e.preventDefault();
        if (KEY_CODES.ENTER_KEY === e.key || KEY_CODES.SPACE_KEY === e.key) {
            handleClear();
        }
    }

    const ariaLabel = `Label, ${required ? 'required' : ''}, ${value === '' ? 'Placeholder' : value}, ${value ? value.length : 0} of ${maxChars} characters`;

    return (
        <div className='textarea-container'>
            {
                label &&
                <Label verticalLayout className='textarea-label'>
                    <TextAreaLabelContent required={required}>{label}</TextAreaLabelContent>
                </Label>
            }
            <DefaultTextArea
                className={`textarea ${disabled ? 'disabled' : ''}`}
                cols={cols}
                rows={rows}
                variant="outlined"
                resize="both"
                onChange={(event) => {
                    if (setError) setError(false);
                    setValue(event.target.value);
                }}
                characterCount={ showCharacterCount&&`${value.length} / ${maxChars}`}
                value={value}
                placeholder={placeholder}
                textareaProps={{ 'aria-label': ariaLabel, ref: inputRef, maxLength: maxChars }}
                showClearButton={true}
                clearButtonProps={{
                    onClick: handleClear,
                    onKeyDown: handleClearKeyDown,
                    "aria-label": 'Clear contents'
                }}
                disabled={disabled}
                error={error}
                errorMessage={error ? errorMessage : ''}
            />
        </div>
    )
};

export default TextArea;