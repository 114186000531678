
import React from 'react';

import { default as DefaultHyperlink } from '@nokia-csf-uxr/ccfk/Hyperlink';

const HyperLink = (props) => {

	const { text, link, style, disabled = false } = props;

	return (
		<DefaultHyperlink
			href={link}
			target='_blank'
			style={style}
			disabled={disabled || link === '' || link === undefined || link === null}
		>
			{text}
		</DefaultHyperlink>
	);
}

export default HyperLink;