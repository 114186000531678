import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useGlobal } from './Global';
import { FetchRequest, GetOpts } from "../CustomObjects/Fetch";
import { useAuthentication } from "../CustomHooks/Authentication";
import { useFileExport } from "./FileExport";

import Env from "../CustomObjects/Environment";

export const useFetch = (url, fetchAtStart = false, theHeaders = {}, method = 'GET', theBody = null, isJson = true, credentials = false) => {
    const [shouldFetch, setShouldFetch] = useState(fetchAtStart);
    const [headers, setHeaders] = useState(theHeaders);
    const [body, setBody] = useState(theBody);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [retries, setRetries] = useState(0);

    const { global, setGlobal, isRefreshingToken } = useGlobal();
    const getUserAuthentication = useAuthentication();
    
    const defaultHeaders = useMemo(() => ({
        'FirstName': global.user?.firstName ?? '',
        'LastName': global.user?.lastName ?? '',
        'Username': global.authorization?.email ?? '',
        'Role': global.user?.role ?? '',
        'UserLogin': global.user?.username ?? '',
        'UserId': global.user?.userId ?? '',
        'Organization': global.user?.organization ?? '',
        'Authorization': 'Bearer ' + (global.authorization?.token ?? '')
    }), [global]);

    useEffect(() => {

        const allHeaders = { ...defaultHeaders, ...headers };

        const setFetchData = async () => {
            const resObj = await FetchRequest(url, GetOpts(allHeaders, method, body, isJson, credentials));

            const statusCode = resObj.status;
            var data;
            var errorMessage;
            
            try {
                data = JSON.parse(resObj.data);
            } catch {
                data = resObj.data;
            }
            try {
                errorMessage = JSON.parse(resObj.error);
            } catch {
                errorMessage = resObj.error;
            }

            if (statusCode === 200) {
                console.log(`Request for '${url}' was SUCCESS!!`);
                setRetries(0);
                setData(data);
                setShouldFetch(false);
                isRefreshingToken.current = false;
            }
            else if (statusCode === 401 && retries < Env.MAX_NUMBER_FETCH_RETRIES) {
                if (isRefreshingToken.current) {
                    console.log(`Unauthorized request for ${url} and Authorization Token is ALREADY being refreshed!! waiting...`);
                    await new Promise(resolve => setTimeout(resolve, 5000));
                }
                else {
                    console.log(`Unauthorized request for ${url} REFRESHING Authorization Token...`);
                    isRefreshingToken.current = true;
                    const loginData = await getUserAuthentication();

                    setGlobal({
                        ...global,
                        authorization: {
                            email: loginData.account.username,
                            token: loginData.idToken,
                            accessToken: loginData.accessToken
                        }
                    });
                }
                setShouldFetch(true);
                setRetries(retries + 1);
            }
            else {
                console.error(`Request for '${url}' was FAILURE: `, errorMessage);
                setError(errorMessage);
                setShouldFetch(false);
                isRefreshingToken.current = false;
            }
        }

        if (shouldFetch) {
            setData(null);
            setError(null);
            setFetchData();
        }
    }, [url, shouldFetch, global, setGlobal, getUserAuthentication, retries, defaultHeaders, headers, body, method, isJson, isRefreshingToken, credentials]);

    return [data, setShouldFetch, error, setHeaders, setBody, setData];
}

export const useFetchWithInfinite = (url, theHeaders = {}, method = 'GET', theBody = null, credentials = false, csvEndpoint = null, dataTableRef = null) => {

    const valuesToSearch = useRef([]);
    const rowsProps = useRef(null);
    const gridApi = useRef(null);
    const csvFileName = useRef('');
    const { downloadCsv } = useFileExport();

    const [requestHeaders, setRequestHeaders] = useState(theHeaders);
    const [requestBody, setRequestBody] = useState(theBody);
    const [refreshData, setRefreshData] = useState(false);
    const [numberOfChips, setNumberOfChips] = useState(0);

    const [data, setShouldFetchData, , setHeaders, setBody] = useFetch(url, false, theHeaders, method, theBody, true, credentials);
    const [csvData, setShouldFetchCsvData, , setCsvHeaders, setCsvBody] = useFetch(csvEndpoint ?? url, false, theHeaders, method, theBody);

    const datasource = useMemo(() => ({
        getRows: (props) => {
            rowsProps.current = props;
            
            gridApi.current.showLoadingOverlay();

            setHeaders({
                ...requestHeaders,
                sortModels: JSON.stringify({ models: props.sortModel }),
                keywords: JSON.stringify({ valuesToSearch: valuesToSearch.current }),
                filterModels: JSON.stringify({ models: props.filterModel }),
                startRow: props.startRow,
                endRow: props.endRow
            });

            setBody(requestBody);

            setShouldFetchData(true);
        }
    }), [setShouldFetchData, setHeaders, requestHeaders, setBody, requestBody]);

    useEffect(() => {
        if (rowsProps.current && data) {
            let lastRow = null;
            if (data.length < (rowsProps.current.endRow - rowsProps.current.startRow)) {
                lastRow = rowsProps.current.startRow + data.length;
            }
            rowsProps.current.successCallback(data, lastRow);
            gridApi.current.hideOverlay();
            if (data.length === 0)
                gridApi.current.showNoRowsOverlay();
        }
    }, [data]);

    useEffect(() => {
        if (datasource && gridApi.current && refreshData) {
            gridApi.current.setGridOption('datasource', datasource);
            setRefreshData(false);
        }
    }, [datasource, refreshData]);

    useEffect(() => {
        if (!csvData)
            return;

        downloadCsv(csvFileName.current, csvData);
        if (dataTableRef && dataTableRef.current)
            dataTableRef.current.setIsFetchingCSV(false);

        // eslint-disable-next-line
    }, [csvData]);

    const onGridReady = useCallback(({ api }) => {
        gridApi.current = api;
        gridApi.current.setGridOption('datasource', datasource);
    }, [datasource]);

    const onKeywordChange = useCallback((newValues) => {
        if (newValues.length !== numberOfChips) {
            setNumberOfChips(newValues.length);
            valuesToSearch.current = newValues.map(chipsItem => chipsItem.toLowerCase());
            gridApi.current.setGridOption('datasource', datasource);
        }
    }, [datasource, numberOfChips]);

    const fetchDataForCSV = (fileName, sortModels, keywords, filterModels, csvColumns) => {
        csvFileName.current = fileName;
        setCsvHeaders({
            ...requestHeaders,
            sortModels: JSON.stringify({ models: sortModels }),
            keywords: JSON.stringify({ valuesToSearch: keywords }),
            filterModels: JSON.stringify({ models: filterModels }),
            csvColumns: JSON.stringify(csvColumns)
        });
        setCsvBody(requestBody);
        setShouldFetchCsvData(true);

        if (dataTableRef && dataTableRef.current)
            dataTableRef.current.setIsFetchingCSV(true);
    }

    return [
        data,
        onGridReady,
        onKeywordChange,
        setRefreshData,
        setRequestHeaders,
        setRequestBody,
        fetchDataForCSV
    ];
}

