import React from 'react';

import dayjs from 'dayjs';

import LanguageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LanguageIcon';

import Env from "../../CustomObjects/Environment";
import { useFetch } from '../../CustomHooks/Fetch';
import { useIsAdminOrKTLeader, useUserEmailsList } from "../../CustomHooks/Global";
import {
	useInviteesAndParticipants,
	useKTSessionsPanelErrors,
	useMailingList,
	usePanelModes,
	useKTInputFields,
	MODES,
	DEFAULT_DURATION
} from '../../CustomHooks/KTSessions';
import {
	validateEmail,
	listsHaveDifferentElements,
	getDifferenceBetweenLists
} from '../../CustomObjects/Utils';
import {
	OverlayPanel,
	TextInput,
	DateTimePicker,
	TextArea,
	RowSeparator,
	SelectButton,
	TipCoachmark,
	SelectAutoFill,
	TextWithChips,
	CheckBox
} from "../Common";
import {
	OverlayPanelTitle,
	PresenterDialog,
	YammerPost,
	FilesUpload,
	MaterialLinks,
	ChipLegend,
} from '.';

import '../../Styles/KTSessionsOverlayPanel.css';

const KTOverlayPanel = ({ fields }) => {

	dayjs.extend(require('dayjs/plugin/utc'));
	dayjs.extend(require('dayjs/plugin/timezone'));
	dayjs.extend(require('dayjs/plugin/advancedFormat'));

	const PRESENTER_SEPARATOR = '~'
	const PRESENTER_PATTERN = new RegExp('^[^' + PRESENTER_SEPARATOR + ']+\\' + PRESENTER_SEPARATOR + '\\s*(1[5-9]|[2-9]\\d|[1-3]\\d{2}|4[0-7]\\d|480)$');

	const [roles] = useFetch(Env.BACKEND_SERVER_URL + 'Certifications/roles', true);
	const [shortnames] = useFetch(Env.BACKEND_SERVER_URL + 'Solutions/shortnames', true);
	const [coachmarkSteps] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/coachmarks', true, { module: 'ktsessions' });

	const isAdminOrKtLeader = useIsAdminOrKTLeader();
	const userEmailsList = useUserEmailsList();

	const inputFields = useKTInputFields();
	const errorFields = useKTSessionsPanelErrors(fields, inputFields);
	const updateMailingList = useMailingList(fields);
	const updateInviteesAndParticipants = useInviteesAndParticipants(fields);
	usePanelModes(fields, inputFields, updateInviteesAndParticipants);

	const overlayPanelProps = {
		id: 'kt-session-overlay-panel',
		visible: fields.panelVisible,
		setVisible: fields.setPanelVisible,
		title: <OverlayPanelTitle fields={fields} textFields={inputFields} errorFields={errorFields} />,
		elevationIndex: 4,
		width: '35vw',
		cancelButtonProps: {
			variant: fields.overlayPanelCancelButtonVariant,
			label: fields.overlayPanelCancelButtonLabel,
			onClick: () => {
				if (fields.panelMode === MODES.EDIT_FINISHED)
					fields.onDeleteClicked({ data: fields.currentKTData });
				else
					fields.setPanelVisible(false);
			},
			disabled: fields.disableCancelButton || fields.errorOcurredInParticipants,
		},
		actionButtonProps: {
			label: fields.overlayPanelActionButtonLabel,
			onClick: () => {

				const formIsInvalidMessage = errorFields.validateForm();
				
				if (!formIsInvalidMessage) {
					fields.setOpenProcessingDiag(true);

					const ktSessionBody = {
						recNum: fields.currentKTData.sessionId ? fields.currentKTData.sessionId.toString() : '',
						status: fields.currentKTData.sessionStatus,
						statusDetail: fields.currentKTData.sessionStatusDetail,
						title: inputFields.title,
						eventId: fields.currentKTData.eventID,
						meetingId: fields.currentKTData.meetingID,
						timezone: fields.timezone,
						startDateTime: dayjs(fields.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
						endDateTime: dayjs(fields.endDateTime).format('YYYY-MM-DD HH:mm:ss'),
						targetAudience: fields.targetAudience,
						solutions: fields.solutions,
						inviteesAndParticipants: fields.inviteesAndParticipants,
						participantList: fields.isRecent || fields.isOld ? [
							...fields.invitedParticipated,
							...fields.notInvitedParticipated,
							...getDifferenceBetweenLists(fields.inviteesAndParticipants, fields.initialInviteesAndParticipants)] : [],
						description: inputFields.description,
						presenters: fields.presenters,
						userEmail: inputFields.userEmail,
						onlyPresentersCanShare: fields.onlyPresentersCanShare,
						postedToYammer: fields.postToYammer,
						filesUploaded: fields.currentKTData.filesUploaded ? fields.filesUploaded.length + fields.currentKTData.filesUploaded : fields.filesUploaded.length,
					}

					if (fields.panelMode === MODES.CREATE_FINISHED) {
						fields.setNumberOfActions(1);
						fields.createNewKTSession(ktSessionBody, null, inputFields.title);
					}
					else if (fields.panelMode === MODES.EDIT_FINISHED) {
						const sessionHasChanged =
							ktSessionBody.userEmail !== fields.currentKTData.userEmail ||
							ktSessionBody.timezone !== fields.currentKTData.timeZone ||
							ktSessionBody.startDateTime !== fields.currentKTData.startDate.replace('T', ' ') ||
							ktSessionBody.endDateTime !== fields.currentKTData.endDate.replace('T', ' ') ||
							ktSessionBody.description !== fields.currentKTData.description ||
							ktSessionBody.filesUploaded !== fields.currentKTData.filesUploaded ||
							ktSessionBody.onlyPresentersCanShare !== fields.currentKTData.onlyPresentersCanShare ||
							listsHaveDifferentElements(ktSessionBody.inviteesAndParticipants, fields.initialInviteesAndParticipants) ||
							listsHaveDifferentElements(ktSessionBody.presenters, fields.currentKTData.presenters.split(','));

						const sessionNeedsNewLearningPoints = listsHaveDifferentElements(ktSessionBody.presenters, fields.currentKTData.presenters.split(',')) ||
							dayjs(ktSessionBody.startDateTime).format('YYYY-MM-DD') !== dayjs(fields.currentKTData.startDate).format('YYYY-MM-DD')

						fields.setNumberOfActions(1);
						fields.updateKTSession(ktSessionBody, { sessionNeedsNewLearningPoints }, inputFields.title, fields.filesUploaded, sessionHasChanged);
					}
				}
				else {
					fields.setMessage(formIsInvalidMessage);
					fields.setMessageVariant('error');
					fields.setShowMessage(true);
				}
			},
			disabled: fields.disableCreateButton || fields.errorOcurredInParticipants,
		},
	};

	const ownerProps = {
		id: 'kt-session-owner',
		label: 'Owner',
		value: inputFields.userEmail,
		setValue: inputFields.setUserEmail,
		maxChars: 90,
		required: true,
		autoComplete: 'off',
		error: errorFields.ownerError,
		setError: errorFields.setOwnerError,
		disabled: !fields.isOwner && !isAdminOrKtLeader
	};

	const timezoneProps = {
		id: 'kt-session-timezone',
		value: fields.timezone,
		setValue: fields.setTimezone,
		Icon: LanguageIcon,
		width: '20rem',
		disabled: fields.isRecent || fields.isOld,
		data: {
			mapping: { value: "value" },
			values: fields.timezoneList
		},
		error: errorFields.timezoneError,
		setError: errorFields.setTimezoneError,
		withAutoFill: true
	}

	const startDateTimeProps = {
		id: 'kt-session-start-date-time',
		label: 'Start',
		value: fields.startDateTime,
		setValue: fields.setStartDateTime,
		format: 'YYYY-MM-DD HH:mm',
		required: true,
		error: errorFields.startDateError,
		setError: errorFields.setStartDateError,
		disabled: fields.isRecent || fields.isOld || (!fields.isOwner && !isAdminOrKtLeader),
		onChange: (v) => { fields.setEndDateTime(dayjs(v).add(60, 'minute')) }
	}

	const endDateTimeProps = {
        id: 'kt-session-end-date-time',
		label: 'End',
		value: fields.endDateTime,
		setValue: fields.setEndDateTime,
		format: 'YYYY-MM-DD HH:mm',
		ampm: false,
		required: true,
		error: errorFields.endDateError,
		setError: errorFields.setEndDateError,
		disabled: fields.isRecent || fields.isOld || (!fields.isOwner && !isAdminOrKtLeader),
	}

	const changeAudienceAndSolutions = () => {
		if (fields.panelMode === MODES.CREATE_FINISHED)
			updateMailingList();
	};

	const targetAudienceProps = {
		id: 'kt-session-target-audience',
		label: 'Target Audience *',
		values: fields.targetAudience,
		setValues: fields.setTargetAudience,
		placeholder: 'Add Audience...',
		disabled: fields.isUpdate || (!fields.isOwner && !isAdminOrKtLeader),
		error: errorFields.audienceError,
		setError: errorFields.setAudienceError,
		limitTags: 2,
		data: {
			mapping: { value: "CertRole" },
			values: roles
		},
		onChange: () => { changeAudienceAndSolutions(); }
	}

	const solutionsProps = {
		id: 'kt-session-solutions',
		label: 'Solutions *',
		values: fields.solutions,
		setValues: fields.setSolutions,
		placeholder: 'Add Solution...',
		disabled: fields.isUpdate || (!fields.isOwner && !isAdminOrKtLeader),
		error: errorFields.solutionsError,
		setError: errorFields.setSolutionsError,
		limitTags: 2,
		data: {
			mapping: { value: 'solutionShortName' },
			values: shortnames
		},
		onChange: () => { changeAudienceAndSolutions(); }
	};

	const participantsProps = {
		id: 'kt-session-participants',
		label: 'Participants *',
		placeholder: 'Email...',
		values: fields.inviteesAndParticipants,
		setValues: fields.setInviteesAndParticipants,
		maxHeight: '8rem',
		separators: [',', ';'],
		disabled: !isAdminOrKtLeader && (!fields.isOwner || fields.isRecent || fields.isOld),
		helper: 'Type a comma/semi-colon separated list of emails (no spaces). Press enter to submit.',
		validator: (v) => {
			return validateEmail(v);
		},
		chipCanBeClosed: (value) => {
			return fields.panelMode && fields.panelMode.includes(MODES.EDIT) ? !fields.allInviteesAndParticipants.includes(value) : true;
		},
		showClearButton: fields.panelMode && fields.panelMode.includes(MODES.CREATE),
		useBackspaceToDelete: false,
		allowDuplicates: false,
		chipErrorMessage: 'Please fill with valid email address',
		externalError: errorFields.inviteesAndParticipantsError,
		setExternalError: errorFields.setInviteesAndParticipantsError,
		getCustomChipClasses: (value) => {
			var cssClasses = [];
			if (fields.chipDefaultClass && !fields.allInviteesAndParticipants.includes(value))
				cssClasses.push(fields.chipDefaultClass);
			else {
				if (fields.invitedNoResponse.includes(value))
					cssClasses.push('invited-no-response');
				if (fields.invitedAccepted.includes(value))
					cssClasses.push('invited-accepted');
				if (fields.invitedDeclined.includes(value))
					cssClasses.push('invited-declined');
				if (fields.invitedTentative.includes(value))
					cssClasses.push('invited-tentative');
				if (fields.invitedParticipated.includes(value))
					cssClasses.push('invited-participated');
				if (fields.notInvitedParticipated.includes(value))
					cssClasses.push('not-invited-participated');
			}

			return cssClasses.length === 0 ? '' : cssClasses.join(' ');
		},
		withCopy: true,
		withDropdownHelperMessage: true,
		options: userEmailsList,
		itemRegExp: new RegExp("\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}\\b", "g")
	}

	const descriptionProps = {
		label: 'Description',
		value: inputFields.description,
		setValue: inputFields.setDescription,
		required: true,
		disabled: fields.isRecent || fields.isOld || (!fields.isOwner && !isAdminOrKtLeader),
		maxChars: 8000,
		placeholder: 'A detailed description will help others find your KT Session...',
		cols: 83,
		rows: 10,
		error: errorFields.descriptionError,
		setError: errorFields.setDescriptionError,
		errorMessage: 'Please provide the session description',
	}

	const presentersProps = {
		id: 'kt-session-presenters',
		label: 'Presenters *',
		placeholder: 'Email...',
		values: fields.presenters,
		setValues: fields.setPresenters,
		maxHeight: '8rem',
		validator: (value) => {
			if (!PRESENTER_PATTERN.test(value))
				return false;
			const email = value.split(PRESENTER_SEPARATOR)[0].trim();
			return validateEmail(email);
		},
		helper: 'Insert presenter email and duration. Presenters will be used to create new Learning Points...',
		disabled: fields.isOld || (!fields.isOwner && !isAdminOrKtLeader),
		showClearButton: true,
		allowDuplicates: false,
		chipErrorMessage: 'Presenter emails must be in the email format: e.g. john.smith@example.org',
		externalError: errorFields.presenterEmailError,
		setExternalError: errorFields.setPresenterEmailError,
		withCopy: true,
		useBackspaceToDelete: false,
		withDropdownHelperMessage: true,
		withCustomDialog: {
			title: 'Add a new Presenter...',
			component: <PresenterDialog inputFields={inputFields} />,
			onChange: (v) => inputFields.setNewPresenter(v),
			onSubmit: () => {
				inputFields.setNewPresenterDuration(DEFAULT_DURATION);
				return `${inputFields.newPresenter} ${PRESENTER_SEPARATOR} ${inputFields.newPresenterDuration}`;
			}
		},
		options: userEmailsList
	}

	return (
		<OverlayPanel {...overlayPanelProps} >
			<TipCoachmark
				ref={fields.coachmarksRef}
				identifier='kt-session-coachmarks'
				steps={coachmarkSteps}
				delay={400}
				condition={fields.panelMode === MODES.CREATE_FINISHED}
			/>
			<div className='op-column'>
				<div className='op-row'>
					<TextInput {...ownerProps} />
				</div>
				<RowSeparator />
				<div className='op-row'>
					<SelectButton {...timezoneProps} />
					<DateTimePicker {...startDateTimeProps} />
					<DateTimePicker {...endDateTimeProps} />
				</div>
				<RowSeparator />
				<RowSeparator />
				<RowSeparator />
				<div className='op-row'>
					<SelectAutoFill {...targetAudienceProps} />
					<SelectAutoFill {...solutionsProps} />
				</div>
				{
					(fields.isOwner || isAdminOrKtLeader || fields.isPresenter) &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<TextWithChips ref={fields.participantsRef} {...participantsProps} />
						<RowSeparator />
						{
							fields.showChipLegend &&
							<ChipLegend />
						}
					</>
				}
				<RowSeparator />
				<TextArea {...descriptionProps} />
				<RowSeparator />
				<RowSeparator />
				<RowSeparator />
				<TextWithChips ref={fields.presentersRef} {...presentersProps} />
				<RowSeparator />
				<RowSeparator />
				<CheckBox
					label={'Restrict screen sharing to presenters only'}
					disabled={fields.isRecent || fields.isOld || (!fields.isOwner && !isAdminOrKtLeader)}
					value={fields.onlyPresentersCanShare}
					setValue={fields.setOnlyPresentersCanShare}
				/>
				
				<YammerPost fields={fields} />
				{
					fields.isUpdate && (fields.isOwner || isAdminOrKtLeader) &&
					<FilesUpload fields={fields} />
				}
				{
					fields.isUpdate &&
					<MaterialLinks fields={fields} />
				}
			</div>
		</OverlayPanel>
	);
}

export default KTOverlayPanel;