import { useMemo } from 'react';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import { AddKPIPlanningButton } from './KPIPlanningButtons';

import LightbulbIcon from '@mui/icons-material/Lightbulb';

import ReactHtmlParser from 'html-react-parser';

import '../../Styles/KPIPlanning.css';

const OrgTreeLabel = ({ node, fields, getKpiByKpiId, getHeadCountTypeByName }) => {

    const label = useMemo(() => {
        var newLabel = node.T;
        if (node.kpis && node.kpis.length > 0) {
            if (node.kpis[0].hcDescription)
                newLabel += ` - HCs: <b>${node.kpis[0].hcDescription}</b>`;
            if (node.kpis[0].kpiDescription)
                newLabel += `, KPIs: <b>${node.kpis[0].kpiDescription}</b>`;
        }
        
        return newLabel;
    }, [node]);

    return (
        <div className='kpi-planning-label-content'>
            <div id='kpi-planning-label'>
                {node.branchHasData ? <LightbulbIcon style={{ color: 'var(--ff-color-green-500)'}} /> : null }
                <Typography>{ReactHtmlParser(label)}</Typography>
            </div>
            <div id='kpi-planning-button'>
                <AddKPIPlanningButton
                    node={node}
                    fields={fields}
                    getKpiByKpiId={getKpiByKpiId}
                    getHeadCountTypeByName={getHeadCountTypeByName}
                />
            </div>
        </div>
    );
    
};

export default OrgTreeLabel;