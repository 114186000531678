import { useState, useEffect } from 'react';

export const useActions = (callback) => {

    const [numberOfActions, setNumberOfActions] = useState(-1);

	useEffect(() => {
		if (numberOfActions === 0) {
			callback();
			setNumberOfActions(-1);
		}
	}, [numberOfActions, callback]);

    return [setNumberOfActions]
}