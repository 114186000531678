import React from 'react';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import ReactHtmlParser from 'html-react-parser';

import '../../Styles/Module.css';

const ModuleHeader = ({ children, backgroundPath, backgroundPosition, titleColor }) => {

	const backgroundStyle = {
		backgroundImage: `url(${backgroundPath ? backgroundPath : require('../../Images/BannerBackgrounds/banner-background-1.jpg')})`,
		backgroundPosition: backgroundPosition ? backgroundPosition : '50% 50%'
	};
	
	return (
		<div style={backgroundStyle} className="module-header">
			<Typography variant="default" style={{ color: titleColor }} typography="HEADLINE_ALPHA">{ReactHtmlParser(children)}</Typography>
		</div>
	);
}
ModuleHeader.defaultProps = {
	titleColor: 'white'
};

export default ModuleHeader;