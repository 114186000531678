import React, { useMemo, useRef, useState } from 'react';

import {
    ExpansionPanel,
    Message,
    TextInput,
    ItemsList
} from '../../Common';

import { validateUrl } from '../../../CustomObjects/Utils';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

import LayerAddIcon from '@nokia-csf-uxr/ccfk-assets/latest/LayerAddIcon';
import FileIcon from '@nokia-csf-uxr/ccfk-assets/latest/FileIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';

const MODES = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
}

const MaterialContent = ({ url, setUrl, urlError, setUrlError }) => {

    return (
        <TextInput
            label='Url'
            value={url}
            setValue={setUrl}
            placeholder='Insert the Url...'
            required
            maxChars={2000}
            showClearButton={false}
            error={urlError}
            setError={setUrlError}
        />
    );
}

const Materials = ({ fields }) => {

    const currentSelectedMaterialId = useRef(null);

    const [mode, setMode] = useState(null);
    const [showMaterialsMessage, setShowMaterialsMessage] = useState(false);
    const [url, setUrl] = useState('');
    const [urlError, setUrlError] = useState(false);
   
    const resetMaterials = () => {
        currentSelectedMaterialId.current = null;
        setUrl('');
        setUrlError(false);
    }

    const onSubmitMaterials = () => {
        if (!validateUrl(url)) {
            setUrlError(true);
            return;
        }

        if (mode === MODES.CREATE) {
            fields.setMaterials([...fields.materials, url]);
        }
        else if (mode === MODES.EDIT) {
            const matIndex = materialsItems.findIndex(mat => mat.id === currentSelectedMaterialId.current);
            var newMaterials = [...fields.materials];
            newMaterials[matIndex] = url;
            fields.setMaterials(newMaterials);
        }

        setShowMaterialsMessage(false);
    }

    const onNewMaterial = () => {
        setMode(MODES.CREATE);
        resetMaterials();
        setShowMaterialsMessage(true);
    }

    const onEditMaterial = (mat) => {
        setMode(MODES.EDIT);
        currentSelectedMaterialId.current = mat.id;
        setUrl(mat.url);
        setShowMaterialsMessage(true);
    }

    const onDeleteMaterial = (mat) => {
        setMode(MODES.DELETE);
        const filteredMats = materialsItems.filter(m => m.id !== mat.id);
        fields.setMaterials([
            ...filteredMats.map(m => m.url)
        ]);
    }

    const materialsItems = useMemo(() => {
        return fields.materials.map((mat, id) => ({
            id: id,
            icon: <FileIcon color='var(--ff-color-purple-500)' />,
            text: `<a href="${mat}" target="_blank"><b>${mat}</b></a>`,
            url: mat
        }));
    }, [fields.materials]);

    const materialMessageTitle = useMemo(() => {
        if (mode === MODES.CREATE)
            return 'New Template Lab Material';
        else if (mode === MODES.EDIT)
            return 'Edit Template Lab Material';
        else
            return '';
        // eslint-disable-next-line
    }, [mode]);

    const materialMessageText = useMemo(() => {
        if (mode === MODES.CREATE)
            return 'Please insert the new template lab material';
        else if (mode === MODES.EDIT)
            return 'Update the template lab material';
        else
            return '';
        // eslint-disable-next-line
    }, [mode]);

    return (
        <>
            <ExpansionPanel header='Template Work Assignment Materials'>
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                }}>
                    <div>
                        <Tooltip tooltip='Add new template lab material' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
                            <IconButton onClick={onNewMaterial}>
                                <LayerAddIcon color='var(--ff-color-nokia-blue-500)' />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ItemsList
                        items={materialsItems}
                        withActions={[
                            {
                                callback: onEditMaterial,
                                icon: <EditIcon />,
                                tooltip: 'Edit Material'
                            },
                            {
                                callback: onDeleteMaterial,
                                icon: <DeleteIcon />,
                                tooltip: 'Delete Material'
                            }
                        ]}
                    />
                </div>
            </ExpansionPanel>
            <Message
                title={materialMessageTitle}
                message={materialMessageText}
                actionButtonLabel='Submit'
                cancelButtonLabel='Cancel'
                open={showMaterialsMessage}
                setOpen={setShowMaterialsMessage}
                withComponent={
                    <MaterialContent
                        url={url}
                        setUrl={setUrl}
                        urlError={urlError}
                        setUrlError={setUrlError}
                    />
                }
                onSubmit={onSubmitMaterials}
            />
        </>
    );
}

export default Materials;