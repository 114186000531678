import React from 'react';

import { LaunchPadTile, LaunchPadTileTitle, LaunchPadTileIcon } from '@nokia-csf-uxr/ccfk/LaunchPad';

const LaunchPadContainer = ({ onClick, icon, title, ...restProps }) => {

    return (
        <LaunchPadTile onClick={onClick} {...restProps}>
            <LaunchPadTileIcon>
                {icon}
            </LaunchPadTileIcon>
            <LaunchPadTileTitle>{title}</LaunchPadTileTitle>
        </LaunchPadTile>
    );
}

export default LaunchPadContainer;