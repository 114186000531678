
import { STANDARD_CSV_MESSAGE } from "./Utils";

function ModuleHeaderObj() {
    this.bannerPath = '../../Images/BannerBackgrounds/banner-background-1.jpg';
    this.bannerPosition = '50% 50%';
    this.title = 'Module Base';
    this.titleTextColor = 'white';
}

function ModuleDataTableObj(messageRef) {
    this.ref = null;
    this.title = '';
    this.data = [];
    this.columnDefinitions = {};
    this.columnsIgnore = [];
    this.tableOptions = {
        onRefresh: () => { },
        onFilterRemove: {
            callback: null
        },
        onExcelFile: {
            fileName: 'Export.csv',
            messageCallback: () => {
                if (messageRef && messageRef.current)
                    messageRef.current.infoMessage(STANDARD_CSV_MESSAGE)
            },
            columns: []
        },
        onAdjustColumns: false
    };
    this.rowOptions = [];
    this.additionalComponents = {
        toolbar: null,
        searchbar: null
    };
    this.client = true;
    this.clientPagination = false;
    this.serverInfiniteRow = {
        onGridReady: null,
        onKeywordChange: null,
        onExcelFile: null
    };
    this.serverPagination = {
        onGridReady: null,
        onKeywordChange: null,
        onExcelFile: null
    };
    this.rowClick = () => { };
    this.noRowsMessage = 'No data to display...';
}

function ModuleOverlayPanelObj() {
    this.component = null;
}

function ModuleFloatingPanelObj() {
    this.component = null;
}

function ModuleMessagesObj() {
    this.title = 'Message Title';
    this.submitAreYouSureMessage = () => { };
}

export function Coachmarks(ref = null, identifier = '', steps = [], delay = 0, condition = false) {
    this.ref = ref;
    this.identifier = identifier;
    this.steps = steps
    this.delay = delay
    this.condition = condition;
}

function ModuleBaseObj(messageRef = null) {
    this.moduleHeader = new ModuleHeaderObj();
    this.dataTable = new ModuleDataTableObj(messageRef);
    this.overlayPanel = new ModuleOverlayPanelObj();
    this.floatingPanel = new ModuleFloatingPanelObj();
    this.messages = new ModuleMessagesObj();
    this.coachmarks = [];

    this.addCoachmarks = (coachmarks) => {
        if (coachmarks instanceof Coachmarks)
            this.coachmarks.push(coachmarks);
        else
            console.warn('Error when addding new coachmarks to module base object. It must be an instace of Coachmarks. object will not be added!!');
    }
}

export default ModuleBaseObj;