import { useMemo, useState, useRef, useEffect } from "react";

import {
    ExpansionPanel,
    Message,
    SelectAutoFill,
    TextInput,
    ItemsList,
    Row,
    RowSeparator,
    Column,
    ColumnSeparator,
    Slider,
    MessageBundle,
} from "../Common";

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import LayerAddIcon from '@nokia-csf-uxr/ccfk-assets/latest/LayerAddIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';
import SecurityIcon from '@nokia-csf-uxr/ccfk-assets/latest/SecurityIcon';

import { integer } from "../../CustomObjects/Utils";

const MODES = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
}

const KPIContent = ({
    kpisData,
    kpiName,
    setKPIName,
    kpiNameError,
    setKPINameError,
    headCountTypesAvailable,
    headCountType,
    setHeadCountType,
    headCountTypeError,
    setHeadCountTypeError,
    percentage,
    setPercentage,
    totalPlanned,
    setTotalPlanned,
    totalPlannedError,
    setTotalPlannedError,
    q1,
    setQ1,
    q1Error,
    setQ1Error,
    q2,
    setQ2,
    q2Error,
    setQ2Error,
    q3,
    setQ3,
    q3Error,
    setQ3Error,
    q4,
    setQ4,
    q4Error,
    setQ4Error,
    recommendedTotalPlanned,
    recommendedQ1,
    recommendedQ2,
    recommendedQ3,
    recommendedQ4,
    notes,
    setNotes,
}) => {

    const percentageLabels = useMemo(() => [
        { value: 0, label: '0%' },
        { value: 25, label: '25%' },
        { value: 50, label: '50%' },
        { value: 75, label: '75%' },
        { value: 100, label: '100%' },
    ], []);
    
    return (
        <>
            <RowSeparator />
            <RowSeparator />

            <Row>
                <Column>
                    <SelectAutoFill
                        label='KPI'
                        placeholder='Select the KPI'
                        values={kpiName}
                        setValues={setKPIName}
                        data={{
                            values: kpisData,
                            mapping: { value: 'kpiName' }
                        }}
                        error={kpiNameError}
                        setError={setKPINameError}
                    />
                </Column>
                <ColumnSeparator />
                <Column>
                    <SelectAutoFill
                        label='Over which Head Count Type'
                        placeholder='Select type'
                        values={headCountType}
                        setValues={setHeadCountType}
                        data={{
                            values: headCountTypesAvailable,
                            mapping: { value: 'longName' }
                        }}
                        error={headCountTypeError}
                        setError={setHeadCountTypeError}
                    />
                </Column>
            </Row>

            {
                kpiName && headCountType &&
                <>
                    <RowSeparator />            
                    <RowSeparator />

                    <Row>
                        <Column>
                            <Slider
                                label={`Percentage: ${percentage}%`}
                                value={percentage}
                                setValue={setPercentage}
                                step={1}
                                min={0}
                                max={100}
                                marks={percentageLabels}
                                sideMargin='0.5em'
                            />
                        </Column>
                        <ColumnSeparator />
                        <Column>
                            <TextInput
                                label={`Total Planned. Recommended: ${recommendedTotalPlanned}`}
                                placeholder='Insert the total planned'
                                value={totalPlanned}
                                setValue={setTotalPlanned}
                                error={totalPlannedError}
                                setError={setTotalPlannedError}
                            />
                        </Column>
                    </Row>

                    <RowSeparator />
                    <RowSeparator />

                    <Row>
                        <Column>
                            <TextInput
                                label={`Q1. Rec: ${recommendedQ1}`}
                                placeholder='Insert the Q1'
                                value={q1}
                                setValue={setQ1}
                                error={q1Error}
                                setError={setQ1Error}
                            />
                        </Column>
                        <ColumnSeparator />
                        <Column>
                            <TextInput
                                label={`Q2. Rec: ${recommendedQ2}`}
                                placeholder='Insert the Q2'
                                value={q2}
                                setValue={setQ2}
                                error={q2Error}
                                setError={setQ2Error}
                            />
                        </Column>
                        <ColumnSeparator />
                        <Column>
                            <TextInput
                                label={`Q3. Rec: ${recommendedQ3}`}
                                placeholder='Insert the Q3'
                                value={q3}
                                setValue={setQ3}
                                error={q3Error}
                                setError={setQ3Error}
                            />
                        </Column>
                        <ColumnSeparator />
                        <Column>
                            <TextInput
                                label={`Q4. Rec: ${recommendedQ4}`}
                                placeholder='Insert the Q4'
                                value={q4}
                                setValue={setQ4}
                                error={q4Error}
                                setError={setQ4Error}
                            />
                        </Column>
                    </Row>

                    <RowSeparator />
                    <RowSeparator />

                    <Row>
                        <Column>
                            <TextInput
                                label='Notes'
                                value={notes}
                                setValue={setNotes}
                            />
                        </Column>
                    </Row>
                </>
            }

        </>
    );
}

const KPISection = ({ fields, kpisData, headCountTypesAvailable, getHeadCountTypeByLongName }) => {

    const messageRef = useRef(null);
    const errorMessage = useRef('');
    const warningMessage = useRef([]);

    const [showKPIMessage, setShowKPIMessage] = useState(false);

    const [kpiName, setKPIName] = useState('');
    const [kpiNameError, setKPINameError] = useState(false);
    const [headCountType, setHeadCountType] = useState('');
    const [headCountTypeError, setHeadCountTypeError] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [totalPlanned, setTotalPlanned] = useState(0);
    const [totalPlannedError, setTotalPlannedError] = useState(false);
    const [q1, setQ1] = useState(0);
    const [q1Error, setQ1Error] = useState(false);
    const [q2, setQ2] = useState(0);
    const [q2Error, setQ2Error] = useState(false);
    const [q3, setQ3] = useState(0);
    const [q3Error, setQ3Error] = useState(false);
    const [q4, setQ4] = useState(0);
    const [q4Error, setQ4Error] = useState(false);
    const [notes, setNotes] = useState('');

    const [currentKPISelected, setCurrentKPISelected] = useState({});

    const [recommendedTotalPlanned, setRecommendedTotalPlanned] = useState(0);
    const [recommendedQ1, setRecommendedQ1] = useState(0);
    const [recommendedQ2, setRecommendedQ2] = useState(0);
    const [recommendedQ3, setRecommendedQ3] = useState(0);
    const [recommendedQ4, setRecommendedQ4] = useState(0);

    useEffect(() => {
        if (!fields.headCountTypes || fields.headCountTypes.length === 0)
            return;

        const headCountTypeSelected = fields.headCountTypes.find(h => h.type === headCountType);
        if (headCountTypeSelected) {
            const newRecommended = Math.round(headCountTypeSelected.value * (percentage / 100));
            const equalAmount = Math.floor(newRecommended / 4);
            var remainingAmount = newRecommended % 4;

            let remainDistribution = [0, 0, 0, 0]; // Q1, Q2, Q3, Q4

            if (remainingAmount > 0) {
                for (let i = 3; i >= 0; i--) {
                    remainDistribution[i] = Math.floor(remainingAmount / (i + 1));
                    remainingAmount -= remainDistribution[i];
                }
            }


            setRecommendedTotalPlanned(newRecommended);
            setRecommendedQ1(equalAmount + remainDistribution[3]);
            setRecommendedQ2(equalAmount + remainDistribution[2]);
            setRecommendedQ3(equalAmount + remainDistribution[1]);
            setRecommendedQ4(equalAmount + remainDistribution[0]);
        }
        else {
            setRecommendedTotalPlanned(0);
            setRecommendedQ1(0);
            setRecommendedQ2(0);
            setRecommendedQ3(0);
            setRecommendedQ4(0);
        }
    }, [fields.headCountTypes, percentage, headCountType]);
    
    const [mode, setMode] = useState(null);

    const resetKPIValues = () => {
        setKPIName('');
        setHeadCountType('');
        setPercentage(0);
        setTotalPlanned(0);
        setQ1(0);
        setQ2(0);
        setQ3(0);
        setQ4(0);
        setNotes('');
    }

    const resetErrors = () => {
        setKPINameError(false);
        setHeadCountTypeError(false);
        setTotalPlannedError(false);
        setQ1Error(false);
        setQ2Error(false);
        setQ3Error(false);
        setQ4Error(false);
    }

    const onNewKPI = () => {
        setMode(MODES.CREATE);
        resetKPIValues();
        resetErrors();
        setCurrentKPISelected({});
        setShowKPIMessage(true);
    }

    const onEditKPI = (kpi) => {
        setMode(MODES.EDIT);
        resetErrors();
        setKPIName(kpi.kpiName);
        setHeadCountType(kpi.headCountType);
        setPercentage(kpi.percentage);
        setTotalPlanned(kpi.totalPlanned);
        setQ1(kpi.q1);
        setQ2(kpi.q2);
        setQ3(kpi.q3);
        setQ4(kpi.q4);
        setNotes(kpi.notes);
        setCurrentKPISelected(kpi);
        setShowKPIMessage(true);
    }

    const onDeleteKPI = (kpi) => {
        setMode(MODES.DELETE);
        const indexToRemove = kpiItems.findIndex(t => t.id === kpi.id);
        var newKpis = [...fields.kpis];
        newKpis.splice(indexToRemove, 1);
        fields.setKpis(newKpis);
    }

    const kpiValuesAreValid = () => {

        if (!kpiName) {
            errorMessage.current = 'Please select a \'<b>KPI</b>\'';
            setKPINameError(true);
            return false;
        }

        if (!headCountType) {
            errorMessage.current = 'Please select a \'<b>Head Count Type</b>\'';
            setHeadCountTypeError(true);
            return false;
        }

        if (!totalPlanned || !integer(totalPlanned)) {
            errorMessage.current = 'Please enter a valid value for \'<b>total planned</b>\'';
            setTotalPlannedError(true);
            return false;
        }

        if (!integer(q1)) {
            errorMessage.current = 'Please enter a valid value for \'<b>Q1</b>\'';
            setQ1Error(true);
            return false;
        }

        if (!integer(q2)) {
            errorMessage.current = 'Please enter a valid value for \'<b>Q2</b>\'';
            setQ2Error(true);
            return false;
        }

        if (!integer(q3)) {
            errorMessage.current = 'Please enter a valid value for \'<b>Q3</b>\'';
            setQ3Error(true);
            return false;
        }

        if (!integer(q4)) {
            errorMessage.current = 'Please enter a valid value for \'<b>Q4</b>\'';
            setQ4Error(true);
            return false;
        }

        if ((Number(q1) + Number(q2) + Number(q3) + Number(q4)) !== Number(totalPlanned)) {
            errorMessage.current = 'Quarter values should match the total planned value. Please revise.';
            setQ1Error(true);
            setQ2Error(true);
            setQ3Error(true);
            setQ4Error(true);
            return false;
        }
        
        if (mode === MODES.CREATE && kpiItems.filter((k) => k.kpiName === kpiName).length > 0) {
            errorMessage.current = `The KPI: '<b>${kpiName}</b>' was already chosen. Please choose another.`;
            setKPINameError(true);
            return false;
        }

        return true;
    }

    const recommendedValuesAreValid = () => {
        warningMessage.current = [];

        if (Number(totalPlanned) !== Number(recommendedTotalPlanned))
            warningMessage.current.push(`<b>Total Planned</b>. Actual: <b>${totalPlanned}</b>. Recommended: <b>${recommendedTotalPlanned}</b>.`);
        if (Number(q1) !== Number(recommendedQ1))
            warningMessage.current.push(`<b>Q1</b>. Actual: <b>${q1}</b>. Recommended: <b>${recommendedQ1}</b>.`);
        if (Number(q2) !== Number(recommendedQ2))
            warningMessage.current.push(`<b>Q2</b>. Actual: <b>${q2}</b>. Recommended: <b>${recommendedQ2}</b>.`);
        if (Number(q3) !== Number(recommendedQ3))
            warningMessage.current.push(`<b>Q3</b>. Actual: <b>${q3}</b>. Recommended: <b>${recommendedQ3}</b>.`);
        if (Number(q4) !== Number(recommendedQ4))
            warningMessage.current.push(`<b>Q4</b>. Actual: <b>${q4}</b>. Recommended: <b>${recommendedQ4}</b>.`);

        return warningMessage.current.length === 0;
    }

    const updateKPIs = () => {
        if (mode === MODES.CREATE) {
            fields.setKpis([
                ...fields.kpis,
                {
                    kpiName: kpiName,
                    headCountType: headCountType,
                    percentage: percentage,
                    totalPlanned: totalPlanned,
                    q1: q1,
                    q2: q2,
                    q3: q3,
                    q4: q4,
                    notes: notes,
                }
            ]);
        }
        else if (mode === MODES.EDIT) {
            const indexToEdit = kpiItems.findIndex(kpi => kpi.id === currentKPISelected.id);
            var newKpis = [...fields.kpis];
            newKpis[indexToEdit] = {
                kpiName: kpiName,
                headCountType: headCountType,
                percentage: percentage,
                totalPlanned: totalPlanned,
                q1: q1,
                q2: q2,
                q3: q3,
                q4: q4,
                notes: notes,
            };
            fields.setKpis(newKpis);
        }
    }

    const onSubmitKPI = () => {

        if (!kpiValuesAreValid()) {
            messageRef.current.errorSnack(errorMessage.current);
            return;
        }

        if (!recommendedValuesAreValid()) {
            messageRef.current.showAreYouSureMessage(
                '<b>Before submitting, please check the following head count discrepancies:</b> <br /><br />' +
                warningMessage.current.join('<br />') + '<br /><br />' +
                '<b>Are you sure you want to submit?</b>'
            );
            return;
        }

        updateKPIs();
        setShowKPIMessage(false);
    }

    const onSubmitAreYouSure = () => {
        messageRef.current.hideAreYouSureMessage();
        updateKPIs();
        setShowKPIMessage(false);
    }

    const kpiItems = useMemo(() => {
        return fields.kpis.map((kpi, id) => ({
            id: id,
            icon: <SecurityIcon color='var(--ff-color-purple-500)' />,
            text: `<b>${kpi.kpiName}</b>`,
            subText: `<b>Percentage</b>: ${kpi.percentage}%. <b>Total (${getHeadCountTypeByLongName(kpi.headCountType).name})</b>: ${kpi.totalPlanned}.  <b>Q1</b>: ${kpi.q1}. <b>Q2</b>: ${kpi.q2}. <b>Q3</b>: ${kpi.q3}. <b>Q4</b>: ${kpi.q4}. ${kpi.notes ? `<b>Notes</b>: ${kpi.notes}` : ''}`,
            ...kpi
        }));
        // eslint-disable-next-line
    }, [fields.kpis]);

    return (
        <>
            <ExpansionPanel header='KPIs'>
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                }}>
                    <div>
                        <Tooltip tooltip='Add new KPI' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
                            <IconButton onClick={onNewKPI}>
                                <LayerAddIcon color='var(--ff-color-nokia-blue-500)' />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ItemsList
                        items={kpiItems}
                        withActions={[
                            {
                                callback: onEditKPI,
                                icon: <EditIcon />,
                                tooltip: 'Edit KPI'
                            },
                            {
                                callback: onDeleteKPI,
                                icon: <DeleteIcon />,
                                tooltip: 'Delete KPI'
                            }
                        ]}
                    />
                </div>
            </ExpansionPanel>
            <Message
                title={`${fields.kpiTeam} KPI's`}
                message={'Add new KPI Planning. <b>NOTE: Please make sure that there is at least one entry created in the \'Head Count Type\' section.</b>'}
                actionButtonLabel='Submit'
                cancelButtonLabel='Cancel'
                open={showKPIMessage}
                setOpen={setShowKPIMessage}
                widthClassOverwrite='medium-width'
                withComponent={
                    <KPIContent
                        kpisData={kpisData}
                        kpiName={kpiName}
                        setKPIName={setKPIName}
                        kpiNameError={kpiNameError}
                        setKPINameError={setKPINameError}
                        headCountTypesAvailable={headCountTypesAvailable}
                        headCountType={headCountType}
                        setHeadCountType={setHeadCountType}
                        headCountTypeError={headCountTypeError}
                        setHeadCountTypeError={setHeadCountTypeError}
                        percentage={percentage}
                        setPercentage={setPercentage}
                        totalPlanned={totalPlanned}
                        setTotalPlanned={setTotalPlanned}
                        totalPlannedError={totalPlannedError}
                        setTotalPlannedError={setTotalPlannedError}
                        q1={q1}
                        setQ1={setQ1}
                        q1Error={q1Error}
                        setQ1Error={setQ1Error}
                        q2={q2}
                        setQ2={setQ2}
                        q2Error={q2Error}
                        setQ2Error={setQ2Error}
                        q3={q3}
                        setQ3={setQ3}
                        q3Error={q3Error}
                        setQ3Error={setQ3Error}
                        q4={q4}
                        setQ4={setQ4}
                        q4Error={q4Error}
                        setQ4Error={setQ4Error}
                        recommendedTotalPlanned={recommendedTotalPlanned}
                        recommendedQ1={recommendedQ1}
                        recommendedQ2={recommendedQ2}
                        recommendedQ3={recommendedQ3}
                        recommendedQ4={recommendedQ4}
                        notes={notes}
                        setNotes={setNotes}
                    />
                }
                onSubmit={onSubmitKPI}
            />
            <MessageBundle
                ref={messageRef}
                title='KPI Planning'
                onSubmitAreYouSure={onSubmitAreYouSure}
            />
        </>


    );
}

export default KPISection;