
import React from "react";

import {
    OverlayPanel,
    Row,
    RowSeparator,
} from "../Common";

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import HeadCountSection from "./HeadCountSection";
import KPISection from "./KPISection";

const KPIPlanningOverlayPanel = ({ fields, headCountTypesData, kpisData, headCountTypesAvailable, getHeadCountTypeByLongName }) => {
    
    const overlayPanelProps = {
        title: fields.kpiTeam,
        visible: fields.overlayPanelVisible,
        setVisible: fields.setOverlayPanelVisible,
        elevationIndex: 4,
        width: '35vw',
        cancelButtonProps: {
            label: 'Cancel'
        },
        actionButtonProps: {
            label: 'Submit',
            onClick: () => {
                //if (!fields.validateKpiSubmission())
                //    return;
                fields.messageRef.current.showAreYouSureMessage('Are you sure you want to submit the current KPI Planning?');
            }
        }
    }

    return (
        <OverlayPanel {...overlayPanelProps}>
            <Row>
                <Typography><b>Year</b>: {fields.year}</Typography>
            </Row>
            <RowSeparator />
            <Row>
                <Typography><b>Team</b>: {fields.kpiTeam}</Typography>
            </Row>
            <RowSeparator />
            <RowSeparator />
            <Row>
                <HeadCountSection
                    fields={fields}
                    headCountTypesData={headCountTypesData}
                />
            </Row>
            <RowSeparator />
            <RowSeparator />
            <Row>
                <KPISection
                    fields={fields}
                    kpisData={kpisData}
                    headCountTypesAvailable={headCountTypesAvailable}
                    getHeadCountTypeByLongName={getHeadCountTypeByLongName}
                />
            </Row>
        </OverlayPanel>
    );
};


export default KPIPlanningOverlayPanel;