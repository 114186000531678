import React from 'react';

import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import AddCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddCircleFillIcon';

const CreateSessionButton = ({ onClick }) => {
	return (
		<Button variant='call-to-action-bigger-font' onClick={onClick}>
			<ButtonText>Create</ButtonText>
			<ButtonIcon>
				<AddCircleFillIcon />
			</ButtonIcon>
		</Button>
	);
}

export default CreateSessionButton