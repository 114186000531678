import React, { useRef, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { OverlayPanelHeader } from '@nokia-csf-uxr/ccfk/OverlayPanel';
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import Button, { ButtonText, ButtonIcon } from '@nokia-csf-uxr/ccfk/Button';
import ButtonsRow from '@nokia-csf-uxr/ccfk/ButtonsRow'
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import AdvancedTheme from '@nokia-csf-uxr/ccfk/AdvancedTheme';

import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';

import { useTheme } from '../../CustomHooks/Global';
import '../../Styles/OverlayPanel.css';

const OverlayPanel = (props) => {

	const defaultTheme = useTheme();

	const overlayPanelRef = useRef(null);

	const {
		visible,
		setVisible,
		title = '',
		elevationIndex = 4,
		width = '20vw',
		id = null,
		children
	} = props;

	const closeOverlayPanel = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	const allCancelButtonProps = {
		show: true,
		onClick: closeOverlayPanel,
		label: 'Cancel',
		disabled: false,
		variant: 'secondary-bigger-font',
		...props.cancelButtonProps
	};

	const allActionButtonProps = {
		show: true,
		onClick: closeOverlayPanel,
		label: 'Ok',
		disabled: false,
		variant: 'call-to-action-bigger-font',
		...props.actionButtonProps
	};

	const handleClickOutside = useCallback((event) => {
		if (overlayPanelRef.current && event.target.classList.contains('MuiBackdrop-root'))
			closeOverlayPanel();
	}, [closeOverlayPanel]);

	const handleEscapeKey = useCallback((event) => {
		if (event.key === 'Escape')
			closeOverlayPanel();
	}, [closeOverlayPanel]);

	const overlayPanelTheme = createTheme({
		components: {
			MuiDrawer: {
				styleOverrides: {
					paper: {
						width: width,
						backgroundColor: '#FAFAFA'
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={overlayPanelTheme}>
			<Drawer
				ref={overlayPanelRef}
				onKeyDown={handleEscapeKey}
				onMouseDown={handleClickOutside}
				id='default-overlay-panel'
				anchor='right'
				open={visible}
				elevation={elevationIndex}
				SlideProps={{ timeout: 500 }}
			>
				<div className="op-at-span">
					<AdvancedTheme advancedTheme={defaultTheme}>
						<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
							<OverlayPanelHeader
								className='op-header'
								title={title}
								buttons={<IconButton onClick={closeOverlayPanel} typographyProps={{ style: { color: '#124191' } }}><CloseIcon /></IconButton>}
							/>
							<HorizontalDivider />
							<div id={id} className="op-body">
								<div className="op-content">
									{children}
								</div>
							</div>
							<HorizontalDivider />
							<ButtonsRow>
								{
									allCancelButtonProps.show &&
									<Button
										id={allCancelButtonProps.id ?? 'overlay-panel-cancel-button'}
										disabled={allCancelButtonProps.disabled}
										onClick={allCancelButtonProps.onClick}
										variant={allCancelButtonProps.variant}
										>
											<ButtonText>{allCancelButtonProps.label}</ButtonText>
											{
												allCancelButtonProps.icon &&
												<ButtonIcon>{allCancelButtonProps.icon()}</ButtonIcon>
											}
									</Button>
								}
								{
									allActionButtonProps.show &&
									<Button
										id={allActionButtonProps.id ?? 'overlay-panel-action-button'}
										disabled={allActionButtonProps.disabled}
										onClick={allActionButtonProps.onClick}
										variant={allActionButtonProps.variant}
										>
											<ButtonText>{allActionButtonProps.label}</ButtonText>
											{
												allActionButtonProps.icon &&
												<ButtonIcon>{allActionButtonProps.icon()}</ButtonIcon>
											}
									</Button>
								}
							</ButtonsRow>
						</div>
					</AdvancedTheme>
				</div>
			</Drawer>
		</ThemeProvider>
	);
}

export default OverlayPanel;