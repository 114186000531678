import React, { useMemo } from 'react';

import { useUser } from '../../CustomHooks/Global';
import { useURLParamsAction } from '../../CustomHooks/URLParams';
import {
    ASSIGNMENT_STATUS,
    useL3PlusCertificationFields,
    useL3PlusAssignments,
    useL3PlusAssignmentSubmission,
    useL3PlusMentorManagers
} from '../../CustomHooks/L3PlusCertification';

import ModuleBaseObj from '../../CustomObjects/Module';

import { statusBarRenderer, link } from '../../Renderers/DataTable';

import { ModuleBase, Toggle } from "../../Components/Common";
import {
    ManageMentorsButton,
    CreateMentorAssignmentButton,
    MentorAssignmentOverlayPanel,
    MentorManagementFloatingPanel
} from '../../Components/Certifications/L3PlusCertification';

import SpeakIcon from '@nokia-csf-uxr/ccfk-assets/latest/SpeakIcon';
import TaskIcon from '@mui/icons-material/Task';
import VerifiedIcon from '@nokia-csf-uxr/ccfk-assets/latest/VerifiedIcon';
import ErrorCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/ErrorCircleFillIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';

const L3PlusCertification = () => {

    const user = useUser();

    const fields = useL3PlusCertificationFields();
    const {
        assignments,
        refreshAssignments,
        onToggleChange,
        createAssignment,
        editAssignment,
        singleAssignment,
        getSingeAssignment
    } = useL3PlusAssignments(fields);
    const { isCourseManager, eligibleCoursesToManage, getManagableCourseDetails } = useL3PlusMentorManagers();
    const { onSubmitAssignment, reUploadOJTDocument } = useL3PlusAssignmentSubmission(fields, refreshAssignments);

    useURLParamsAction(['id'], singleAssignment,
        (paramsObj) => {
            fields.messageRef.current.showProcessingDiag();
            getSingeAssignment(paramsObj['id']);
        },
        () => {
            fields.messageRef.current.hideProcessingDiag();
            if (singleAssignment && singleAssignment.length > 0)
                editAssignment({ data: singleAssignment[0] });
        }
    );

    useURLParamsAction(['request'], null, createAssignment);

    const moduleBaseObj = useMemo(() => {

        const newModuleBaseObj = new ModuleBaseObj(fields.messageRef);

        newModuleBaseObj.moduleHeader.bannerPath = require('../../Images/BannerBackgrounds/banner-background-8.jpg');
        newModuleBaseObj.moduleHeader.bannerPosition = "50% 85%";
        newModuleBaseObj.moduleHeader.title = "L3+ On the Job Training";

        newModuleBaseObj.dataTable.ref = fields.dataTableRef;
        newModuleBaseObj.dataTable.data = assignments;
        newModuleBaseObj.dataTable.columnsIgnore = fields.defaultColsToHide;
        newModuleBaseObj.dataTable.columnDefinitions = {
            status: {
                headerName: '',
                type: 'status',
                width: 44,
                maxWidth: 44,
                resizable: false,
                cellRenderer: statusBarRenderer,
                cellRendererParams: {
                    valueColorMap: {
                        [ASSIGNMENT_STATUS.ASSIGNED]: 'var(--ff-color-purple-500)',
                        [ASSIGNMENT_STATUS.UPLOADED]: 'var(--ff-color-nokia-blue-500)',
                        [ASSIGNMENT_STATUS.FAILED]: 'var(--ff-color-red-500)',
                        [ASSIGNMENT_STATUS.SUCCESS]: 'var(--ff-color-green-500)',
                    },
                    valueIconMap: {
                        [ASSIGNMENT_STATUS.ASSIGNED]: SpeakIcon,
                        [ASSIGNMENT_STATUS.UPLOADED]: TaskIcon,
                        [ASSIGNMENT_STATUS.FAILED]: ErrorCircleFillIcon,
                        [ASSIGNMENT_STATUS.SUCCESS]: VerifiedIcon,
                    },
                }
            },
            courseRole: {
                headerName: 'Role'
            },
            courseDisplayName: {
                headerName: 'Certification'
            },
            mentorFeedback: {
                headerName: 'Feedback'
            },
            OJTMaterialsURL: {
                headerName: 'OJT Template',
                cellRenderer: link,
                cellRendererParams: {
                    text: 'Folder...'
                }
            }
        }

        newModuleBaseObj.dataTable.tableOptions.onRefresh = refreshAssignments;
        newModuleBaseObj.dataTable.tableOptions.onExcelFile.fileName = 'L3+ Certifications';

        newModuleBaseObj.dataTable.rowOptions = [
            {
                icon: <EditIcon />,
                tooltip: 'Details',
                callback: editAssignment
            }
        ]

        newModuleBaseObj.dataTable.noRowsMessage = `${user.firstName} ${user.lastName} has no associated OJT's in ${fields.showEvaluated ? '"Completed"' : '"In Progress"'} state...`;

        newModuleBaseObj.dataTable.additionalComponents.toolbar = <Toggle
            checked={fields.showEvaluated}
            setChecked={fields.setShowEvaluated}
            checkedText='Completed'
            uncheckedText='In Progress'
            onChange={onToggleChange}
            disabled={fields.toggleDisabled}
        />
        newModuleBaseObj.dataTable.additionalComponents.searchbar =
            <div style={{display: 'flex', gap: '0.5em'}}>
                {
                    isCourseManager &&
                    <ManageMentorsButton onClick={() => fields.setPanelManageVisible(true)} />
                }
                <CreateMentorAssignmentButton onClick={createAssignment} />
            </div>;

        newModuleBaseObj.dataTable.rowClick = editAssignment

        newModuleBaseObj.overlayPanel.component =
            <MentorAssignmentOverlayPanel
                ref={fields.overlayPanelRef}
                fields={fields}
                reUploadOJTDocument={reUploadOJTDocument}
            />

        newModuleBaseObj.floatingPanel.component = 
            <MentorManagementFloatingPanel
                fields={fields}
                eligibleCoursesToManage={eligibleCoursesToManage}
                getManagableCourseDetails={getManagableCourseDetails}
            />

        newModuleBaseObj.messages.title = 'L3+ On the Job Training';
        newModuleBaseObj.messages.submitAreYouSureMessage = onSubmitAssignment;

        return newModuleBaseObj;
    }, [
        fields,
        assignments,
        createAssignment,
        editAssignment,
        user,
        refreshAssignments,
        onToggleChange,
        onSubmitAssignment,
        isCourseManager,
        eligibleCoursesToManage,
        getManagableCourseDetails,
        reUploadOJTDocument
    ]);

    return (
        <ModuleBase
            messageRef={fields.messageRef}
            moduleBaseObj={moduleBaseObj}
        />
    )
}

export default L3PlusCertification;