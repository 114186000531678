
import React, { useState, useRef } from 'react';

import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { ClickAwayListener, PopperUnstyled } from '@mui/base';

import '../../Styles/DateTimePicker.css';

const DateTimePicker = (props) => {

	const {
		value, setValue, label,
		ampm = false, format = 'YYYY-MM-DD HH:mm:ss',
		required = false, error = false, setError = null,
		disabled = false, onChange = null, id = null
	} = props;

	const convertDate = (date, withOffset) => {
		const offset = (dayjs.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'utc').valueOf() - date.valueOf()) / 1000;
		return dayjs(date).utc().add(withOffset ? offset : 0, 'second');
	}

	const [open, setOpen] = useState(false);
	const dateTimeValue = useRef(convertDate(value, true));

	const ref = useRef(null);

	const labelClasses = 'datetimepicker-label' + (required ? ' required' : '');

	return (
		<div id={id} className='datetimepicker'>
			<span className={labelClasses} >{label}</span>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ClickAwayListener onClickAway={() => { setOpen(false); }}><div>
					<DesktopDateTimePicker
						className={`desktopdatetimepicker ${error && 'with-error'}`}
						value={value}
						onChange={
							(v) => {
								if (setError)
									setError(false);
								setValue(v);
								if (onChange)
									onChange(v);
							}
						}
						onOpen={() => { setOpen(!open); }}
						open={false}
						format={format}
						ampm={ampm}
						sx={{ margin: '1px' }}
						ref={ref}
						disabled={disabled}
					/>
					<PopperUnstyled
						open={open}
						anchorEl={ref.current}
						placement='bottom-start'
					>
						<StaticDateTimePicker
							value={dateTimeValue.current}
							displayStaticWrapperAs="mobile"
							onChange={
								(v) => {
									if (setError)
										setError(false);
									setValue(v);
									dateTimeValue.current = convertDate(v, false);
									if (onChange)
										onChange(v);
								}}
							onClose={() => { setOpen(false); }}
							format={format}
							ampm={ampm}
							slotProps={{ toolbar: { hidden: true }, actionBar: { actions: [] } }}
						/>
					</PopperUnstyled>
				</div></ClickAwayListener>
			</LocalizationProvider>
		</div>
	);
}

export default DateTimePicker;