import React, { useState, useEffect, useCallback } from 'react';

import {
    default as CarouselDefault,
    CarouselIndicator,
    CarouselIndicatorContainer,
    CarouselContent
} from '@nokia-csf-uxr/ccfk/Carousel';

import CarouselContainer from './CarouselContainer';

const Carousel = (props) => {

    const CHANGE_CARROUSEL_INTERVAL = 30;

    const CarouselData = [
        {
            imagePath: require('../../Images/Carousel/Slide5.jpg'),
            backgroundPosition: '50% 50%',
            address: "https://nokia.sharepoint.com/:u:/r/sites/mkmcd/SitePages/Introducing-the-upgraded-Nokia-Knowledge-Center-(NKC).aspx?csf=1&web=1&e=jTcjOZ",
            title: "Nokia Knowledge Center",
            text: "Introducing the upgraded Nokia Knowledge Center (NKC)"
        },
        {
            imagePath: require('../../Images/Carousel/Slide6.jpg'),
            backgroundPosition: '50% 40%',
            address: "https://nokia.sharepoint.com/sites/mkmcd/SitePages/Tech-Talks-Community.aspx",
            title: "Tech Talks",
            text: "Insights from Tech Leaders."
        },
        {
            imagePath: require('../../Images/Carousel/Slide2.jpg'),
            backgroundPosition: '50% 35%',
            address: "https://nokia.sharepoint.com/sites/nokiaknowledgecenter/Shared Documents/UI_UX Project/../../../mkmcd/SitePages/AnyCloud.aspx",
            title: "AnyCloud",
            text: "Ready to begin? Start your journey here."
        },
        {
            imagePath: require('../../Images/Carousel/Slide3.jpg'),
            backgroundPosition: '50% 15%',
            address: "https://nokia.sharepoint.com/sites/mkmcd/SitePages/Knowledge-Hubs-and-Communities-of-Practice.aspx#our-knowledge-hubs-for-each-community-of-practice",
            title: "Communities of Practice",
            text: "Mastering the art of knowledge sharing."
        },
    ];

    const MIN_INDICATOR = 0;
    const MAX_INDICATOR = CarouselData.length - 1;

    const [carrouselIndex, setCarrouselIndex] = useState(0);

    const onSelectCarrousel = (activeIndex) => {
        setCarrouselIndex(activeIndex);
    };

    const handleNextSlide = useCallback(() => {
        const nextSelectedIndex = carrouselIndex + 1;
        if (nextSelectedIndex <= MAX_INDICATOR) {
            setCarrouselIndex(nextSelectedIndex);
        }
        else {
            setCarrouselIndex(MIN_INDICATOR);
        }
    }, [carrouselIndex, MAX_INDICATOR]);

    const carouselSlides = CarouselData.map((data, index) => {
        const keyValue = `${index}-slide`;
        return (
            <CarouselContainer
                key={keyValue}
                imagePath={data.imagePath}
                backgroundPosition={data.backgroundPosition}
                address={data.address}
                title={data.title}
                text={data.text}
            />
        );
    });

    const carouselIndicators = CarouselData.map((_, index) => {
        const keyValue = `${index}-slide`;
        return (
            <CarouselIndicator key={keyValue} index={index} onSelect={onSelectCarrousel} selected={carrouselIndex === index} />
        );
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNextSlide();
        }, CHANGE_CARROUSEL_INTERVAL * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [carrouselIndex, handleNextSlide]);

    return (
        <CarouselDefault {...props}>
            <CarouselContent activeIndex={carrouselIndex} >
                {carouselSlides}
            </CarouselContent>
            <CarouselIndicatorContainer>
                {carouselIndicators}
            </CarouselIndicatorContainer>
        </CarouselDefault>
    );

}

export default Carousel;