import { useFetch, useFetchWithInfinite } from "./Fetch";

import Env from "../CustomObjects/Environment";
import { useEffect } from "react";

import { useUser } from "./Global";

import { EmailInstance } from "./Emails";

import dayjs from 'dayjs';

export const DATA_TYPES = {
    ALL: 'ALL',
    L3_CERT_REPORTS: 'L3 CERT',
    L3_PM_CERT_REPORTS: 'L3 PM CERT',
    L4_CERT_REPORTS: 'L4 CERT',
    CURRICULUM_REPORTS_L1: 'L1 CURRICULUM',
    CURRICULUM_REPORTS_L2: 'L2 CURRICULUM',
    CURRICULUM_REPORTS_L3: 'L3 CURRICULUM',
    PMP_CERT_REPORTS: 'PMP CERT',
}

export const reportsStartDateCellRenderer = ({ value }) => {
    if (value)
        return dayjs(value).calendar();
    else
        return '(N/A)';
};

export const reportsEndDateCellRenderer = ({ value }) => {
    if (value)
        return dayjs(value).calendar();
    else
        return '(Unknown)';
};

export const userDataRenderer = ({ value }) => {
    return value ?? '(Unknown)';
}

export const useReportsData = (dataType, dataTableRef) => {
    const [reports, onGridReady, onKeywordChange, setFetchReports, setFetchReportHeaders, , fetchDataForCSV] =
        useFetchWithInfinite(Env.BACKEND_SERVER_URL + 'Reports', { dataType: dataType }, 'GET', null, false, null, dataTableRef);

    const updateReports = (dataType) => {
        setFetchReportHeaders({ dataType: dataType });
        setFetchReports(true);
    }

    return {
        reports,
        onGridReady,
        onKeywordChange,
        updateReports,
        fetchDataForCSV
    };
}

export const useGenerateDiploma = (messageRef) => {

    const [successWithDiplomaFileName, setGenerateDiploma, creationError, , setGenerateDiplomaBody] = useFetch(Env.BACKEND_SERVER_URL + 'Reports', false, {}, 'POST');

    const generateDiploma = (data) => {
        setGenerateDiplomaBody({
            title: data.courseDiplomaName,
            courseLevel: data.courseLevel,
            userFirstName: data.firstName,
            userLastName: data.lastName,
            userEmail: data.userEmail,
            completionDate: data.transcriptCompletionDate,
            release: data.courseRelease ?? '',
        });
        setGenerateDiploma(true);
        messageRef.current.showProcessingDiag();
    }

    useEffect(() => {
        if (creationError) {
            messageRef.current.errorMessage(`Error when generating Diploma: ${creationError.data}`);
            messageRef.current.hideProcessingDiag();
        }
        // eslint-disable-next-line
    }, [creationError]);

    return {
        generateDiploma,
        successWithDiplomaFileName,
        creationError
    }
}

export const useQueueDiploma = (messageRef, currentReportData, successWithDiplomaFileName, queueEmailWithFileNames, queueEmailWithFileNamesSuccess, queueEmailWithFileNamesError) => {

    const user = useUser();

    useEffect(() => {
        if (!successWithDiplomaFileName)
            return;

        const body = `
            <h2>${currentReportData.courseLevel} Certification Diploma</h2>

            <h3>Congratulations ${currentReportData.firstName} ${currentReportData.lastName}</h3>

            <h4>You have completed your certification for: <b>'${currentReportData.courseDiplomaName}'</b></h4>

            <p>Please open the attachment to receive your diploma.</p>
            <br />
            <p>We encourage you to share your badges in the internal or external media. You can manage your badges in the Achievement Center at 
            <a href="https://nokia.sharepoint.com/sites/learn/achiev/SitePages/index.aspx/MyAwards" target="_blank">My Badges</a>. By default badges are 
            public and shared on the intranet. You can control their privacy status at any time.</p>
        `;

        const emailInstance = new EmailInstance(
            'do_not_reply@nokia.com',
            [currentReportData.userEmail],
            [user.email],
            ['I_NKC_KMCD_LOG_DIPLOMAS@nokia.com'],
            `${currentReportData.courseLevel} Certification Diploma`,
            body,
            [successWithDiplomaFileName.data],
        );
        queueEmailWithFileNames(emailInstance);

        // eslint-disable-next-line
    }, [successWithDiplomaFileName]);

    useEffect(() => {
        if (queueEmailWithFileNamesSuccess || queueEmailWithFileNamesError)
            messageRef.current.hideProcessingDiag();
        if (queueEmailWithFileNamesSuccess)
            messageRef.current.successMessage("Diploma has been successfully submitted! You should receive an email shortly");
        if (queueEmailWithFileNamesError)
            messageRef.current.errorMessage(`Error when submitting diploma: ${queueEmailWithFileNamesError.data}`);
        // eslint-disable-next-line
    }, [queueEmailWithFileNamesSuccess, queueEmailWithFileNamesError]);


    
}