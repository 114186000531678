import { useEffect } from 'react';

export const useSingleExecutionPerSession = (sessionVarName, callback, delay = 0, condition = true) => {

    useEffect(() => {
        const newSessionVar = sessionStorage.getItem(sessionVarName);
       
        if (!newSessionVar && condition) {
            const timeoutId = setTimeout(() => {
                callback();
                sessionStorage.setItem(sessionVarName, true);
            }, delay);

            return () => clearTimeout(timeoutId);
        }

    }, [callback, sessionVarName, delay, condition])
}

export const useSingleExecutionPerLocal = (sessionVarName, callback, delay = 0, condition = true) => {

    useEffect(() => {
        const newSessionVar = localStorage.getItem(sessionVarName);

        if (!newSessionVar && condition) {
            const timeoutId = setTimeout(() => {
                callback();
                localStorage.setItem(sessionVarName, true);
            }, delay);

            return () => clearTimeout(timeoutId);
        }

    }, [callback, sessionVarName, delay, condition])
}
