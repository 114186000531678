import React, { useMemo, useRef, useState } from 'react';

import {
    ExpansionPanel,
    SelectButton,
    Message,
    SelectAutoFill,
    ItemsList,
    SnackBar
} from '../../Common';

import { uppercaseFirstLetter } from '../../../CustomObjects/Utils';

import LayerAddIcon from '@nokia-csf-uxr/ccfk-assets/latest/LayerAddIcon';
import UserCheckIcon from '@nokia-csf-uxr/ccfk-assets/latest/UserCheckIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';

const MODES = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
}

const PLACEHOLDER_COLUMN = 'placeholder';
const FIELD_SEPARATOR = '#';
const FIELD_PLACEHOLDER_SEPARATOR = ':';

const credentialsItemsUIColumns = [
    'type', 'id', 'icon', 'text', 'subText'
];

const defaultRegex = '.*';

const CredentialContent = ({ newCredentials, setNewCredentials, credentialsErrors, setCredentialsErrors, newCredentialsPlaceholders, credentialFieldValidation }) => {

    const getFieldLabel = (i, credentialField) => {
        const regExp = new RegExp(credentialFieldValidation.current.hasOwnProperty(i) ? credentialFieldValidation.current[i] : defaultRegex, 'i');

        return `${uppercaseFirstLetter(credentialField)}${!regExp.test('')? ' *':''}`;
    }

    return (
        <div
            style={{
                paddingTop: '1em',
                display: 'flex',
                flexDirection: 'column',
                gap: '2em'
            }}
        >
            {
                Object.entries(newCredentials).map(([credentialField, value], i) => (
                    <SelectAutoFill 
                        key={i}
                        data={{
                            values: newCredentialsPlaceholders[credentialField],
                            mapping: { value: PLACEHOLDER_COLUMN }
                        }}
                        label={getFieldLabel(i, credentialField)}
                        values={value}
                        setValues={(newValue) => setNewCredentials({ ...newCredentials, [credentialField]: newValue })}
                        placeholder={`Insert the ${uppercaseFirstLetter(credentialField)}...`}
                        error={credentialsErrors[credentialField]}
                        setError={(newValue) => setCredentialsErrors({ ...credentialsErrors, [credentialField]: newValue })}
                        withFreeText
                        hideClearButton
                    />
                ))
            }
        </div>
    );
}

const Credentials = ({ fields, credentialsTypes }) => {

    const credentialIdSelected = useRef(null);
    const credentialFieldDefinition = useRef([]);
    const credentialFieldValidation = useRef([]);
    const credentialFieldPlaceholders = useRef([]);

    const [mode, setMode] = useState(null);
    const [credentialTypeSelected, setCredentialTypeSelected] = useState('');
    const [showCredentialsMessage, setShowCredentialsMessage] = useState(false);
    const [showCredentialErrorMessage, setShowCredentialErrorMessage] = useState(false);

    const [newCredentials, setNewCredentials] = useState({});
    const [credentialsErrors, setCredentialsErrors] = useState({});
    const [newCredentialsPlaceholders, setNewCredentialsPlaceholders] = useState({});

    const setCurrentCredentialData = (type, id) => {
        credentialIdSelected.current = id;
        const credentialData = credentialsTypes.filter(credDef => credDef.type === type)[0];
        credentialFieldDefinition.current = credentialData.fieldDefinition.split(FIELD_SEPARATOR);
        credentialFieldValidation.current = credentialData.fieldValidation.split(FIELD_SEPARATOR);
        credentialFieldPlaceholders.current = credentialData.fieldPlaceholders.split(FIELD_SEPARATOR);
    }

    const getFieldPlaceholders = (index) => {
        if (credentialFieldPlaceholders.current.length > index)
            return credentialFieldPlaceholders.current[index].split(FIELD_PLACEHOLDER_SEPARATOR).map(p => ({ [PLACEHOLDER_COLUMN] : p }));
        return [];
    }

    const filteredCredentialObjToOnlyCredentialFields = (credentialObj) => {
        return Object.entries(credentialObj)
            .filter(([credentialField]) => !credentialsItemsUIColumns.includes(credentialField));
    }

    const resetNewCredentials = () => {
        var newDefinitions = {};
        var newErrors = {};
        var newPlaceholders = {};

        credentialFieldDefinition.current
            .forEach((field, i) => {
                newDefinitions[field] = '';
                newErrors[field] = false;
                newPlaceholders[field] = getFieldPlaceholders(i);
            });
        setNewCredentials({ ...newDefinitions });
        setCredentialsErrors({ ...newErrors });
        setNewCredentialsPlaceholders({ ...newPlaceholders });
    }

    const fillNewCredentials = (credentialObj) => {
        setCredentialTypeSelected(credentialObj.type);
        var credentialsToBeEditted = {};
        var credentialsErrorsToBeEdited = {};
        var placeholdersToBeEdited = {};
        
        filteredCredentialObjToOnlyCredentialFields(credentialObj)
            .forEach(([credentialField, value], i) => {
                credentialsToBeEditted[credentialField] = value;
                credentialsErrorsToBeEdited[credentialField] = false;
                placeholdersToBeEdited[credentialField] = getFieldPlaceholders(i);
            });
        setNewCredentials({ ...credentialsToBeEditted });
        setCredentialsErrors({ ...credentialsErrorsToBeEdited });
        setNewCredentialsPlaceholders({ ...placeholdersToBeEdited });
    }

    const credentialItemSubText = (credentialObj) => {
        return filteredCredentialObjToOnlyCredentialFields(credentialObj)
            .filter(([, value]) => value)
            .map(([credentialField, value]) => `<b>${uppercaseFirstLetter(credentialField)}:</b> ${value}`)
            .join(' ');
    }

    const onNewCredential = (type) => {
        setMode(MODES.CREATE);
        setCurrentCredentialData(type, null);
        resetNewCredentials();
        setShowCredentialsMessage(true);
    }

    const onEditCredential = (cred) => {
        setMode(MODES.EDIT);
        setCurrentCredentialData(cred.type, cred.id);
        fillNewCredentials(cred);
        setShowCredentialsMessage(true);
    }

    const checkCredentialFields = (newCredentials) => {
        for (var i = 0; i < credentialFieldDefinition.current.length; i++) {
            const fieldName = credentialFieldDefinition.current[i];

            if (credentialFieldPlaceholders.current.some(p => newCredentials[fieldName] === p))
                continue;

            const regExp = new RegExp(credentialFieldValidation.current.hasOwnProperty(i) ? credentialFieldValidation.current[i] : defaultRegex, 'i');
                
            if (!regExp.test(newCredentials[fieldName])) {
                setCredentialsErrors({ ...credentialsErrors, [fieldName]: true });
                return false
            }
        }

        return true;
    }

    const onSubmitCredentials = (newCredentials) => {

        if (!checkCredentialFields(newCredentials)) {
            setShowCredentialErrorMessage(true);
            return;
        }

        if (mode === MODES.CREATE) {
            fields.setCredentials([
                ...fields.credentials,
                {
                    type: credentialTypeSelected,
                    ...newCredentials
                }
            ]);
        }
        else if (mode === MODES.EDIT) {
            const indexToEdit = credentialsItems.findIndex(cred => cred.id === credentialIdSelected.current);
            var credentialsList = [...fields.credentials];
            credentialsList[indexToEdit] = {
                type: credentialTypeSelected,
                ...newCredentials
            };
            fields.setCredentials(credentialsList);
        }

        setShowCredentialsMessage(false);
    }

    const onDeleteCredential = (cred) => {
        setMode(MODES.DELETE);
        const indexToRemove = credentialsItems.findIndex(c => c.id === cred.id);
        var currentCredentials = [...fields.credentials]
        currentCredentials.splice(indexToRemove, 1);
        fields.setCredentials(currentCredentials);
    }

    const credentialsItems = useMemo(() => {
        var newCredentialsItems = [];
        fields.credentials.forEach(cred => {
            const credentialDefinitions = credentialsTypes.filter(credDef => credDef.type === cred.type)[0].fieldDefinition.split(FIELD_SEPARATOR);

            const newCrendentialItem = {}
            newCrendentialItem.type = cred.type;
            credentialDefinitions.forEach(field => {
                if (cred[field])
                    newCrendentialItem[field] = cred[field];
                else
                    newCrendentialItem[field] = '';
            });
            newCredentialsItems.push(newCrendentialItem);
        });

        return newCredentialsItems.map((cred, id) => {
            return ({
                id: id,
                icon: <UserCheckIcon color='var(--ff-color-purple-500)' />,
                text: '<b>' + cred.type + '</b>',
                subText: credentialItemSubText(cred),
                ...cred
            });
        });
       
        // eslint-disable-next-line
    }, [fields.credentials]);

    const credentialsMessageTitle = useMemo(() => {
        if (mode === MODES.CREATE)
            return `New Lab Credentials for ${credentialTypeSelected}`;
        else if (mode === MODES.EDIT)
            return `Edit Lab Credentials for ${credentialTypeSelected}`;
        else
            return '';
    }, [mode, credentialTypeSelected]);

    const credentialsMessageText = useMemo(() => {
        if (mode === MODES.CREATE)
            return 'Please insert the new set of credentials';
        else if (mode === MODES.EDIT)
            return 'Update the set of credentials';
        else
            return '';
        // eslint-disable-next-line
    }, [mode]);

    return (
        <>
            <ExpansionPanel header='Credentials'>
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                }}>
                    <SelectButton
                        value={credentialTypeSelected}
                        setValue={setCredentialTypeSelected}
                        data={{
                            values: credentialsTypes,
                            mapping: {
                                value: 'type'
                            }
                        }}
                        width={'10em'}
                        Icon={LayerAddIcon}
                        defaultIconColor='var(--ff-color-nokia-blue-500)'
                        withLabel={false}
                        onChange={onNewCredential}
                        tooltipOverride='Add a new credential set'
                    />
                    <ItemsList
                        items={credentialsItems}
                        withActions={[
                            {
                                callback: onEditCredential,
                                icon: <EditIcon />,
                                tooltip: 'Edit Credential'
                            },
                            {
                                callback: onDeleteCredential,
                                icon: <DeleteIcon />,
                                tooltip: 'Delete Credential'
                            }
                        ]}
                    />
                </div>
            </ExpansionPanel>
            <Message
                title={credentialsMessageTitle}
                message={credentialsMessageText}
                actionButtonLabel='Submit'
                cancelButtonLabel='Cancel'
                open={showCredentialsMessage}
                setOpen={setShowCredentialsMessage}
                withComponent={
                    <CredentialContent
                        newCredentials={newCredentials}
                        setNewCredentials={setNewCredentials}
                        credentialsErrors={credentialsErrors}
                        setCredentialsErrors={setCredentialsErrors}
                        newCredentialsPlaceholders={newCredentialsPlaceholders}
                        credentialFieldValidation={credentialFieldValidation}
                    />
                }
                onSubmit={() => onSubmitCredentials(newCredentials)}
            />
            <SnackBar
                variant='error'
                open={showCredentialErrorMessage}
                setOpen={setShowCredentialErrorMessage}
                message='Please make sure all credentials follow the correct format'
            />
        </>
    );
}

export default Credentials;