import React from 'react';

import '../../Styles/DataTable.css';

const DataTableStatusColumn = ({ color, Icon }) => {

    return (
        <div className="status-column-container">
            <div style={{ backgroundColor: color }}></div>
            {
                Icon && <Icon color={color} style={{color: color}} />
            }
        </div>
    );
}

export default DataTableStatusColumn;