
import dayjs from 'dayjs';

import { useFetchWithInfinite } from "./Fetch";

import Env from "../CustomObjects/Environment";

export const dateCellRenderer = ({ value }) => {
    if (value)
        return dayjs(value).format('YYYY-MM-DD');
    else
        return '';
};

export const useCourseCatalogData = (dataTableRef) => {
    const [catalogData, onGridReady, onKeywordChange, setRefreshData, setRequestHeaders, , fetchDataForCSV] =
        useFetchWithInfinite(Env.BACKEND_SERVER_URL + 'Solutions/CourseCatalog', { ignoreFilters: true }, 'GET', null, false, null, dataTableRef);

    const updateCourseData = (years = [], quarters = [], periods = [], ignoreFilters = false) => {
        setRequestHeaders({
            years: years,
            quarters: quarters,
            periods: periods,
            ignoreFilters: ignoreFilters
        });
        setRefreshData(true);
    }

    return {
        catalogData,
        onGridReady,
        onKeywordChange,
        updateCourseData,
        fetchDataForCSV
    };
}

export const QUARTERS = { NULL: "NULL", Q1: "Q1", Q2: "Q2", Q3: "Q3", Q4: "Q4" };

export const PERIODS = { NULL: "NULL", P01: "P01", P02: "P02", P03: "P03", P04: "P04", P05: "P05", P06: "P06", P07: "P07", P08: "P08", P09: "P09", P10: "P10", P11: "P11", P12: "P12" }
