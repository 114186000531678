import React, { useEffect, useState } from 'react';

import ReactHtmlParser from 'html-react-parser';

import {
    default as NotificationsBase,
    NotificationsCount,
    NotificationsButton,
    NotificationsPopup,
    NotificationsPopupHeader,
    NotificationsList,
    NotificationsItem,
    NotificationsItemIcon,
    NotificationsItemTitle,
    NotificationsItemSubtitle
} from '@nokia-csf-uxr/ccfk/Notifications';
import { Message } from '../Common';

import MessageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MessageIcon';
import InfoIcon from '@nokia-csf-uxr/ccfk-assets/legacy/InfoIcon';
import CloseCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseCircleIcon';

import '../../Styles/Notifications.css';

const Notifications = ({ data }) => {
    const [notifications, setNotifications] = useState([]);
    const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogVariant, setDialogVariant] = useState('Info');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (data)
            setNotifications(data
                .map((item, i) => (
                    { id: i, new: true, unread: true, type: 'Info', ...item })
                )
            );
    }, [data])

    const showBadge = () => {
        return notifications.some(n => n.new)
    };

    const getNotificationCount = () => {
        return notifications.filter(n => n.new).length;
    };

    const handleOpenPopup = () => {
        if (notifications.length > 0)
            setShowNotificationsPopup(!showNotificationsPopup);
    };

    const handleClosePopup = () => {
        setShowNotificationsPopup(false);
    }

    const handleTooltip = () => {
        const count = getNotificationCount();
        if (count > 0)
            return `${count} new notifications`;
        return 'No new notifications';
    };

    const handleUnread = (item) => (e) => {
        if (e.type === 'keydown' && !(e.key === ' ' || e.key === 'Enter')) {
            return;
        }

        e.preventDefault();
        const updatedNotifiations = notifications.map(n => (n.id === item.id ? { ...n, unread: false, new: false } : n));
        setNotifications([...updatedNotifiations]);

        setDialogTitle(item.title);
        setDialogText(item.text);
        setDialogVariant(item.type);
        setOpenDialog(true);
    };

    return (
        <>
            <NotificationsBase
                onEscFocusOut={handleClosePopup}
                onClickOutside={handleClosePopup}
            >
                <NotificationsCount role="alert">
                    {getNotificationCount()}
                </NotificationsCount>
                <NotificationsButton
                    dotBadge={showBadge()}
                    iconButtonProps={{ onClick: handleOpenPopup }}
                    tooltipProps={{ tooltip: handleTooltip() }}
                />
                <NotificationsPopup
                    open={showNotificationsPopup}
                    elevationsProps={{ allowElevationBackgroundColorOverride: true }}
                >
                    <NotificationsPopupHeader>Messages</NotificationsPopupHeader>
                    <NotificationsList className='notifications-item-list'>
                        {
                            notifications.map(item => (
                                <NotificationsItem
                                    data-index={item.id}
                                    key={item.id}
                                    new={item.new}
                                    unread={item.unread}
                                    listItemProps={{
                                        role: "listitem",
                                        'aria-label': item.title,
                                        onKeyDown: handleUnread(item),
                                        onClick: handleUnread(item)
                                    }}
                                >
                                    <NotificationsItemIcon>
                                        {
                                            item.type === 'Info' &&
                                            <MessageIcon color='var(--color-info)' size={32} />
                                        }
                                        {
                                            item.type === 'Warning' &&
                                            <InfoIcon color='var(--color-warning)' size={32} />
                                        }
                                        {
                                            item.type === 'Error' &&
                                            <CloseCircleIcon color='var(--color-danger)' size={32} />
                                        }
                                    </NotificationsItemIcon>
                                    <NotificationsItemTitle className='notifications-item-title'>
                                        {ReactHtmlParser(item.title)}
                                        <NotificationsItemSubtitle className='notifications-item-subtitle'>
                                            {ReactHtmlParser(item.text)}
                                        </NotificationsItemSubtitle>
                                    </NotificationsItemTitle>
                                </NotificationsItem>
                            ))
                        }
                    </NotificationsList>
                </NotificationsPopup>
            </NotificationsBase>
            <Message
                title={dialogTitle}
                message={dialogText}
                variant={dialogVariant}
                actionButtonLabel='Ok'
                open={openDialog}
                setOpen={setOpenDialog}
                onSubmit={() => setOpenDialog(false)}
            />
        </>
    );
}

export default Notifications;