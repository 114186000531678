import React from 'react';
import { useParams } from 'react-router-dom';
import Env from "../CustomObjects/Environment";
import { useUser } from "../CustomHooks/Global";
import { LegacyModule } from '../Components/Common';

const KnowledgeArticle = () => {

	const { id } = useParams()
	const user = useUser();

	return (
		<LegacyModule
			scrolling='true'
			url={Env.LEGACY_NKC_SERVER_URL + "/CaseArticle.aspx?id=" + id + "&login=" + user.username + "&email=" + user.email + "&access=not_direct_access"}
		/>
	);
}

export default KnowledgeArticle;
