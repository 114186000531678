import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import App, { AppHeader, AppContent, AppContentWrapper, AppBody } from '@nokia-csf-uxr/ccfk/App';
import AppBanner, { AppBannerLogo, AppBannerName, AppBannerContent } from '@nokia-csf-uxr/ccfk/AppBanner';
import { SideDrawerButton } from '@nokia-csf-uxr/ccfk/SideDrawer';
import KEY_CODES from '@nokia-csf-uxr/ccfk/common/KeyCodes';
import AdvancedTheme from '@nokia-csf-uxr/ccfk/AdvancedTheme';

import { useIsAdmin, useIsTester, useLeftMenu, useTheme } from '../CustomHooks/Global';
import { useFetch } from '../CustomHooks/Fetch';
import AppRoutes from '../CustomObjects/AppRoutes';
import Env from "../CustomObjects/Environment";
import { About, ContactUs, UserAccount, LeftSidePanelMenu, Notifications } from '../Components';
import { LinearLoading } from '../Components/Common';

import baseTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-light-theme.json";

const Main = () => {
    const sideDrawerRef = createRef(null);
    const SideDrawerButtonRef = createRef(null);
    const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(true);

    const isTestServer = Env.FRONTEND_SERVER_URL === Env.NKC_TEST_SERVER_URL;
    const userIsAdmin = useIsAdmin();
    const userIsTester = useIsTester();
    const allowed = !(isTestServer && !userIsAdmin && !userIsTester)
    
    const theme = useTheme();
    const leftMenu = useLeftMenu()

    const [notifications, setFetchNotifications] = useFetch(Env.BACKEND_SERVER_URL + 'Admin/messages', false, { isManageMessages: false });

    useEffect(() => {
        if (leftMenu)
            setFetchNotifications(true);
    }, [leftMenu, setFetchNotifications])

    const SideDrawerButtonProps = {
        badged: false,
        buttonProps: {
            ref: SideDrawerButtonRef,
            onClick: () => {
                setIsSideDrawerVisible(!isSideDrawerVisible);
            },
            onKeyUp: (event) => { event.key === KEY_CODES.ARROW_DOWN && sideDrawerRef.current.focus(); }
        }
    };

    if (!theme)
        return (
            <AdvancedTheme advancedTheme={baseTheme} >
                <LinearLoading style={{display:'flex', alignItems: 'center', height: '100vh', width: '60%', margin: 'auto' }} />
            </AdvancedTheme>
        );

    return (
        <>
            {   theme &&
                <AdvancedTheme advancedTheme={theme} >
                    <App rtl={false} disableAnimation={false}>
                        <AppHeader style={{ boxShadow: 'none' }}>
                            <AppBanner>
                                <SideDrawerButton variant="default-custom" {...SideDrawerButtonProps} />
                                <AppBannerLogo />
                                <AppBannerName>Knowledge Center{isTestServer && ' - TEST SERVER'}</AppBannerName>
                                <AppBannerContent>
                                    {allowed && <ContactUs />}
                                    {allowed && <About />}
                                    {allowed && <Notifications data={notifications ? notifications : null} />}
                                    <UserAccount />
                                </AppBannerContent>
                            </AppBanner>
                        </AppHeader>
                        <AppBody style={{ overflowY: 'hidden' }}>
                            <LeftSidePanelMenu
                                sideDrawerRef={sideDrawerRef}
                                isSideDrawerVisible={isSideDrawerVisible}
                                setIsSideDrawerVisible={setIsSideDrawerVisible}
                                leftMenu={allowed ? leftMenu : leftMenu.filter(e => e.label === 'Home')}
                            />
                            <AppContentWrapper>
                                {allowed && <AppContent style={{ padding: 0, display: 'flex' }}>
                                    {

                                        leftMenu &&
                                        <AppRoutes routes={leftMenu} />
                                    }
                                </AppContent>
                                }
                                {!allowed && <AppContent style={{ padding: 0, display: 'flex' }}>
                                    <br />
                                    <p>This is a Test Server. Please click on the link to access the production environment of <a href={'https://nokiaknowledgecenter.ext.net.nokia.com/'}>Nokia Knowledge Center</a>.</p>
                                </AppContent>
                                }
                            </AppContentWrapper>
                        </AppBody>
                    </App>
                </AdvancedTheme>
            }
        </>
	);
}

Main.propTypes = {
    elevationIndex: PropTypes.number,
    dark: PropTypes.bool,
    menuItemsHaveIcons: PropTypes.bool,
};

Main.defaultProps = {
    elevationIndex: 0,
    dark: true,
    menuItemsHaveIcons: true,
}

export default Main;
