import { Portal } from '@mui/base';
import { default as SnackbarMUI } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import ReactHtmlParser from 'html-react-parser';

import '../../Styles/SnackBar.css';

const SnackBar = ({ variant, open, setOpen, message }) => {
    
    const onClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Portal>
            <SnackbarMUI
                open={open}
                autoHideDuration={2000}
                onClose={onClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={onClose}
                    severity={variant.toLowerCase()}
                    sx={{ width: '100%' }}
                >
                    <Typography>{ ReactHtmlParser(message) }</Typography>
                </Alert>
            </SnackbarMUI>
        </Portal>
    );
}
SnackBar.defaultProps = {
    variant: 'info',
    open: false,
    setOpen: () => { },
    message: ''
}


export default SnackBar;