import React from 'react';
import Env from "../CustomObjects/Environment";
import { useUser } from "../CustomHooks/Global";
import { LegacyModule } from '../Components/Common';

const PMAT = () => {

	const user = useUser();

	return (
		<LegacyModule
			url={Env.LEGACY_NKC_SERVER_URL + "/ProjectManagement.aspx?login=" + user.username + "&email=" + user.email + "&access=not_direct_access"}
		/>
	);
}

export default PMAT;
