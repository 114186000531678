import React, { useEffect, useRef, useState } from 'react';

import { validateEmail } from '../../../CustomObjects/Utils';
import Env from '../../../CustomObjects/Environment';

import {
    FloatingPanel,
    DataTable,
    SelectAutoFill,
    RowSeparator,
    Message,
    TextInput,
    Slider,
} from "../../../Components/Common";

import { userLimitRenderer } from '../../../CustomHooks/LabResources';

import { useFetch } from '../../../CustomHooks/Fetch';

import Button, { ButtonText } from '@nokia-csf-uxr/ccfk/Button';

import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';

const MODES = {
    ACTION_FINISHED: 'noAction',
    SET_DEFAULT_LIMIT: 'defaultLimit',
    SET_OR_UPDATE_USER_LIMIT: 'newLimit',
    EDIT_USER_LIMT: 'editLimit',
    DELETE_LIMIT: 'deleteLimit'
}

const useManageUserLimitsFields = () => {
    const datatableRef = useRef(null);

    const userLimitResourceData = useRef(null);
    const limitIdToDelete = useRef(null);

    const [certification, setCertification] = useState(null);
    const [defaultUserLimit, setDefaultUserLimit] = useState(null);

    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoVariant, setInfoVariant] = useState('');
    const [showManageLimit, setShowManageLimit] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setUserEmailError] = useState(false);
    const [limit, setLimit] = useState(0);

    const [mode, setMode] = useState(null);

    const resetLimitInfo = () => {
        setUserEmail('');
        setUserEmailError(false);
        setLimit(0);
    }

    const setLimitInfo = (userEmail, limit) => {
        setUserEmail(userEmail);
        setUserEmailError(false);
        setLimit(limit);
    }

    return {
        datatableRef,
        userLimitResourceData,
        limitIdToDelete,

        certification,
        setCertification,
        defaultUserLimit,
        setDefaultUserLimit,

        showInfoMessage,
        setShowInfoMessage,
        infoMessage,
        setInfoMessage,
        infoVariant,
        setInfoVariant,
        showManageLimit,
        setShowManageLimit,

        userEmail,
        setUserEmail,
        userEmailError,
        setUserEmailError,
        limit,
        setLimit,

        mode,
        setMode,

        resetLimitInfo,
        setLimitInfo
    }
}

export const useLabUserLimits = () => {
    const [userLimits, setFetchUserLimits, , setUserLimitsHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/UserLimits', false);
    const [successUserLimit, setUpdateUserLimit, errorUserLimit, , setUserLimitBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/UserLimit', false, {}, 'POST');
    const [successDeleteUserLimit, setDeleteUserLimit, errorDeleteUserLimit, , setDeleteUserLimitBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/DeleteUserLimit', false, {}, 'POST');
    const [successDefaultLimit, setUpdateDefaultLimit, errorDefaultLimit, , setDefaultLimitBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/DefaultLimit', false, {}, 'POST');
    const [userLimitsResources, setFetchUserLimitsResources] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/UserLimitsResources', false);

    const getUserLimits = (courseNumber) => {
        setUserLimitsHeaders({
            courseNumber: courseNumber
        });
        setFetchUserLimits(true);
    }

    const updateUserLimit = (courseNumber, userEmail, userLimit) => {
        setUserLimitBody({
            courseNumber: courseNumber,
            userEmail: userEmail,
            userLimit: userLimit,
        });
        setUpdateUserLimit(true);
    }

    const deleteUserLimit = (id) => {
        setDeleteUserLimitBody({
            id: id
        });
        setDeleteUserLimit(true);
    }

    const updateDefaultLimit = (courseNumber, defaultLimit) => {
        setDefaultLimitBody({
            courseNumber: courseNumber,
            defaultLimit: defaultLimit
        });
        setUpdateDefaultLimit(true);
    }

    const updateUserLimitsResources = () => {
        setFetchUserLimitsResources(true);
    }

    const getUserLimitsResourceData = (courseDisplayName) => {
        return userLimitsResources.filter(resource => resource.courseDisplayName === courseDisplayName)[0];
    }

    return {
        userLimits,
        getUserLimits,

        successUserLimit,
        errorUserLimit,
        updateUserLimit,

        successDeleteUserLimit,
        errorDeleteUserLimit,
        deleteUserLimit,

        successDefaultLimit,
        errorDefaultLimit,
        updateDefaultLimit,

        userLimitsResources,
        updateUserLimitsResources,
        getUserLimitsResourceData,
    }
}

const ManageButtons = ({ onDefaultUserLimit, onNewUserLimit }) => {
    return (
        <div style={{
            display: 'flex',
            gap: '0.5em',
            marginLeft: '1em'
        }}>
            <Button variant='secondary-bigger-font' onClick={onDefaultUserLimit}>
                <ButtonText>Set Default Limit</ButtonText>
            </Button>
            <Button variant='call-to-action-bigger-font' onClick={onNewUserLimit}>
                <ButtonText>Add New Limit</ButtonText>
            </Button>
        </div>
    );
}

const ManageUserLimitMessage = ({ fields, onSubmitLimit }) => {

    const marks = [
        {
            value: 0,
            label: 'Unlimited'
        },
        {
            value: 60,
            label: '60 Atempts'
        }
    ]

    const manageUserLimitTitle = () => {
        if (fields.mode === MODES.SET_DEFAULT_LIMIT)
            return 'Set a new default limit'
        else if (fields.mode === MODES.SET_OR_UPDATE_USER_LIMIT)
            return 'Set a new user limit'
        else if (fields.mode === MODES.EDIT_USER_LIMT)
            return 'Update the user limit'
    }

    const manageUserLimitMessage = () => {
        if (fields.mode === MODES.SET_DEFAULT_LIMIT)
            return `Please set a new default limit for the certification: <b>${fields.certification}</b>.`
        else if (fields.mode === MODES.SET_OR_UPDATE_USER_LIMIT)
            return `Please set a new user limit for the certification: <b>${fields.certification}</b>.`
        else if (fields.mode === MODES.EDIT_USER_LIMT)
            return `Please edit the user limit for the certification: <b>${fields.certification}</b>.`
    }

    return (
        <Message 
            title={manageUserLimitTitle()}
            message={manageUserLimitMessage()}
            actionButtonLabel='Submit'
            cancelButtonLabel='Cancel'
            open={fields.showManageLimit}
            setOpen={fields.setShowManageLimit}
            withComponent={
                <div style={{ display: 'flex', flexFlow: 'column', rowGap: '1em' }}>
                    {
                        (fields.mode === MODES.SET_OR_UPDATE_USER_LIMIT || fields.mode === MODES.EDIT_USER_LIMT) &&
                        <TextInput
                            label='User Email'
                            value={fields.userEmail}
                            setValue={fields.setUserEmail}
                            required
                            placeholder='Set the user email'
                            error={fields.userEmailError}
                            setError={fields.setUserEmailError}
                            disabled={fields.mode === MODES.EDIT_USER_LIMT}
                        />
                    }
                    <Slider
                        label='Limit'
                        value={fields.limit}
                        setValue={fields.setLimit}
                        step={1}
                        min={0}
                        max={60}
                        marks={marks}
                    />
                </div>
            }
            onSubmit={onSubmitLimit}
        />
    );
}

const ManageUserLimits = ({ fields, updateResources, userLimitsResources, updateUserLimitsResources, getUserLimitsResourceData }) => {

    const userLimitsFields = useManageUserLimitsFields();

    const {
        userLimits,
        getUserLimits,

        successUserLimit,
        errorUserLimit,
        updateUserLimit,

        successDeleteUserLimit,
        errorDeleteUserLimit,
        deleteUserLimit,

        successDefaultLimit,
        errorDefaultLimit,
        updateDefaultLimit
    } = useLabUserLimits();

    const onCertificationChange = () => {
        if (userLimitsFields.certification && userLimitsResources) {
            userLimitsFields.userLimitResourceData.current = getUserLimitsResourceData(userLimitsFields.certification);
            userLimitsFields.setDefaultUserLimit(userLimitsFields.userLimitResourceData.current.userLimit);
            getUserLimits(userLimitsFields.userLimitResourceData.current.courseNumber);
        }
        else {
            userLimitsFields.setDefaultUserLimit(0);
            getUserLimits('');
        }
        userLimitsFields.datatableRef.current.setClientDataNullAndShowLoadingOverlay();
    }

    const displayError = (message) => {
        userLimitsFields.setInfoMessage(message);
        userLimitsFields.setInfoVariant('ERROR');
        userLimitsFields.setShowInfoMessage(true);
    }

    const displaySuccess = (message) => {
        userLimitsFields.setInfoMessage(message);
        userLimitsFields.setInfoVariant('SUCCESS');
        userLimitsFields.setShowInfoMessage(true);
    }

    const displayDeleteConfirmMessage = () => {
        userLimitsFields.setInfoMessage("Are you sure you want to delete the user limit?");
        userLimitsFields.setInfoVariant('WARNING');
        userLimitsFields.setShowInfoMessage(true);
    }

    const onDefaultUserLimit = () => {
        if (!userLimitsFields.certification) {
            displayError("Please select a certification before setting a new default user limit.");
            return;
        }

        userLimitsFields.setLimitInfo('', userLimitsFields.defaultUserLimit);
        userLimitsFields.setMode(MODES.SET_DEFAULT_LIMIT);
        userLimitsFields.setShowManageLimit(true);
    }

    const onNewUserLimit = () => {
        if (!userLimitsFields.certification) {
            displayError("Please select a certification before setting a new user limit.");
            return;
        }

        userLimitsFields.resetLimitInfo();
        userLimitsFields.setMode(MODES.SET_OR_UPDATE_USER_LIMIT);
        userLimitsFields.setShowManageLimit(true);
    }

    const onEditUserLimit = ({ data }) => {
        userLimitsFields.setLimitInfo(data.userEmail, data.allowedLimit);
        userLimitsFields.setMode(MODES.EDIT_USER_LIMT);
        userLimitsFields.setShowManageLimit(true);
    }

    const onSubmitLimit = () => {
        if (userLimitsFields.mode === MODES.SET_OR_UPDATE_USER_LIMIT && !validateEmail(userLimitsFields.userEmail)) {
            displayError("Please insert a valid email");
            userLimitsFields.setUserEmailError(true);
            return;
        }

        if (userLimitsFields.mode === MODES.SET_OR_UPDATE_USER_LIMIT || userLimitsFields.mode === MODES.EDIT_USER_LIMT)
            updateUserLimit(userLimitsFields.userLimitResourceData.current.courseNumber, userLimitsFields.userEmail, userLimitsFields.limit);

        if (userLimitsFields.mode === MODES.SET_DEFAULT_LIMIT)
            updateDefaultLimit(userLimitsFields.userLimitResourceData.current.courseNumber, userLimitsFields.limit);
    }

    const onDeleteUserLimit = ({ data }) => {
        userLimitsFields.limitIdToDelete.current = data.id.toString();
        userLimitsFields.setMode(MODES.DELETE_LIMIT);
        displayDeleteConfirmMessage();
    }

    const onSubmitInfoMessage = () => {
        if (userLimitsFields.mode === MODES.DELETE_LIMIT)
            deleteUserLimit(userLimitsFields.limitIdToDelete.current);
        userLimitsFields.setShowInfoMessage(false)
    }

    //SUCCESS OR FAILURE USER LIMIT
    useEffect(() => {
        if (successUserLimit) {
            displaySuccess(successUserLimit.data);
            getUserLimits(userLimitsFields.userLimitResourceData.current.courseNumber);
            userLimitsFields.setShowManageLimit(false);
            userLimitsFields.setMode(MODES.ACTION_FINISHED);
        }
        else if (errorUserLimit) {
            displayError(errorUserLimit.data);
        }
        // eslint-disable-next-line
    }, [successUserLimit, errorUserLimit]);

    //SUCCESS OR FAILURE DELETE USER LIMIT
    useEffect(() => {
        if (successDeleteUserLimit) {
            displaySuccess(successDeleteUserLimit.data);
            getUserLimits(userLimitsFields.userLimitResourceData.current.courseNumber);
            userLimitsFields.setShowManageLimit(false);
            userLimitsFields.setMode(MODES.ACTION_FINISHED);
        }
        else if (errorDeleteUserLimit) {
            displayError(errorDeleteUserLimit.data);
        }
    // eslint-disable-next-line
    }, [successDeleteUserLimit, errorDeleteUserLimit]);

    //SUCCESS OR FAILURE DEFAULT LIMIT
    useEffect(() => {
        if (successDefaultLimit) {
            displaySuccess(successDefaultLimit.data);
            updateResources();
            updateUserLimitsResources();
            userLimitsFields.setShowManageLimit(false);
            userLimitsFields.setMode(MODES.ACTION_FINISHED);
        }
        else if (errorDefaultLimit) {
            displayError(errorDefaultLimit.data);
        }
        // eslint-disable-next-line
    }, [successDefaultLimit, errorDefaultLimit]);

    // WHEN USER LIMITS RESOURCES ARE UPDATED
    useEffect(() => {
        if (!userLimitsFields.certification || !userLimitsResources)
            return;
        const resourceData = getUserLimitsResourceData(userLimitsFields.certification);
        if (!resourceData) {
            userLimitsFields.setCertification('');
            return;
        }
        userLimitsFields.setDefaultUserLimit(resourceData.userLimit);
        // eslint-disable-next-line
    }, [userLimitsResources]);

    return (
        <FloatingPanel
            title='Manage User Limits'
            visible={fields.showManageUserLimits}
            setVisible={fields.setShowManageUserLimits}
            initialSize={{width: 600, height: 800}}
        >
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column',
                    rowGap: '1em'
                }}
            >
                <div style={{ width: '30em' }}>
                    <RowSeparator />
                    <RowSeparator />
                    <SelectAutoFill
                        label='Select Certification *'
                        values={userLimitsFields.certification}
                        setValues={userLimitsFields.setCertification}
                        data={{
                            values: userLimitsResources,
                            mapping: {
                                value: 'courseDisplayName'
                            }
                        }}
                        placeholder='Choose a certification to manage user limits'
                        onChange={onCertificationChange}
                    />
                    {
                        userLimitsFields.certification &&
                        <div style={{width : '13em'}}>
                            <RowSeparator />
                            <RowSeparator />
                            <TextInput
                                label='Default limit for associated resources'
                                    value={userLimitsFields.defaultUserLimit === 0 ? 'Unlimited' : `${userLimitsFields.defaultUserLimit} Attempt(s)`}
                                setValue={userLimitsFields.setDefaultUserLimit}
                                disabled
                            />
                        </div>
                    }
                </div>
                <div style={{flex: '0.75'}}>
                    <DataTable
                        ref={userLimitsFields.datatableRef}
                        data={userLimits}
                        columnDefinitions={{
                            allowedLimit: {
                                type: 'number',
                                flex: 0.5,
                                cellRenderer: userLimitRenderer
                            }
                        }}
                        withAdditionalComponents={{
                            searchbar: <ManageButtons
                                onDefaultUserLimit={onDefaultUserLimit}
                                onNewUserLimit={onNewUserLimit}
                            />
                        }}
                        withRowOptions={[
                            {
                                icon: <EditIcon />,
                                tooltip: 'Edit User Limit',
                                callback: onEditUserLimit
                            },
                            {
                                icon: <DeleteIcon />,
                                tooltip: 'Delete User Limit',
                                callback: onDeleteUserLimit
                            }
                        ]}
                        columnsIgnore={['id', 'userId']}
                        withSearch
                        withRowClick={onEditUserLimit}
                        noRowsMessage={userLimitsFields.certification ? 'No user limits defined for this certification...' : 'No certification is selected...'}
                    />
                </div>
            </div>
            <ManageUserLimitMessage
                fields={userLimitsFields}
                onSubmitLimit={onSubmitLimit}
            />
            <Message
                title='User Limits'
                message={userLimitsFields.infoMessage}
                actionButtonLabel={userLimitsFields.mode === MODES.DELETE_LIMIT ? 'Yes' : 'Ok'}
                cancelButtonLabel={userLimitsFields.mode === MODES.DELETE_LIMIT ? 'No' : null}
                open={userLimitsFields.showInfoMessage}
                setOpen={userLimitsFields.setShowInfoMessage}
                variant={userLimitsFields.infoVariant}
                onSubmit={onSubmitInfoMessage}
            />
        </FloatingPanel>
    );
}

export default ManageUserLimits;