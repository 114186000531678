import { useMemo, useState, useRef } from "react";

import {
    ExpansionPanel,
    Message,
    SelectAutoFill,
    TextInput,
    ItemsList,
    RowSeparator,
} from "../Common";

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import LayerAddIcon from '@nokia-csf-uxr/ccfk-assets/latest/LayerAddIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon';
import SecurityIcon from '@nokia-csf-uxr/ccfk-assets/latest/SecurityIcon';

import { integerPositive } from "../../CustomObjects/Utils";

const MODES = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
}

const HeadCountTypeContent = ({
    headCountTypesData,
    setCurrentHeadCountType,
    currentHeadCountType,
    currentHeadCountTypeError,
    setCurrentHeadCountTypeError,
    currentHeadCountValue,
    setCurrentHeadCountValue,
    currentHeadCountValueError,
    setCurrentHeadCountValueError,
    currentNotes,
    setCurrentNotes,
}) => {

    return (
        <>
            <RowSeparator />
            <RowSeparator />
            <SelectAutoFill
                label='Type'
                placeholder='Select type'
                values={currentHeadCountType}
                setValues={setCurrentHeadCountType}
                data={{
                    values: headCountTypesData,
                    mapping: { value: 'longName' }
                }}
                error={currentHeadCountTypeError}
                setError={setCurrentHeadCountTypeError}
            />
            <RowSeparator />
            <TextInput
                label='Head Count'
                placeholder='Insert the total head count value'
                value={currentHeadCountValue}
                setValue={setCurrentHeadCountValue}
                error={currentHeadCountValueError}
                setError={setCurrentHeadCountValueError}
            />
            <RowSeparator />
            <TextInput
                label='Notes'
                value={currentNotes}
                setValue={setCurrentNotes}
            />
        </>
    );
}

const HeadCountSection = ({ fields, headCountTypesData }) => {

    const errorMessage = useRef('');

    const [showHeadCountMessage, setShowHeadCountMessage] = useState(false);
    
    const [currentHeadCountType, setCurrentHeadCountType] = useState('');
    const [currentHeadCountTypeError, setCurrentHeadCountTypeError] = useState(false);
    const [currentHeadCountValue, setCurrentHeadCountValue] = useState(0);
    const [currentHeadCountValueError, setCurrentHeadCountValueError] = useState(false);
    const [currentNotes, setCurrentNotes] = useState('');

    const [currentHeadCountSelected, setCurrentHeadCountSelected] = useState({});

    const [mode, setMode] = useState(null);

    const headCountItems = useMemo(() => {
        return fields.headCountTypes.map((headCountType, id) => ({
            id: id,
            icon: <SecurityIcon color='var(--ff-color-purple-500)' />,
            text: `<b>${headCountType.type}</b>`,
            subText: `<b>Total head count</b>: ${headCountType.value}. ${headCountType.notes ? `<b>Notes</b>: ${headCountType.notes}` : ''}`,
            ...headCountType
        }));
    }, [fields.headCountTypes]);

    const resetHeadCountValues = () => {
        setCurrentHeadCountType('');
        setCurrentHeadCountValue(0);
        setCurrentNotes('');
    }

    const resetErrors = () => {
        setCurrentHeadCountTypeError(false);
        setCurrentHeadCountValueError(false);
    }

    const onNewHeadCountType = () => {
        setMode(MODES.CREATE);
        resetHeadCountValues();
        resetErrors();
        setCurrentHeadCountSelected({});
        setShowHeadCountMessage(true);
    }

    const onEditHeadCountType = (headCountType) => {
        setMode(MODES.EDIT);
        resetErrors();
        setCurrentHeadCountType(headCountType.type);
        setCurrentHeadCountValue(headCountType.value);
        setCurrentNotes(headCountType.notes);
        setCurrentHeadCountSelected(headCountType);
        setShowHeadCountMessage(true);
    };

    const onDeleteHeadCountType = (headCountType) => {
        if (thereIsKpisAssociated(headCountType)) {
            fields.messageRef.current.errorSnack(`The role: '<b>${headCountType.type}</b>' cannot be deleted because it is associated with a KPI.`);
            return;
        }
        
        setMode(MODES.DELETE);
        const indexToRemove = headCountItems.findIndex(t => t.id === headCountType.id);
        var newHeadCountTypes = [...fields.headCountTypes];
        newHeadCountTypes.splice(indexToRemove, 1);
        fields.setHeadCountTypes(newHeadCountTypes);
    };

    const typeValuesAreValid = () => {

        if (!currentHeadCountType) {
            errorMessage.current = 'Please choose a \'<b>Type</b>\'.';
            setCurrentHeadCountTypeError(true);
            return false;
        }

        if (!currentHeadCountValue || !integerPositive(currentHeadCountValue)) {
            errorMessage.current = 'Please enter a valid \'<b>head count value</b>\'.';
            setCurrentHeadCountValueError(true);
            return false;
        }

        if (mode === MODES.CREATE && headCountItems.filter((t) => t.type === currentHeadCountType).length > 0) {
            errorMessage.current = `The type: '<b>${currentHeadCountType}</b>' was already set. Please choose another.`;
            setCurrentHeadCountTypeError(true);
            return false;
        }

        return true;
    }

    const thereIsKpisAssociated = (headCountTypeToDelete) => {
        return fields.kpis.some(kpi => kpi.headCountType === headCountTypeToDelete.type);
    }

    const onSubmitHeadCountType = () => {

        if (!typeValuesAreValid()) {
            fields.messageRef.current.errorSnack(errorMessage.current);
            return;
        }

        if (mode === MODES.CREATE) {
            fields.setHeadCountTypes([
                ...fields.headCountTypes,
                {
                    type: currentHeadCountType,
                    value: currentHeadCountValue,
                    notes: currentNotes
                }
            ]);
        }
        else if (mode === MODES.EDIT) {
            const indexToEdit = headCountItems.findIndex(headCountType => headCountType.id === currentHeadCountSelected.id);
            var newHeadCountTypes = [...fields.headCountTypes];
            newHeadCountTypes[indexToEdit] = {
                type: currentHeadCountType,
                value: currentHeadCountValue,
                notes: currentNotes,
            };
            fields.setHeadCountTypes(newHeadCountTypes);
        }
        
        setShowHeadCountMessage(false);
    }
    
    return (
        <>
            <ExpansionPanel header='Head Count Types'>
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                }}>
                    <div>
                        <Tooltip tooltip='Add new role' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
                            <IconButton onClick={onNewHeadCountType}>
                                <LayerAddIcon color='var(--ff-color-nokia-blue-500)' />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ItemsList
                        items={headCountItems}
                        withActions={[
                            {
                                callback: onEditHeadCountType,
                                icon: <EditIcon />,
                                tooltip: 'Edit Role'
                            },
                            {
                                callback: onDeleteHeadCountType,
                                icon: <DeleteIcon />,
                                tooltip: 'Delete Role'
                            }
                        ]}
                    />
                </div>
            </ExpansionPanel>
            <Message
                title={`${fields.kpiTeam} HC Types`}
                message={'Add new entry'}
                actionButtonLabel='Submit'
                cancelButtonLabel='Cancel'
                open={showHeadCountMessage}
                setOpen={setShowHeadCountMessage}
                withComponent={
                    <HeadCountTypeContent
                        headCountTypesData={headCountTypesData}
                        currentHeadCountType={currentHeadCountType}
                        setCurrentHeadCountType={setCurrentHeadCountType}
                        currentHeadCountTypeError={currentHeadCountTypeError}
                        setCurrentHeadCountTypeError={setCurrentHeadCountTypeError}
                        currentHeadCountValue={currentHeadCountValue}
                        setCurrentHeadCountValue={setCurrentHeadCountValue}
                        currentHeadCountValueError={currentHeadCountValueError}
                        setCurrentHeadCountValueError={setCurrentHeadCountValueError}
                        currentNotes={currentNotes}
                        setCurrentNotes={setCurrentNotes}
                    />
                }
                onSubmit={onSubmitHeadCountType}
            />
        </>
    )
}

export default HeadCountSection;

