import React from "react";

import Slider from "../../Common/Slider";

import { rangeArray } from "../../../CustomObjects/Utils";

const durationLabels = [
    {
        value: 0,
        label: 'Unlimited'
    },
    ...rangeArray(1, 23).map(h => ({
        value: h
    })),
    ...rangeArray(24, 120, 24).map(d => ({
        value: d,
        label: `${d/24} Day(s)`
    }))
];

const LabDuration = ({ fields }) => {

    return (
        <Slider
            label='AWS Expiration *'
            value={fields.labDuration}
            setValue={fields.setLabDuration}
            min={0}
            max={120}
            marks={durationLabels}
        />
    );
}

export default LabDuration;