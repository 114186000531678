import React, { useState } from 'react';

import { default as FloatingPanelDefault } from '@nokia-csf-uxr/ccfk/FloatingPanel';
import { FloatingPanelHeader } from '@nokia-csf-uxr/ccfk/FloatingPanel';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';
import FullScreenExitIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FullScreenExitIcon';
import FullScreenIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FullScreenIcon';

import '../../Styles/FloatingPanel.css';

const LEFT_MENU_WIDTH = 250;

const FloatingPanel = (props) => {

    const {
        title,
        initialSize = { width: 800, height: 800 },
        startPosition = {
            x: (window.innerWidth / 2) - (initialSize.width / 2) - LEFT_MENU_WIDTH,
            y: 50
        },
        visible,
        setVisible,
        children
    } = props;

    const [fullscreen, setFullscreen] = useState(false);
    const [size, setSize] = useState(null);
    const [position, setPosition] = useState(null);

    const getPosition = (position) => {
        if (fullscreen) return { x: 0, y: 0 };
        return position ? { x: position.x, y: position.y } : startPosition;
    };

    const getSize = (size) => {
        if (fullscreen)
            return { width: '100%', height: '100%' };
        return size ? { width: size.width, height: size.height } : initialSize;
    };

    const handleFullscreen = () => {
        setFullscreen(!fullscreen);
    }

    const handleClose = () => {
        setVisible(false);
    }

    const rndProps = {
        size: getSize(size),
        position: getPosition(position),
        onDragStop: (args) => {
            const { x, y } = args[1];
            setPosition({ x, y });
        },
        onResizeStop: (e, direction, ref, delta, position) => {
            setSize({ width: ref.style.width, height: ref.style.height });
        },
    }

    return (
        visible &&
        <FloatingPanelDefault
            className='floating-panel-container'
            variant={fullscreen ? 'fullscreen' : 'default'}
            rndProps={rndProps}
        >
            <FloatingPanelHeader renderButtons={(
                <>
                    <Tooltip tooltip={fullscreen ? 'Restore' : 'Maximize' } placement='bottom'>
                        <IconButton data-prevent-drag onClick={handleFullscreen}>
                            { fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon /> }
                        </IconButton>
                    </Tooltip>
                    <Tooltip tooltip='Close' placement='bottom'>
                        <IconButton data-prevent-drag onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}>
                <Typography className='floating-panel-title'>{title}</Typography>
            </FloatingPanelHeader>
            <div className='floating-panel-content'>
                {children}
            </div>
        </FloatingPanelDefault>
    )
}

export default FloatingPanel;