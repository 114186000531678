
import React from 'react';

import _uniqueId from 'lodash/uniqueId';

import DefaultCheckbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import Label from '@nokia-csf-uxr/ccfk/Label';

import '../../Styles/CheckBox.css';

const CheckBox = (props) => {

    const { label, value, setValue, disabled = false } = props;

    const onChange = (e) => { setValue(e.target.checked); }

    const labelId = `checkbox-label-${_uniqueId()}`;

    return (
        <div className='checkbox-container'>
            <Label id={labelId}>
                <DefaultCheckbox disabled={disabled} className='cb' checked={value} onChange={onChange} inputProps={{ ariaLabel: labelId }} />
                <CheckboxLabelContent disabled={disabled} >{ label }</CheckboxLabelContent>
            </Label>
        </div>
    );
}

export default CheckBox;
