import React from 'react';

import {
    ModuleContainer,
    ModuleHeader,
    ModuleContent,
    DataTable,
    MessageBundle,
    TipCoachmark,
} from "../../Components/Common";

import ModuleBaseObj from '../../CustomObjects/Module';

const ModuleBase = ({ moduleBaseObj, messageRef }) => {
    
    return (
        <ModuleContainer>
            <ModuleHeader
                backgroundPath={moduleBaseObj.moduleHeader.bannerPath}
                backgroundPosition={moduleBaseObj.moduleHeader.bannerPosition}
                titleColor={moduleBaseObj.moduleHeader.titleTextColor}
            >
                {moduleBaseObj.moduleHeader.title}
            </ModuleHeader>
            <ModuleContent>
                <DataTable
                    ref={moduleBaseObj.dataTable.ref}
                    data={moduleBaseObj.dataTable.data}
                    columnDefinitions={moduleBaseObj.dataTable.columnDefinitions}
                    columnsIgnore={moduleBaseObj.dataTable.columnsIgnore}
                    withTableOptions={moduleBaseObj.dataTable.tableOptions}
                    withRowOptions={moduleBaseObj.dataTable.rowOptions}
                    withAdditionalComponents={moduleBaseObj.dataTable.additionalComponents}
                    withRowClick={moduleBaseObj.dataTable.rowClick}
                    noRowsMessage={moduleBaseObj.dataTable.noRowsMessage}
                    withClientPagination={moduleBaseObj.dataTable.clientPagination}
                    withServerInfiniteRow={moduleBaseObj.dataTable.serverInfiniteRow}
                    withServerPagination={moduleBaseObj.dataTable.serverPagination}
                    withSearch
                    withFilters
                />
                <MessageBundle
                    ref={messageRef}
                    title={moduleBaseObj.messages.title}
                    onSubmitAreYouSure={moduleBaseObj.messages.submitAreYouSureMessage}
                />
                {
                    moduleBaseObj.coachmarks && moduleBaseObj.coachmarks.length > 0 &&
                    moduleBaseObj.coachmarks.map((coachmarksObj, i) => {
                        return (
                            <TipCoachmark
                                key={i}
                                ref={coachmarksObj.ref}
                                identifier={coachmarksObj.identifier}
                                steps={coachmarksObj.steps}
                                delay={coachmarksObj.delay}
                                condition={coachmarksObj.condition}
                            />
                        );
                    })
                }
                {
                    moduleBaseObj.overlayPanel.component
                }
            </ModuleContent>
            {moduleBaseObj.floatingPanel.component}
        </ModuleContainer>
    );
}
ModuleBase.defaultProps = {
    moduleBaseObj : new ModuleBaseObj(),
}

export default ModuleBase;