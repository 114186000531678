import React, { useRef } from 'react';

import { useFetch, useFetchWithInfinite } from '../../CustomHooks/Fetch';
import { useUser } from '../../CustomHooks/Global';
import { MODES, useKTSessionsFields } from '../../CustomHooks/KTSessions';
import { useURLParamsAction } from '../../CustomHooks/URLParams'
import Env from "../../CustomObjects/Environment";
import {
	ModuleContainer,
	ModuleHeader,
	ModuleContent,
	DataTable,
	Message,
	TextInput,
} from "../../Components/Common";
import {
	KTOverlayPanel,
	FeedbackPanel,
	CreateSessionButton
} from '../../Components/KTSessions';
import {
	statusBarRenderer,
	smileFaceRenderer,
	dateCellRendererLocalTime,
	commaSeparatedValue,
	materialsLink,
	hasFiles
} from '../../Renderers/DataTable';

//import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
//import ToggleSwitch, { ToggleSwitchLabelContent } from '@nokia-csf-uxr/ccfk/ToggleSwitch';
//import CalendarIcon from '@nokia-csf-uxr/ccfk-assets/CalendarIcon';
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//import Button from '@mui/material/Button';

import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import DeleteForeverIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteForeverIcon';
import EmailIcon from '@nokia-csf-uxr/ccfk-assets/latest/EmailIcon';

import ScheduleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ScheduleIcon';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import VerifiedIcon from '@mui/icons-material/Verified';

import '../../Styles/KTSessions.css';

const KTSessions = () => {
	const user = useUser();

	const dataTableRef = useRef(null);

	const [ktSessions, onGridReady, onKeywordChange, setFetchKtSessions, , , fetchDataForCSV] = useFetchWithInfinite(Env.BACKEND_SERVER_URL + 'KTSessions', {}, 'GET', null, false, null, dataTableRef);
	const [singleKtSession, setFetchSingleKtSession, , setSingleKtSessionHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions');

	const updateKTSessionsTable = () => {
		setFetchKtSessions(true);
	};

	const ktSessionFields = useKTSessionsFields(updateKTSessionsTable);

	//const [checked, setChecked] = useState(false);
	//const handleChange = (data) => {
	//	setChecked(data.value);
	//};

	useURLParamsAction(
		['id'], singleKtSession,
		(paramsObj) => {
			ktSessionFields.setOpenProcessingDiag(true);
			setSingleKtSessionHeaders({ recNum: paramsObj['id'] });
			setFetchSingleKtSession(true);
		},
		() => {
			ktSessionFields.setOpenProcessingDiag(false);
			if (!singleKtSession || singleKtSession.length === 0)
				return;

			ktSessionFields.setCurrentKTData(singleKtSession[0]);
			ktSessionFields.setPanelMode(MODES.EDIT);
			ktSessionFields.setFeedbackPanelVisible(false);
			ktSessionFields.setOpenProcessingDiag(true);
		}
	);

	useURLParamsAction(
		['id', 'showFeedback'], singleKtSession,
		(paramsObj) => {
			ktSessionFields.setOpenProcessingDiag(true);
			setSingleKtSessionHeaders({ recNum: paramsObj['id'] });
			setFetchSingleKtSession(true);
		},
		() => {
			ktSessionFields.setOpenProcessingDiag(false);
			if (!singleKtSession || singleKtSession.length === 0)
				return;

			ktSessionFields.setCurrentKTData(singleKtSession[0]);
			ktSessionFields.setFeedbackPanelVisible(true);	
		}
	);

	useURLParamsAction(['create'], null,
		() => {
			ktSessionFields.setPanelMode(MODES.CREATE);
			ktSessionFields.setCurrentKTData({});
			ktSessionFields.setFeedbackPanelVisible(false);
		}
	);

	//const ToggleComponent = () => {
	//	return (
	//		<>
	//			<div className="kt-session-toggle">
	//				<ToggleSwitchLabelContent>{checked ? "My KT Sessions" : "All KT Sessions"}</ToggleSwitchLabelContent>
	//				<Tooltip placement="bottom" tooltip="Session type view" >
	//					<ToggleSwitch className="toggle-switch" checked={checked} onChange={handleChange} >
	//						<CalendarIcon />
	//					</ToggleSwitch>
	//				</Tooltip>
	//			</div>
	//			<Tooltip placement="bottom" tooltip="Calendar view" >
	//				<IconButton onClick={() => { }}>
	//					<CalendarMonthIcon />
	//				</IconButton>
	//			</Tooltip>
	//		</>
	//	);
	//}

	return (
		<ModuleContainer>
			<ModuleHeader
				backgroundPath={require('../../Images/BannerBackgrounds/banner-background-3.jpg')}
				backgroundPosition='50% 3%'
			>
				KT Sessions
			</ModuleHeader>
			<ModuleContent>
				<DataTable
					title=''
					ref={dataTableRef}
					data={ktSessions}
					columnDefinitions={{
						sessionStatus: {
							type: 'status',
							headerName: '',
							width: 44,
							maxWidth: 44,
							resizable: false,
							cellRenderer: statusBarRenderer,
							cellRendererParams: {
								valueColorMap: {
									'COMPLETE': 'var(--ff-color-nokia-blue-200)',
									'TODAY': 'var(--legacy-color-vivid-violet-300)',
									'SCHEDULED': 'var(--color-brand-primary)'
								},
								valueIconMap: {
									'COMPLETE': VerifiedIcon,
									'TODAY': UpcomingIcon,
									'SCHEDULED': ScheduleIcon
								}
							}
						},
						sessionName: {
							headerName: 'Session',
							flex: 1.8,
						},
						startDate: {
							type: 'date',
							cellRenderer: dateCellRendererLocalTime,
							flex: 0.9,
						},
						learningPoints: {
							width: 120,
							flex: 0.7
						},
						duration: {
							type: 'number',
							headerName: 'Duration',
							flex: 0.5,
							cellRenderer: ({ value }) => value ? value + ' min' : ''
						},
						team: {
							flex: 1.1
						},
						rating: {
							type: 'number',
							cellRenderer: smileFaceRenderer,
							cellRendererParams: { minValue: 0, maxValue: 5 },
							width: 100,
							maxWidth: 100,
							resizable: false,
						},
						solutionShortNames: {
							headerName: 'Solutions',
							flex: 1,
							cellRenderer: commaSeparatedValue
						},
						materialsLink: {
							headerName: 'Materials',
							cellRenderer: materialsLink,
							flex: 0.7
						},
						filesUploaded: {
							minWidth: 80,
							maxWidth: 80,
							type: 'number',
							headerName: 'Files',
							cellRenderer: hasFiles
                        },
						targetAudience: {
							headerName: 'Role',
							flex: 1,
							cellRenderer: commaSeparatedValue
						}
					}}
					columnsIgnore={ktSessionFields.ignoreColumnList}
					withTableOptions={{
						onRefresh: updateKTSessionsTable,
						onFilterRemove: true,
						onExcelFile: {
							fileName: 'KTSessions.csv'
						},
						onAdjustColumns: false
					}}
					withRowClick={ktSessionFields.onEditClicked}
					withRowOptions={[
						{
							icon: <EmailIcon />,
							tooltip: 'Forward',
							callback: ktSessionFields.onForwardClicked,
							renderCondition: ({ data }) => {
								return data.sessionStatus !== 'COMPLETE'
							}
						},
						{
							icon: <EditIcon />,
							tooltip: 'Details',
							callback: ktSessionFields.onEditClicked
						},
						{
							icon: <DeleteForeverIcon />,
							tooltip: 'Delete/Cancel',
							callback: ktSessionFields.onDeleteClicked,
							renderCondition: ({ data }) => {
								return user.role.toLowerCase().includes('admin') ||
									user.role.toLowerCase().includes('ktleader') ||
									(data.creatorEmail === user.email && data.sessionStatus !== 'COMPLETE')
							}
						}
					]}
					withAdditionalComponents={{
						//toolbar: <ToggleComponent />,
						searchbar: <CreateSessionButton onClick={ktSessionFields.onAddSessionClick} />
					}}
					withSearch
					withFilters
					withServerInfiniteRow={{
						onGridReady: onGridReady,
						onKeywordChange: onKeywordChange,
						onExcelFile: fetchDataForCSV
					}}
				/>
				<KTOverlayPanel
					fields={ktSessionFields}
				/>
				<Message
					title="KT Sessions"
					variant={ktSessionFields.messageVariant}
					message={ktSessionFields.message}
					open={ktSessionFields.showMessage}
					setOpen={ktSessionFields.setShowMessage}
					actionButtonLabel="OK"
					onSubmit={() => ktSessionFields.setShowMessage(false)}
				/>
				<Message
					title="Forward to..."
					message="Enter the email address of the person you want to forward this session to."
					withComponent={<TextInput
						label='Email Address'
						value={ktSessionFields.forwardEmail}
						setValue={ktSessionFields.setForwardEmail}
						error={ktSessionFields.forwardEmailError}
						setError={ktSessionFields.setForwardEmailError}
						disabled={false}
						required={true}
					/>}
					actionButtonLabel="Send"
					cancelButtonLabel="Cancel"
					open={ktSessionFields.openForwardDiag}
					setOpen={ktSessionFields.setOpenForwardDiag}
					onSubmit={ktSessionFields.onForwardSubmit}
				/>
				<Message
					title="Delete KT Session"
					message="Are you sure you want to delete/cancel this session?"
					actionButtonLabel="Yes"
					cancelButtonLabel="No"
					variant='error'
					open={ktSessionFields.openDeleteDiag}
					setOpen={ktSessionFields.setOpenDeleteDiag}
					onSubmit={ktSessionFields.onDeleteSubmit}
				/>
				<Message
					title="KT Sessions"
					message='Please wait while request is processed...'
					open={ktSessionFields.openProcessingDiag}
					withLoading
				/>
			</ModuleContent>
			<FeedbackPanel
				fields={ktSessionFields}
			/>
		</ModuleContainer>
	);
}

export default KTSessions;