
import { useNavigate } from 'react-router-dom';

import { QuickHelpButton } from '@nokia-csf-uxr/ccfk/QuickHelp';

import QuickHelpContainer from '@nokia-csf-uxr/ccfk/QuickHelp';

const About = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/about");
	}

	return (
		<QuickHelpContainer >
			<QuickHelpButton tooltipProps={{ tooltip: "About" }} onClick={handleClick} />
		</QuickHelpContainer>
	);
}

export default About;