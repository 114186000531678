
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

import Typography from '@nokia-csf-uxr/ccfk/Typography';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import Hyperlink from '@nokia-csf-uxr/ccfk/Hyperlink';

import MagicIcon from '@nokia-csf-uxr/ccfk-assets/latest/MagicIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import ClipboardIcon from '@nokia-csf-uxr/ccfk-assets/latest/ClipboardIcon';
import TickIcon from '@nokia-csf-uxr/ccfk-assets/latest/TickIcon';

import LinkAltIcon from '@nokia-csf-uxr/ccfk-assets/latest/LinkAltIcon';

import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbDownOffAltRoundedIcon from '@mui/icons-material/ThumbDownOffAltRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';

import MarkdownStyled from '../../Components/Common/MarkdownStyled';
import TypingAnimationMarkdown from '../../Components/Common/TypingAnimationMarkdown';

import { useAvatar, useUser } from "../../CustomHooks/Global";

import thinkingGif from '../../Images/GIF/thinking.gif';

import '../../Styles/PowerSearchX.css';

const optionsColor = 'var(--ff-color-tone-400)';
const optionsSize = 17;

export const PXAvatar = () => {
	return (
		<Avatar sx={{ width: 32, height: 32, bgcolor: 'var(--ff-color-purple-100)' }}>
			<AutoAwesomeOutlinedIcon style={{ color: 'var(--ff-color-purple-600)' }} />
		</Avatar>
	);
}

export const UserAvatar = () => {

	const user = useUser();
	const avatar = useAvatar();

	return (
		<Avatar sx={{ width: 32, height: 32, fontSize: 15, color: 'black', fontWeight: 500, bgcolor: 'var(--ff-color-cyan-400)' }}>
			{avatar ? <img style={{ width: 40, height: 40 }} src={avatar} alt="PersonAvatar"></img> : user.firstName[0] + user.lastName[0]}
		</Avatar>
	);
}

export const KnowledgeFragmentCard = ({ knowledgeObj }) => {

	return (
		<div className='psllm-message-knowledge-fragment'>
			<Paper id='card' elevation={12}>
				<Typography className='psllm-text' style={{ fontWeight: 600 }}>{knowledgeObj.category}</Typography>
				<Typography className='psllm-text'><MarkdownStyled>{knowledgeObj.text}</MarkdownStyled></Typography>
				{
					knowledgeObj.webUrl &&
					<a href={knowledgeObj.webUrl} target="_blank" rel="noopener noreferrer">Read more</a>
				}
			</Paper>
			<IconButton id='frag-icon-empty' size='small' disabledRipple>
				<TipsAndUpdatesOutlinedIcon style={{ fontSize: optionsSize, color: 'var(--ff-color-purple-500)' }} />
			</IconButton>
			<IconButton id='frag-icon-filled' size='small' disabledRipple>
				<TipsAndUpdatesIcon style={{ fontSize: optionsSize, color: 'var(--ff-color-purple-500)' }} />
			</IconButton>
		</div>
	);
}

export const LLMResponse = ({ fields, messageObj, scrollToBottom, updateMessage, isAtBottom }) => {

	const onResponseStreamEnd = () => {
		if (isAtBottom.current)
			scrollToBottom();
		updateMessage(messageObj.id, { ...messageObj, showKnowledgeFragments: true });
	}

	const onMessageClipboardClick = () => {
		navigator.clipboard.writeText(messageObj.text);
		updateMessage(messageObj.id, { ...messageObj, clipboardTooltip: 'Copied!' });
		setTimeout(() => {
			updateMessage(messageObj.id, { ...messageObj, clipboardTooltip: 'Copy' });
		}, 2000);
	}

	const onMessageFeedbackClick = (upDown) => {
		if (messageObj.positiveFeedback !== null)
			return;

		fields.setFeedback({
			show: true,
			placeholder: upDown ? 'What was satisfying about this response?' : 'What was unsatisfying about this response?',
			text: '',
			upDown: upDown,
			messageObj: messageObj,
		});
	}
	
	if (messageObj.text) {
		return (
			<>
				<div className='psllm-message-items'>
					<PXAvatar />
					<Typography className='psllm-text'><b>Power Search X</b></Typography>
					<Typography className='psllm-text psllm-message-content'>
						<TypingAnimationMarkdown text={messageObj.text} delay={5} step={5} onAnimationComplete={onResponseStreamEnd} />
						{
							messageObj.feedbackText &&
							<div className='psllm-message-feedback-section'>
								{
									messageObj.positiveFeedback ?
										<ThumbUpAltRoundedIcon style={{ fontSize: optionsSize, color: optionsColor, alignSelf: 'center' }} /> :
										<ThumbDownRoundedIcon style={{ fontSize: optionsSize, color: optionsColor, alignSelf: 'center' }} />
								}
								<Typography className='psllm-text'><MarkdownStyled>{messageObj.feedbackText}</MarkdownStyled></Typography>
							</div>
						}
						{
							messageObj.sources && messageObj.sources.length > 0 && messageObj.showKnowledgeFragments &&
							<div className='psllm-message-sources-container'>
								{
									messageObj.sources.map((s, i) => (
										<Tooltip
											closeOnReferenceHidden={false}
											placement="bottom"
											trigger="hover"
											tooltip={s.category + ": " + s.title}
										>
											<Hyperlink id={"source-" + i} target="_blank" href={s.webUrl} >
												<div className='psllm-message-sources-title'>{s.title}</div>
												<LinkAltIcon size={20} style={{marginLeft: 4}} color={'var(--ff-color-purple-500)'} />
											</Hyperlink>
										</Tooltip>
									))
								}
							</div>
						}
						{
							messageObj.knowledge && messageObj.knowledge.length > 0 && messageObj.showKnowledgeFragments &&
							<div className='psllm-message-knowledge-fragments-container'>
								<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Knowledge Fragments'>
									<IconButton id='frag-icon-menu'>
										<MagicIcon size={20} color={'var(--ff-color-purple-500)'} />
									</IconButton>
								</Tooltip>
								{
									messageObj.knowledge.map((k, i) => (
										<KnowledgeFragmentCard key={i} knowledgeObj={k} />
									))
								}
							</div>
						}
					</Typography>
					{
						messageObj.interactionId &&
						<div className='psllm-message-options'>
							<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip={messageObj.clipboardTooltip}>
								<IconButton size='small' disabledRipple onClick={onMessageClipboardClick}>
									{
										messageObj.clipboardTooltip === 'Copied!' ? <TickIcon size={optionsSize} color={optionsColor} /> : <ClipboardIcon size={optionsSize} color={optionsColor} />
									}
								</IconButton>
							</Tooltip>
							{
								(messageObj.positiveFeedback === null || messageObj.positiveFeedback === true) &&
								<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Positive Feedback'>
									<IconButton size='small' disabledRipple onClick={() => onMessageFeedbackClick(true)}>
										{
											messageObj.positiveFeedback ?
												(<ThumbUpAltRoundedIcon style={{ fontSize: optionsSize, color: optionsColor }} />) :
												(<ThumbUpOffAltOutlinedIcon style={{ fontSize: optionsSize, color: optionsColor }} />)
										}
									</IconButton>
								</Tooltip>
							}
							{
								(messageObj.positiveFeedback === null || messageObj.positiveFeedback === false) &&
								<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Negative Feedback'>
									<IconButton size='small' disabledRipple onClick={() => onMessageFeedbackClick(false)}>
										{
											messageObj.positiveFeedback === false ?
												(<ThumbDownRoundedIcon style={{ fontSize: optionsSize, color: optionsColor }} />) :
												(<ThumbDownOffAltRoundedIcon style={{ fontSize: optionsSize, color: optionsColor }} />)
										}
									</IconButton>
								</Tooltip>
							}
						</div>
					}
				</div>
			</>
		);
	}

	return <></>;
}

export const UserPrompt = ({ messageObj, fields, scrollToBottom }) => {

	const onEditClick = () => {
		fields.setPrompt(messageObj.text);
		scrollToBottom();
	}

	if (messageObj.text) {
		return (
			<div className='psllm-message-items'>
				<UserAvatar />
				<Typography className='psllm-text'><b>You</b></Typography>
				<Typography className='psllm-text'>{messageObj.text}</Typography>
				<div className='psllm-message-options'>
					<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Edit'>
						<IconButton size='small' disabledRipple onClick={onEditClick}>
							<EditIcon size={optionsSize} color={optionsColor} onClick={onEditClick} />
						</IconButton>
					</Tooltip>
				</div>
			</div>
		);

	}

	return <></>;
}

export const ThinkingResponse = () => {

	return (
		<div className='psllm-message'>
			<div className='psllm-message-items'>
				<PXAvatar />
				<img src={thinkingGif} alt='Thinking...' style={{ width: 40, opacity: 0.7 }} />
			</div>
		</div>
	);
}