import React,
{
    useState, forwardRef, useCallback,
    useImperativeHandle, useRef, useEffect
} from 'react';
import _uniqueId from 'lodash/uniqueId';

import SearchwChips from '@nokia-csf-uxr/ccfk/SearchwChips';
import Label from '@nokia-csf-uxr/ccfk/Label';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';
import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';

import '../../Styles/KeywordSearch.css';

const KeywordSearch = forwardRef((
    {
        values, className, onChange, additionalComponents,
        disabled, validator, chipDisplay, errorMessage,
        label, size, height, placeholder, showClearButton,
        chipRender, error, id, ...restProps
    },
    ref) => {

    useImperativeHandle(ref, () => ({
        resetKeywords: () => {
            onKeywordChange([getEmptyField()]);
        },
        updateKeyword: (newItem) => {
            const updatedData = updatedFilterData.current.map(item => {
                if (item.id === newItem.id)
                    return newItem;
                return item;
            });
            onKeywordChange(updatedData);
        },
        clearCurrentSelectedItem: () => {
            currentSelectedItem.current = { id: 0 };
            onKeywordChange(updatedFilterData.current);
        },
        getKeywords: () => {
            return updatedFilterData.current
                .filter(item => item.type === 'chip')
                .map(item => item.value);
        },
        setKeywords: (newKeywords) => {
            const newChips = getExternalValues(newKeywords, '');
            newChips.push(getEmptyField());
            onKeywordChange(newChips);
        },
        hasNoChips: () => {
            return updatedFilterData.current
                .filter(item => item.type === 'chip')
                .length === 0;
        },
        hasChipsError: () => chipsError.current,
    }));

    const getEmptyField = () => {
        return {
            id: _uniqueId(),
            type: 'field',
            autocomplete: undefined,
            focused: false,
            value: "",
            placeholder: undefined,
        }
    };

    const [keywordValues, setKeywordValues] = useState([getEmptyField()]);

    const externalValues = useRef([]);
    const updatedFilterData = useRef(keywordValues);
    const currentSelectedItem = useRef({ id: 0 });
    const chipsError = useRef(false);
    const keywordSearchRef = useRef(null);

    const getFieldId = (field) => field && field.id ? field.id : _uniqueId();

    const getInternalValues = useCallback((newValues) => {

        var internalValues = newValues.filter(
            item => !externalValues.current.find(value => value.id === item.id)
        );

        if (internalValues.length === 0)
            internalValues.push(getEmptyField());

        if (!chipRender)
            return internalValues;

        const focusedItem = internalValues.find(item => item.type === 'field' && item.focused);
        if (focusedItem && focusedItem.id !== currentSelectedItem.current.id)
            currentSelectedItem.current = focusedItem;
        
        internalValues = internalValues.map(item => {
            var newItem;
            
            if (item.id === currentSelectedItem.current.id)
                newItem = {
                    ...item, renderPopup: (props) => {
                        const chipRenderElement = chipRender(props, item);
                        const chipRect = chipRenderElement.ref?.current? chipRenderElement.ref.current.getBoundingClientRect() : null;
                        const keywordSearchRect = keywordSearchRef.current? keywordSearchRef.current.getBoundingClientRect() : null;

                        if (chipRect && keywordSearchRect && chipRect.right > keywordSearchRect.right)
                            chipRenderElement.ref.current.style.right = '2px';

                        return chipRenderElement;
                    }
                };
            else if (item.type === 'field')
                newItem = { ...item, value: '', renderPopup: undefined };
            else
                newItem = { ...item, renderPopup: undefined };

            return newItem;
        });

        return internalValues;
    }, [chipRender]);

    const getExternalValues = useCallback((newValues, chipClass) => {
        const externalChips = newValues.map(value => (
            {
                id: _uniqueId(),
                type: 'chip',
                value: value,
                className: chipClass,
            }
        ));

        return externalChips.flatMap(value => [getEmptyField(), value]);
    }, []);

    const valuesToDisplay = useCallback((newValues) => {
        const internalValues = getInternalValues(newValues);
        externalValues.current = getExternalValues(values, 'external-chip');

        return [
            ...externalValues.current,
            ...internalValues
        ]

    }, [values, getInternalValues, getExternalValues]);

    const addPlaceHolder = (items) => {
        const keywords = items.map(item => {
            if (item && item.type === 'chip')
                return {
                    ...item,
                    id: getFieldId(item),
                    value: chipDisplay(item.value),
                    error: !validator(item.value),
                    placeholder: undefined,
                    className: 'keyword-chip ' + (item.className ? item.className : disabled ? 'external-chip' : ''),
                }
            return {
                ...item,
                id: getFieldId(item),
                placeholder: undefined,
            }
        });
        chipsError.current = keywords.some(item => item.error);

        if (keywords.length === 1 && !disabled)
            return keywords.map(item => ({ ...item, placeholder: placeholder }))

        return keywords;
    }

    const onKeywordChange = useCallback((newValues) => {
        if (newValues.length > 1 && !newValues[0].value && newValues[0].type !== 'chip')
            newValues = newValues.slice(1);
        const finalValues = valuesToDisplay(newValues);
        updatedFilterData.current = finalValues;
        setKeywordValues(finalValues);
        onChange(finalValues);
    }, [onChange, valuesToDisplay]);

    useEffect(() => {
        onKeywordChange(updatedFilterData.current);
    }, [values, onKeywordChange]);

    const clearChipsButton = () => {
        return (
            <IconButton
                size={size}
                style={{ margin: '0 0.55rem' }}
                onClick={() => {
                    onKeywordChange([getEmptyField()])
                }}
            >
                <CloseIcon />
            </IconButton>
        )
    }

    const searchWithChipsProps = {
        style: {
            height: height,
            pointerEvents: disabled ? 'none' : 'auto',
            backgroundColor: disabled ? '#f1f1f1' : 'white',
        },
        autoFocus: false,
        data: addPlaceHolder(keywordValues),
        onChange: onKeywordChange,
        size: size,
        error: chipsError.current || error,
        afterChips: showClearButton && !disabled && clearChipsButton(),
    }

    return (
        <>
            {
                label &&
                <Label verticalLayout>
                    <TextInputLabelContent>{label}</TextInputLabelContent>
                </Label>
            }
            <div id={id} className={`keyword-search-container ${className}`} ref={keywordSearchRef}>
                <div>
                    <SearchwChips {...restProps} {...searchWithChipsProps} />
                    {
                        errorMessage && (chipsError.current || error) &&
                        <Label verticalLayout>
                            <TextInputLabelContent className="error-text">{errorMessage}</TextInputLabelContent>
                        </Label>
                    }
                </div>
                {
                    additionalComponents &&
                    <div className="keyword-search-additional">
                        {additionalComponents}
                    </div>
                }
            </div>
        </>
    );
});

KeywordSearch.defaultProps = {
    values: [],
    className: '',
    placeholder: '',
    height: undefined,
    label: '',
    disabled: false,
    validator: () => true,
    chipDisplay: (value) => value,
    chipRender: undefined,
    errorMessage: '',
    onChange: () => { },
    size: 'medium',
    showClearButton: false,
    error: false,
    id: null
}

export default KeywordSearch;