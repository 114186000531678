
export const STANDARD_CSV_MESSAGE = '<b>CSV will now be generated</b>. Please don\'t move away from the page to avoid the generation cancelation.';

export const validateEmail = (email) => /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email);

export const validateUrl = (url) => /^https?:\/\/[\w.]+(?:\/.*)?$/.test(url);

export const isBlankText = (text) => /^\s*$/.test(text);

export const timezones = () => Intl.supportedValuesOf("timeZone");

export const listsHaveDifferentElements = (list1, list2) => {
	if (list1.length !== list2.length)
		return true;
	return !list1.every(element => list2.includes(element));
}

export const getDifferenceBetweenLists = (list1, list2) => {
	return list1.filter(item => !list2.includes(item));
}

export const uppercaseFirstLetter = (word) => {
	return word.charAt(0).toUpperCase() + word.slice(1);
}

export const rangeArray = (start, end, step = 1) => {
	const length = Math.round((end - start) / step) + 1;
	return Array.from({ length }, (_, index) => start + (step * index));
}

export const integerPositive = (number) => {
	return number && Number.isInteger(Number(number)) && Number(number) > 0;
}

export const integer = (number) => {
	return number !== '' && Number.isInteger(Number(number)) && Number(number) >= 0;
}

export const percentage = (number) => {
	var result = number * 100;
	result = Math.round(result * 100) / 100; // Round to 2 decimal places
	return result;
}

export const evalExpression = (expression, ...scopeVars) => {
	try {
		const context = Object.assign({}, ...scopeVars);
		// eslint-disable-next-line no-new-func
		const func = new Function(...Object.keys(context), `return ${expression}`);
		return func(...Object.values(context));
	}
	catch (e) {
        console.warn('Error evaluating expression: ' + expression + '. Exception: ' + e + '. Returning False...');
        return false;
    }
}

export const roundToDecimal = (number, places) => {
	return Math.round(number * Math.pow(10, places)) / Math.pow(10, places);
}