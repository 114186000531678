import { createContext, useContext, useState, useEffect } from "react";
import { useLeftMenu } from '../CustomHooks/Global';
import { useLocation } from 'react-router-dom';

const PageNavigationContext = createContext(undefined);

export const PageNavigationProvider = ({ children }) => {

    const leftMenu = useLeftMenu();
    const location = useLocation();

    const [leftMenuSelectedIndex, setLeftMenuSelectedIndex] = useState(0);
    const [leftMenuOpenedGroupIndex, setLeftMenuOpenedGroupIndex] = useState(-1);
    const [currentRoute, setCurrentRoute] = useState("");

    useEffect(() => {

        if (leftMenu === undefined)
            return;

        if (location.pathname === currentRoute)
            return;

        setCurrentRoute(location.pathname);

        const routeToSelect = leftMenu.filter(leftMenuRoute => {
            return leftMenuRoute.path === location.pathname;
        })[0];

        if (routeToSelect !== undefined) {
            setLeftMenuSelectedIndex(routeToSelect.itemIndex);
            setLeftMenuOpenedGroupIndex(routeToSelect.groupIndex);
        }
    }, [currentRoute, leftMenu, location]);

    return <PageNavigationContext.Provider value={{ leftMenuSelectedIndex, setLeftMenuSelectedIndex, leftMenuOpenedGroupIndex, setLeftMenuOpenedGroupIndex }}>{children}</PageNavigationContext.Provider>;
}

export const useLeftMenuIndexes = () => {
    return useContext(PageNavigationContext);
};