import { useCallback, useEffect, useRef, useState } from "react";
import Env from "../CustomObjects/Environment";

import { useFetch } from './Fetch';

export const useSharepoint = (documentFolderServerProperty, usingMultipleFolders = true, appendHashBytes = false, setNumberOfActions = null) => {

    const filesToUpload = useRef([]);
    const folderNameRef = useRef('');

    const [createFolderResponse, setCreateFolder, createFolderError, , setCreateFolderBody] = useFetch(Env.BACKEND_SERVER_URL + 'Sharepoint', false, {}, 'POST', {});
    const [uploadFilesToSharepointResponse, setUploadFilesToSharepoint, uploadFilesToSharepointError, , setUploadFilesToSharepointBody] = useFetch(Env.BACKEND_SERVER_URL + 'Sharepoint', false, {}, 'PATCH', {});
    const [uploadFilesToServerResponse, setUploadFilesToServer, uploadFilesToServerError, , setUploadFilesToServerBody] = useFetch(Env.BACKEND_SERVER_URL + 'Files', false, {}, 'POST', {}, false);
    const [deleteFilesFromServerResponse, setDeleteFilesFromServer, deleteFilesFromServerError, , setDeleteFilesFromServerBody] = useFetch(Env.BACKEND_SERVER_URL + 'Files', false, {}, 'PATCH', {});

    const [creationSuccess, setCreationSucess] = useState(null);
    const [creationError, setCreationError] = useState('');
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [uploadError, setUploadError] = useState('');

    const createFolderInSharepoint = useCallback((folderName) => {
        setCreationSucess(null);
        setCreationError('');
        setCreateFolderBody({
            folderName: folderName,
            documentFolderServerProperty: documentFolderServerProperty,
            usingMultipleFolders: usingMultipleFolders,
            appendHashBytes: appendHashBytes
        })
        setCreateFolder(true);
    }, [documentFolderServerProperty, usingMultipleFolders, appendHashBytes, setCreateFolder, setCreateFolderBody]);

    const uploadFilesToServerAndSharepoint = useCallback((folderName, files) => {
        folderNameRef.current = folderName;

        setUploadSuccess(null);
        setUploadError('');
        const formData = new FormData();
        files.forEach(file => {
			formData.append('files', file.file);
		});
        setUploadFilesToServerBody(formData);
        setUploadFilesToServer(true);
    }, [setUploadFilesToServer, setUploadFilesToServerBody]);

    const uploadFilesToSharepoint = useCallback((sessionName, filePaths) => {
        setUploadFilesToSharepointBody({
            files: filePaths,
            folderName: sessionName,
            documentFolderServerProperty: documentFolderServerProperty,
            usingMultipleFolders: usingMultipleFolders,
            appendHashBytes: appendHashBytes
        })
        setUploadFilesToSharepoint(true);
    }, [documentFolderServerProperty, usingMultipleFolders, appendHashBytes, setUploadFilesToSharepoint, setUploadFilesToSharepointBody]);

    const deleteFilesFromServer = useCallback((filePaths) => {
        setDeleteFilesFromServerBody(filePaths);
        setDeleteFilesFromServer(true);
    }, [setDeleteFilesFromServer, setDeleteFilesFromServerBody]);

    // CREATE SHAREPOINT FOLDER
    useEffect(() => {
        if (!createFolderResponse && !createFolderError)
            return;

        if (createFolderResponse) {
            console.log('Sharepoint folder created successfully!');
            setCreationSucess(createFolderResponse);
        }
        else if (createFolderError) {
            console.error('Error creating Sharepoint folder: ', createFolderError);
            setCreationError(`Error creating Sharepoint folder: ${createFolderError}`);
        }

        if (setNumberOfActions)
            setNumberOfActions((prev) => prev - 1);
    }, [createFolderResponse, createFolderError, setNumberOfActions]);

    // UPLOAD FILES TO SERVER
    useEffect(() => {
        if (uploadFilesToServerResponse) {
            console.log('Successfully uploaded files to server: ', uploadFilesToServerResponse.details);
            console.log('Now uploading files to Sharepoint...');

            filesToUpload.current = uploadFilesToServerResponse.details.map(detail => detail.filepath);
            uploadFilesToSharepoint(folderNameRef.current, filesToUpload.current);
        }
        else if (uploadFilesToServerError) {
            console.error('Error when uploading files to server: ', uploadFilesToServerError);
            setUploadError(`Error when uploading files to server: ${uploadFilesToServerError}`);
            if (setNumberOfActions)
                setNumberOfActions((prev) => prev - 1);
            filesToUpload.current = [];
        }
    }, [uploadFilesToServerResponse, uploadFilesToServerError, setNumberOfActions, uploadFilesToSharepoint]);

    // UPLOAD FILES TO SHAREPOINT
    useEffect(() => {
        if (!uploadFilesToSharepointResponse && !uploadFilesToSharepointError)
            return;

        if (uploadFilesToSharepointResponse) {
            console.log('Successfully uploaded files into Sharepoint!');
            setUploadSuccess(uploadFilesToSharepointResponse);
        }
        else if (uploadFilesToSharepointError) {
            console.error('Error when uploading files to Sharepoint folder: ', uploadFilesToSharepointError);
            setUploadError(`Error when uploading files to Sharepoint folder: ${uploadFilesToSharepointError}`);
        }

        if (filesToUpload.current.length > 0) {
            console.log('Now deleting files from server...');
            deleteFilesFromServer(filesToUpload.current);
        }
        else {
            if (setNumberOfActions) setNumberOfActions((prev) => prev - 1);
        }
    }, [uploadFilesToSharepointResponse, uploadFilesToSharepointError, setNumberOfActions, deleteFilesFromServer]);

    // DELETE FILES FROM SERVER
    useEffect(() => {
        if (!deleteFilesFromServerResponse && !deleteFilesFromServerError)
            return;

        if (deleteFilesFromServerResponse) {
            console.log('Successfully deleted files from server!');
        }
        else if (deleteFilesFromServerError) {
            console.error('Error when deleting files from server: ', deleteFilesFromServerError);
            //setUploadError(`Error when deleting files from server: ${deleteFilesFromServerError}`);
        }

        if (setNumberOfActions)
            setNumberOfActions((prev) => prev - 1);
    }, [deleteFilesFromServerResponse, deleteFilesFromServerError, setNumberOfActions]);

    return [
        creationSuccess, creationError, 
        uploadSuccess, uploadError, 
        createFolderInSharepoint, uploadFilesToServerAndSharepoint];
}