import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Dialog, {
    DialogTitle,
    DialogContent,
    DialogFooter
} from '@nokia-csf-uxr/ccfk/Dialog';
import Button from '@nokia-csf-uxr/ccfk/Button';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import ClipboardIcon from '@nokia-csf-uxr/ccfk-assets/latest/ClipboardIcon';

import KEY_CODES from '@nokia-csf-uxr/ccfk/common/KeyCodes';

import '../../Styles/MuiAutoComplete.css';
import '../../Styles/Message.css';

const filter = createFilterOptions();

const TextWithChips = forwardRef((
    {
        id,
        values,
        setValues,
        onChange,
        separators,
        itemRegExp,
        label,
        placeholder,
        helper,
        minHeight,
        height,
        maxHeight,
        limitTags,
        disabled,
        useBackspaceToDelete,
        externalError,
        setExternalError,
        chipErrorMessage,
        showClearButton,
        validator,
        allowDuplicates,
        chipCanBeClosed,
        getCustomChipClasses,
        withCopy,
        withDropdownHelperMessage,
        withCustomDialog,
        inputIcon,
        underlined,
        hideClearButton,
        options,
        maxNumberOfOptions
    }, ref
) => {

    const currentChips = useRef([]);
    const commonElements = useRef([]);
    const errorExists = useRef(false);
    const currentErrorMessage = useRef('');
    const chipWithError = useRef('');
    const currentValues = useRef([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [openCustomDialog, setOpenCustomDialog] = useState(false);

    useImperativeHandle(ref, () => ({
        hasNoChips: () => currentChips.current.length === 0,
        hasChipsError: () => errorExists.current,
        getChips: () => currentChips.current,
        resetChips: () => handleOnChange(null, []),
    }));

    const isBackspace = (key) => key === KEY_CODES.BACKSPACE;

    const splitValueWithSeparators = (value, separators) => {
        const escapedSeparators = separators.map(sep => {
            return sep.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        });
        const pattern = escapedSeparators.join('|');
        return value.split(new RegExp(pattern));
    }

    const applySeparators = (values) => {
        var newValues = [];
        if (separators) {
            values.forEach(item => {
                if (separators.some(char => item.includes(char)))
                    splitValueWithSeparators(item, separators)
                        .filter(itemValue => itemValue)
                        .forEach(itemValue => newValues.push(itemValue));
                else
                    newValues.push(item);
            });
        }
        else {
            newValues = [...values];
        }

        return newValues;
    }

    const applyItemRegExp = (values) => {
        if (itemRegExp) {
            var newValues = [];
            values.forEach(item => {
                const match = item.match(itemRegExp);
                if (match)
                    newValues.push(...match);
                else
                    newValues.push(item);
            });

            return newValues;
        }
        else {
            return values;
        }
    }

    const updateCommonElementsRef = (values) => {
        if (!allowDuplicates) {
            const uniqueElements = new Set();
            commonElements.current = values.filter(value => {
                if (uniqueElements.has(value)) {
                    return true;
                } else {
                    uniqueElements.add(value);
                    return false;
                }
            });
        }
    }

    const normalizeValues = (values) => {
        const normalizedValues = [...values];
        const lastElementIsAnObject = typeof normalizedValues[normalizedValues.length - 1] === 'object';
        const updateLastElementWithJustTheValueComponent = () =>
            normalizedValues[normalizedValues.length - 1] = normalizedValues[normalizedValues.length - 1].value;

        if (lastElementIsAnObject)
            updateLastElementWithJustTheValueComponent();

        return normalizedValues;
    }

    const submitValues = (values) => {
        currentChips.current = values;
        updateCommonElementsRef(values);
        setValues(values);
    }

    const isDuplicate = (value) => {
        return commonElements.current.includes(value);
    }

    const validateChip = (value) => {
        if (!errorExists.current) {
            if (!allowDuplicates && isDuplicate(value)) {
                currentErrorMessage.current = 'Please remove one of the duplicate values';
                errorExists.current = true;
                chipWithError.current = value;
            }

            const chipHasValidValue = validator(value);
            if (!chipHasValidValue) {
                currentErrorMessage.current = chipErrorMessage;
                errorExists.current = true;
                chipWithError.current = value;
            }
        }
    }

    const getComponentClasses = () => {
        var cssClasses = '';
        cssClasses = cssClasses + 'with-scrollbar';
        if (disabled) cssClasses = cssClasses + ' disabled';
        if (externalError) cssClasses = cssClasses + ' external-error';
        if (!showClearButton) cssClasses = cssClasses + ' without-clear-button';
        if (underlined) cssClasses = cssClasses + ' underlined';

        return cssClasses;
    }
    
    const getChipClasses = (value) => {
        var cssClasses = '';
        const chipIsInvalid = errorExists.current && value === chipWithError.current;

        if (chipIsInvalid) cssClasses = cssClasses + 'error';
        if (!chipCanBeClosed(value) && !chipIsInvalid) cssClasses = cssClasses + ' without-close-button';
        if (!chipIsInvalid) cssClasses = cssClasses + ' ' + getCustomChipClasses(value);

        return cssClasses
    }   

    const handleOnChange = (_, newValues) => {
        currentErrorMessage.current = '';
        errorExists.current = false;
        if (setExternalError)
            setExternalError(false);

        newValues = normalizeValues(newValues);
        const newValuesAreEnteredByUser = newValues.length >= currentValues.current.length;

        currentValues.current = newValues;

        if (withCustomDialog && newValuesAreEnteredByUser) {
            withCustomDialog.onChange(newValues[newValues.length - 1]);
            setOpenCustomDialog(true);
        }
        else if (!withCustomDialog && newValuesAreEnteredByUser) {
            newValues = applySeparators(newValues);
            newValues = applyItemRegExp(newValues);
            submitValues(newValues);
        }
        else {
            submitValues(newValues);
        }

        if (onChange)
            onChange(newValues);
    }

    const handleTextFieldKeyDown = (e) => {
        if (!useBackspaceToDelete && isBackspace(e.key) && e.target.value === '') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const copyValuesToClipBoard = () => {
        navigator.clipboard.writeText(values);
    }

    const getConditionalProps = () => {
        var props = {}
        if (withDropdownHelperMessage || withCustomDialog) {
            props.filterOptions = (options, params) => {
                var filtered = filter(options, params);

                if (maxNumberOfOptions)
                    filtered = filtered.slice(0, maxNumberOfOptions);

                if (params.inputValue) {
                    var dropdownPlaceholder = `Add "${params.inputValue}"`;
                    if (typeof withDropdownHelperMessage === 'function')
                        dropdownPlaceholder = withDropdownHelperMessage(params.inputValue);

                    filtered.push({
                        value: params.inputValue,
                        label: dropdownPlaceholder
                    })
                }

                return filtered;
            }
        }

        if (withDropdownHelperMessage && options.length > 0) {
            props.renderOption = (props, option, { selected }) => {
                return (
                    <li {...props} className={props.className}>
                        {
                            typeof option !== 'object' &&
                            <Checkbox
                                size='small'
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />

                        }
                        {
                            typeof option !== 'object' ? option : option.label
                        }
                    </li>
                )
            }
        }

        return props;
    }

    const getTextFieldStyleProps = () => {
        const props = {};
        if (minHeight)
            props.minHeight = minHeight;
        if (height)
            props.height = height;
        if (maxHeight)
            props.maxHeight = maxHeight;

        return props;
    }

    const handleCloseDialog = () => {
        setOpenCustomDialog(false);
    }

    const submitDialog = () => {
        currentValues.current[currentValues.current.length - 1] = withCustomDialog.onSubmit();
        submitValues(currentValues.current);
        setOpenCustomDialog(false);
    }

    useEffect(() => {
        if (values && values.length > 0) {
            currentValues.current = values;
            currentChips.current = values;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorExists.current)
            setErrorMessage(currentErrorMessage.current);
        else
            setErrorMessage('');
    }, [values])

    return (
        <div className='text-with-chips-container'>
            {
                withCopy &&
                <Tooltip placement="top" tooltip="Copy" >
                    <IconButton onClick={copyValuesToClipBoard}>
                    <ClipboardIcon />
                    </IconButton>
                </Tooltip>
            }
            <Autocomplete
                id={id}
                size="small"
                className={getComponentClasses()}
                fullWidth
                multiple
                disableClearable={hideClearButton}
                limitTags={!disabled ? limitTags : -1}
                disabled={disabled}
                value={values}
                onChange={handleOnChange}
                options={options}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const chipProps = getTagProps({ index });
                        validateChip(option);
                        const chipClasses = getChipClasses(option);
                        return (
                            <Chip classes={{ root: chipClasses }} size='small' label={option} {...chipProps} />
                        );
                    })
                }
                renderInput={(params) => {
                    return (
                        <div className="text-with-chips-input">
                            { inputIcon }
                            <TextField
                                {...params}
                                sx={{ ...getTextFieldStyleProps() }}
                                label={label}
                                placeholder={placeholder}
                                onKeyDown={handleTextFieldKeyDown}
                            />
                        </div>
                    )
                }}
                {...getConditionalProps()}
            />
            <FormHelperText>
                { errorMessage !== '' ? errorMessage : helper }
            </FormHelperText>
            {
                withCustomDialog &&
                <Dialog variant='dark-custom' isOpen={openCustomDialog} className='message-container'>
                    <DialogTitle title={withCustomDialog.title}/>
                    <DialogContent>
                        { withCustomDialog.component }
                    </DialogContent>
                    <DialogFooter>
                        <Button variant="secondary-bigger-font" onClick={handleCloseDialog}>Cancel</Button>
                        <Button variant="call-to-action-bigger-font" onClick={submitDialog}>Add</Button>
                    </DialogFooter>
                </Dialog>
            }
        </div>
    );
})

TextWithChips.defaultProps = {
    label: '',
    placeholder: '',
    helper: '',
    limitTags: -1,
    disabled: false,
    useBackspaceToDelete: true,
    validator: () => true,
    allowDuplicates: false,
    externalError: false,
    setExternalError: false,
    chipErrorMessage: '',
    showClearButton: true,
    chipCanBeClosed: () => true,
    getCustomChipClasses: () => '',
    withCopy: false,
    underlined: false,
    hideClearButton: false,
    options: [],
    maxNumberOfOptions: 50
}

export default TextWithChips;