import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@nokia-csf-uxr/ccfk/Typography';

import '../../Styles/Carousel.css';

const CarouselContainer = ({ imagePath, backgroundPosition, address, title, text }) => {

    const navigate = useNavigate();

    const imageStyle = {
        backgroundImage: `url(${imagePath})`,
        backgroundPosition: backgroundPosition
    };

    const openLink = () => {
        if (address.includes('http'))
            window.open(address, "_blank", 'noreferrer');
        else
            navigate(address)
    };

    return (
        <div style={imageStyle} className="image-carousel" onClick={openLink} >
            <Typography className="image-title">{title}</Typography>
            {
                text && 
                <div className="image-text">
                    <Typography>{text}</Typography>
                </div>
            }
        </div>
    );

}

CarouselContainer.defaultProps = {
    backgroundPosition: '50% 50%'
}

export default CarouselContainer;