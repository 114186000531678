
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import MenuSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/MenuSmallIcon';

import { SelectMultipleButton } from '../Common';

const ToolbarSelectMultipleButton = ({ label, values, setValues, data, onChange }) => {
    return (
        <div style={{ display: 'flex' }}>
            <SelectMultipleButton
                withLabel={false}
                values={values}
                setValues={setValues}
                data={{
                    values: Object.values(data).map(value => ({ value: value })),
                    mapping: { value: 'value' }
                }}
                width='10em'
                Icon={MenuSmallIcon}
                onChange={onChange}
            />
            <br />
            <Typography style={{ textAlign: 'end', alignSelf: 'center' }}><b>{label}</b></Typography>
        </div>
    );
}

export default ToolbarSelectMultipleButton;