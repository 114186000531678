import React, { useState } from 'react';

import { default as RatingMUI } from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import '../../Styles/Rating.css';

//const ratingLabels = {
//    0: 'No Rating',
//    0.5: 'Useless',
//    1: 'Useless+',
//    1.5: 'Poor',
//    2: 'Poor+',
//    2.5: 'Ok',
//    3: 'Ok+',
//    3.5: 'Good',
//    4: 'Good+',
//    4.5: 'Excellent',
//    5: 'Excellent+',
//};

const ratingIcons = {
    0: <SentimentVeryDissatisfiedIcon color="error" />,
    0.5: <SentimentVeryDissatisfiedIcon color="error" />,
    1: <SentimentDissatisfiedIcon color="error" />,

    1.5: <SentimentDissatisfiedIcon color="warning" />,
    2: <SentimentSatisfiedIcon color="warning" />,
    2.5: <SentimentSatisfiedIcon color="warning" />,

    3: <SentimentSatisfiedAltIcon color="success" />,
    3.5: <SentimentSatisfiedAltIcon color="success" />,

    4: <SentimentVerySatisfiedIcon color="success" />,
    4.5: <SentimentVerySatisfiedIcon color="success" />,
    5: <SentimentVerySatisfiedIcon color="success" />,
};

const Rating = ({ value, setValue }) => {

    const [hover, setHover] = useState(-1);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
            }}
        >
            <Box sx={{ ml: 1, mt: -0.5 }}>
                <IconButton size='small' color='error' onClick={() => setValue(0)}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </Box>
            <Box sx={{ ml: 1 }}>
                <RatingMUI
                    size='large'
                    value={value}
                    precision={0.5}
                    onChange={(_, newValue) => {
                        setValue(newValue);
                    }}
                    onChangeActive={(_, newHover) => {
                        setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.3 }} fontSize="inherit" />}
                    />
            </Box>
            <Box sx={{ ml: 1, mt: -0.4 }}>
                {ratingIcons[hover !== -1 ? hover : value]}
            </Box>
        </Box>
    );

}

export default Rating;