import { useEffect, useState, useRef, useCallback } from "react";

import { useFetch } from "./Fetch";

import Env from "../CustomObjects/Environment";

export const useKPIPlanningFields = () => {

    const messageRef = useRef(null);
    const treeRef = useRef(null);

    const [overlayPanelVisible, setOverlayPanelVisible] = useState(false);
    const [kpiTeam, setKpiTeam] = useState('');
    const [headCountTypes, setHeadCountTypes] = useState([]);
    const [initialHeadCountTypes, setInitialHeadCountTypes] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [initialKpis, setInitialKpis] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [filterOrgsWithKPIs, setFilterOrgsWithKPIs] = useState(false);
    const [searchTerms, setSearchTerms] = useState([]);
    const [isPageEditable, setIsPageEditable] = useState(true);
    
    const resetKPIDefinitionFields = () => {
        setHeadCountTypes([]);
        setInitialHeadCountTypes([]);
        setKpis([]);
        setInitialKpis([]);
        setYear(new Date().getFullYear());
    }

    const validateKpiSubmission = () => {
        if (headCountTypes.length === 0) {
            messageRef.current.errorMessage('Please add at least one <b>head count type</b>.');
            return false;
        }

        if (kpis.length === 0) {
            messageRef.current.errorMessage('Please add at least one <b>KPI</b>.');
            return false;
        }

        return true;
    }
    
    return {
        messageRef,
        treeRef,

        overlayPanelVisible,
        setOverlayPanelVisible,

        kpiTeam,
        setKpiTeam,
        
        headCountTypes,
        setHeadCountTypes,
        initialHeadCountTypes,
        setInitialHeadCountTypes,

        kpis,
        setKpis,
        initialKpis,
        setInitialKpis,

        year,
        setYear,

        filterOrgsWithKPIs,
        setFilterOrgsWithKPIs,
        searchTerms,
        setSearchTerms,
        isPageEditable,
        setIsPageEditable,

        resetKPIDefinitionFields,
        validateKpiSubmission,
    }
}

export const useOrgTreeJSON = () => {

    const [orgTreeJSON] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/OrgTreeJSON', true);

    const [orgTreeJSONData, setOrgTreeJSONData] = useState(null);

    useEffect(() => {
        if (!orgTreeJSON)
            return;

        try {
            const result = JSON.parse(orgTreeJSON);
            setOrgTreeJSONData(result ?? null);
        }
        catch (e) {
            console.error('Error parsing orgTreeJSON: ', e);
        }
    }, [orgTreeJSON]);
    
    return {
        orgTreeJSONData,
    }
}

export const useKPIsSummary = () => {
    const [kpisSummaryData, setFetchKPIsSummary] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/Summary', true);
    
    const getKpisSummaryData = () => {
        setFetchKPIsSummary(true);
    }
    
    return {
        kpisSummaryData,
        getKpisSummaryData,
    }

}

export const useOrgTreeEnrichmentWithKPIs = (fields, orgTreeJSONData, kpisSummaryData) => {
    const [filteredOrgTreeEnriched, setFilteredOrgTreeEnriched] = useState(null);

    const orgTreeEnriched = useRef(null);
    const orgsThatMatchedSearchTerms = useRef(null);

    const enrichTeam = useCallback((org) => {
        const orgKPIs = kpisSummaryData.filter(kpi => kpi.team === org.T);
        var branchHasData = orgKPIs.length > 0;

        if (org.C && Array.isArray(org.C) && org.C.length > 0) {
            org.C = org.C.map(enrichTeam);
            branchHasData = branchHasData || org.C.some(c => c.branchHasData);
        }

        return {
            ...org,
            kpis: orgKPIs,
            branchHasData: branchHasData,
        }
    }, [kpisSummaryData]);

    const filterTeam = (org, filterOrgsWithKPIs, searchTerms) => {
        if (org.C && Array.isArray(org.C) && org.C.length > 0)
            org.C = org.C.filter((org) => filterTeam(org, filterOrgsWithKPIs, searchTerms))
 
        if (!filterOrgsWithKPIs && searchTerms.length === 0)
            return true;
        else if (filterOrgsWithKPIs && searchTerms.length === 0)
            return (org.branchHasData || org.C.some(c => c.branchHasData));
        else if (!filterOrgsWithKPIs && searchTerms.length > 0) {
            orgsThatMatchedSearchTerms.current.push(org.T);
            return (
                searchTerms.every(s => org.T.toUpperCase().includes(s.toUpperCase())) ||
                searchTerms.every(s => org.C.some(c => c.T.toUpperCase().includes(s.toUpperCase()))) ||
                org.C.some(c => orgsThatMatchedSearchTerms.current.includes(c.T))
            );
        }
        else {
            orgsThatMatchedSearchTerms.current.push(org.T);
            return (
                (org.branchHasData || org.C.some(c => c.branchHasData)) &&
                (
                    searchTerms.every(s => org.T.toUpperCase().includes(s.toUpperCase())) ||
                    searchTerms.every(s => org.C.some(c => c.T.toUpperCase().includes(s.toUpperCase()))) ||
                    org.C.some(c => orgsThatMatchedSearchTerms.current.includes(c.T))
                )
            );
        }
    };

    const filterOrgTree = (params = null) => {

        var filterOrgsWithKPIs = fields.filterOrgsWithKPIs;
        var searchTerms = fields.searchTerms;

        if (Array.isArray(params))
            searchTerms = params;
        else if (typeof params === 'boolean')
            filterOrgsWithKPIs = params;
        
        orgsThatMatchedSearchTerms.current = [];
        const orgTreeEnrichedCopy = JSON.parse(JSON.stringify(orgTreeEnriched.current));
        setFilteredOrgTreeEnriched(orgTreeEnrichedCopy.filter((org) => filterTeam(org, filterOrgsWithKPIs, searchTerms)));
    }

    useEffect(() => {
        if (!orgTreeJSONData || !kpisSummaryData)
            return;

        orgTreeEnriched.current = orgTreeJSONData.map(enrichTeam);
        filterOrgTree();
        // eslint-disable-next-line
    }, [orgTreeJSONData, kpisSummaryData]);

    return {
        filteredOrgTreeEnriched,
        filterOrgTree,
    }
}

export const useHeadCountTypes = (fields) => {
    const [headCountTypesData] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/HeadCountTypes', true);
    const [availableHeadCountTypes, setAvailableHeadCountTypes] = useState([]);

    useEffect(() => {
        if (!headCountTypesData || !fields.headCountTypes)
            return;

        const filteredHeadCountTypes = headCountTypesData.filter(headCountData => {
            return fields.headCountTypes.some(headCount => headCount.type === headCountData.longName);
        });

        setAvailableHeadCountTypes(filteredHeadCountTypes);
    }, [headCountTypesData, fields.headCountTypes]);

    const getHeadCountTypeByLongName = (longName) => {
        if (!headCountTypesData)
            return null;

        const headCountType = headCountTypesData.find(h => h.longName === longName);
        return headCountType ?? null;
    }

    const getHeadCountTypeByName = (name) => {
        if (!headCountTypesData)
            return null;

        const headCountType = headCountTypesData.find(h => h.name === name);
        return headCountType ?? null;
    }

    return {
        headCountTypesData,
        availableHeadCountTypes,
        getHeadCountTypeByLongName,
        getHeadCountTypeByName,
    }
}

export const useKPIs = () => {
    const [kpisData] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/KPIs', true);

    const getKpiByName = (longName) => {
        if (!kpisData)
            return null;

        const kpi = kpisData.find(k => k.kpiName === longName);
        return kpi ?? null;
    }

    const getKpiByKpiId = (kpiId) => {
        if (!kpisData)
            return null;

        const kpi = kpisData.find(k => k.kpiId === kpiId);
        return kpi ?? null;
    }
        
    return {
        kpisData,
        getKpiByName,
        getKpiByKpiId,
    }
}

export const useKPIPlanningSubmission = (fields, getKpiByName, getHeadCountTypeByLongName, getKpisSummaryData) => {
    const [successCreation, setCreateKpiPlanning, failureCreation, , setCreateKpiPlanningBody] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning', false, {}, 'POST');

    const createKPIDBObject = (kpis) => {
        return kpis.map(kpi => {
            return {
                id: getKpiByName(kpi.kpiName).kpiId,
                headCountType: getHeadCountTypeByLongName(kpi.headCountType).name,
                headCountPercentage: kpi.percentage,
                plannedValueTOTAL: kpi.totalPlanned,
                plannedValueQ1: kpi.q1,
                plannedValueQ2: kpi.q2,
                plannedValueQ3: kpi.q3,
                plannedValueQ4: kpi.q4,
                notes: kpi.notes,
            }
        });
    }

    const createHeadCountTypesDBObject = (headCountTypes) => {
        return headCountTypes.map(headCountType => {
            return {
                type: getHeadCountTypeByLongName(headCountType.type).name,
                value: headCountType.value,
                notes: headCountType.notes,
            }
        });
    }

    const submitKpiPlanning = () => {

        fields.messageRef.current.hideAreYouSureMessage();

        const addedKpisList = fields.kpis.filter(kpi => {
            const initialKpi = fields.initialKpis.find(initialKpi =>
                initialKpi.kpiName === kpi.kpiName
            );

            return !initialKpi;
        });
        
        const modifiedKpisList = fields.kpis.filter(kpi => {
            const initialKpi = fields.initialKpis.find(initialKpi =>
                initialKpi.kpiName === kpi.kpiName
            );

            return (
                initialKpi &&
                (
                    initialKpi.headCountType !== kpi.headCountType ||
                    initialKpi.percentage !== kpi.percentage ||
                    initialKpi.totalPlanned !== kpi.totalPlanned ||
                    initialKpi.q1 !== kpi.q1 ||
                    initialKpi.q2 !== kpi.q2 ||
                    initialKpi.q3 !== kpi.q3 ||
                    initialKpi.q4 !== kpi.q4 ||
                    initialKpi.notes !== kpi.notes
                )
            )
        });

        const removedKpisList = fields.initialKpis.filter(kpi => {
            const newKpi = fields.kpis.find(newKpi =>
                newKpi.kpiName === kpi.kpiName
            );

            return !newKpi;
        });
        
        const addedHeadCountTypesList = fields.headCountTypes.filter(headCountType => {
            const initialHeadCountType = fields.initialHeadCountTypes.find(initialHeadCountType =>
                initialHeadCountType.type === headCountType.type
            );

            return !initialHeadCountType;
        });

        const modifiedHeadCountTypesList = fields.headCountTypes.filter(headCountType => {
            const initialHeadCountType = fields.initialHeadCountTypes.find(initialHeadCountType =>
                initialHeadCountType.type === headCountType.type
            );

            return (
                initialHeadCountType &&
                (
                    initialHeadCountType.value !== headCountType.value ||
                    initialHeadCountType.notes !== headCountType.notes
                )
            )
        });

        const removedHeadCountTypesList = fields.initialHeadCountTypes.filter(headCountType => {
            const newHeadCountType = fields.headCountTypes.find(newHeadCountType =>
                newHeadCountType.type === headCountType.type
            );

            return !newHeadCountType;
        });

        const noChangeInKpis = addedKpisList.length === 0 && modifiedKpisList.length === 0 && removedKpisList.length === 0;
        const noChangeInHeadCount = addedHeadCountTypesList.length === 0 && modifiedHeadCountTypesList.length === 0 && removedHeadCountTypesList.length === 0;

        if (noChangeInKpis && noChangeInHeadCount) {
            fields.messageRef.current.infoMessage('No changes were make to the team KPI\'s. Data was not modified...');
            fields.setOverlayPanelVisible(false);
            return;
        }
        
        const kpis = createKPIDBObject(fields.kpis);
        const addedKpis = createKPIDBObject(addedKpisList);
        const modifiedKpis = createKPIDBObject(modifiedKpisList);
        const removedKpis = createKPIDBObject(removedKpisList);

        const headCounts = createHeadCountTypesDBObject(fields.headCountTypes);
        const addedHeadCounts = createHeadCountTypesDBObject(addedHeadCountTypesList);
        const modifiedHeadCounts = createHeadCountTypesDBObject(modifiedHeadCountTypesList);
        const removedHeadCounts = createHeadCountTypesDBObject(removedHeadCountTypesList);

        const kpiPlanningBody = {
            team: fields.kpiTeam,
            year: fields.year,
            kpis: kpis,
            addedKpis: addedKpis,
            modifiedKpis: modifiedKpis,
            removedKpis: removedKpis,
            headCounts: headCounts,
            addedHeadCounts: addedHeadCounts,
            modifiedHeadCounts: modifiedHeadCounts,
            removedHeadCounts: removedHeadCounts,
        };
        
        setCreateKpiPlanningBody(kpiPlanningBody);
        setCreateKpiPlanning(true);
        
        fields.messageRef.current.showProcessingDiag();
    }

    useEffect(() => {
        if (!successCreation)
            return;

        fields.messageRef.current.hideProcessingDiag();
        fields.messageRef.current.successMessage('KPI Planning submitted successfully.');
        fields.setOverlayPanelVisible(false);

        getKpisSummaryData();
        // eslint-disable-next-line
    }, [successCreation]);

    useEffect(() => {
        if (!failureCreation)
            return;

        fields.messageRef.current.hideProcessingDiag();
        fields.messageRef.current.errorMessage(failureCreation.data);
        // eslint-disable-next-line
    }, [failureCreation]);

    return {
        submitKpiPlanning,
    }
    
}

export const useKPIReport = () => {
    const [kpiReport, setFetchKPIReport] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/Report');

    const getKPIReport = () => {
        setFetchKPIReport(true);
    }

    return {
        kpiReport,
        getKPIReport
    }
}

export const useKPIConfig = (fields) => {
    const [kpiConfig] = useFetch(Env.BACKEND_SERVER_URL + 'KPIPlanning/GetConfig', true);
    
    useEffect(() => {
        if (!kpiConfig)
            return;

        if (kpiConfig.hasOwnProperty('IS_PAGE_EDITABLE')) 
            fields.setIsPageEditable(kpiConfig.IS_PAGE_EDITABLE.toLowerCase() === 'true');
        // eslint-disable-next-line
    }, [kpiConfig]);
}