import React from 'react';

import { RowSeparator, CheckBox } from "../Common";

const YammerPost = ({ fields }) => {
	const yammerPostProps = {
		label: 'Post to Viva Engage',
		value: fields.postToYammer,
		setValue: fields.setPostToYammer,
		disabled: fields.isUpdate,
	}

	return (
		<div>
			<RowSeparator />
			<CheckBox {...yammerPostProps} />
		</div>
	);
}

export default YammerPost;