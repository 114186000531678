import React from 'react';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import VerticalDivider from '@nokia-csf-uxr/ccfk/VerticalDivider';

import StatusBar, {
    StatusBarSection,
    StatusBarTextTitle
} from '@nokia-csf-uxr/ccfk/StatusBar';

import { CircularLoading } from "../../Components/Common";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AnchorMultiDirectionIcon from '@nokia-csf-uxr/ccfk-assets/latest/AnchorMultiDirectionIcon';
import RefreshIcon from '@nokia-csf-uxr/ccfk-assets/latest/RefreshIcon';
import FileCsvIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FileCsvIcon';

import '../../Styles/DataTable.css';

const DataTableToolBar = ({ title, onFilterRemove, onRefresh, onExcelFile, onAdjustColumns, additionalComponents, isFetchingCSV }) => {

    return (
        <StatusBar className="toolbar-container">
            <StatusBarSection>
                <StatusBarTextTitle>
                    <Typography className="toolbar-title">{title}</Typography>
                </StatusBarTextTitle>
            </StatusBarSection>
            <StatusBarSection className="toolbar-content">
            {
                additionalComponents && additionalComponents
            }
            </StatusBarSection>
            <StatusBarSection className="toolbar-buttons">
                <VerticalDivider />
                {
                    onFilterRemove &&
                    <Tooltip placement="bottom" tooltip="Remove Filters" >
                        <IconButton onClick={onFilterRemove}>
                            <FilterAltOffIcon style={{ color: 'var(--g-color-global-secondary-content)' }} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    onAdjustColumns &&
                    <Tooltip placement="bottom" tooltip="Fit columns" >
                        <IconButton onClick={onAdjustColumns}>
                            <AnchorMultiDirectionIcon />
                        </IconButton>
                    </Tooltip>
                }
                {
                    onRefresh && 
                    <Tooltip placement="bottom" tooltip="Refresh table" >
                        <IconButton onClick={onRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                }
                {
                    onExcelFile &&
                    <>
                        {
                            !isFetchingCSV &&
                            <Tooltip placement="bottom" tooltip="Download CSV" >
                                <IconButton onClick={onExcelFile}>
                                    <FileCsvIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            isFetchingCSV &&
                            <CircularLoading size={28} />
                        }
                    </>
                }
            </StatusBarSection>
        </StatusBar>
    );

}

export default DataTableToolBar;