import React, { forwardRef, useImperativeHandle } from 'react';
import { MODES, LAB_TYPES, LAB_MODES, LAB_STATES, useLabResourcesTextFields } from '../../../CustomHooks/LabResources';

import {
    OverlayPanel,
    Column,
    Row,
    RowSeparator,
    SelectAutoFill,
    TextInput,
    ToggleButtonGroup,
} from "../../Common";
import Credentials from './Credentials';
import Materials from './Materials';
import LabDuration from './LabDuration';

const MAX_TEMPLATE_WORK_ASSIGNMENTS = 10;

const LabResourcesOverlayPanel = forwardRef((
    { fields, certifications, getActiveResourceData, onCertificationChange, regions, credentialsTypes, messages },
    ref
) => {

    const textFields = useLabResourcesTextFields();

    useImperativeHandle(ref, () => ({
        getTextFields: () => textFields,
    }));

    const fieldsAreValid = () => {
        if (!fields.certification) {
            fields.setCertificationError(true);
            messages.failure("Please choose a Certification...")
            return false;
        }

        if (!fields.labType) {
            fields.setLabTypeError(true);
            messages.failure("Please select a Lab Type...")
            return false;
        }

        const cloudEnvIdIsValid = /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/.test(textFields.cloudEnvId);
        if (fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE && (!textFields.cloudEnvId || !cloudEnvIdIsValid)) {
            textFields.setCloudEnvIdError(true);
            messages.failure("Please set an Enviromnent Catalog ID with valid format...")
            return false;
        }

        if (fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE && !fields.region) {
            fields.setRegionError(true);
            messages.failure("Please choose a Region...")
            return false;
        }

        if (fields.materials.length > MAX_TEMPLATE_WORK_ASSIGNMENTS) {
            messages.failure(`<b>Only ${MAX_TEMPLATE_WORK_ASSIGNMENTS} work assignment materials are supported!</b> Please remove some of them.`)
            return false;
        }

        return true;
    }

    const overlayProps = {
        id: 'lab-res-overlay-panel',
        title: fields.mode === MODES.CREATE ? 'New lab resource' : 'LAB: ' + fields.currentLabResourceData.labName,
        visible: fields.overlaypanelVisible,
        setVisible: fields.setOverlayPanelVisible,
        elevationIndex: 4,
        width: '35vw',
        cancelButtonProps: {
            label: 'Cancel'
        },
        actionButtonProps: {
            label: fields.mode,
            onClick: () => {
                if (!fieldsAreValid())
                    return;

                if (fields.mode === MODES.EDIT) {
                    messages.areYouSure("Are you sure you want to update the lab resource?");
                    return;
                }

                const resourceData = getActiveResourceData(fields.certification);
                var warningMessage = '';

                if (fields.labMode === LAB_MODES.OPEN_ACCESS) {
                    const openAccessLabExists = resourceData.filter(res => res.isOpenAccess).length > 0;
                    if (openAccessLabExists)
                        warningMessage += "</br><b>Open Access lab already exists for current certification</b>.</br>";
                }

                if (fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE) {
                    const legacyLabs = resourceData.filter(res => res.labType === LAB_TYPES.LEGACY.DB_VALUE);
                    const allResourceLabsAreLegacy = resourceData.length > 0 && legacyLabs.length === resourceData.length;
                    if (allResourceLabsAreLegacy)
                        warningMessage += `
                            </br><b>You are creating a new '${LAB_TYPES.PUBCL_AWS.UI_VALUE}' instance when all other resources associated to the same certification are '${LAB_TYPES.LEGACY.UI_VALUE}'. 
                            This action will update the default user limit attempts to '${LAB_TYPES.PUBCL_AWS.DEFAULT_LIMIT}'</b>.</br>
                        `;
                }

                if (warningMessage)
                    warningMessage = `<b>WARNING:</b> Please be aware of the following note(s):</br>${warningMessage}</br>`;
                messages.areYouSure(`${warningMessage}Are you sure you want to create a new lab resource?`);
            }
        }
    }

    const labNameProps = {
        label: 'Lab Name',
        value: fields.labName,
        setValue: fields.setLabName,
        disabled: true,
        showClearButton: false,
        placeholder: 'Choose a certification and lab type to generate the Lab Name...'
    }

    const certificationProps = {
        id: 'lab-res-overlay-panel-certifications',
        label: 'Certification *',
        values: fields.certification,
        setValues: fields.setCertification,
        data: {
            values: certifications,
            mapping: {
                value: 'courseDisplayName'
            }
        },
        placeholder: 'Choose the certification...',
        onChange: onCertificationChange,
        disabled: fields.mode === MODES.EDIT,
        error: fields.certificationError,
        setError: fields.setCertificationError
    }

    const labTypesProps = {
        id: 'lab-res-overlay-panel-lab-types',
        label: 'Lab Type *',
        values: fields.labType,
        setValues: fields.setLabType,
        data: {
            values: [{ type: LAB_TYPES.LEGACY.UI_VALUE }, { type: LAB_TYPES.PUBCL_AWS.UI_VALUE }],
            mapping: {
                value: 'type'
            }
        },
        placeholder: 'Choose the lab type...',
        disabled: fields.mode === MODES.EDIT,
        error: fields.labTypeError,
        setError: fields.setLabTypeError
    }

    const credentialsProps = {
        fields: fields,
        credentialsTypes: credentialsTypes
    }

    const cloudEnvIdProps = {
        label: 'Cloud Env Catalog ID *',
        value: textFields.cloudEnvId,
        setValue: textFields.setCloudEnvId,
        placeholder: 'Set the Cloud Env Catalog ID...',
        error: textFields.cloudEnvIdError,
        setError: textFields.setCloudEnvIdError,
    }

    const regionProps = {
        label: 'Region *',
        values: fields.region,
        setValues: fields.setRegion,
        data: {
            values: regions,
            mapping: {
                value: 'name'
            }
        },
        placeholder: 'Choose the region...',
        error: fields.regionError,
        setError: fields.setRegionError
    }

    const labDurationProps = {
        fields: fields
    }

    const materialsProps = {
        fields: fields
    }

    const labModeProps = {
        label: 'Lab Mode *',
        buttonProps: [
            {
                value: LAB_MODES.WEEKLY,
                label: LAB_MODES.WEEKLY,
            },
            {
                value: LAB_MODES.OPEN_ACCESS,
                label: LAB_MODES.OPEN_ACCESS,
            }
        ],
        value: fields.labMode,
        setValue: fields.setLabMode,
        disabled: fields.mode === MODES.EDIT
    }

    const labStateProps = {
        label: 'Lab State *',
        buttonProps: [
            {
                value: LAB_STATES.ACTIVE,
                label: LAB_STATES.ACTIVE,
            },
            {
                value: LAB_STATES.INACTIVE,
                label: LAB_STATES.INACTIVE,
            }
        ],
        value: fields.labState,
        setValue: fields.setLabState,
    }

    return (
        <OverlayPanel {...overlayProps}>
            <Column>
                <Row>
                    <Column>
                        <TextInput {...labNameProps} />
                    </Column>
                </Row>
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
                <Row>
                    <Column>
                        <SelectAutoFill {...certificationProps} />
                    </Column>
                    <Column flex={0.4}>
                        <SelectAutoFill {...labTypesProps} />
                    </Column>
                </Row>
                {
                    fields.labType === LAB_TYPES.LEGACY.UI_VALUE &&
                    <>
                        <RowSeparator />
                        <RowSeparator />
                        <Row>
                            <Column>
                                <Credentials {...credentialsProps} />
                            </Column>
                        </Row>
                    </>
                }
                {
                    fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE &&
                    <>
                        <RowSeparator />
                        <RowSeparator />
                        <Row>
                            <Column>
                                <TextInput {...cloudEnvIdProps} />
                            </Column>
                        </Row>
                        <RowSeparator />
                        <RowSeparator />
                        <RowSeparator />
                        <RowSeparator />
                        <Row>
                            <Column>
                                <SelectAutoFill {...regionProps} />
                            </Column>
                        </Row>
                        <RowSeparator />
                        <RowSeparator />
                        <Row>
                            <Column>
                                <LabDuration {...labDurationProps} />
                            </Column>
                        </Row>
                    </>
                }
                <RowSeparator />
                <RowSeparator />
                <Row>
                    <Column>
                        <Materials {...materialsProps} />
                    </Column>
                </Row>
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
                <Row>
                    <Column>
                        <ToggleButtonGroup {...labModeProps} />
                    </Column>
                </Row>
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
                <Row>
                    <Column>
                        <ToggleButtonGroup {...labStateProps} />
                    </Column>
                </Row>
            </Column>
        </OverlayPanel>
    );
});

export default LabResourcesOverlayPanel;