import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ChevronDownIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronDownIcon';
import ChevronUpIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChevronUpIcon';
import SideDrawer, { SideDrawerList, SideDrawerItem, SideDrawerItemDivider } from '@nokia-csf-uxr/ccfk/SideDrawer';
import KEY_CODES from '@nokia-csf-uxr/ccfk/common/KeyCodes';
import { CircularLoading } from '../Common/Loading';

import { useLeftMenuIndexes } from '../../CustomHooks/PageNavigation';
import { useBlurOnPopState } from '../../CustomHooks/BlurOnPop';
import AppIcons from '../../CustomObjects/AppIcons';

import FiberNewIcon from '@mui/icons-material/FiberNew';

import '../../Styles/LeftMenu.css';

const HighlightedItem = () => <FiberNewIcon style={{ color: 'var(--ff-color-purple-500)' }} />

const LeftSidePanelMenu = (props) => {
    const { sideDrawerRef, isSideDrawerVisible, setIsSideDrawerVisible, leftMenu } = props;
    const NONE_SELECTED_INDEX = -1;
    const isValidKey = event => (event.key === KEY_CODES.ENTER_KEY || event.key === KEY_CODES.SPACE_KEY);
    const navigate = useNavigate();
    useBlurOnPopState();

    const { leftMenuSelectedIndex, leftMenuOpenedGroupIndex, setLeftMenuOpenedGroupIndex } = useLeftMenuIndexes();

    const [highlightedIndexes, setHighlightedIndexes] = useState({ groupIndexes: [], itemIndexes: [] });

    const handleItemClick = item => {
        setHighlightedIndexes({ ...highlightedIndexes, itemIndexes: [...highlightedIndexes.itemIndexes, item.itemIndex]});
        navigate(leftMenu[item.itemIndex].path);
    };

    const itemProps = (item) => {
        const selected = leftMenuSelectedIndex === item.itemIndex;

        let ariaLabel = selected ? `${item.label} selected` : item.label;

        return {
            onKeyPress: (event) => {
                if (isValidKey(event)) {
                    event.preventDefault();
                    handleItemClick(item);
                }
            },
            onClick: () => {
                handleItemClick(item);
            },
            selected,
            'aria-label': ariaLabel
        }
    };

    const groupHeaderProps = groupItem => ({
        onKeyDown: (event) => {
            const toggleGroup = () => {
                leftMenuOpenedGroupIndex === groupItem.groupIndex ? setLeftMenuOpenedGroupIndex(NONE_SELECTED_INDEX) : setLeftMenuOpenedGroupIndex(groupItem.groupIndex);
            };

            if (isValidKey(event)) {
                toggleGroup();
            }
        },
        onClick: () => {
            setHighlightedIndexes({ ...highlightedIndexes, groupIndexes: [...highlightedIndexes.groupIndexes, groupItem.groupIndex] });
            leftMenuOpenedGroupIndex === groupItem.groupIndex ? setLeftMenuOpenedGroupIndex(NONE_SELECTED_INDEX) : setLeftMenuOpenedGroupIndex(groupItem.groupIndex);
        },
        expandableHeaderIndicator: leftMenuOpenedGroupIndex === groupItem.groupIndex ? <ChevronUpIcon /> : <ChevronDownIcon />,
        isOpen: leftMenuOpenedGroupIndex === groupItem.groupIndex,
    });

    const SideDrawerProps = {
        onKeyDown: (event) => {
            event.key === KEY_CODES.ESCAPE && setIsSideDrawerVisible(false);
        },
        visible: isSideDrawerVisible,
        elevationProps: {
            elevationIndex: 4
        }
    };

    if (leftMenu === undefined) {
        return (
            <SideDrawer {...SideDrawerProps}>
                <CircularLoading style={{ width: '48px', height: '48px', margin: 'auto' }} overlay />
            </SideDrawer>
        );
    }
    
    const toDisplay = leftMenu.filter(entry => entry.display && entry.label !== 'Admin');
    const adminDisplay = leftMenu.filter(entry => entry.display && entry.label === 'Admin');

    var handledGroups = [];

    //Heights for left menu entries
    //default: 4.25rem
    //all-slim: 2.125rem
    return (
        <SideDrawer variant="persistent" {...SideDrawerProps} style={{ marginRight: '0.2em' }}>
            <div style={{ maxHeight: adminDisplay.length === 1 ? 'calc(100% - 2.125rem)' : '100%', flex: 0 }}>
                <SideDrawerList variant='all-slim'>
                    {toDisplay.map(entry => {
                        const toHighlight = entry.highlight && !highlightedIndexes.itemIndexes.includes(entry.itemIndex);

                        if (!entry.groupLabel)
                            return <SideDrawerItem
                                className="side-menu-item"
                                key={`menu-item-${entry.itemIndex}`}
                                ref={sideDrawerRef}
                                icon={AppIcons(entry.element)}
                                label={entry.label} 
                                badge={toHighlight ? <HighlightedItem /> : <></>}
                                {...itemProps(entry)}
                            />

                        if (!handledGroups.includes(entry.groupLabel)) {
                            const groupToDisplay = toDisplay.filter(groupEntry => groupEntry.groupLabel === entry.groupLabel);
                            const shouldHighlightGroup = groupToDisplay.some(groupEntry => groupEntry.highlight) && !highlightedIndexes.groupIndexes.includes(entry.groupIndex);
                            handledGroups.push(entry.groupLabel);

                            return (
                                <SideDrawerList key={`group-item-${entry.groupIndex}`} variant='all-slim'>
                                    <SideDrawerItem
                                        className="side-menu-item"
                                        label={entry.groupLabel}
                                        icon={AppIcons(entry.groupElement)}
                                        aria-label="EXPANDABLE GROUP ONE 0 Badges"
                                        badge={shouldHighlightGroup ? <HighlightedItem /> : <></>}
                                        {...groupHeaderProps(entry)}
                                    />
                                    {
                                        groupToDisplay.map(groupEntry => {
                                        const toHighlightSubItem = groupEntry.highlight && !highlightedIndexes.itemIndexes.includes(groupEntry.itemIndex);

                                        return leftMenuOpenedGroupIndex === entry.groupIndex &&
                                            <SideDrawerItem
                                                className="side-menu-item"
                                                key={`menu-item-${groupEntry.itemIndex}`}
                                                label={groupEntry.label}
                                                groupItem
                                                groupHeaderHasIcon
                                                badge={toHighlightSubItem ? <HighlightedItem /> : <></>}
                                                {...itemProps(groupEntry)}
                                            />;
                                    })}
                                </SideDrawerList>
                            );
                        }

                        return null;
                    })}
                </SideDrawerList>
            </div>
            {
                adminDisplay.length === 1 &&
                <div>
                    <SideDrawerList variant='all-slim' key='admin-group-menu'>
                        <SideDrawerItemDivider />
                        <SideDrawerItem
                            key='admin-item-menu'
                            className="side-menu-item"
                            icon={AppIcons(adminDisplay[0].element)}
                            label={adminDisplay[0].label}
                            {...itemProps(adminDisplay[0])}
                        />
                    </SideDrawerList>
                </div>
            }
        </SideDrawer>
    );
}

export default LeftSidePanelMenu;