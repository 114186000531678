import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import CalendarIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarIcon';
import RefreshIcon from '@nokia-csf-uxr/ccfk-assets/latest/RefreshIcon';

export const ScheduleLabButton = ({ onScheduleLabClick }) => {
	
	return (
		<Button variant='call-to-action-bigger-font' onClick={onScheduleLabClick}>
			<ButtonText>Book a Lab</ButtonText>
			<ButtonIcon>
				<CalendarIcon />
			</ButtonIcon>
		</Button>
	);
}

export const RefreshSlotsButton = ({ onUpdateSlotsClick }) => {

	return (
		<div style={{ alignSelf:'end' }}>
			<Button variant='call-to-action-bigger-font' onClick={onUpdateSlotsClick}>
				<ButtonText>Update available slots</ButtonText>
				<ButtonIcon>
					<RefreshIcon />
				</ButtonIcon>
			</Button>
		</div>
	);
}