import React, { useMemo } from 'react';

import ReactHtmlParser from 'html-react-parser';

import Dialog, {
    DialogTitle,
    DialogContent,
    DialogFooter
} from '@nokia-csf-uxr/ccfk/Dialog';
import Button from '@nokia-csf-uxr/ccfk/Button';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import { CircularLoading } from ".";

import InfoCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/InfoCircleFillIcon';
import TickCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/TickCircleFillIcon';
import WarningFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/WarningFillIcon';
import ErrorCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/ErrorCircleFillIcon';

import '../../Styles/Message.css';

const Message = ({ title, message, variant, actionButtonLabel, cancelButtonLabel, open, setOpen, onSubmit, withLoading, withComponent, widthClassOverwrite }) => {

    const MessageIcon = () => {
        if (variant.toUpperCase() === 'SUCCESS')
            return <TickCircleFillIcon color='var(--color-success)' style={{ marginRight: 'var(--spacing-x-small)' }} />;
        else if (variant.toUpperCase() === 'WARNING')
            return <WarningFillIcon color='var(--color-warning)' style={{ marginRight: 'var(--spacing-x-small)' }} />;
        else if (variant.toUpperCase() === 'ERROR')
            return <ErrorCircleFillIcon color='var(--color-danger)' style={{ marginRight: 'var(--spacing-x-small)' }} />;

        return <InfoCircleFillIcon color='var(--color-info)' style={{ marginRight: 'var(--spacing-x-small)' }} />;
    }

    const dialogClasses = useMemo(() => {
        var classes = 'message-container';

        if (message.length > 400 && message.length <= 600)
            classes += ' medium-width';
        else if (message.length > 600)
            classes += ' bigger-width';

        if (widthClassOverwrite)
            classes += ` ${widthClassOverwrite}`;

        return classes;
    }, [message, widthClassOverwrite]);

    return (
        <Dialog variant='dark-custom' isOpen={open} className={dialogClasses}>
            <DialogTitle
                title={ReactHtmlParser(title)}
                icon={<MessageIcon/>}
            />
            <DialogContent>
                <Typography typography="BODY">{ReactHtmlParser(message)}</Typography>
                {
                    withComponent &&
                    <div style={{ marginTop: 'var(--spacing-medium)' }}>
                        { withComponent }
                    </div>
                }
                {withLoading && <CircularLoading overlay style={{ width: '64px', height: '64px', marginLeft: 'auto', marginRight: 'auto', marginTop: 'var(--spacing-medium)' }} /> }
            </DialogContent>
            {
                (cancelButtonLabel || actionButtonLabel) &&
                <DialogFooter>
                    {cancelButtonLabel && <Button variant="secondary-bigger-font" onClick={() => setOpen(false)} autoFocus>{cancelButtonLabel}</Button> }
                    { actionButtonLabel && <Button variant="call-to-action-bigger-font" onClick={onSubmit}>{actionButtonLabel}</Button> }
                </DialogFooter>
            }
        </Dialog>
    );

};

Message.defaultProps = {
    title: '',
    message: '',
    variant: 'info', // success, info, warning, error,
    actionButtonLabel: '',
    cancelButtonLabel: '',
    onSubmit: () => { },
}

export default Message;