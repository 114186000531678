import React from 'react';

import ToggleSwitch, { ToggleSwitchLabelContent } from '@nokia-csf-uxr/ccfk/ToggleSwitch';

import ToggleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleIcon';
import ToggleDisabledIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleDisabledIcon';

import '../../Styles/Toggle.css';

const Toggle = ({ checked, setChecked, checkedText, uncheckedText, onChange, disabled }) => {

	const handleChange = (data) => {
		setChecked(data.value);
		onChange(data.value);
	};

	return (
		<div className='toggle-container'>
			<ToggleSwitchLabelContent className='toggle-label-text' >{checked ? checkedText : uncheckedText}</ToggleSwitchLabelContent>
			<ToggleSwitch
				variant='plain-modified'
				checked={checked}
				onChange={handleChange}
				toggleIcon={checked ? <ToggleIcon /> : <ToggleDisabledIcon />}
				disabled={disabled}
			/>
		</div>
	);
}
Toggle.defaultProps = {
	checked: false,
	setChecked: () => { },
	checkedText: '',
	uncheckedText: '',
	onChange: () => { },
	disabled: false,
}

export default Toggle;