import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/latest/SettingsIcon';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

export const AddKPIPlanningButton = ({ node, fields, getKpiByKpiId, getHeadCountTypeByName }) => {

    const onPlanKPI = (e) => {
        e.stopPropagation();

        if (!node.kpis || node.kpis.length === 0) {
            fields.setKpiTeam(node.T);
            fields.resetKPIDefinitionFields();
        }
        else {
            var kpisData;

            try {
                kpisData = JSON.parse(node.kpis[0].dataJSON);
            }
            catch (e) {
                console.error('Error parsing KPI dataJSON: ', e);
                fields.messageRef.current.errorMessage('Error parsing KPI dataJSON: ' + e);
            }

            fields.setKpiTeam(kpisData.team);
            fields.setYear(kpisData.year);
            
            if (kpisData.hcs && kpisData.hcs.length > 0) {
                const newHeadCountTypes = kpisData.hcs.map(h => ({
                    type: getHeadCountTypeByName(h.headCountType).longName,
                    value: h.headCount,
                    notes: h.notes
                }));

                fields.setHeadCountTypes(newHeadCountTypes);
                fields.setInitialHeadCountTypes(newHeadCountTypes);
            }
            else if (!kpisData.hcs || kpisData.hcs.length === 0) {
                fields.setHeadCountTypes([]);
                fields.setInitialHeadCountTypes([]);
            }

            if (kpisData.kpis && kpisData.kpis.length > 0) {
                const newKpis = kpisData.kpis.map(k => ({
                    kpiName: getKpiByKpiId(k.kpiId).kpiName,
                    headCountType: getHeadCountTypeByName(k.headCountType).longName,
                    percentage: k.headCountPercentage,
                    totalPlanned: k.plannedValueTOTAL,
                    q1: k.plannedValueQ1,
                    q2: k.plannedValueQ2,
                    q3: k.plannedValueQ3,
                    q4: k.plannedValueQ4,
                    notes: k.notes,
                }));

                fields.setKpis(newKpis);
                fields.setInitialKpis(newKpis);
            }
            else if (!kpisData.kpis || kpisData.kpis.length === 0) {
                fields.setKpis([]);
                fields.setInitialKpis([]);
            }

        }

        fields.setOverlayPanelVisible(true);
    }

    return (
        <>
            {
                fields.isPageEditable &&
                <Tooltip placement="top" tooltip="Plan Team KPI" modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}>
                    <IconButton onClick={onPlanKPI}>
                        <SettingsIcon color='var(--ff-color-green-700)' />
                    </IconButton>
                </Tooltip>
            }
            {
                !fields.isPageEditable &&
                <Tooltip placement="top" tooltip="Currently not editable" modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}>
                    <IconButton disabled>
                        <NoAccountsIcon style={{ color: 'var(--ff-color-red-400)' }} />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}