import React from 'react';

import Env from '../../CustomObjects/Environment';

import { usePageHits } from '../../CustomHooks/Monitoring';
import { useLocation } from 'react-router-dom';

import '../../Styles/Module.css';

const ModuleContainer = ({ children }) => {

	const location = useLocation();

	usePageHits(Env.FRONTEND_SERVER_URL + location.pathname + location.search);
	
	return (
		<div className="module-container">
			{children}
		</div>
	);
}

export default ModuleContainer;