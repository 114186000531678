import {
	Column,
	Row,
	RowSeparator,
	OverlayPanel,
	SelectAutoFill,
	CircularLoading,
} from "../../Common";

import ScheduleLabUserStateMessages from "./ScheduleLabUserStateMessages";
import UserLabsSlots from "./UserLabSlots";
import ScheduledSlotDetails from "./ScheduledSlotDetails";

import { useUser, useIsAdmin, useIsRole } from "../../../CustomHooks/Global";
import { MODES, RESERVATION_STATUS } from "../../../CustomHooks/LabScheduler";

import { dateCellRendererLocalizedDayOnly } from "../../../Renderers/DataTable";

const getPreviousSaturday = (date) => {
	const dayOfWeek = date.getDay();
	const diff = (dayOfWeek + 7 - 6) % 7;
	const previousFriday = new Date(date);
	previousFriday.setDate(date.getDate() - diff);
	return previousFriday;
}

const ScheduleLabOverlayPanel = ({ fields, labRoles, labTrainings, updateLabTrainings, getUserSlots, resetLabTrainings }) => {

	const user = useUser();
	const userIsAdmin = useIsAdmin();
	const isLabLead = useIsRole('labLead');

	const userHasAdminPrivileges = userIsAdmin || isLabLead;
	const labIsBooked = fields.currentLabData.status === RESERVATION_STATUS.BOOKED;
	const labIsActive = fields.currentLabData.status === RESERVATION_STATUS.ACTIVE;
	const labIsUserLab = user.email === fields.currentLabData.userEmail;
	const labIsOpenAccess = fields.currentLabData.isOpenAccess;
	const lastSaturdayBeforeBookWeek = getPreviousSaturday(new Date(fields.currentLabData.startDate));
	const currentDateIsAlreadyInBookingWeek = new Date() >= lastSaturdayBeforeBookWeek;

	const isScheduleLab = (fields.mode === MODES.SCHEDULE) && (Object.keys(fields.currentLabData).length === 0)
	const labIsActiveAndExtendable = labIsActive && labIsUserLab && !labIsOpenAccess;

	const labIsCancelable = () => {
		if (!userHasAdminPrivileges && !labIsUserLab)
			return false;

		if (!labIsBooked && !labIsActive)
			return false;

		if (labIsOpenAccess)
			return true;

		if (!labIsOpenAccess && labIsBooked && !currentDateIsAlreadyInBookingWeek)
			return true;

		return false;
	}


	const overlayProps = {
		title: fields.mode === MODES.SCHEDULE ? 'Book A Lab' : fields.currentLabData.courseDisplayName,
		visible: fields.panelVisible,
		setVisible: fields.setPanelVisible,
		elevationIndex: 4,
		width: '35vw',
		cancelButtonProps: {
			show: labIsCancelable(),
			variant: 'critical-bigger-font',
			label: 'Release Lab',
			onClick: () => {
				fields.setMode(MODES.RELEASE);
				fields.messageRef.current.showAreYouSureMessage(`
					<b>Are you sure you want delete this reservation?</b><br/><br/>
					<b>Certification:</b> ${fields.currentLabData.courseDisplayName}<br/>
					${fields.currentLabData.isOpenAccess ?
						"<br/><b>Open Access Lab</b><br/><br/>" :
						`
						<b>Start:</b> ${dateCellRendererLocalizedDayOnly({ value: fields.currentLabData.startDate })}<br/>
						<b>End:</b> ${dateCellRendererLocalizedDayOnly({ value: fields.currentLabData.endDate })}<br/><br/>
						`
					}
				`);
			}
		},
		actionButtonProps: {
			id: isScheduleLab ? "lab-scheduler-reserve-slot" : "lab-scheduler-extend-slot",
			label: isScheduleLab ? "Reserve Slot" : "Extend Slot",
			show: isScheduleLab || labIsActiveAndExtendable,
			onClick: () => {
				if (isScheduleLab) {
					if (!fields.anySlotWasSelected()) {
						fields.messageRef.current.errorMessage("<b>Please choose a free slot</b> in order to book a lab.");
						return;
					}

					fields.setMode(MODES.SCHEDULE);
					fields.messageRef.current.showAreYouSureMessage(`
						<b>Your reservation details:</b><br/><br/>
						<b>Certification:</b> ${fields.selectedUserSlot.courseDisplayName ?? fields.selectedTestSlot.courseDisplayName}<br/>
						${
						fields.selectedUserSlot.isOpenAccess || fields.selectedTestSlot.isOpenAccess ?
						"<br/><b>Open Access Lab</b><br/><br/>" :
						`
						<b>Start:</b> ${dateCellRendererLocalizedDayOnly({ value: fields.selectedUserSlot.startDate ?? fields.selectedTestSlot.startDate })}<br/>
						<b>End:</b> ${dateCellRendererLocalizedDayOnly({ value: fields.selectedUserSlot.endDate ?? fields.selectedTestSlot.endDate })}<br/><br/>
						` 
						}
						${
						Object.keys(fields.selectedTestSlot).length > 0 ? "<b>WARNING: Selected slot is for testing porpuses only</b><br/><br/>" : ""
						}
						<b>Are you sure you want to proceed?</b>
					`);
				}
				else if (labIsActiveAndExtendable) {
					const newStartDate = new Date(fields.currentLabData.startDate);
					const newEndDate = new Date(fields.currentLabData.endDate);

					fields.setMode(MODES.EXTEND);
                    fields.messageRef.current.showAreYouSureMessage(`
                        <b>Are you sure you want to extend this reservation one more week?</b><br/><br/>
                        <b>Certification:</b> ${fields.currentLabData.courseDisplayName}<br/>
                        
                        <b>Start:</b> ${dateCellRendererLocalizedDayOnly({ value: newStartDate.setDate(newStartDate.getDate() + 7) })}<br/>
                        <b>End:</b> ${dateCellRendererLocalizedDayOnly({ value: newEndDate.setDate(newEndDate.getDate() + 7) })}<br/><br/>
						<b>WARNING:</b> Please be aware that it may not be possible to extend the reservation if the same lab resource is already booked 
						for the next week.
                    `);
                }
			}
		},
	}

	const roleProps = {
		values: fields.role,
		setValues: fields.setRole,
		data: {
			values: labRoles,
			mapping: {
				value: "courseRole"
			}
		},
		label: 'Role*',
		placeholder: 'Choose the role...',
		disabled: !labTrainings,
		onChange: () => {
			resetLabTrainings();

			fields.resetMessages();
			fields.resetTraining();
			fields.resetUserSlots();
			fields.resetTestSlots();
			fields.resetSelectedUserSlot();
			fields.resetSelectedTestSlot();
			if (fields.role)
				updateLabTrainings(fields.role);
		}
	}

	const trainingProps = {
		values: fields.training,
		setValues: fields.setTraining,
		data: {
			values: labTrainings,
			mapping: {
				value: "courseDisplayName"
			}
		},
		label: 'Training*',
		placeholder: 'Choose the training...',
		onChange: () => {
			fields.resetMessages();
			fields.resetUserSlots();
			fields.resetTestSlots();
			fields.resetSelectedUserSlot();
			fields.resetSelectedTestSlot();
			if (fields.role && fields.training)
				getUserSlots(fields.training)
		}
	}
	
	return (
		<OverlayPanel {...overlayProps}>
			<Column>
				{
					fields.mode === MODES.SCHEDULE &&
					<>
						<RowSeparator />
						<RowSeparator />
						<Row>
							<Column flex={0.5}>
								<SelectAutoFill {...roleProps} />
							</Column>
							<Column>
								<SelectAutoFill {...trainingProps} />
							</Column>
						</Row>
						{
							fields.isLoadingSlots &&
							<>
								<RowSeparator />
								<RowSeparator />
								<RowSeparator />
								<RowSeparator />
								<CircularLoading
									size={48}
								/>
							</>
						}
						{
							!fields.isLoadingSlots &&
							<>
								<RowSeparator />
								<RowSeparator />
								<ScheduleLabUserStateMessages fields={fields} />
								<RowSeparator />
								<UserLabsSlots
									fields={fields}
									onRefreshSlots={() => {
										fields.resetUserSlots();
										fields.resetTestSlots();
										fields.resetSelectedUserSlot();
										fields.resetSelectedTestSlot();
                                        fields.resetMessages();
                                        getUserSlots(fields.training);
                                    }}
								/>
							</>
						}

					</>
				}
				{
					(fields.mode === MODES.EDIT || fields.mode === MODES.RELEASE || fields.mode === MODES.EXTEND) &&
					<ScheduledSlotDetails data={fields.currentLabData} />
				}
			</Column>
		</OverlayPanel>
	);


}

export default ScheduleLabOverlayPanel;