
import React from 'react';

import Card from '@nokia-csf-uxr/ccfk/Card';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import ImportIcon from '@nokia-csf-uxr/ccfk-assets/latest/ImportIcon';

import Env from "../CustomObjects/Environment";
import { useFetch } from "../CustomHooks/Fetch";

import {
	ModuleContainer,
	ModuleHeader
} from "../Components/Common";

import '../Styles/WeTube.css';

const WeTube = () => {

	const [weTubeData] = useFetch(Env.BACKEND_SERVER_URL + 'WeTube', true);
	
	const videoCards = weTubeData && weTubeData.map(function (v) {
		return (
			<Card
				style={{ padding: 'var(--spacing-x-small)', height: 240, width: 305 }}
			>
				<a href={v["videoLink"]} target="blank">
					<img src={require('../Images/WeTube/' + v['videoImage'])} alt={v['videoDescription']} style={{ width: "290px", height: "170px" }} />
				</a>
				<div class='wetube-card-content'>
					<Typography typography="LABEL" style={{ margin: '10px 0px 10px 0px', color: 'var(--g-color-global-secondary-text)' }}>{v['videoName']}</Typography>
					{v["materialsLink"] && <a href={v["materialsLink"]} target="blank"><ImportIcon /></a>}
				</div>
			</Card>
		);
	});

	return (
		<ModuleContainer>
			<ModuleHeader
				backgroundPath={require('../Images/BannerBackgrounds/banner-background-2.jpg')}
				backgroundPosition={"50% 84%"}
				titleColor={"var(--ff-color-brown-100)"}
			>
				{"WeTube"}
			</ModuleHeader>
			<div class='wetube-content'>
				{ videoCards }
			</div>
		</ModuleContainer>
	);
}

export default WeTube;
