
import {
	Row,
	ItemsList,
	RowSeparator,
} from "../../Common";

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import RefreshIcon from '@nokia-csf-uxr/ccfk-assets/latest/RefreshIcon';

const UserLabsSlots = ({ fields, onRefreshSlots }) => {
	return (
		<>
			{
				fields.userSlots && fields.userSlots.length > 0 &&
				<Row>
					<ItemsList
						title={
							<div style={{ display: 'flex' }}>
								<Typography typography="TITLE_16" style={{ color: 'var(--ff-color-nokia-blue-500)' }}>Available Slots</Typography>
								<Tooltip placement="top" tooltip="Refresh Slots" modifiers={[{ name: 'offset', options: { offset: [0, 0] } }]}>
									<IconButton onClick={onRefreshSlots}>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</div>
						}
						items={fields.userSlots}
						selectedItem={fields.selectedUserSlot}
						setSelectedItem={fields.setSelectedUserSlot}
						onClick={() => fields.resetSelectedTestSlot()}
					/>
				</Row>
			}
			{
				fields.testSlots && fields.testSlots.length > 0 &&
				<>
					<RowSeparator/>
					<Row>
						<ItemsList
							title={
								<div style={{ display: 'flex' }}>
									<Typography typography="TITLE_16" style={{ color: 'var(--ff-color-nokia-blue-500)' }}>Test Slots</Typography>
								</div>
							}
							items={fields.testSlots}
							selectedItem={fields.selectedTestSlot}
							setSelectedItem={fields.setSelectedTestSlot}
							onClick={() => fields.resetSelectedUserSlot()}
						/>
					</Row>
				</>
			}
		</>
	);
}

export default UserLabsSlots;
