
import {
	Row,
	RowSeparator,
} from "../../Common";

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import "../../../Styles/ScheduleLabOverlayPanel.css";

//import ActiveIcon from '@nokia-csf-uxr/ccfk-assets/latest/ActiveIcon';
import WarningFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/WarningFillIcon';
import ErrorCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/ErrorCircleFillIcon';

const ScheduleLabUserStateMessages = ({ fields }) => {
	return (
		<>
			{
				//fields.statusMessages && fields.statusMessages.length > 0 &&
				//<>
				//	{
				//		fields.statusMessages.map((m, i) =>
				//			<Row key={'status-' + i}>
				//				<div className='schedule-lab-message-container'>
				//					<ActiveIcon color='var(--ff-color-green-600)' />
				//					<Typography className='schedule-lab-status-message'>{m}</Typography>
				//				</div>
				//			</Row>
				//		)
				//	}
				//	<RowSeparator />
				//</>
			}
			{
				fields.warningMessages && fields.warningMessages.length > 0 &&
				<>
					{
						fields.warningMessages.map((m, i) =>
							<Row key={'warning-' + i}>
								<div className='schedule-lab-message-container'>
									<WarningFillIcon color='var(--ff-color-yellow-500)' />
									<Typography className='schedule-lab-warning-message'>{m}</Typography>
								</div>
							</Row>
						)
					}
					<RowSeparator />
				</>
			}
			{
				fields.errorMessages && fields.errorMessages.length > 0 &&
				<>
					{
						fields.errorMessages.map((m, i) =>
							<Row key={'error-' + i}>
								<div className='schedule-lab-message-container'>
									<ErrorCircleFillIcon color='var(--ff-color-red-600)' />
									<Typography className='schedule-lab-error-message'>{m}</Typography>
								</div>
							</Row>
						)
					}
					<RowSeparator />
				</>
			}
		</>
	);
}

export default ScheduleLabUserStateMessages;
