import React from 'react';

import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';

import { ColumnSeparator } from "../Common";

import '../../Styles/KTSessionsOverlayPanel.css';

const ChipLegend = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<Chip type='chip' size='small' className='invited-participated'>
				<ChipLabel label='Participated' />
			</Chip>
			<ColumnSeparator />
			<Chip type='chip' size='small' className='invited-accepted'>
				<ChipLabel label='Accepted' />
			</Chip>
			<ColumnSeparator />
			<Chip type='chip' size='small' className='invited-tentative'>
				<ChipLabel label='Tentative' />
			</Chip>
			<ColumnSeparator />
			<Chip type='chip' size='small' className='invited-declined'>
				<ChipLabel label='Declined' />
			</Chip>
			<ColumnSeparator />
			<Chip type='chip' size='small'>
				<ChipLabel label='No Response' />
			</Chip>
		</div>
	);
}

export default ChipLegend;