
import React, { useRef, useState } from 'react';

import SplitScreen, { Pane } from "@nokia-csf-uxr/ccfk/SplitScreen";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import { usePowerSearchConfiguration, CONFIG_MODES, SUBMIT_MODES } from '../../CustomHooks/PowerSearchConfiguration';
import ConfigurationSections from '../../Components/PowerSearch/ConfigurationSections';
import SystemSection from '../../Components/PowerSearch/SystemSection';
import NewUserConfigMessage from '../../Components/PowerSearch/NewUserConfigMessage';
import { MessageBundle, SelectAutoFill, Toggle } from '../../Components/Common';

import logo from '../../Images/Logo/psx_logo_red_800.png';

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/latest/SettingsIcon';
import AddCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddCircleFillIcon';
import RefreshIcon from '@nokia-csf-uxr/ccfk-assets/latest/RefreshIcon';
import DeleteForeverIcon from '@nokia-csf-uxr/ccfk-assets/latest/DeleteForeverIcon';
import CopyIcon from '@nokia-csf-uxr/ccfk-assets/latest/CopyIcon';

import PublishIcon from '@mui/icons-material/Publish';

import '../../Styles/PowerSearchX.css';

const PowerSearchXConfiguration = () => {

    const messageRef = useRef(null);
    const newUserTargetRef = useRef(null);

    const [configUserTarget, setConfigUserTarget] = useState('');
    const [newUserTargetMessageVisible, setNewUserTargetMessageVisible] = useState(false);
    const [configMode, setConfigMode] = useState('');
    const [configActive, setConfigActive] = useState(true);
    const [submitMode, setSubmitMode] = useState(null);

    const {
        configurationWasLoaded,
        getConfiguration,
        getConfigurationFromDB,
        updateConfiguration,
        submitConfiguration,
        submitConfigurationWithDetails,
        getConfigurationTypeData,
        configurationUserEmails
    } = usePowerSearchConfiguration(messageRef, configMode, setConfigMode, configActive, setConfigActive, submitMode, setSubmitMode, configUserTarget, setConfigUserTarget);

    const changeConfig = () => {
        if (configUserTarget === CONFIG_MODES.GLOBAL) {
            setConfigActive(true);
            setConfigMode(CONFIG_MODES.GLOBAL);
            getConfigurationFromDB(CONFIG_MODES.GLOBAL);
            messageRef.current.showProcessingDiag();
        }
        else if (configUserTarget !== '') {
            setConfigMode(CONFIG_MODES.USER);
            getConfigurationFromDB(CONFIG_MODES.USER);
            messageRef.current.showProcessingDiag();
        }
    }

    return (
        <div className='psllm-module-container'>
            <div className='psllm-top-section-container'>
                <img src={logo} alt='Power Search X Configuration' className='psllm-logo' />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                <div style={{ width: '20em'}}>
                    <SelectAutoFill
                        label='Target Configuration'
                        values={configUserTarget}
                        setValues={setConfigUserTarget}
                        placeholder='Select User Target...'
                        data={{
                            mapping: { value: "createdBy" },
                            values: configurationUserEmails,
                        }}
                        hideClearButton
                        onChange={changeConfig}
                    />
                </div>
                <div>
                    <Tooltip tooltip='Add User Configuration From Current Configuration' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
                        <IconButton onClick={() => {
                            setNewUserTargetMessageVisible(true);
                        }}>
                            <AddCircleFillIcon color='var(--ff-color-nokia-blue-500)' />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip tooltip='Refresh Current Configuration' placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}>
                        <IconButton onClick={() => {
                            getConfigurationFromDB(configMode);
                            messageRef.current.showProcessingDiag();
                        }}>
                            <RefreshIcon color='var(--ff-color-nokia-blue-500)' />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{width: '6em', marginBottom: '0.5em'}}>
                    <Toggle
                        checked={configActive}
                        setChecked={setConfigActive}
                        checkedText='Active'
                        uncheckedText='Active'
                        disabled={configMode === CONFIG_MODES.GLOBAL}
                    />
                </div>
                
            </div>
            <div className='psllm-config-container'>
                <SplitScreen
                    data-test="splitScreen"
                    defaultSplitRatio={80}
                    paneRatio={{ minRatio: 45, maxRatio: 55 }}
                    onDragStarted={() => console.log('onDragStarted callback invoked')}
                    onDragFinished={() => console.log('onDragFinished callback invoked')}
                    onChange={() => console.log('onChange callback is being invoked')}
                >
                    <Pane>
                        <SystemSection
                            configurationWasLoaded={configurationWasLoaded}
                            getConfiguration={getConfiguration}
                            updateConfiguration={updateConfiguration}
                        />
                    </Pane>
                    <Pane>
                        <ConfigurationSections
                            configurationWasLoaded={configurationWasLoaded}
                            getConfiguration={getConfiguration}
                            updateConfiguration={updateConfiguration}
                            getConfigurationTypeData={getConfigurationTypeData}
                            messageRef={messageRef}
                        />
                    </Pane>
                </SplitScreen>
                <div className='psllm-config-buttons'>
                    {
                        configMode === CONFIG_MODES.USER &&
                        <Button
                            variant='secondary-bigger-font'
                            onClick={() => {
                                messageRef.current.showAreYouSureMessage(
                                    `
                                    Are you sure you want to commit the current user configuration as <b>GLOBAL</b>?
                                    </br>
                                    </br>
                                    <b>WARNING:</b> This will overwrite the current <b>GLOBAL</b> configuration and <b>disable</b> the user configuration.
                                    `
                                );
                                setSubmitMode(SUBMIT_MODES.COMMIT_TO_GLOBAL);
                            }}
                        >
                            <ButtonText>Commit to 'Global'</ButtonText>
                            <ButtonIcon>
                                <PublishIcon />
                            </ButtonIcon>
                        </Button>
                    }
                    {
                        configMode === CONFIG_MODES.USER &&
                        <Button
                            variant='secondary-bigger-font'
                            onClick={() => {
                                messageRef.current.showAreYouSureMessage(
                                    `
                                    Are you sure you want to overwrite user configuration with the current <b>GLOBAL</b> configuration?
                                    `
                                );
                                setSubmitMode(SUBMIT_MODES.COPY_FROM_GLOBAL);
                            }}
                        >
                            <ButtonText>Copy from 'Global'</ButtonText>
                            <ButtonIcon>
                                <CopyIcon />
                            </ButtonIcon>
                        </Button>
                    }
                    <Button
                        variant='call-to-action-bigger-font'
                        onClick={() => {
                            messageRef.current.showAreYouSureMessage('Are you sure you want to submit the current configuration?');
                            setSubmitMode(SUBMIT_MODES.COMMIT_GENERAL);
                        }}
                    >
                        <ButtonText>Update Config</ButtonText>
                        <ButtonIcon>
                            <SettingsIcon />
                        </ButtonIcon>
                    </Button>
                    {
                        configMode === CONFIG_MODES.USER &&
                        <Button
                            variant='critical-bigger-font'
                            onClick={() => {
                                messageRef.current.showAreYouSureMessage(
                                    `
                                    Are you sure you want to delete the current user configuration?
                                    </br>
                                    </br>
                                    <b>WARNING:</b> This action is irreversible.
                                    `
                                );
                                setSubmitMode(SUBMIT_MODES.DELETE_USER_CONFIG);
                            }}
                        >
                            <ButtonText>Delete Config</ButtonText>
                            <ButtonIcon>
                                <DeleteForeverIcon />
                            </ButtonIcon>
                        </Button>
                    }
                </div>
            </div>
            <MessageBundle
                ref={messageRef}
                title='Power Search X'
                onSubmitAreYouSure={submitConfiguration}
            />
            <NewUserConfigMessage
                ref={newUserTargetRef}
                visible={newUserTargetMessageVisible}
                setVisible={setNewUserTargetMessageVisible}
                configurationUserEmails={configurationUserEmails}
                setConfigUserTarget={setConfigUserTarget}
                submitConfigurationWithDetails={submitConfigurationWithDetails}
            />
        </div>
    );
}

export default PowerSearchXConfiguration;
