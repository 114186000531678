import React from 'react';

import dayjs from 'dayjs';

import '../Styles/Login.css';

import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Button, { ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import App from '@nokia-csf-uxr/ccfk/App';
import AdvancedTheme from '@nokia-csf-uxr/ccfk/AdvancedTheme';
import Env from "../CustomObjects/Environment";

import { useMsalProps } from "../CustomHooks/Msal";
//import defaultTheme from "../Themes/CCFK-default-theme.json";
import defaultTheme from "@nokia-csf-uxr/ccfk/AdvancedTheme/themes/CCFK-light-theme.json";

import loginBackground1 from '../Images/LoginBackgrounds/Hand_Holding_Chip_Amplifier_16x9.jpg';
import loginBackground2 from '../Images/LoginBackgrounds/Hummingbird_Amplifier_16x9.jpg';
import loginBackground3 from '../Images/LoginBackgrounds/Man_with_VR_Headset_Amplifier_16x9.jpg';
import loginBackground4 from '../Images/LoginBackgrounds/Robot_arm_Hydroponics_Amplifier_16x9.jpg';
import loginBackground5 from '../Images/LoginBackgrounds/Robot_Frog_Amplifier_16x9.jpg';
import loginBackground6 from '../Images/LoginBackgrounds/Safety_Glasses_Close-up_Amplifier_16x9.jpg';
import loginBackground7 from '../Images/LoginBackgrounds/Scientists_Hydroponics_Amplifier_16x9.jpg';
import loginBackground8 from '../Images/LoginBackgrounds/Touchscreen_Amplifier_16x9.jpg';

//import logo from '../Images/Logo/Nokia_logo_RGB_White.png';

const RIGHT = 'right';
const LEFT = 'left';

const imagesData = [
	{
		image: loginBackground1,
		logoAlignment: LEFT,
		titleAlignment: LEFT,
		bodyAlignment: LEFT,
		footerAlignment: RIGHT
	},
	{
		image: loginBackground2,
		logoAlignment: LEFT,
		titleAlignment: LEFT,
		bodyAlignment: LEFT,
		footerAlignment: LEFT
	},
	{
		image: loginBackground3,
		logoAlignment: RIGHT,
		titleAlignment: RIGHT,
		bodyAlignment: RIGHT,
		footerAlignment: RIGHT
	},
	{
		image: loginBackground4,
		logoAlignment: RIGHT,
		titleAlignment: RIGHT,
		bodyAlignment: RIGHT,
		footerAlignment: RIGHT
	},
	{
		image: loginBackground5,
		logoAlignment: LEFT,
		titleAlignment: LEFT,
		bodyAlignment: LEFT,
		footerAlignment: LEFT
	},
	{
		image: loginBackground6,
		logoAlignment: RIGHT,
		titleAlignment: RIGHT,
		bodyAlignment: RIGHT,
		footerAlignment: RIGHT
	},
	{
		image: loginBackground7,
		logoAlignment: LEFT,
		titleAlignment: LEFT,
		bodyAlignment: LEFT,
		footerAlignment: LEFT
	},
	{
		image: loginBackground8,
		logoAlignment: RIGHT,
		titleAlignment: RIGHT,
		bodyAlignment: RIGHT,
		footerAlignment: RIGHT
	},
];

const selected = imagesData[Math.floor(Math.random() * imagesData.length)];

const Login = () => {
	const isTestServer = Env.FRONTEND_SERVER_URL === Env.NKC_TEST_SERVER_URL;

	const { instance } = useMsalProps();

	const login = () => {
		const loginRequest = {
			scopes: ["User.Read"]
		};
		instance.loginRedirect(loginRequest);
	}

	return (
		<AdvancedTheme advancedTheme={defaultTheme} >
			<App>
				<div className="image-background" style={{ backgroundImage: `url(${selected.image})` }}></div>

				<div className="login-container">
					{/*<div className="login-logo">*/}
					{/*	<div className="logo-image" style={{ backgroundImage: `url(${logo})`, backgroundPosition: selected.logoAlignment }}></div>*/}
					{/*</div>*/}
					<div className="login-title" >
						<Typography style={{ textAlign: selected.titleAlignment }}>
							Welcome to the<br />Nokia Knowledge Center<br />{isTestServer && 'TEST SERVER'}
						</Typography>
					</div>
					<div className="login-body" style={{ justifyContent: selected.bodyAlignment }}>
						<Button className="login-button" variant="call-to-action" onClick={login}>
							<ButtonText className="login-button-text">SSO Sign In</ButtonText>
						</Button>
					</div>
					<div className="login-footer">
						<Typography style={{ textAlign: selected.footerAlignment }} variant="default">&copy; {dayjs().year()} Nokia. CNS KMCD Development Team</Typography>
					</div>
				</div>
			</App>
		</AdvancedTheme>
	);
}

export default Login;