import React, { useMemo } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import { default as ToggleButtonGroupMUI } from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import { TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';

const ToggleButtonGroup = ({ label, buttonProps, onChange, value, setValue, disabled }) => {

    const onToggleChange = (_, newValue) => {
        if (newValue === null) return;
        onChange(newValue);
        setValue(newValue);
    };

    const currentBtnProps = useMemo(() =>
        buttonProps && buttonProps.length > 0 ? buttonProps.find(btn => btn.value === value) : null
    , [buttonProps, value]);
    
    const StyledToggleButtonGroup = styled(ToggleButtonGroupMUI)(() => ({
        '&.MuiToggleButtonGroup-root': {
            height: '2.3rem'
        },
        '& .MuiToggleButton-root': {
            color: 'var(--ff-color-grey-700)',
            textTransform: 'initial',
            backgroundColor: 'white',
            margin: '0.2em',
            display: 'flex',
            gap: '0.4em',
            borderRadius: '7px',
            font: 'inherit',
            fontSize: 'var(--font-size-xx-small)',
            '&:hover': {
                backgroundColor: 'var(--ff-color-tone-100)'
            },
            '&.Mui-selected': {
                color: 'var(--ff-color-tone-10)',
                '& svg *': {
                    fill: 'var(--ff-color-tone-10)',
                },
                backgroundColor: currentBtnProps && currentBtnProps.selectedColor ? currentBtnProps.selectedColor : 'var(--ff-color-nokia-blue-500)',
                '&:hover': {
                    backgroundColor: currentBtnProps && currentBtnProps.selectedColor ? currentBtnProps.selectedColor : 'var(--ff-color-nokia-blue-500)',
                },
            },
            '&.Mui-disabled:not(.Mui-selected)': {
                color: 'var(--ff-color-grey-400)',
                backgroundColor: 'var(--ff-color-grey-100)'
            },
            '& span.trigger': {
                display: 'flex',
                gap: '0.4em',
                alignItems: 'center'
            }
        }
    }));
    
    return (
        <>
            {
                label &&
                <TextInputLabelContent>{label}</TextInputLabelContent>
            }
            <StyledToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onToggleChange}
                disabled={disabled}
            >
                {
                    buttonProps && buttonProps.length > 0 &&
                    buttonProps.map((btn, i) => 
                        <ToggleButton key={i} value={btn.value}>
                            {
                                !btn.tooltip &&
                                <>
                                    {btn.label && btn.label}
                                    {btn.icon && btn.icon}
                                </>
                            }
                            {
                                btn.tooltip && 
                                <Tooltip tooltip={btn.tooltip} placement='top' modifiers={[{ name: 'offset', options: { offset: [0, 7] } }]}>
                                    { btn.label && btn.label } 
                                    { btn.icon && btn.icon }
                                </Tooltip>
                            }
                        </ToggleButton>
                    )
                }
            </StyledToggleButtonGroup>
        </>
    );
}
ToggleButtonGroup.defaultProps = {
    buttonProps: [],
    onChange: () => { },
    disabled: false
}


export default ToggleButtonGroup;