
import HomeIcon from '@nokia-csf-uxr/ccfk-assets/legacy/HomeIcon';
import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/legacy/SettingsIcon';
import CalendarIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CalendarIcon';
import CertifiedIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CertifiedIcon';
import AdhocActionIcon from '@nokia-csf-uxr/ccfk-assets/legacy/AdhocActionIcon';
import RackIcon from '@nokia-csf-uxr/ccfk-assets/legacy/RackIcon';
import NokiaCertifiedIcon from '@nokia-csf-uxr/ccfk-assets/legacy/NokiaCertifiedIcon';
import ChartBarIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ChartBarIcon';
import LogListIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LogListIcon';
import ProfileIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ProfileIcon';
import CellIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CellIcon';
import LogSummaryIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LogSummaryIcon';
import ViewListIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ViewListIcon';
import LanguageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LanguageIcon';
import AdminLockIcon from '@nokia-csf-uxr/ccfk-assets/legacy/AdminLockIcon';
import ArrowTriangleRightCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowTriangleRightCircleIcon';
import TicketIcon from '@nokia-csf-uxr/ccfk-assets/legacy/TicketIcon';
import DomainManagementIcon from '@nokia-csf-uxr/ccfk-assets/legacy/DomainManagementIcon';
import MessageIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MessageIcon';
import LaunchpadAdministrationIcon from '@nokia-csf-uxr/ccfk-assets/legacy/LaunchpadAdministrationIcon';
import Icon from '@nokia-csf-uxr/ccfk/Icon';
import HistoryIcon from '@nokia-csf-uxr/ccfk-assets/legacy/HistoryIcon';
import CalendarScheduleIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarScheduleIcon';
import CellClusterIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CellClusterIcon';

const AppIcons = (key, props) => {

    const iconObj = {
        'home': <HomeIcon {...props} />,
        'groupmenu': <SettingsIcon {...props} />,
        'ktsessions': <CalendarIcon {...props} />,
        'certifications': <CertifiedIcon {...props} />,
        'solutionsmanagement': <AdhocActionIcon {...props} />,
        'labs': <RackIcon {...props} />,
        'booklab': <CalendarScheduleIcon {...props} />,
        'kpiplanning': <ChartBarIcon {...props} />,
        'troubletickets': <TicketIcon {...props} />,
        'aim': <CellIcon {...props} />,
        'reports': <LogListIcon {...props} />,
        'certbudgetreport': <ProfileIcon {...props} />, 
        'wetube': <ArrowTriangleRightCircleIcon {...props} />,
        'cnsmasters': <NokiaCertifiedIcon {...props} />,
        'pmat': <DomainManagementIcon {...props} />,
        'documentrepository': <LogSummaryIcon {...props} />,
        'glossary': <ViewListIcon {...props} />,
        'externalresources': <LanguageIcon {...props} />,
        'admin': <AdminLockIcon {...props} />,
        'message': <MessageIcon {...props} />,
        'userroles': <LaunchpadAdministrationIcon {...props} />,
        'powersearch': <Icon {...props} size={32} src={'assets/images/powersearch.svg'} />,
        'powersearchhistory': <HistoryIcon {...props} />,
        'powersearchx': <Icon {...props} size={32} src={'assets/images/psx_logo.svg'} />,
        'powersearchxconfiguration': <CellClusterIcon {...props} />
    }

    return iconObj[key];
}

export default AppIcons;
