import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

import Env from '../../CustomObjects/Environment';

import { useFetchWithInfinite, useFetch } from '../../CustomHooks/Fetch';
import { useFileExport } from '../../CustomHooks/FileExport';

import ModuleBaseObj from '../../CustomObjects/Module';

import { ModuleBase, FloatingPanel, JsonViewer, CircularLoading } from "../../Components/Common";

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import { statusBarRenderer, dateCellRendererLocalized, iconRenderer } from '../../Renderers/DataTable';

import ViewIcon from '@nokia-csf-uxr/ccfk-assets/latest/ViewIcon';
import FileJsonIcon from '@nokia-csf-uxr/ccfk-assets/legacy/FileJsonIcon';

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';

import '../../Styles/PowerSearchHistory.css';

const HistoryDetails = ({ history, detailsVisible, setDetailsVisible }) => {

    return (
        <>
            {
                history && history.length > 0 &&
                <FloatingPanel
                    title={'History Details'}
                    visible={detailsVisible}
                    setVisible={setDetailsVisible}
                    >
                        <div className='history-details-content'>
                            {
                                history.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            {Object.entries(item).map(([key, value]) => {
                                                try {
                                                    const json = JSON.parse(value);
                                                    return (
                                                        <div key={key}>
                                                            <Typography typography="TITLE_16">{key}:</Typography>
                                                            <JsonViewer src={json} />
                                                        </div>
                                                    );
                                                } catch {
                                                    return (
                                                        <div key={key}>
                                                            <Typography typography="TITLE_16">{key}:</Typography>
                                                            <Typography>{value}</Typography>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </div>
                </FloatingPanel>
            }
        </>
    )
}

const FetchJsonButton = ({ fetchHistoryJson, isGeneratingJson }) => {
    return (
        <div>
            {
                isGeneratingJson &&
                <CircularLoading size={28} />
            }
            {
                !isGeneratingJson &&
                <Tooltip placement="bottom" tooltip="JSON Export" >
                    <IconButton onClick={fetchHistoryJson}>
                        <FileJsonIcon />
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
}

const PowerSearchHistory = () => {

    const messageRef = useRef(null);
    const dataTableRef = useRef(null);
    const { downloadJson } = useFileExport();

    const [
        history,
        onGridReady,
        onKeywordChange,
        setFetchHistory,,,
        fetchDataForCSV
    ] = useFetchWithInfinite(
        Env.BACKEND_SERVER_URL + 'PowerSearch/GroupedConversationHistory',
        {},
        'GET',
        null,
        false,
        Env.BACKEND_SERVER_URL + 'PowerSearch/ConversationHistory',
        dataTableRef
        );

    const [historyDetails, setFetchHistoryDetails, , setHistoryDetailsHeaders, , setHistoryDetails] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/ConversationHistoryDetails');
    const [historyJson, setFetchHistoryJson, , setHistoryJsonHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/ConversationHistory');
    const [historyDetailsCache, setHistoryDetailsCache] = useState({});

    const [historyHasBeenUpdatedFromCache, setHistoryHasBeenUpdatedFromCache] = useState(false);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [isGeneratingJson, setIsGeneratingJson] = useState(false);

    const fetchHistoryDetails = useCallback((interactionId) => {
        if (historyDetailsCache.hasOwnProperty(interactionId)) {
            setHistoryDetails(historyDetailsCache[interactionId]);
            setHistoryHasBeenUpdatedFromCache(true);
        } else {
            setHistoryDetailsHeaders({
                interactionId: interactionId
            });
            setFetchHistoryDetails(true);
        }
    }, [historyDetailsCache, setFetchHistoryDetails, setHistoryDetailsHeaders, setHistoryDetails]);

    const fetchHistoryJson = useCallback(() => {
        const filterModels = dataTableRef.current.gridApi().getFilterModel();
        const keywords = dataTableRef.current.searchRef().current.getChips();
        const sortModels = dataTableRef.current.gridApi().getColumnState().filter(col => col.sort);

        setHistoryJsonHeaders({
            sortModels: JSON.stringify({ models: sortModels }),
            keywords: JSON.stringify({ valuesToSearch: keywords }),
            filterModels: JSON.stringify({ models: filterModels })
        });
        setFetchHistoryJson(true);
        setIsGeneratingJson(true);
    }, [setFetchHistoryJson, setHistoryJsonHeaders]);

    useEffect(() => {
        if ((historyDetails && historyDetails.length > 0) || historyHasBeenUpdatedFromCache) {
            if (!historyDetailsCache.hasOwnProperty(historyDetails[0].interactionId))
                setHistoryDetailsCache({ ...historyDetailsCache, [historyDetails[0].interactionId]: historyDetails });
            setDetailsVisible(true);
            setHistoryHasBeenUpdatedFromCache(false);
        }
        // eslint-disable-next-line
    }, [historyDetails, historyHasBeenUpdatedFromCache]);

    useEffect(() => {
        if (!historyJson || historyJson.length === 0)
            return;

        const desiredHistoryTypes = {
            prompt: {
                field: 'user',
                initial: () => '',
                process: (_, columns) => columns.description
            },
            knowledge: {
                initial: () => [],
                process: (knowledgeObjList, columns) => {
                    const newKnowledgeObj = Object.entries(columns).reduce((acc, [column, value]) => {
                        if (column === 'description') {
                            if (value.type === 0)
                                value.type = 'Keyword';
                            else if (value.type === 1)
                                value.type = 'Vector';
                            Object.assign(acc, value);
                        }
                        else if (column === 'createDate') {
                            acc[column] = value
                        }

                        return acc;
                    }, {});

                    return [...knowledgeObjList, newKnowledgeObj];
                }
            },
            history: {
                initial: () => ({}),
                process: (historyObj, columns, interactionObj) => {
                    const newHistoryObj = Object.entries(columns).reduce((acc, [column, value]) => {
                        if (column === 'description') {
                            const systemPromptFromHistory = value.filter(h => h.role === 'system')[0].content;
                            interactionObj['system'] = systemPromptFromHistory;

                            acc['items'] = value;
                        }
                        else if (column === 'createDate') {
                            acc[column] = value
                        }

                        return acc;
                    }, {});

                    return { ...historyObj, ...newHistoryObj };
                },
            },
            response: {
                field: 'assistant',
                initial: () => '',
                process: (_, columns) => columns.description
            },
            feedback: {
                initial: () => ({}),
                process: (feedbackObj, columns) => {
                    const newFeedbackObj = Object.entries(columns).reduce((acc, [column, value]) => {
                        if (column === 'description') {

                            if (value.hasOwnProperty('feedback')) {
                                Object.defineProperty(value, 'text', Object.getOwnPropertyDescriptor(value, 'feedback'));
                                delete value['feedback'];
                            }

                            Object.assign(acc, value);
                        }
                        else if (column === 'createDate') {
                            acc[column] = value
                        }
                        return acc;
                    }, {});

                    return { ...feedbackObj, ...newFeedbackObj };
                },
            }
        };

        const jsonOutput = historyJson.reduce((acc, historyItemRow) => {
            const { conversationId, interactionId, ...otherColumns } = historyItemRow;

            const historyItemType = Object.keys(desiredHistoryTypes).find(type => Object.entries(otherColumns).find(([column, value]) => column === 'type' && value === type));

            if (!historyItemType)
                return acc;

            var fieldObjectKey = historyItemType;
            if (desiredHistoryTypes[historyItemType].field)
                fieldObjectKey = desiredHistoryTypes[historyItemType].field;

            const otherColumnsJSONParsed = Object.entries(otherColumns).reduce((acc, [key, value]) => {
                try {
                    const json = JSON.parse(value);
                    acc[key] = json;
                } catch {
                    acc[key] = value;
                }

                return acc;
            }, {});

            if (!acc[conversationId]) {
                acc[conversationId] = {};
            }
            if (!acc[conversationId].hasOwnProperty(interactionId)) {
                acc[conversationId][interactionId] = {};
            }
            if (!acc[conversationId][interactionId].hasOwnProperty(fieldObjectKey)) {
                acc[conversationId][interactionId][fieldObjectKey] = desiredHistoryTypes[historyItemType].initial();
            }

            acc[conversationId][interactionId][fieldObjectKey] =
                desiredHistoryTypes[historyItemType].process(
                    acc[conversationId][interactionId][fieldObjectKey],
                    otherColumnsJSONParsed,
                    acc[conversationId][interactionId]
                );

            return acc;
        }, {});

        downloadJson('PowerSearchHistory.json', jsonOutput);
        setIsGeneratingJson(false);

    // eslint-disable-next-line
    }, [historyJson]);

    const moduleBaseObj = useMemo(() => {

        const newModuleBaseObj = new ModuleBaseObj(messageRef);

        newModuleBaseObj.moduleHeader.bannerPath = require('../../Images/BannerBackgrounds/banner-background-8.jpg');
        newModuleBaseObj.moduleHeader.bannerPosition = "50% 35%";
        newModuleBaseObj.moduleHeader.title = "Power Search History";

        newModuleBaseObj.dataTable.ref = dataTableRef;
        newModuleBaseObj.dataTable.data = history;
        newModuleBaseObj.dataTable.columnDefinitions = {
            status: {
                type: 'status',
                headerName: '',
                width: 15,
                maxWidth: 15,
                resizable: false,
                cellRenderer: statusBarRenderer,
                cellRendererParams: {
                    valueColorMap: {
                        'success': 'var(--ff-color-green-500)',
                        'error': 'var(--ff-color-red-500)'
                    }
                }
            },
            feedback: {
                type: 'text',
                flex: 0.4,
                width: 120,
                maxWidth: 120,
                resizable: false,
                cellRenderer: iconRenderer,
                cellRendererParams: {
                    valueColorMap: {
                        good: 'var(--ff-color-green-500)',
                        bad: 'var(--ff-color-red-500)',
                    },
                    valueIconMap: {
                        good: ThumbUpAltRoundedIcon,
                        bad: ThumbDownRoundedIcon,
                    }
                }
            },
            createDate: {
                type: 'date',
                cellRenderer: dateCellRendererLocalized
            }
        };
        newModuleBaseObj.dataTable.tableOptions.onRefresh = () => setFetchHistory(true);
        newModuleBaseObj.dataTable.tableOptions.onExcelFile.fileName = 'PowerSearchHistory.csv';

        newModuleBaseObj.dataTable.serverInfiniteRow.onGridReady = onGridReady;
        newModuleBaseObj.dataTable.serverInfiniteRow.onKeywordChange = onKeywordChange;
        newModuleBaseObj.dataTable.serverInfiniteRow.onExcelFile = fetchDataForCSV;

        newModuleBaseObj.dataTable.rowOptions = [
            {
                icon: <ViewIcon />,
                tooltip: 'View Details',
                callback: ({ data }) => fetchHistoryDetails(data.interactionId)
            }
        ];

        newModuleBaseObj.dataTable.rowClick = ({ data }) => {
            fetchHistoryDetails(data.interactionId);
        };

        newModuleBaseObj.dataTable.additionalComponents.toolbar = <FetchJsonButton
            fetchHistoryJson={fetchHistoryJson}
            isGeneratingJson={isGeneratingJson}
        />;

        newModuleBaseObj.messages.title = 'Power Search History';

        newModuleBaseObj.floatingPanel.component = <HistoryDetails
            history={historyDetails}
            detailsVisible={detailsVisible}
            setDetailsVisible={setDetailsVisible}
        />;

        return newModuleBaseObj;
    }, [
        history,
        setFetchHistory,
        fetchDataForCSV,
        onGridReady,
        onKeywordChange,
        detailsVisible,
        historyDetails,
        fetchHistoryDetails,
        fetchHistoryJson,
        isGeneratingJson
    ]);

    return (
        <ModuleBase
            messageRef={messageRef}
            moduleBaseObj={moduleBaseObj}
        />
    );
}

export default PowerSearchHistory;