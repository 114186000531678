import React, { forwardRef, useImperativeHandle } from 'react';

import Typography from '@nokia-csf-uxr/ccfk/Typography';

import CloseCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCircleFillIcon';
import StatusBusyIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusBusyIcon';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import VerifiedIcon from '@mui/icons-material/Verified';

import {
	OverlayPanel,
	RowSeparator,
	Column,
	ColumnSeparator,
	Row,
	SelectAutoFill,
	FileUpload,
	TextArea,
	ButtonLink,
	CheckBox
} from "../../Common";
import { NotifySMEButton } from '../../../Components/Labs';

import {
	MODES,
	STATUS,
	SUBMIT_TYPE,
	useWAModes,
	useWATextFields,
} from "../../../CustomHooks/WorkAssignments";
import { dateCellRendererLocalized } from '../../../Renderers/DataTable';

import '../../../Styles/WorkAssignmentOverlayPanel.css';

const WorkAssignmentOverlayPanel = forwardRef(({ fields }, ref) => {

	const textFields = useWATextFields();
	const { getOverlayPanelUserName, getActionButtonLabel } = useWAModes(fields, textFields);

	useImperativeHandle(ref, () => ({
		getTextFields: () => textFields
	}));

	const roleProps = {
		id: 'wa-overlay-panel-role',
		values: fields.role,
		setValues: fields.setRole,
		data: {
			values: fields.waRoles,
			mapping: {
				value: "courseRole"
			}
		},
		label: 'Role*',
		placeholder: 'Choose the role...',
		onChange: () => {
			if (fields.mode === MODES.UPLOAD)
				fields.resetCertification();
			if (fields.role)
				fields.updateWACertifications(fields.role);
		},
		disabled: fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.EVALUATE
	}

	const certificationProps = {
		id: 'wa-overlay-panel-training',
		values: fields.certification,
		setValues: fields.setCertification,
		data: {
			values: fields.waCertifications,
			mapping: {
				value: "courseDisplayName"
			}
		},
		label: 'Certification*',
		placeholder: 'Choose the certification...',
		disabled: fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.EVALUATE
	}

	const fileUploadProps = {
		values: fields.filesToUpload,
		setValues: fields.setFilesToUpload,
		width: '100%',
		margin: '0rem',
		unit: 'MB'
	}

	const statusProps = {
		id: 'wa-overlay-panel-status',
		values: fields.status,
		setValues: fields.setStatus,
		data: {
			values: Object.values(STATUS).map(s => ({ status: s })),
			mapping: {
				value: 'status'
			}
		},
		label: 'Evaluation Status',
		placeholder: 'Update the student status...',
		hideClearButton: true,
		disabled:
			fields.mode === MODES.VIEW_AND_UPLOAD ||
			fields.currentAssignmentData.status === STATUS.PASSED ||
			fields.currentAssignmentData.status === STATUS.FAILED ||
			fields.currentAssignmentData.status === STATUS.REJECTED,
		itemsCssClasses: {
			[STATUS.UPLOADED]: 'uploaded-item',
			[STATUS.PASSED]: 'passed-item',
			[STATUS.FAILED]: 'failed-item',
			[STATUS.REJECTED]: 'rejected-item'
		}
	}

	const feedbackProps = {
		id: 'wa-overlay-panel-feedback',
		label: 'Feedback',
		value: textFields.feedback,
		setValue: textFields.setFeedback,
		maxChars: 8000,
		cols: 83,
		rows: 10,
		disabled:
			fields.mode === MODES.VIEW_AND_UPLOAD ||
			fields.currentAssignmentData.status === STATUS.PASSED ||
			fields.currentAssignmentData.status === STATUS.FAILED ||
			fields.currentAssignmentData.status === STATUS.REJECTED,
	}

	const overlayProps = {
		id: 'wa-overlay-panel',
		title: fields.mode === MODES.UPLOAD ? 'Submit your work' : fields.currentAssignmentData.courseDisplayName,
		visible: fields.panelVisible,
		setVisible: fields.setPanelVisible,
		elevationIndex: 4,
		width: '35vw',
		cancelButtonProps: {
			label: "Cancel"
		},
		actionButtonProps: {
			disabled: (fields.mode === MODES.UPLOAD || fields.mode === MODES.VIEW_AND_UPLOAD) && !fields.confirmationCheck,
			label: getActionButtonLabel(),
			onClick: () => {
				if (fields.mode === MODES.VIEW_AND_UPLOAD && fields.currentAssignmentData.status === STATUS.PASSED) {
					fields.setPanelVisible(false);
					return;
				}

				else if ((fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.UPLOAD) && !fields.fieldsAreValid()) {
					fields.setInfoMessageVariant('error');
					fields.setInfoMessage("Please make sure that a <b>certification is chosen</b>, at least <b>one file is selected for upload</b> and confirmed the <b>disclaimer</b> message.");
					fields.setShowInfoMessage(true);
				}

				else if ((fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.UPLOAD) && fields.fieldsAreValid()) {
					fields.setSubmitType(SUBMIT_TYPE.UPLOAD_WA);
					fields.setAreYouSureMessage(`Are you sure you want to submit a new Work Assignment for <b>${fields.certification}</b>?`);
					fields.setShowAreYouSureMessage(true);
				}

				else if (fields.mode === MODES.EVALUATE && fields.status === STATUS.FAILED && !textFields.feedbackIsFilled()) {
					fields.setInfoMessageVariant('error');
					fields.setInfoMessage("Before submitting a <b>FAILED</b> evaluation please provide some <b>feedback</b>.");
					fields.setShowInfoMessage(true);
				}

				else if (fields.mode === MODES.EVALUATE) {
					var message = `Are you sure you want to update <b>${fields.currentAssignmentData.firstName} ${fields.currentAssignmentData.lastName}</b> 
					Work Assignment for <b>${fields.certification}</b>?<br>`
					if (fields.currentAssignmentData.status !== fields.status)
						message += `<br><b>New status</b>: ${fields.status}.<br>`
					if ((fields.status === STATUS.PASSED || fields.status === STATUS.FAILED || fields.status === STATUS.REJECTED) && fields.currentAssignmentData.status !== fields.status)
						message += "<br><b>Please be aware that, after this change it will no longer be possible to update the status</b>."

					fields.setSubmitType(SUBMIT_TYPE.UPDATE_WA);
					fields.setAreYouSureMessage(message);
					fields.setShowAreYouSureMessage(true);
				}
			}
		},
	}

	return (
		<OverlayPanel {...overlayProps}>
			<Column>
				{
					(fields.mode === MODES.UPLOAD || fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.EVALUATE) &&
					<>
						<Typography><strong>Trainee: </strong>{getOverlayPanelUserName()}</Typography>
						{
							fields.currentAssignmentData.modUserEmail && fields.currentAssignmentData.modDate &&
							<>
								<RowSeparator />
								<Typography>
									<strong>Last Modified by: </strong>{fields.currentAssignmentData.modUserEmail} at {dateCellRendererLocalized({ value: fields.currentAssignmentData.modDate })}
								</Typography>
							</>
						}
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<Row>
							<Column flex={0.5}>
								<SelectAutoFill {...roleProps} />
							</Column>
							<Column>
								<SelectAutoFill {...certificationProps} />
							</Column>
						</Row>
					</>
				}
				{
					(fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.EVALUATE) &&
					<>
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<RowSeparator />
						<Row>
							<Column flex={0.5}>
								<SelectAutoFill {...statusProps} />
							</Column>
							<ColumnSeparator />
							{
								fields.status === STATUS.UPLOADED &&
								<Column flex={0.5}>
									<CloudDoneIcon fontSize="large" style={{ color: 'var(--ff-color-nokia-blue-400)' }} />
								</Column>
							}
							{
								fields.status === STATUS.PASSED &&
								<Column flex={0.5}>
									<VerifiedIcon fontSize="large" style={{ color: 'var(--ff-color-green-400)' }} />
								</Column>
							}
							{
								fields.status === STATUS.FAILED &&
								<Column flex={0.5}>
									<CloseCircleFillIcon size={36} color='var(--ff-color-red-500)' />
								</Column>
							}
							{
								fields.status === STATUS.REJECTED &&
								<Column flex={0.5}>
									<StatusBusyIcon size={36} color='var(--ff-color-red-700)' />
								</Column>
							}
						</Row>
						{
							fields.mode === MODES.EVALUATE &&
							<>
								<RowSeparator />
								<RowSeparator />
								<Row>
									<Column flex={0.1}>
										<ButtonLink url={fields.currentAssignmentData.path} text='Go To Work Assignment' />
									</Column>
									{
										fields.currentAssignmentData.status === STATUS.UPLOADED && fields.userIsAdminOrWALead() &&
										<Column>
											<NotifySMEButton onClick={fields.showNotificationDialog} />
										</Column>
									}
								</Row>
							</>
						}
						<RowSeparator />
						<RowSeparator />
						<TextArea {...feedbackProps} />
					</>
				}
				{
					(fields.mode === MODES.UPLOAD || fields.mode === MODES.VIEW_AND_UPLOAD) && fields.currentAssignmentData.status !== STATUS.PASSED &&
					<>
						<RowSeparator />
						<RowSeparator />
						<Row>
							<FileUpload {...fileUploadProps} />
						</Row>
						<RowSeparator />
						<RowSeparator />
						<Row>
							<CheckBox
								value={fields.confirmationCheck}
								setValue={fields.setConfirmationCheck}
								label={
								<div style={{overflow: 'auto', color: 'red'}}>
									<b>I confirm that the work assignment I am submitting is entirely my own work and that I have not copied, used another person's work or worked with others inappropriately to complete this work assignment.</b>
								</div>
							}
							/>
						</Row>
					</>
				}
			</Column>
		</OverlayPanel>

	)
});

export default WorkAssignmentOverlayPanel;