import React, { useRef, useState } from 'react';

import Hyperlink from '@nokia-csf-uxr/ccfk/Hyperlink'
import Typography from '@nokia-csf-uxr/ccfk/Typography'
import { default as DefaultFileUpload, FileUploadSection, FileUploadIcon, FileUploadList } from '@nokia-csf-uxr/ccfk/FileUpload';
import FileUploadListItem from './FileUploadListItem';
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { getAdvancedThemeContext, getComponentAdvancedTheme, getValueFromVar } from '@nokia-csf-uxr/ccfk/AdvancedTheme';
import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import { getEasing, getDuration } from '@nokia-csf-uxr/ccfk/common';

const FileUpload = (props) => {
    const [isDragActive, setIsDragActive] = useState(false);
    //const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [showGeneralErrorMessage, setShowGeneralErrorMessage] = useState(false);

    const { values, setValues, width = '32rem', margin = '3.25rem', unit = 'KB' } = props;

    const acceptedFiles = values, setAcceptedFiles = setValues;

    const generalErrorMessage = useRef();
    const acceptedFilesRef = useRef(acceptedFiles);
    const rejectedFilesRef = useRef([]);
    const openDialog = useRef();

    const INLINEFEEDBACK_STYLE = {
        border: 'none',
        margin: TOKENS.SPACING_XX_SMALL,
        position: 'absolute',
        top: 0,
        width: 'calc(100% - 0.5rem)',
        zIndex: 3
    };
    
    const INLINEFEEDBACK_ANIMATION = {
        from: { opacity: 0, ...INLINEFEEDBACK_STYLE },
        enter: { opacity: 1, ...INLINEFEEDBACK_STYLE },
        leave: { opacity: 0, ...INLINEFEEDBACK_STYLE },
        config: {
            duration: getDuration(TOKENS.DURATION_MODERATE),
            easing: getEasing(TOKENS.TRANSITION_ACCELERATE),
        }
    };

    const sizeFormatter = (size) => (unit === 'MB') ? `${(size / 1024 / 1024).toFixed(2)} MB` : `${(size / 1024).toFixed(1)} KB`;

    const uploadTimeComparator = (a, b) => {
        if (a.lastModified < b.lastModified) {
            return -1;
        }
        if (a.lastModified > b.lastModified) {
            return 1;
        }
        return 0;
    };

    const allFiles = acceptedFiles.concat(rejectedFiles).sort(uploadTimeComparator);

    const $AT = getAdvancedThemeContext(({ advancedTheme }) => advancedTheme);
    const resolvedFileUploadAT = getComponentAdvancedTheme($AT, 'FileUpload', 'default');
    const PRIMARYTEXT_COLOR = getValueFromVar({ ...$AT['GLOBAL-VARIABLES'], ...TOKENS }, resolvedFileUploadAT.text.primaryContent);
    const SUBTEXT_COLOR = getValueFromVar({ ...$AT['GLOBAL-VARIABLES'], ...TOKENS }, resolvedFileUploadAT.text.secondaryContent);

    const updateProgress = (file) => {
        let percentage = 0;
        let delay = 0
        for (percentage = 0, delay = 0; percentage <= 100; percentage += 2, delay += 50) {
            const p = percentage;
            setTimeout(() => {
                if (file != null && file.status === 'uploading') {
                    file.progress = p;
                    if (p === 100) {
                        // set error randomly to files
                        //if (Math.floor(Math.random() * 10) % 2 === 1) {
                        //    file.errorMessage = 'Unknown error while file was uploading.';
                        //}
                        file.status = 'complete';
                    }
                    setAcceptedFiles([...acceptedFilesRef.current]);
                }
            }, delay);
        }
    };

    /** simulate the upload processing */
    const simulateUploading = () => {
        acceptedFilesRef.current.forEach(({ file }) => {
            if (file.status === 'pending') {
                file.status = 'uploading';
                file.progress = 0;
                setAcceptedFiles([...acceptedFilesRef.current]);
                setTimeout(() => { updateProgress(file); }, Math.floor(Math.random() * 2000));
            }
        });
    };

    const acceptedFilesCallback = (data) => {
        const newData = data.map(file => {
            // if uploaded file name exists, set error message to inline notification.
            if (acceptedFilesRef.current.findIndex(({ file: acceptedFile }) => acceptedFile.name === file.name) !== -1) {
                generalErrorMessage.current = 'A file with the same name has already been uploaded.';
                setShowGeneralErrorMessage(true);
                return null;
            } else {
                file.status = 'pending';
                return { file }; //moved to end of map function
            }
        }).filter((element) => element != null);
        
        if (newData.length > 0) {
            acceptedFilesRef.current = newData.concat(acceptedFilesRef.current);
            setAcceptedFiles(acceptedFilesRef.current);
            simulateUploading();
            // Fade out / close error message when a new accepted file is uploaded.
            setShowGeneralErrorMessage(false);
        }
    };

    const fileRejections = (data) => {
        if (data[0] && data[0].errors) {
            generalErrorMessage.current = data[0].errors[0].message;
            setShowGeneralErrorMessage(true);
        }
    };

    const handleFeedbackClose = () => {
        setShowGeneralErrorMessage(false);
    };

    const handleDelete = (deleteFile) => {
        if (deleteFile.error) {
            const files = [...rejectedFiles];
            const indexToDelete = files.findIndex(({ file }) => file.name === deleteFile.name);
            files.splice(indexToDelete, 1);
            rejectedFilesRef.current = files;
            setRejectedFiles(files);
        } else {
            const files = [...acceptedFiles];
            const indexToDelete = files.findIndex(({ file }) => file.name === deleteFile.name);
            files.splice(indexToDelete, 1);
            acceptedFilesRef.current = files;
            setAcceptedFiles(acceptedFilesRef.current);
        };
    };

    const handleRetry = (retryFile) => {
        if (retryFile.errorMessage) {
            acceptedFilesRef.current.forEach(({ file }) => {
                if (file.name === retryFile.name) {
                    file.status = 'pending';
                    file.errorMessage = undefined;
                    file.progress = 0;
                }
            });
            setAcceptedFiles([...acceptedFilesRef.current]);
            simulateUploading();
        }
    }
    
    return (
        <div style={{ width: width, margin: margin }}>
            <DefaultFileUpload
                dragStatus={(status) => { setIsDragActive(status.isDragActive); }}
                acceptedFiles={acceptedFilesCallback}
                fileRejections={fileRejections}
                open={(open) => { openDialog.current = open }}
                {...props}
            >
                <InlineFeedbackMessage
                    variant="error"
                    show={showGeneralErrorMessage}
                    onClose={handleFeedbackClose}
                    animation={INLINEFEEDBACK_ANIMATION}
                    closeButton
                >
                    {generalErrorMessage.current}
                </InlineFeedbackMessage>
                {allFiles.length > 0 && (
                    !isDragActive && (<FileUploadList>
                        {allFiles.map(({ file }, index) =>
                            <div key={`${file.name}-${index}`}>
                                <FileUploadListItem
                                    id={`${file.name}-${index}`}
                                    index={index}
                                    fileName={file.name}
                                    errorMessage={file.errorMessage}
                                    secondaryContent={sizeFormatter(file.size)}
                                    progress={file.progress}
                                    status={file.status}
                                    onDelete={() => handleDelete(file)}
                                    onDownload={() => console.log(`Download file: ${file.name}.`)}
                                    onRetry={() => file.errorMessage && handleRetry(file)}
                                />
                                {allFiles.length > 1 && index !== allFiles.length - 1 &&
                                    <HorizontalDivider />
                                }
                            </div>
                        )}
                    </FileUploadList>)
                )}
                <FileUploadSection>
                    {(acceptedFiles.length === 0 || isDragActive) && <FileUploadIcon />}
                    {!isDragActive && (
                        <>
                            <Typography variant="default" typography="BODY" style={{ color: PRIMARYTEXT_COLOR }}>Drag and drop files here, or <Hyperlink aria-label='Browse' href="" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); openDialog.current && openDialog.current(); }}>browse</Hyperlink> </Typography>
                            <Typography variant="default" typography="CAPTION" style={{ color: SUBTEXT_COLOR }}>Files cannot be bigger than 300MB</Typography>
                        </>
                    )}
                    {isDragActive && <Typography typography="TITLE_16" style={{ fontSize: '0.875rem' }}>Drop to add files(s)</Typography>}
                </FileUploadSection>
            </DefaultFileUpload>
        </div >);
}

export default FileUpload;