import React from 'react';

import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import MoreVerticalIcon from '@nokia-csf-uxr/ccfk-assets/legacy/MoreVerticalIcon';

const DataTableOptions = ({ withRowOptions, props, ...otherProps }) => {
    
    return (
        <div {...otherProps}>
            {
                withRowOptions.map((option, index) => {
                    const shouldShowOption = option.renderCondition?.(props) ?? true;

                    if (shouldShowOption)
                        return (
                            <Tooltip key={index} placement="top" tooltip={option.tooltip} >
                                <IconButton onClick={() => { if (option.callback) option.callback(props) }} >
                                    {
                                        option.icon ? option.icon : null
                                    }
                                </IconButton>
                            </Tooltip>
                        );
                    else
                        return null;
                })
            }
            <div>
                <IconButton >
                    <MoreVerticalIcon />
                </IconButton>
            </div>
        </div>

    );
};

export default DataTableOptions;