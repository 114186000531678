import { useEffect, useRef, useState } from "react";
import { useFetch } from "./Fetch";

import Env from "../CustomObjects/Environment";

export const MODES = {
    CREATE: 'Create',
    EDIT: 'Update'
}

export const LAB_TYPES = {
    LEGACY: {
        DB_VALUE: 'LEGACY',
        UI_VALUE: 'Legacy',
        DEFAULT_LIMIT: 0
    },
    PUBCL_AWS: {
        DB_VALUE: 'PUBCL (AWS)',
        UI_VALUE: 'Public Cloud AWS',
        DEFAULT_LIMIT: 1
    }
}

export const LAB_MODES = {
    OPEN_ACCESS: 'Open Access',
    WEEKLY: 'Weekly',
}

export const LAB_STATES = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
}

const CERTIFICATION_ROLE_LOOKUP = {
    '-A-': '-ARCH-',
    '-E-': '-INT-ENG-',
    '-C-': '-CARE-ENG-',
    '-G-': '-AGN-',
}

export const useLabResourcesFields = () => {

    const datatableRef = useRef(null);
    const labResourcesOverlayPanelRef = useRef(null);

    const [labName, setLabName] = useState('');
    const [certification, setCertification] = useState('');
    const [certificationError, setCertificationError] = useState(false);
    const [labType, setLabType] = useState('');
    const [labTypeError, setLabTypeError] = useState(false);
    const [credentials, setCredentials] = useState([]);
    const [region, setRegion] = useState('');
    const [regionError, setRegionError] = useState(false);
    const [labDuration, setLabDuration] = useState(0);
    const [materials, setMaterials] = useState([]);
    const [labMode, setLabMode] = useState(LAB_MODES.WEEKLY);
    const [labState, setLabState] = useState(LAB_STATES.ACTIVE);

    const [showManageUserLimits, setShowManageUserLimits] = useState(false);

    const [currentLabResourceData, setCurrentLabResourceData] = useState({});

    const [overlaypanelVisible, setOverlayPanelVisible] = useState(false);
    const [showProcessingDiag, setShowProcessingDiag] = useState(false);
    const [areYouSureMessage, setAreYouSureMessage] = useState('');
    const [showAreYouSureMessage, setShowAreYouSureMessage] = useState(false);
    const [infoMessageVariant, setInfoMessageVariant] = useState(null);
    const [infoMessage, setInfoMessage] = useState('');
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const [active, setActive] = useState(true);
    const [mode, setMode] = useState(null);
    const [activeToggleDisabled, setActiveToggleDisabled] = useState(false);

    const reset = () => {
        setLabName('');
        setCertification('');
        setLabType('');
        setCredentials([]);
        setRegion('');
        setLabDuration(0);
        setMaterials([]);
        setLabMode(LAB_MODES.WEEKLY);
        setLabState(LAB_STATES.ACTIVE);
    }

    const resetErrors = () => {
        setCertificationError(false);
        setLabTypeError(false);
        setRegionError(false);
    }

    return {
        datatableRef,
        labResourcesOverlayPanelRef,

        labName,
        setLabName,
        certification,
        setCertification,
        certificationError,
        setCertificationError,
        labType,
        setLabType,
        labTypeError,
        setLabTypeError,
        credentials,
        setCredentials,
        region,
        setRegion,
        regionError,
        setRegionError,
        labDuration,
        setLabDuration,
        materials,
        setMaterials,
        labMode,
        setLabMode,
        labState,
        setLabState,

        showManageUserLimits,
        setShowManageUserLimits,

        currentLabResourceData,
        setCurrentLabResourceData,

        overlaypanelVisible,
        setOverlayPanelVisible,
        showProcessingDiag,
        setShowProcessingDiag,
        areYouSureMessage,
        setAreYouSureMessage,
        showAreYouSureMessage,
        setShowAreYouSureMessage,
        infoMessageVariant,
        setInfoMessageVariant,
        infoMessage,
        setInfoMessage,
        showInfoMessage,
        setShowInfoMessage,

        active,
        setActive,
        mode,
        setMode,
        activeToggleDisabled,
        setActiveToggleDisabled,

        reset,
        resetErrors,
    }
}

export const useLabResourcesTextFields = () => {
    const [cloudEnvId, setCloudEnvId] = useState('');
    const [cloudEnvIdError, setCloudEnvIdError] = useState(false);

    const reset = () => {
        setCloudEnvId('');
    }

    const resetErrors = () => {
        setCloudEnvIdError(false);
    }

    return {
        reset,
        resetErrors,
        cloudEnvId,
        setCloudEnvId,
        cloudEnvIdError,
        setCloudEnvIdError
    }
}

export const useLabResourcesManager = (fields, getCertificationData, getRegionData, updateUserLimitsResources, messages) => {

    const currentCertificationData = useRef(null);

    const [resources, setFetchResources] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources', true);
    const [updateResourceSuccess, setUpdateResource, updateResourceError,, setUpdateResourceBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources', false, {}, 'POST');

    const [activeResources, setActiveResources] = useState(null);
    const [filteredResources, setFilteredResources] = useState(null);

    const getResourceData = (labName) => {
        return resources.filter(res => res.labName.includes(labName));
    }

    const getActiveResourceData = (courseDisplayName) => {
        return activeResources.filter(res => res.courseDisplayName === courseDisplayName);
    }

    const fillResourceData = (data) => {
        fields.setLabName(data.labName);
        fields.setCertification(data.courseDisplayName);
        fields.setLabType(data.labType === LAB_TYPES.LEGACY.DB_VALUE ? LAB_TYPES.LEGACY.UI_VALUE : LAB_TYPES.PUBCL_AWS.UI_VALUE);
        fields.setCredentials(JSON.parse(data.labCredentialsJSON));
        fields.setMaterials(JSON.parse(data.waTemplatesJSON));
        fields.labResourcesOverlayPanelRef.current.getTextFields().setCloudEnvId(data.awsCatalogUUID ?? '');
        fields.setRegion(data.awsRegionUUID ? getRegionData(data.awsRegionUUID).name : '');
        fields.setLabDuration(data.duration);
        fields.setLabMode(data.isOpenAccess ? LAB_MODES.OPEN_ACCESS : LAB_MODES.WEEKLY);
        fields.setLabState(data.isActive ? LAB_STATES.ACTIVE : LAB_STATES.INACTIVE);
    }

    const updateResources = () => {
        setFetchResources(true);
        fields.setActiveToggleDisabled(true);
        fields.datatableRef.current.setClientDataNullAndShowLoadingOverlay();
    }

    const onNewResource = () => {
        fields.reset();
        fields.resetErrors();
        fields.labResourcesOverlayPanelRef.current.getTextFields().reset();
        fields.labResourcesOverlayPanelRef.current.getTextFields().resetErrors();
        fields.setMode(MODES.CREATE);
        fields.setOverlayPanelVisible(true);
        fields.setShowManageUserLimits(false);
    }

    const onEditResource = ({ data }) => {
        fillResourceData(data);
        fields.setMode(MODES.EDIT);
        fields.setCurrentLabResourceData(data);
        fields.resetErrors();
        fields.labResourcesOverlayPanelRef.current.getTextFields().resetErrors();
        fields.setOverlayPanelVisible(true);
    }

    const onManageUserLimit = () => {
        fields.setShowManageUserLimits(true);
        updateUserLimitsResources();
    }

    const onCreateOrUpdateResource = () => {
        fields.setShowAreYouSureMessage(false);
        fields.setShowProcessingDiag(true);

        if (fields.mode === MODES.CREATE) {
            setUpdateResourceBody({
                courseNumber: currentCertificationData.current.courseNumber,
                labName: fields.labName,
                labType: fields.labType === LAB_TYPES.LEGACY.UI_VALUE ? LAB_TYPES.LEGACY.DB_VALUE : LAB_TYPES.PUBCL_AWS.DB_VALUE,
                labCredentialsJSON: fields.labType === LAB_TYPES.LEGACY.UI_VALUE ? JSON.stringify(fields.credentials) : JSON.stringify([]),
                waTemplatesJSON: JSON.stringify(fields.materials),
                awsCatalogUUID: fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE ? fields.labResourcesOverlayPanelRef.current.getTextFields().cloudEnvId : '',
                awsRegionUUID: fields.labType === LAB_TYPES.PUBCL_AWS.UI_VALUE && fields.region ? getRegionData(fields.region).UUID : '',
                duration: fields.labType === LAB_TYPES.LEGACY.UI_VALUE ? 0 : fields.labDuration,
                isOpenAccess: fields.labMode === LAB_MODES.OPEN_ACCESS,
                isActive: fields.labState === LAB_STATES.ACTIVE,
            });
            setUpdateResource(true);
        }
        else if (fields.mode === MODES.EDIT) {
            setUpdateResourceBody({
                id: fields.currentLabResourceData.id.toString(),
                labCredentialsJSON: JSON.stringify(fields.credentials),
                waTemplatesJSON: JSON.stringify(fields.materials),
                awsCatalogUUID: fields.labResourcesOverlayPanelRef.current.getTextFields().cloudEnvId,
                awsRegionUUID: fields.region ? getRegionData(fields.region).UUID : '',
                duration: fields.labType === LAB_TYPES.LEGACY.UI_VALUE ? 0 : fields.labDuration,
                isActive: fields.labState === LAB_STATES.ACTIVE,
            });
            setUpdateResource(true);
        }
    }

    const getNextFreeIndexForLabName = (labName) => {
        const labsForCertification = getResourceData(labName);

        if (labsForCertification.length === 0)
            return 1;

        const labsForCertificationIndexes = labsForCertification
            .map(res => parseInt(res.labName.split('-').reverse()[0]))
            .filter(i => !isNaN(i));

        return Math.max(...labsForCertificationIndexes) + 1;
    }

    const expandLabName = (labName) => {
        for (let [key, replace] of Object.entries(CERTIFICATION_ROLE_LOOKUP)) {
            if (labName.includes(key)) {
                return labName.replace(key, replace);
            }
        }

        return labName;
    }

    useEffect(() => {
        if (resources)
            updateResources();
        // eslint-disable-next-line
    }, [fields.active]);

    useEffect(() => {
        if (resources) {
            fields.setActiveToggleDisabled(false);
            setActiveResources([...resources.filter(res => res.isActive === true)]);
            setFilteredResources([...resources.filter(res => res.isActive === fields.active)]);
        }
        // eslint-disable-next-line
    }, [resources]);

    useEffect(() => {
        if (fields.certification)
            currentCertificationData.current = getCertificationData(fields.certification);
        // eslint-disable-next-line
    }, [fields.certification]);

    useEffect(() => {
        if (fields.mode === MODES.EDIT)
            return;

        if (!fields.certification || !fields.labType) {
            fields.setLabName('');
            return;
        }

        const labNameType = fields.labType.charAt(0);
        const labNameAccessType = fields.labMode === LAB_MODES.OPEN_ACCESS ? 'O' : 'W';
        var labName = currentCertificationData.current.genericCourseNumber
            .replace('GS02-', '')
            .replace('31000-F', '')
            + '-' + labNameType + labNameAccessType;
        labName = expandLabName(labName);
        const nextfreeIndex = getNextFreeIndexForLabName(labName);
        labName += '-' + nextfreeIndex;
        fields.setLabName(labName);
        // eslint-disable-next-line
    }, [fields.certification, fields.labType, fields.labMode]);

    useEffect(() => {
        if (updateResourceSuccess) {
            fields.setShowProcessingDiag(false);
            fields.setOverlayPanelVisible(false);
            messages.success(updateResourceSuccess.data);
            updateResources();
        }
        else if (updateResourceError) {
            fields.setShowProcessingDiag(false);
            messages.failure(updateResourceError.data);
        }
        // eslint-disable-next-line
    }, [updateResourceSuccess, updateResourceError]);

    return {
        activeResources,
        filteredResources,
        getActiveResourceData,
        updateResources,
        onNewResource,
        onManageUserLimit,
        onEditResource,
        onCreateOrUpdateResource,
    };
}

export const useLabResourcesDialogMessages = (fields) => {

    const areYouSure = (message) => {
        fields.setAreYouSureMessage(message);
        fields.setShowAreYouSureMessage(true);
    }

    const success = (message) => {
        fields.setInfoMessage(message);
        fields.setInfoMessageVariant('SUCCESS');
        fields.setShowInfoMessage(true);
    }

    const warning = (message) => {
        fields.setInfoMessage(message);
        fields.setInfoMessageVariant('WARNING');
        fields.setShowInfoMessage(true);
    }

    const failure = (message) => {
        fields.setInfoMessage(message);
        fields.setInfoMessageVariant('ERROR');
        fields.setShowInfoMessage(true);
    }

    return {
        areYouSure,
        success,
        warning,
        failure
    }
}

export const useLabResourcesCertifications = () => {

    const [certifications] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/Certifications', true);

    const getCertificationData = (courseDisplayName) => {
        return certifications.filter(cert => cert.courseDisplayName === courseDisplayName)[0];
    }

    return {
        certifications,
        getCertificationData,
    };
}

export const useLabResourcesCredentialsTypes = () => {

    const [crendentialsTypes] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/CredentialsTypes', true);

    return crendentialsTypes;
}

export const useLabResourcesRegions = () => {

    const [regions] = useFetch(Env.BACKEND_SERVER_URL + 'LabResources/Regions', true);

    const getRegionData = (name) => {
        return regions.filter(reg => reg.name === name || reg.UUID === name)[0];
    }

    return {
        getRegionData,
        regions
    };
}

export const labDurationRenderer = ({ value, data }) => {
    if (data.labType === LAB_TYPES.PUBCL_AWS.DB_VALUE && value === 0)
        return 'Unlimited';
    else if (data.labType === LAB_TYPES.PUBCL_AWS.DB_VALUE && value !== 0)
        return `${value} hours`
    return 'N/A'
}

export const userLimitRenderer = ({ value }) => {
    if (value === 0)
        return 'Unlimited';
    return `${value} Attempt(s)`
}