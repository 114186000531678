import Papa from 'papaparse';
import { saveAs } from 'file-saver';

export const useFileExport = () => {

    const downloadCsv = (fileName, data) => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    
        saveAs(blob, fileName);
    }

    const downloadJson = (fileName, data) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    
        saveAs(blob, fileName);
    }

    return { downloadCsv, downloadJson };
}