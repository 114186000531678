import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
    CONFIG_LABEL_MAP,
    PARAM_TYPES,
    MODELS,
    removeConfigParam,
} from '../../CustomHooks/PowerSearchConfiguration';

import {
    RowSeparator,
    ExpansionPanel,
    TextInput,
    SelectAutoFill,
    TextWithChips,
    CheckBox,
    Slider,
} from '../../Components/Common';
import { roundToDecimal } from '../../CustomObjects/Utils';

function ConfigParameter(type, parameter, value, showWarning = true) {
    this.type = type
    this.parameter = parameter
    this.value = value
    this.showWarning = showWarning
}

const handleInputChange = (newValue, setValueCallback, onChangeCallback) => {
    setValueCallback(newValue);
    onChangeCallback(newValue);
}

const ParameterInput = forwardRef(({ label, initialValue, onChange, maxChars, active }, ref) => {
    const [paramValue, setParamValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        updateValue: (newValue) => handleInputChange(newValue, setParamValue, onChange),
        value: paramValue
    }));
    
    return (
        <>
            <TextInput
                label={label}
                value={paramValue}
                setValue={newValue => handleInputChange(newValue, setParamValue, onChange)}
                maxChars={maxChars}
                disabled={!active}
            />
            <RowSeparator />
        </>
    );
})

const SelectInput = forwardRef(({ label, initialValue, data, onChange, active }, ref) => {

    const [paramValue, setParamValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        updateValue: (newValue) => handleInputChange(newValue, setParamValue, onChange),
        value: paramValue
    }));

    return (
        <>
            <RowSeparator />
            <RowSeparator />
            <SelectAutoFill
                data={data}
                label={label}
                values={paramValue}
                setValues={newValue => handleInputChange(newValue, setParamValue, onChange)}
                disabled={!active}
            />
            <RowSeparator />
        </>
    );
})

const TextWithChipsInput = forwardRef(({ label, initialValue, onChange, active }, ref) => {
    const [paramValue, setParamValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        updateValue: (newValue) => handleInputChange(newValue, setParamValue, onChange),
        value: paramValue
    }));

    return (
        <>
            <RowSeparator />
            <RowSeparator />
            <TextWithChips
                label={label}
                values={paramValue}
                setValues={newValue => handleInputChange(newValue, setParamValue, onChange)}
                placeholder='Enter a new value...'
                withDropdownHelperMessage='Press Enter to add'
                disabled={!active}
            />
            <RowSeparator />
        </>
    );
})

const CheckBoxInput = forwardRef(({ label, initialValue, onChange, active }, ref) => {
    const [paramValue, setParamValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        updateValue: (newValue) => handleInputChange(newValue, setParamValue, onChange),
        value: paramValue
    }));
    
    return (
        <>
            <RowSeparator />
            <CheckBox
                label={label}
                value={paramValue}
                setValue={newValue => handleInputChange(newValue, setParamValue, onChange)}
                disabled={!active}
            />
            <RowSeparator />
        </>
    );
})

const SliderInput = forwardRef(({ label, initialValue, step, min, max, marks, onChange, active }, ref) => {

    const [paramValue, setParamValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
        updateValue: (newValue) => handleInputChange(newValue, setParamValue, onChange),
        value: paramValue
    }));

    return (
        <>
            <Slider
                label={label}
                value={paramValue}
                setValue={newValue => handleInputChange(newValue, setParamValue, onChange)}
                step={step ?? 1}
                min={min ?? 0}
                max={max ?? 100}
                marks={marks ?? null}
                disabled={!active}
            />
        </>
    );
})

const ConfigurationSections = ({ configurationWasLoaded, getConfiguration, updateConfiguration, getConfigurationTypeData, messageRef }) => {

    const [configurationAvailable, setConfigurationAvailable] = useState(null);
    const inputRefs = useRef({});
    const sourceChange = useRef(null);
    const paramsThatChangedForWarningMessage = useRef([]);

    const getParamDependencies = (type, parameter, newValue) => {
        if (type === 'llm_gw' && parameter === 'model' && !MODELS[newValue])
            return [];

        if (type === 'llm_gw' && parameter === 'model') {
            if (MODELS[newValue].context === '4K') {
                return [
                    new ConfigParameter('llm_gw', 'max_tokens', 1024),
                    new ConfigParameter('ps_ks', 'result_size', 5),
                    new ConfigParameter('ps_mb', 'ctx_size', 4096),
                    new ConfigParameter('ps_mb', 'ctx_headroom_quota', 0.5),
                    new ConfigParameter('ps_mb', 'ctx_history_quota', 0.25),
                    new ConfigParameter('ps_mb', 'ctx_knowledge_quota', 0.25),
                    new ConfigParameter('ps_mb', 'history_size', 5),
                ];
            }
            else if (MODELS[newValue].context === '8K') {
                return [
                    new ConfigParameter('llm_gw', 'max_tokens', 1536),
                    new ConfigParameter('ps_ks', 'result_size', 10),
                    new ConfigParameter('ps_mb', 'ctx_size', 8192),
                    new ConfigParameter('ps_mb', 'ctx_headroom_quota', 0.3),
                    new ConfigParameter('ps_mb', 'ctx_history_quota', 0.3),
                    new ConfigParameter('ps_mb', 'ctx_knowledge_quota', 0.4),
                    new ConfigParameter('ps_mb', 'history_size', 10),
                ];
            }
            else if (MODELS[newValue].context === '16K') {
                return [
                    new ConfigParameter('llm_gw', 'max_tokens', 2048),
                    new ConfigParameter('ps_ks', 'result_size', 15),
                    new ConfigParameter('ps_mb', 'ctx_size', 16384),
                    new ConfigParameter('ps_mb', 'ctx_headroom_quota', 0.25),
                    new ConfigParameter('ps_mb', 'ctx_history_quota', 0.25),
                    new ConfigParameter('ps_mb', 'ctx_knowledge_quota', 0.5),
                    new ConfigParameter('ps_mb', 'history_size', 12),
                ];
            }
            else if (MODELS[newValue].context === '32K') {
                return [
                    new ConfigParameter('llm_gw', 'max_tokens', 2048),
                    new ConfigParameter('ps_ks', 'result_size', 15),
                    new ConfigParameter('ps_mb', 'ctx_size', 32768),
                    new ConfigParameter('ps_mb', 'ctx_headroom_quota', 0.2),
                    new ConfigParameter('ps_mb', 'ctx_history_quota', 0.3),
                    new ConfigParameter('ps_mb', 'ctx_knowledge_quota', 0.5),
                    new ConfigParameter('ps_mb', 'history_size', 12),
                ];
            }
            else if (MODELS[newValue].context === 'HUGE') {
                return [
                    new ConfigParameter('llm_gw', 'max_tokens', 4000),
                    new ConfigParameter('ps_ks', 'result_size', 30),
                    new ConfigParameter('ps_mb', 'ctx_size', 2000000),
                    new ConfigParameter('ps_mb', 'ctx_headroom_quota', 0.1),
                    new ConfigParameter('ps_mb', 'ctx_history_quota', 0.1),
                    new ConfigParameter('ps_mb', 'ctx_knowledge_quota', 0.8),
                    new ConfigParameter('ps_mb', 'history_size', 20),
                ];
            }
        }

        else if (type === 'ps_mb' && parameter === 'ctx_headroom_quota') {
            const oldHeadRoomQuota = inputRefs.current['ps_mb.ctx_headroom_quota'].current.value;
            const newHeadRoomQuota = newValue;
            const oldHistoryAndKnowledgeQuota = roundToDecimal(1.0 - oldHeadRoomQuota, 2);
            const newHistoryAndKnowledgeQuota = roundToDecimal(1.0 - newHeadRoomQuota, 2);
            const oldHistoryQuota = inputRefs.current['ps_mb.ctx_history_quota'].current.value;

            if (oldHeadRoomQuota === newHeadRoomQuota)
                return [];

            const newHistoryQuota = Math.round((newHistoryAndKnowledgeQuota * oldHistoryQuota) / oldHistoryAndKnowledgeQuota * 20) / 20;
            const newKnowledgeQuota = newHistoryQuota === 0 ? 0 : roundToDecimal(1.0 - newHeadRoomQuota - newHistoryQuota, 2);

            if (oldHistoryAndKnowledgeQuota === 0)
                return [];

            return [
                new ConfigParameter('ps_mb', 'ctx_history_quota', newHistoryQuota, false),
                new ConfigParameter('ps_mb', 'ctx_knowledge_quota', newKnowledgeQuota, false)
            ];
        }

        else if (type === 'ps_mb' && parameter === 'ctx_history_quota') {
            const headRoomQuota = inputRefs.current['ps_mb.ctx_headroom_quota'].current.value;
            const oldHistoryQuota = inputRefs.current['ps_mb.ctx_history_quota'].current.value;
            const newHistoryQuota = newValue;
            const maxHistoryAndKnowledgeQuota = roundToDecimal(1.0 - headRoomQuota, 2);

            if (oldHistoryQuota === newHistoryQuota)
                return [];

            if (newHistoryQuota > maxHistoryAndKnowledgeQuota)
                return [new ConfigParameter('ps_mb', 'ctx_headroom_quota', roundToDecimal(1.0 - newHistoryQuota,2), false)];
            else
                return [new ConfigParameter('ps_mb', 'ctx_knowledge_quota', roundToDecimal(maxHistoryAndKnowledgeQuota - newHistoryQuota, 2), false)];
        }

        else if (type === 'ps_mb' && parameter === 'ctx_knowledge_quota') {
            const headRoomQuota = inputRefs.current['ps_mb.ctx_headroom_quota'].current.value;
            const oldKnowledgeQuota = inputRefs.current['ps_mb.ctx_knowledge_quota'].current.value;
            const newKnowledgeQuota = newValue;
            const maxHistoryAndKnowledgeQuota = roundToDecimal(1.0 - headRoomQuota, 2);

            if (oldKnowledgeQuota === newKnowledgeQuota)
                return [];

            if (newKnowledgeQuota > maxHistoryAndKnowledgeQuota)
                return [new ConfigParameter('ps_mb', 'ctx_headroom_quota', roundToDecimal(1.0 - newKnowledgeQuota, 2), false)];
            else
                return [new ConfigParameter('ps_mb', 'ctx_history_quota', roundToDecimal(maxHistoryAndKnowledgeQuota - newKnowledgeQuota, 2), false)];
        }

        //else if (type === 'ps_mb' && parameter === 'ctx_history_quota') {
        //    const theKnowledgeQuota = Math.round((1.0 - newValue) * 10) / 10;

        //    return [new ConfigParameter('ps_mb', 'ctx_knowledge_quota', theKnowledgeQuota, false)]
        //}

        //else if (type === 'ps_mb' && parameter === 'ctx_knowledge_quota') {
        //    const theHistoryQuota = Math.round((1.0 - newValue) * 10) / 10;

        //    return [new ConfigParameter('ps_mb', 'ctx_history_quota', theHistoryQuota, false)]
        //}

        return [];
    }

    const handleConfigChange = (type, parameter, newValue) => {
        updateConfiguration(type, parameter, newValue);

        if (!sourceChange.current)
            sourceChange.current = type + '.' + parameter;

        const isOriginChange = sourceChange.current === type + '.' + parameter;

        if (isOriginChange) {
            const paramDependencies = getParamDependencies(type, parameter, newValue);
            paramsThatChangedForWarningMessage.current.push(...paramDependencies.filter(p => p.showWarning));

            paramDependencies.forEach(dependParam => {
                const paramKey = dependParam.type + '.' + dependParam.parameter;
                if (inputRefs.current[paramKey])
                    inputRefs.current[paramKey].current.updateValue(dependParam.value);
            });

            if (paramsThatChangedForWarningMessage.current && paramsThatChangedForWarningMessage.current.length > 0) {
                var message = 'Changing: <br><br>';
                message += `<b>${CONFIG_LABEL_MAP[type] + ' ' + formatParameterLabel(parameter) + ' = ' + newValue}</b><br><br>`;
                message += 'has automatically updated:  <br><br>';
                paramsThatChangedForWarningMessage.current.forEach(param => {
                    message += `<b>${CONFIG_LABEL_MAP[param.type]}</b>: <b>${formatParameterLabel(param.parameter)}</b> = ${param.value} <br>`;
                });

                messageRef.current.warningMessage(message);
                paramsThatChangedForWarningMessage.current = [];
            }

            sourceChange.current = null;
        }
    }

    const formatParameterLabel = (parameter) => {
        return parameter
            .replace(/_([a-z])/g, ' $1')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getInputComponent = (type, parameter, initialValue) => {
        const configTypeData = getConfigurationTypeData(type, parameter);
        const paramKey = type + '.' + parameter;

        inputRefs.current[paramKey] = createRef();

        if (configTypeData && configTypeData.valueType === PARAM_TYPES.SELECT && configTypeData.allowedValues) {
            const allowedValues = configTypeData.allowedValues.split(',').map(v => ({ allowed: v }));
            return (
                <SelectInput
                    key={parameter}
                    ref={inputRefs.current[paramKey]}
                    label={formatParameterLabel(parameter)}
                    initialValue={initialValue}
                    data={{
                        values: allowedValues,
                        mapping: { value: 'allowed' }
                    }}
                    onChange={newValue => handleConfigChange(type, parameter, newValue)}
                    active={configTypeData.active}
                />
            );
        }

        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.ARRAY) {
            return (
                <TextWithChipsInput
                    key={parameter}
                    ref={inputRefs.current[paramKey]}
                    label={formatParameterLabel(parameter)}
                    initialValue={initialValue}
                    onChange={newValue => handleConfigChange(type, parameter, newValue)}
                    active={configTypeData.active}
                />
            );
        }

        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.BOOLEAN) {
            return (
                <CheckBoxInput
                    key={parameter}
                    ref={inputRefs.current[paramKey]}
                    label={formatParameterLabel(parameter)}
                    initialValue={initialValue}
                    onChange={newValue => handleConfigChange(type, parameter, newValue)}
                    active={configTypeData.active}
                />
            );
        }

        else if (configTypeData && configTypeData.valueType === PARAM_TYPES.RANGE) {
            var val = null;
            var step = null;
            var min = null;
            var max = null;
            var marks = null;

            try {
                const allowedValues = JSON.parse(configTypeData.allowedValues);
                val = parseFloat(initialValue);
                step = parseFloat(allowedValues.step);
                min = parseFloat(allowedValues.min);
                max = parseFloat(allowedValues.max);
                marks = allowedValues.marks ?? null;
            } catch (e) {
                console.error('Error parsing range values for range parameter: ', e);
            }

            return (
                <SliderInput
                    key={parameter}
                    ref={inputRefs.current[paramKey]}
                    label={formatParameterLabel(parameter)}
                    initialValue={val}
                    onChange={newValue => handleConfigChange(type, parameter, newValue)}
                    active={configTypeData.active}
                    step={step}
                    min={min}
                    max={max}
                    marks={marks}
                />
            );
        }

        return (
            <ParameterInput
                key={parameter}
                ref={inputRefs.current[paramKey]}
                label={formatParameterLabel(parameter)}
                initialValue={initialValue}
                onChange={newValue => handleConfigChange(type, parameter, newValue)}
                maxChars={500}
                active={configTypeData && configTypeData.valueType === PARAM_TYPES.STRING ? configTypeData.active : true}
            />
        );
    }

    useEffect(() => {
        if (!configurationWasLoaded) {
            setConfigurationAvailable(null);
        }
        else if (configurationWasLoaded && !configurationAvailable) {
            
            let newConfig = JSON.parse(JSON.stringify(getConfiguration()));
            
            removeConfigParam(newConfig, ['ps_mb', 'system_prompt']);
            removeConfigParam(newConfig, ['ps_mb', 'prefix']);
            removeConfigParam(newConfig, ['ps_mb', 'postfix']);
            removeConfigParam(newConfig, ['ps_mb', 'summary_system_prompt']);
            removeConfigParam(newConfig, ['ps_mb', 'summary_user_prompt']);
            removeConfigParam(newConfig, ['ps_mb', 'summary_rolling_user_prompt']);
            removeConfigParam(newConfig, ['ps_mb', 'query_reformulation_user_prompt']);

            setConfigurationAvailable(newConfig);
        }
        // eslint-disable-next-line
    }, [configurationWasLoaded]);
    
    return (
        <>
            {
                configurationAvailable &&
                Object.keys(configurationAvailable).map(type => (
                    <ExpansionPanel key={type} header={CONFIG_LABEL_MAP[type] || type}>
                        {
                            Object.keys(configurationAvailable[type]).map(parameter => (
                                getInputComponent(type, parameter, configurationAvailable[type][parameter])
                            ))
                        }
                    </ExpansionPanel>
                ))
            }
        </>
    );
}

export default ConfigurationSections;