
import React, { useMemo, useState, useEffect, useRef } from 'react';

import { useFetch } from "../../CustomHooks/Fetch";

import { dateCellRenderer, useCourseCatalogData, QUARTERS, PERIODS } from "../../CustomHooks/CourseCatalog";

import Env from "../../CustomObjects/Environment";

import { ModuleBase } from "../../Components/Common";
import ModuleBaseObj from '../../CustomObjects/Module';

import ToolbarSelectMultipleButton from '../../Components/Datatable/ToolbarSelectMultipleButton';
import { materialsLink } from '../../Renderers/DataTable';

const CourseCatalog = () => {
    const [years, setYears] = useState([]);
    const [quarters, setQuarters] = useState(Object.values(QUARTERS));
    const [periods, setPeriods] = useState(Object.values(PERIODS));

    const messageRef = useRef(null);
    const dataTableRef = useRef(null);

    const {
        catalogData,
        onGridReady,
        onKeywordChange,
        updateCourseData,
        fetchDataForCSV
    } = useCourseCatalogData(dataTableRef);

    const [launchYears] = useFetch(Env.BACKEND_SERVER_URL + 'Solutions/CourseCatalog/LaunchYears', true);
    
    useEffect(() => {
        if (launchYears !== null) setYears(launchYears);
    }, [launchYears]);

    const moduleBaseObj = useMemo(() => {
        const moduleBase = new ModuleBaseObj(messageRef);

        moduleBase.moduleHeader.bannerPath = require('../../Images/BannerBackgrounds/banner-background-3.jpg');
        moduleBase.moduleHeader.bannerPosition = "50% 73%";
        moduleBase.moduleHeader.title = "Course Catalog";
        moduleBase.moduleHeader.titleTextColor = "var(--ff-color-grey-300)";

        moduleBase.dataTable.ref = dataTableRef;
        moduleBase.dataTable.data = catalogData;

        moduleBase.dataTable.columnDefinitions = {
            courseObjectType: {
                headerName: 'Type',
                flex: 0.5
            },
            trainingTitle: {
                headerName: 'Title',
                flex: 1.7
            },
            courseLevel: {
                headerName: 'Level',
                flex: 0.4
            },
            solutionLongName: {
                headerName: 'Solution',
                flex: 1
            },
            courseRole: {
                headerName: 'Role',
                flex: 0.5
            },
            status: {
                headerName: 'Status',
                flex: 0.5
            },
            launchDate: {
                headerName: 'Launch',
                flex: 0.5,
                type: 'date',
                cellRenderer: dateCellRenderer
            },
            phaseOutDate: {
                headerName: 'Phase-Out',
                flex: 0.5,
                type: 'date',
                cellRenderer: dateCellRenderer
            },
            courseURL: {
                headerName: 'URL',
                flex: 0.5,
                cellRenderer: materialsLink
            }
        };

        moduleBase.dataTable.columnsIgnore = ['launchYear', 'launchQuarter', 'launchPeriod'];
        moduleBase.dataTable.tableOptions.onRefresh = () => { updateCourseData(years, quarters, periods); };
        moduleBase.dataTable.tableOptions.onFilterRemove.callback = () => {
            setYears(launchYears);
            setQuarters(Object.values(QUARTERS));
            setPeriods(Object.values(PERIODS));

            updateCourseData([], [], [], true);
        };

        moduleBase.dataTable.tableOptions.onExcelFile.fileName = 'Course Catalog.csv';
        moduleBase.dataTable.tableOptions.onExcelFile.columns = ['courseObjectType', 'trainingTitle', 'courseLevel', 'solutionLongName', 'courseRole', 'status',
            'launchDate', 'phaseOutDate', 'courseURL'];

        moduleBase.dataTable.serverInfiniteRow.onGridReady = onGridReady;
        moduleBase.dataTable.serverInfiniteRow.onKeywordChange = onKeywordChange;
        moduleBase.dataTable.serverInfiniteRow.onExcelFile = fetchDataForCSV;
        
        moduleBase.dataTable.additionalComponents.toolbar =
            <>
                <ToolbarSelectMultipleButton
                    label="Year"
                    values={years}
                    setValues={setYears}
                    data={launchYears === null ? [] : launchYears}
                    onChange={(selected) => updateCourseData(selected, quarters, periods)}
                />
                <br/>
                <ToolbarSelectMultipleButton
                    label="Quarter"
                    values={quarters}
                    setValues={setQuarters}
                    data={QUARTERS}
                    onChange={(selected) => updateCourseData(years, selected, periods)}
                />
                <br />
                <ToolbarSelectMultipleButton
                    label="Period"
                    values={periods}
                    setValues={setPeriods}
                    data={PERIODS}
                    onChange={(selected) => updateCourseData(years, quarters, selected)}
                />
            </>;

        moduleBase.dataTable.rowOptions = false;

        return moduleBase;
    }, [fetchDataForCSV, onGridReady, onKeywordChange, catalogData, updateCourseData, launchYears, periods, years, quarters]);

    return (
        <ModuleBase
            messageRef={messageRef}
            moduleBaseObj={moduleBaseObj}
        />
    );
}

export default CourseCatalog;
