import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import ArrowLineUpCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowLineUpCircleIcon';

import Env from "../../CustomObjects/Environment";
import { FloatingPanel, Rating, TextArea, Hyperlink } from "../Common";
import { useFetch } from '../../CustomHooks/Fetch';
import { useUser } from '../../CustomHooks/Global';

import '../../Styles/KTSessionsFloatingPanel.css';

const FeedbackPanel = ({ fields }) => {

    const recSession = useRef(null);
    const learningPoints = useRef(null);
    const ktRater = useRef(null);

    const user = useUser();

    const [feedbacks, setFeedbacks] = useState({});
    const [learningIndexLink, setLearningIndexLink] = useState('');

    const [questions] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions/questions', true);
    const [userRatings, setFetchUserRatings, , setUserRatingsHeader] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions/ratings');
    const [updateRatingsResponse, setUpdateRatings, updateRatingsError, , setRatingsBody] = useFetch(Env.BACKEND_SERVER_URL + 'KTSessions/ratings', false, {}, 'POST');

    const questionsHaveBeenCorrectlyAnswered = () => {
        const incorrectQuestions = questions.filter(q => q.Mandatory && (!feedbacks.hasOwnProperty(q.QuestionNumber) || !feedbacks[q.QuestionNumber]));
        return incorrectQuestions.length === 0;
    }

    const handleUpdateRatings = () => {
        if (!questionsHaveBeenCorrectlyAnswered()) {
            fields.setMessage("Please answer all mandatory questions.");
            fields.setMessageVariant('error');
            fields.setShowMessage(true);
            return;
        }

        fields.setOpenProcessingDiag(true);
        const newRatings = {}
        for (let questionID in feedbacks) newRatings[questionID] = String(feedbacks[questionID])

        setRatingsBody({
            recSession: fields.currentKTData.sessionId,
            sessionName: fields.currentKTData.sessionName,
            ktRater: user.username,
            organization: user.organization,
            nokiaUserId: user.userId,
            ratings: newRatings
        });
        setUpdateRatings(true);
    }

    const fetchUserRatings = useCallback(() => {
        if (recSession.current && ktRater.current) {
            setUserRatingsHeader({
                recSession: recSession.current,
                ktRater: ktRater.current
            });
            setFetchUserRatings(true);
        }
    }, [setFetchUserRatings, setUserRatingsHeader]);

    const getLearningIndexLink = () => {
        if (learningPoints.current)
            return 'https://nokia.sharepoint.com/sites/learn/achiev/SitePages/index.aspx/reward/' + learningPoints.current;
        return '';
    }

    // DATA CHANGES
    useEffect(() => {
        if (fields.currentKTData) {
            recSession.current = fields.currentKTData.sessionId
            learningPoints.current = fields.currentKTData.learningPoints
        }
        if (user) {
            ktRater.current = user.username
        }
    }, [fields.currentKTData, user]);

    // LOAD FEEDBACK FOR SESSION WHEN OPENING PANEL
    useEffect(() => {
        if (fields.feedbackPanelVisible) {
            fields.setOpenProcessingDiag(true);
            fetchUserRatings();
        }
        setFeedbacks({});
    // eslint-disable-next-line
    }, [fields.feedbackPanelVisible]);

    // FILL RATING FIELDS WITH USER INFO
    useEffect(() => {
        fields.setOpenProcessingDiag(false);
        if (userRatings && userRatings.length > 0) {
            const newRatings = {};
            userRatings.forEach(rating => {
                let newRating = parseFloat(rating.rating);
                if (!isNaN(newRating))
                    newRatings[rating.questionID] = newRating;
                else
                    newRatings[rating.questionID] = rating.rating;
            });
            setFeedbacks(newRatings);
            setLearningIndexLink(getLearningIndexLink());
        }
        else {
            setLearningIndexLink('');
        }
    // eslint-disable-next-line
    }, [userRatings]);

    // AFTER SUBMIT RATINGS
    useEffect(() => {
        fields.setOpenProcessingDiag(false);
        if (updateRatingsResponse) {
            fields.setMessage("Ratings have been successfully submitted!.");
            fields.setMessageVariant('success');
            fields.setShowMessage(true);
            setLearningIndexLink(getLearningIndexLink());
        }
        if (updateRatingsError) {
            fields.setMessage(updateRatingsError);
            fields.setMessageVariant('error');
            fields.setShowMessage(true);
        }
    // eslint-disable-next-line
    }, [updateRatingsResponse, updateRatingsError]);

    return (
        <FloatingPanel
            title={fields.currentKTData.sessionName}
            visible={fields.feedbackPanelVisible}
            setVisible={fields.setFeedbackPanelVisible}
        >
            <div className='kt-session-floating-panel-content'>
                <div className='kt-session-floating-panel-text-with-border'>
                    {
                        learningIndexLink ?
                            <>Claim your learning points <Hyperlink style={{ textDecoration: 'none', color: 'var(--g-color-global-primary-base)', fontWeight: 700 }} text='HERE' link={learningIndexLink} /></>
                              :
                            <>The Evaluation and the Learning Points Link will be available once the session is completed.</>
                    }
                </div>
                <div className='kt-session-floating-panel-text'>
                    Please provide your feedback on the training you completed recently. It will take just 5 min of your time and will
                    help us a lot to improve our trainings. Your answers will be treated anonymously. Thank you for your support.
                </div>
                {
                    questions && questions.map((question, i) => (
                        <div key={i}>
                            <p>{i + 1}. {question.Question} {question.Mandatory && ' *'}</p>
                            {
                                question.Type === 'Rating' &&
                                <Rating
                                    value={feedbacks[question.QuestionNumber] ?? 0}
                                    setValue={(newValue) => {
                                        const newFeedbacks = { ...feedbacks };
                                        newFeedbacks[question.QuestionNumber] = newValue;
                                        setFeedbacks(newFeedbacks);
                                    }} 
                                />
                            }
                            {
                                question.Type === 'Text' &&
                                <TextArea
                                    placeholder='Comments/suggestions...'
                                    value={feedbacks[question.QuestionNumber] ?? ''}
                                    setValue={(newValue) => {
                                        const newFeedbacks = { ...feedbacks };
                                        newFeedbacks[question.QuestionNumber] = newValue;
                                        setFeedbacks(newFeedbacks);
                                    }}
                                />
                            }
                        </div>
                    ))
                }
                <div>
                    <Button disabled={fields.currentKTData.sessionStatus === 'SCHEDULED' ? true : false} variant='call-to-action-bigger-font' onClick={handleUpdateRatings}>
                        <ButtonText>Submit</ButtonText>
                        <ButtonIcon><ArrowLineUpCircleIcon /></ButtonIcon>
                    </Button>
                </div>
                <div className='kt-session-floating-panel-text-with-border'>
                    {
                        learningIndexLink ?
                            <>Claim your learning points <Hyperlink style={{ textDecoration: 'none', color: 'var(--g-color-global-primary-base)', fontWeight: 700 }} text='HERE' link={learningIndexLink} /></>
                            :
                            <>The Evaluation and the Learning Points Link will be available once the session is completed.</>
                    }
                </div>
            </div>
        </FloatingPanel>
    );
}

export default FeedbackPanel;