import React from 'react';

import {
    ModuleContainer,
    ModuleHeader,
    ModuleContent,
    DataTable,
    Toggle,
    Message
} from "../../Components/Common"; 
import { ManageLabResourcesSection, LabResourcesOverlayPanel, ManageUserLimits } from '../../Components/Labs';

import { statusBarRenderer } from '../../Renderers/DataTable';

import {
    useLabResourcesFields,
    useLabResourcesManager,
    useLabResourcesCertifications,
    useLabResourcesCredentialsTypes,
    useLabResourcesRegions,
    useLabResourcesDialogMessages,
    labDurationRenderer,
    userLimitRenderer,
} from '../../CustomHooks/LabResources';
import { useLabUserLimits } from '../../Components/Labs/LabResources/ManageUserLimits';

import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import StatusAvailableIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusAvailableIcon';
import StatusBusyIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusBusyIcon';

const LabResources = () => {

    const fields = useLabResourcesFields();
    const {
        certifications,
        getCertificationData
    } = useLabResourcesCertifications();
    const credentialsTypes = useLabResourcesCredentialsTypes();
    const {
        userLimitsResources,
        updateUserLimitsResources,
        getUserLimitsResourceData
    } = useLabUserLimits();
    const messages = useLabResourcesDialogMessages(fields);
    const {
        regions,
        getRegionData
    } = useLabResourcesRegions();
    const {
        filteredResources,
        getActiveResourceData,
        updateResources,
        onNewResource,
        onManageUserLimit,
        onEditResource,
        onCreateOrUpdateResource
    } = useLabResourcesManager(fields, getCertificationData, getRegionData, updateUserLimitsResources, messages);

	return (
        <ModuleContainer>
            <ModuleHeader
                backgroundPath={require('../../Images/BannerBackgrounds/banner-background-4.jpg')}
                backgroundPosition='50% 60%'
            >
                Lab Resources
            </ModuleHeader>
            <ModuleContent>
                <DataTable
                    ref={fields.datatableRef}
                    data={filteredResources}
                    columnDefinitions={{
                        isActive: {
                            type: 'status',
                            headerName: '',
                            width: 44,
                            maxWidth: 44,
                            resizable: false,
                            cellRenderer: statusBarRenderer,
                            cellRendererParams: {
                                valueColorMap: {
                                    true: 'var(--ff-color-green-400)',
                                    false: 'var(--ff-color-red-400)',
                                },
                                valueIconMap: {
                                    true: StatusAvailableIcon,
                                    false: StatusBusyIcon,
                                }
                            }
                        },
                        labType: {
                            flex: 0.5
                        },
                        duration: {
                            type: 'number',
                            flex: 0.4,
                            headerName: 'AWS Expiration',
                            cellRenderer: labDurationRenderer
                        },
                        userLimit: {
                            type: 'number',
                            headerName: 'Default User Limit',
                            flex: 0.5,
                            cellRenderer: userLimitRenderer
                        }
                    }}
                    columnsIgnore={[
                        'coursenumber', 'labowners', 'labcredentialsjson', 'labmaterialsurl', 'watemplatesjson',
                        'awscataloguuid', 'awsregionuuid', 'isopenaccess', 'id'
                    ]}
                    withTableOptions={{
                        onRefresh: updateResources,
                        onFilterRemove: true,
                        onExcelFile: {
                            fileName: 'Resources.csv'
                        },
                        onAdjustColumns: false
                    }}
                    withRowOptions={[
                        {
                            icon: <EditIcon />,
                            tooltip: 'details',
                            callback: onEditResource
                        },
                    ]}
                    withAdditionalComponents={{
                        toolbar: <Toggle
                            checked={fields.active}
                            setChecked={fields.setActive}
                            checkedText='Active'
                            uncheckedText='Active'
                            disabled={fields.activeToggleDisabled}
                        />,
                        searchbar: <ManageLabResourcesSection
                            onNewResource={onNewResource}
                            onManageUserLimit={onManageUserLimit}
                        />
                    }}
                    withRowClick={onEditResource}
                    withSearch
                    withFilters
                    noRowsMessage='There are no Lab Resources...'
                />
                <LabResourcesOverlayPanel
                    ref={fields.labResourcesOverlayPanelRef}
                    fields={fields}
                    certifications={certifications}
                    getActiveResourceData={getActiveResourceData}
                    regions={regions}
                    credentialsTypes={credentialsTypes}
                    messages={messages}
                />
                <Message
                    title='Lab Resources'
                    variant='WARNING'
                    message={fields.areYouSureMessage}
                    open={fields.showAreYouSureMessage}
                    setOpen={fields.setShowAreYouSureMessage}
                    onSubmit={onCreateOrUpdateResource}
                    actionButtonLabel='Yes'
                    cancelButtonLabel='No'
                />
                <Message
                    title='Lab Resources'
                    message='Please wait while request is processed...'
                    open={fields.showProcessingDiag}
                    withLoading
                />
                <Message
                    title='Lab Resources'
                    variant={fields.infoMessageVariant}
                    message={fields.infoMessage}
                    open={fields.showInfoMessage}
                    setOpen={fields.setShowInfoMessage}
                    actionButtonLabel="OK"
                    onSubmit={() => fields.setShowInfoMessage(false)}
                />
            </ModuleContent>
            <ManageUserLimits
                fields={fields}
                updateResources={updateResources}
                userLimitsResources={userLimitsResources}
                updateUserLimitsResources={updateUserLimitsResources}
                getUserLimitsResourceData={getUserLimitsResourceData}
            />
        </ModuleContainer>
	);
}

export default LabResources;
