import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import UserSettingsIcon from '@nokia-csf-uxr/ccfk-assets/latest/UserSettingsIcon';
import AddCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddCircleFillIcon';

const ManageLabResourcesSection = ({ onNewResource, onManageUserLimit }) => {

	return (
		<div style={{
			display: 'flex',
			gap: '0.5em'
		}}>
			<Button variant='secondary-bigger-font' onClick={onManageUserLimit}>
				<ButtonText>Manage User Limits</ButtonText>
				<ButtonIcon>
					<UserSettingsIcon />
				</ButtonIcon>
			</Button>
			<Button variant='call-to-action-bigger-font' onClick={onNewResource}>
				<ButtonText>Create</ButtonText>
				<ButtonIcon>
					<AddCircleFillIcon />
				</ButtonIcon>
			</Button>
		</div>
	);
}

export default ManageLabResourcesSection;