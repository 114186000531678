import Button, {
	ButtonText,
	ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';

import BackupIcon from '@mui/icons-material/Backup';

const UploadAssignmentButton = ({ onClick }) => {

	return (
		<Button variant='call-to-action-bigger-font' onClick={onClick}>
			<ButtonText>Submit</ButtonText>
			<ButtonIcon>
				<BackupIcon />
			</ButtonIcon>
		</Button>
	);
}

export default UploadAssignmentButton;