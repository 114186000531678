
import React from 'react';

const Column = (props) => {

    const { flex = 1, children } = props;

    return (
        <div style={{
            display: 'flex',
            flex: flex,
            flexDirection: 'column',
            flexWrap: 'nowrap',
            rowGap: '3%'
        }}>
            {children}
        </div>
    );
}

const Row = ({ children }) => {
    return (
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'nowrap',
            columnGap: '1%',
            alignItems: 'flex-start'
        }}>
            {children}
        </div>
    );
}

const ColumnSeparator = () => { return <div style={{ width: '2%' }}></div>; }
const RowSeparator = () => { return <div style={{ height: '10px' }}></div>; }

export { ColumnSeparator, RowSeparator, Column, Row };