import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Message, TextInput } from '../../Components/Common';

import { CONFIG_MODES } from '../../CustomHooks/PowerSearchConfiguration';

const NewUserConfigMessage = forwardRef(({ visible, setVisible, configurationUserEmails, setConfigUserTarget, submitConfigurationWithDetails }, ref) => {
    const [newUserTargetError, setNewUserTargetError] = useState(false);
    const [newUserTarget, setNewUserTarget] = useState('');

    useImperativeHandle(ref, () => ({
        value: newUserTarget
    }));

    return (
        <Message
            title='Power Search X'
            message='Please insert a new configuration user target'
            open={visible}
            setOpen={setVisible}
            actionButtonLabel='Create'
            cancelButtonLabel='Cancel'
            withComponent={
                <TextInput
                    label='New User'
                    placeholder='Enter new user email...'
                    value={newUserTarget}
                    setValue={setNewUserTarget}
                    error={newUserTargetError}
                    setError={setNewUserTargetError}
                />
            }
            onSubmit={() => {
                if (!newUserTarget || configurationUserEmails.some((config) => config.createdBy.toUpperCase() === newUserTarget.toUpperCase())) {
                    setNewUserTargetError(true);
                    return;
                }

                setConfigUserTarget(newUserTarget);
                submitConfigurationWithDetails(newUserTarget, CONFIG_MODES.USER, 'active');
                setVisible(false);
            }}
        />
    );
});

export default NewUserConfigMessage;