
import { Home } from "../Modules/Home";
import { KTSessions } from "../Modules/KTSessions";
import {
    CertificationRoles, CurriculumReports, L3CertReports, L3PMCertReports, L4CertAttributes, L4CertReports,
    L4ReviewSessionTechLeads, L4ReviewSessionParticipation, PMPCertReports, ScheduleL4Review, CertificationRating,
    L3PlusCertification
} from "../Modules/Certifications";
import { Regions, Solutions, SolutionNameAdmin, YammerGroupsAndCoP, CourseCatalog } from "../Modules/SolutionsManagement";
import { LabReservations, LabResources, ScheduleLab, L3WorkAssignments } from '../Modules/Labs';
import CNSMasters from "../Modules/CNSMasters";
import { TrainingDemand } from '../Modules/PlanningAndKPIs';
import { CaseHandling, JiraTickets } from '../Modules/TroubleTickets';
import Reports from '../Modules/Reports';
import CertificationBudgetReport from '../Modules/CertificationBudgetReport';
import { GettingStarted, AIMGlossary, DeliverablesView, KeyGatesView, KeyRolesView, ProcessesView, SEModelView, TemplateDLCenter } from '../Modules/AIM';
import WeTube from '../Modules/WeTube';
import PMAT from '../Modules/PMAT';
import DocumentRepository from '../Modules/DocumentRepository';
import Glossary from '../Modules/Glossary';
import {
    Admin, UserRoles, PowerSearchHistory, FocusAreas, Customers, DocumentTypeAndSubtypes, DocumentInfrastructure,
    NKCNotifications, CoreEngineering, PowerSearchXConfiguration
} from "../Modules/Admin";
import About from "../Modules/About";
import PowerSearch from "../Modules/PowerSearch";
import PowerSearchX from "../Modules/PowerSearchX";
import KPIPlanning from "../Modules/KPIPlanning";
import KnowledgeArticle from "../Modules/KnowledgeArticle";

const AppModules = {
    'home': <Home />,
    'ktsessions': <KTSessions />,
    'l3certreports': <L3CertReports />,
    'l3pmcertreports': <L3PMCertReports />,
    'l4certreports': <L4CertReports />,
    'schedulel4reviewsession': <ScheduleL4Review />,
    'certificationrating': <CertificationRating />,
    'l4reviewsessiontechleads': <L4ReviewSessionTechLeads />,
    'l4reviewsessionparticipation': <L4ReviewSessionParticipation />,
    'l4certattributes': <L4CertAttributes />,
    'l3pluscertification': <L3PlusCertification />,
    'curriculumreports': <CurriculumReports />,
    'pmpcertreports': <PMPCertReports />,
    'certificationroles': <CertificationRoles />,
    'solutions': <Solutions />,
    'solutionnameadmin': <SolutionNameAdmin />,
    'regions': <Regions />,
    'vivaengagegroupsandcop': <YammerGroupsAndCoP />,
    'coursecatalog': <CourseCatalog />,
    'schedulelab': <ScheduleLab />,
    'laberesources': <LabResources />,
    'labreservations': <LabReservations />,
    'l3workassignments': <L3WorkAssignments />,
    'cnsmasters': <CNSMasters />,
    'kpiplanning': <KPIPlanning />,
    'trainingdemand': <TrainingDemand />,
    'casehandling': <CaseHandling />,
    'jiratickets': <JiraTickets />,
    'reports': <Reports />,
    'certbudgetreport': <CertificationBudgetReport />,
    'gettingstarted': <GettingStarted />,
    'aimglossary': <AIMGlossary />,
    'templatedlcenter': <TemplateDLCenter />,
    'semodelview': <SEModelView />,
    'processesview': <ProcessesView />,
    'keygatesview': <KeyGatesView />,
    'deliverablesview': <DeliverablesView />,
    'keyrolesview': <KeyRolesView />,
    'wetube': <WeTube />,
    'pmat': <PMAT />,
    'documentrepository': <DocumentRepository />,
    'glossary': <Glossary />,
    'admin': <Admin />,
    'userroles': <UserRoles />,
    'powersearchhistory': <PowerSearchHistory />,
    'focusareas': <FocusAreas />,
    'customers': <Customers />,
    'documenttypeandsubtypes': <DocumentTypeAndSubtypes />,
    'documentinfrastructure': <DocumentInfrastructure />,
    'nkcnotifications': <NKCNotifications />,
    'coreengineering': <CoreEngineering />,
    'about': <About />,
    'powersearch': <PowerSearch />,
    'powersearchx': <PowerSearchX />,
    'powersearchxconfiguration': <PowerSearchXConfiguration />,
    'knowledgearticle': <KnowledgeArticle />
};

export default AppModules;
