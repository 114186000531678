import Env from "../CustomObjects/Environment";
import MockPublicClientApplication from "../Classes/Test/MockPublicClientApplication";
import msalConfig from "../CustomObjects/MsalConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { createContext, useContext, useState, useEffect } from "react";

const MockAppMsalContext = createContext(null);

const storageParams = {
	'none': InteractionStatus.None,
	'undefined': InteractionStatus.UNKNOWN,
}

export const AppMsalProvider = ({ children }) => {

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [inProgress, setInProgress] = useState(InteractionStatus.UNKNOWN);

	useEffect(() => {
		const msalItems = JSON.parse(sessionStorage.getItem("msalItems"));
		if (msalItems) {
			setInProgress(storageParams[msalItems.inProgress]);
			setIsAuthenticated(Boolean(msalItems.isAuthenticated));
		}
	}, []);

	if (!Env.MOCK_AUTHENTICATION) {
		const instance = new PublicClientApplication(msalConfig);
		return (
			<MsalProvider instance={instance}>
				{children}
			</MsalProvider>
		);
	}

	const instance = new MockPublicClientApplication(setIsAuthenticated, setInProgress);
	return (
		<MockAppMsalContext.Provider value={{ instance, inProgress, isAuthenticated }}>
			{children}
		</MockAppMsalContext.Provider>
	);
}

export const useMsalProps = () => {
	const realMsalProps = useMsal();
	const mockMsalProps = useContext(MockAppMsalContext);

	if (!Env.MOCK_AUTHENTICATION)
		return realMsalProps;

	return mockMsalProps;
}

export const AuthenticatedComponents = ({ children }) => {
	const { isAuthenticated } = useMsalProps();

	if (!Env.MOCK_AUTHENTICATION) {
		return (
			<AuthenticatedTemplate>
				{ children }
			</AuthenticatedTemplate>
		);
	}

	return (
		isAuthenticated &&
		<>
			{ children }
		</>
	);
}

export const UnAuthenticatedComponents = ({ children }) => {
	const { isAuthenticated } = useMsalProps();

	if (!Env.MOCK_AUTHENTICATION) {
		return (
			<UnauthenticatedTemplate>
				{children}
			</UnauthenticatedTemplate>
		);
	}

	return (
		!isAuthenticated &&
		<>
			{children}
		</>
	);
}









