
import { useEffect, useMemo, useRef, useState } from "react";
import Env from "../CustomObjects/Environment";

import { useFetch } from './Fetch';
import { useUser, useIsAdmin, useIsRole } from "./Global";
import { useSharepoint } from "./Sharepoint";
import { useEmailsNotifications, EmailInstance } from "./Emails";

export const MODES = {
    UPLOAD: 'upload',
    VIEW_AND_UPLOAD: 'viewAndUpload',
    EVALUATE: 'evaluate'
}

export const STATUS = {
    UPLOADED: 'Uploaded',
    PASSED: 'Passed',
    FAILED: 'Failed',
    REJECTED: 'Rejected',
}

export const SUBMIT_TYPE = {
    NOTIFY_SME: 'Notify',
    UPLOAD_WA: 'Upload',
    UPDATE_WA: 'Update'
}

export const EVALUATION_TYPE = {
    SME: 'sme',
    AUTO: 'auto'
}

const sharepointUrlRexExp = new RegExp('^https://nokia.sharepoint.com/sites/[^/]+/[^/]+/(.+)$')

const waEmailBody = (fullName, certification, filesUploaded, isReminder) => {
    var body = `
        <h1>Work Assignment Upload ${(isReminder ? "(REMINDER)" : "")} </h1>
        <p>${fullName} has uploaded their Certification Assignment for: <u>${certification}</u></p>
        <br/>
    `;

    if (filesUploaded && filesUploaded.length > 0) {
        body += `<p>Files:</p>`;
        filesUploaded.map(file => body += `<p>${file.split('/')[1]}</p>`);

        body += `
            <br/>
            <p>Are now available for validation.</p>
            <br/>
        `;
    }

    if (!isReminder) {
        body += `
            <p style="font-size: 12pt; color: #005aff"><b>Trainee, please be aware:</b></p>
            <ul>
                <li>For queries/issues please <a href="https://jiradc2.ext.net.nokia.com/servicedesk/customer/portal/30/create/502">raise a ticket in JSM</a>.</li>
                <li>To upload new documents, use the Nokia Knowledge Center interface to <a href="${Env.FRONTEND_SERVER_URL}labs/l3workassignments">submit again the Work Assignment</a>.</li>
            </ul>
            <br/>
            <p style="font-size: 12pt; color: #005aff"><b>SME's, please be aware:</b></p>
            <ul>
                <li>Uploaded materials are accessible under <a href="${Env.FRONTEND_SERVER_URL}labs/l3workassignments">NKC Work Assignment page</a>.</li>
                <li>The datagrid displays the assignments pending evaluation.</li>
                <li>Submitted materials can be accessed via the link in the datagrid (column 'Assignments') or in the assignment details overlay panel.</li>
            </ul>
        `;
    }

    return body;
}

const waEvaluationEmailBody = (fullName, certification, status, feedback) => {
    var body = `
        <h1>Work Assignment Evaluation</h1>
        <p>Dear ${fullName},</p>
        <br/>
        <p>Your work assignment, related to the <u>${certification}</u> was reviewed and your status is: <b>${status}</b>.</p>
        <br/>
        <p>Please see below the SME Feedback:</p>
        <br/>
        <p style="background-color: #c7cdd6; padding: 0.5em">${feedback}</p>
        <br/>
        <p><b>Note</b>: when your Work Assignment is marked as 'Passed' in the Nokia Knowledge Center, the corresponding 
        learning object, in NLDH, will be marked as completed and you'll be able to proceed to the next step in your certification.</p>
    `;

    return body;
}

const waAlwaysPassEmailBody = (fullName, certification, ojtTemplate) => {
    var body = `
        <h1>Work Assignment Auto-Evaluation</h1>
        <p>Dear ${fullName},</p>
        <br/>
        <p>Your work assignment, related to the <u>${certification}</u> was uploaded and automatically evaluated and your status is: <b>Passed</b>.</p>
        <br/>
    `;

    if (ojtTemplate)
        body += `
            <p>Please use the following <a href="${ojtTemplate}">document</a> to validate your answers.</p>
        `;

    body += `
        <p><b>Note</b>: when your Work Assignment is marked as 'Passed' in the Nokia Knowledge Center, the corresponding 
        learning object, in NLDH, will be marked as completed and you'll be able to proceed to the next step in your certification.</p>
    `;
    
    return body;
}

export const useWAFields = () => {

    const user = useUser();
    const isAdmin = useIsAdmin();
    const isWALead = useIsRole('workAssignmentLead');
    const [SMEs, userIsSME] = useSMEs();
    const [waRoles] = useWARoles();
    const [waCertifications, setWaCertifications, updateWACertifications] = useWACertifications();

    const [panelVisible, setPanelVisible] = useState(false);
    const [mode, setMode] = useState(MODES.UPLOAD);
    const [modeHasChanged, setModeHasChanged] = useState(false);
    const [currentAssignmentData, setCurrentAssignmentData] = useState({});
    const [showEvaluated, setShowEvaluated] = useState(false);
    const [role, setRole] = useState('');
    const [certification, setCertification] = useState('');
    const [status, setStatus] = useState('');
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoMessageVariant, setInfoMessageVariant] = useState('info');
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [showProcessingDiag, setShowProcessingDiag] = useState(false);
    const [areYouSureMessage, setAreYouSureMessage] = useState('');
    const [showAreYouSureMessage, setShowAreYouSureMessage] = useState(false);
    const [submitType, setSubmitType] = useState(SUBMIT_TYPE.NOTIFY_SME);
    const [toggleDisabled, setToggleDisabled] = useState(false);
    const [confirmationCheck, setConfirmationCheck] = useState(false);

    const defaultColsToHide = ['coursenumber', 'userid', 'smes', 'modUserEmail', 'firstName', 'lastName', 'confirmationcheck'];

    const reset = () => {
        resetCurrentAssignmentData();
        resetRole();
        resetCertification();
        resetFilesUpload();
        resetWaCertifications();
        resetConfirmationCheck();
    }

    const resetCurrentAssignmentData = () => {
        setCurrentAssignmentData({});
    }

    const resetRole = () => {
        setRole('');
    }

    const resetCertification = () => {
        setCertification('');
    }

    const resetFilesUpload = () => {
        setFilesToUpload([]);
    }

    const resetWaCertifications = () => {
        setWaCertifications([]);
    }

    const resetConfirmationCheck = () => {
        setConfirmationCheck(false);
    }

    const fieldsAreValid = () => {
        return role && certification && filesToUpload.length > 0 && confirmationCheck;
    }

    const onUploadClick = () => {
        reset();
        setMode(MODES.UPLOAD);
        setModeHasChanged(true);
    }

    const onEditAssignment = ({ data }) => {
        if (userIsSMEForCourseOrAdminOrWALead(data))
            setMode(MODES.EVALUATE);
        else
            setMode(MODES.VIEW_AND_UPLOAD);

        resetFilesUpload();
        resetConfirmationCheck();
        setCurrentAssignmentData(data);
        setModeHasChanged(true);
    };

    const noRowsMessage = () => {
        if (showEvaluated)
            return `Currently ${user.firstName} ${user.lastName} has no evaluated assignments...`;
        else if (!showEvaluated && userIsSME)
            return `${user.firstName} ${user.lastName} has no assignments to evaluate...`;
        else if (!showEvaluated && !userIsSME)
            return `Currently ${user.firstName} ${user.lastName} has no assignments pending evaluation...`;
    }

    const showNotificationDialog = () => {
        setSubmitType(SUBMIT_TYPE.NOTIFY_SME);
        setAreYouSureMessage("Are you sure you want to notify SME's?");
        setShowAreYouSureMessage(true);
    }

    const userIsSMEOrAdminOrWALead = () => {
        return userIsSME || isAdmin || isWALead;
    }

    const userIsAdminOrWALead = () => {
        return isAdmin || isWALead;
    };

    const userIsSMEForCourseOrAdminOrWALead = (data) => {
        return data.SMEs.includes(user.email) || isAdmin || isWALead;
    };

    return {
        SMEs,
        userIsSME,
        waRoles,
        waCertifications,
        setWaCertifications,
        updateWACertifications,

        panelVisible,
        setPanelVisible,

        mode,
        setMode,
        modeHasChanged,
        setModeHasChanged,
        currentAssignmentData,
        setCurrentAssignmentData,
        showEvaluated,
        setShowEvaluated,

        role,
        setRole,
        certification,
        setCertification,
        status,
        setStatus,
        filesToUpload,
        setFilesToUpload,

        infoMessage,
        setInfoMessage,
        infoMessageVariant,
        setInfoMessageVariant,
        showInfoMessage,
        setShowInfoMessage,
        showProcessingDiag,
        setShowProcessingDiag,
        areYouSureMessage,
        setAreYouSureMessage,
        showAreYouSureMessage,
        setShowAreYouSureMessage,
        submitType,
        setSubmitType,
        toggleDisabled,
        setToggleDisabled,
        confirmationCheck,
        setConfirmationCheck,

        defaultColsToHide,

        reset,
        resetCurrentAssignmentData,
        resetRole,
        resetCertification,
        resetFilesUpload,
        resetWaCertifications,

        fieldsAreValid,

        onUploadClick,
        onEditAssignment,
        noRowsMessage,
        showNotificationDialog,
        userIsSMEOrAdminOrWALead,
        userIsAdminOrWALead,
    }
}

export const useWATextFields = () => {
    const [feedback, setFeedback] = useState('');

    const feedbackIsFilled = () => {
        return feedback;
    }

    return {
        feedback,
        setFeedback,
        feedbackIsFilled,
    }
}

export const useWorkAssignments = (fields, datatableRef) => {
    const user = useUser();
    const userIsAdmin = useIsAdmin();
    const userIsWALead = useIsRole('workAssignmentLead');

    const [assignments, setFetchAssignments, , setAssignmentsHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments', false);

    const onToggleChange = (checked) => {
        updateAssignments(checked);
        datatableRef.current.setClientDataNullAndShowLoadingOverlay();
    }

    const updateAssignments = (showEvaluated) => {
        setAssignmentsHeaders({
            userEmail: userIsAdmin || userIsWALead ? '' : user.email,
            showEvaluated: showEvaluated
        });
        setFetchAssignments(true);
        fields.setToggleDisabled(true);
    }

    const refreshAssignments = () => {
        updateAssignments(fields.showEvaluated);
        datatableRef.current.setClientDataNullAndShowLoadingOverlay();
    };

    useEffect(() => {
        updateAssignments(false);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!assignments)
            return;
        fields.setToggleDisabled(false);
    // eslint-disable-next-line
    }, [assignments]);

    return {
        assignments,
        //updateAssignments,
        refreshAssignments,
        onToggleChange
    };
}

export const useSingleWorkAssignment = () => {

    const [assignment, setFetchAssignment, , setAssignmentHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments', false);

    const getWorkAssignment = (id) => {
        setAssignmentHeaders({
            id: id
        });
        setFetchAssignment(true);
    }

    return [assignment, getWorkAssignment];
}

export const useWARoles = () => {
    const [roles] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments/Roles', true);

    return [roles];
}

export const useWACertifications = () => {
    const [waCertifications, setFetchCertifications, , setCertificationsHeaders,, setWaCertifications] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments/Certifications', false);

    const updateWACertifications = (certRole) => {
        setCertificationsHeaders({ certRole: certRole });
        setFetchCertifications(true);
    }

    return [waCertifications, setWaCertifications, updateWACertifications];
}

export const useWAUpload = (fields, overlaypanelRef, refreshAssignments) => {
    const user = useUser();
    const currentCertificationDetails = useRef(null);
    const currentFilesUploaded = useRef([]);
    const currentPath = useRef(null);
    const evaluationType = useRef(null);
    const submitStatus = useRef(null);

    const [userIsValidResponse, setValidateUser, userIsValidError, , setUserIsValidBody] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments/ValidateAssignmentWithPath', false, {}, 'POST');
    const [, , successInSharepointFileUpload, errorInSharepointFileUpload, , uploadWAToSharepoint] = useSharepoint("sharepointDocumentFolderWA");
    const [assignmentUpdateResponse, setUpdateAssignment, assignmentUpdateError, , setAssignmentUpdateBody] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments/Update', false, {}, 'POST');
    const { queueEmail, queueEmailSuccess, queueEmailError } = useEmailsNotifications();

    const getCurrentSelectedCertificationDetails = () => {
        const theCertification = fields.waCertifications.filter(cert => cert.courseDisplayName === fields.certification);

        if (theCertification.length > 0)
            return theCertification[0];
        return null;
    }

    const getFolderName = () => {
        if (currentPath.current)
            return currentPath.current.match(sharepointUrlRexExp)[1]
        return `${currentCertificationDetails.current.courseNumber}/${user.username}`;
    }

    const resetCurrentCertificationDetails = () => {
        currentCertificationDetails.current = null;
    }

    const operationSuccess = (message) => {
        fields.setShowProcessingDiag(false);
        fields.setInfoMessageVariant('success');
        fields.setInfoMessage(message);
        fields.setShowInfoMessage(true);
        fields.setPanelVisible(false);
        refreshAssignments();
        resetCurrentCertificationDetails();
    }

    const failedOperation = (message) => {
        fields.setShowProcessingDiag(false);
        fields.setInfoMessageVariant('error');
        fields.setInfoMessage(message);
        fields.setShowInfoMessage(true);
        resetCurrentCertificationDetails();
    }

    const uploadAssignment = () => {
        currentCertificationDetails.current = getCurrentSelectedCertificationDetails();

        setUserIsValidBody({
            certificationCourseNumber: currentCertificationDetails.current.courseNumber
        });
        setValidateUser(true);
        fields.setShowProcessingDiag(true);
    }

    const updateAssignment = (
        assignmentEvaluationType,
        courseNumber,
        status,
        feedback,
        nokiaUserId,
        modNokiaUserId,
        path,
        documentFolderServerProperty,
        confirmationCheck
    ) => {
        setAssignmentUpdateBody({
            courseNumber: courseNumber,
            status: status,
            feedback: feedback,
            nokiaUserId: nokiaUserId,
            modNokiaUserId: modNokiaUserId,
            path: path,
            documentFolderServerProperty: documentFolderServerProperty,
            confirmationCheck: confirmationCheck
        });
        setUpdateAssignment(true);
        evaluationType.current = assignmentEvaluationType;
        submitStatus.current = status;
        fields.setShowProcessingDiag(true);
    }

    // 1. USER ELIGIBILITY SUCCESS
    useEffect(() => {
        if (!userIsValidResponse)
            return;

        console.log("User is ELIGBLE for certification. Uploading files to server...");
        currentPath.current = userIsValidResponse.data;
        uploadWAToSharepoint(getFolderName(), fields.filesToUpload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIsValidResponse]);

    // 1. USER ELIGIBILITY ERROR
    useEffect(() => {
        if (!userIsValidError)
            return;

        failedOperation(userIsValidError.data);
        // eslint-disable-next-line
    }, [userIsValidError]);

    // 2. FILES UPLOADED SUCCESS
    useEffect(() => {
        if (!successInSharepointFileUpload)
            return;

        console.log("Files successfully uploaded into sharepoint. Inserting assignment in DB...");
        currentFilesUploaded.current = successInSharepointFileUpload.items[getFolderName()].files;

        const status = currentCertificationDetails.current.OJTVerificationMethod === 'always-pass' ? STATUS.PASSED : STATUS.UPLOADED;
        const feedback = currentCertificationDetails.current.OJTVerificationMethod === 'always-pass' ? 'Completed (automatically verified)' : '';
        const evaluationType = currentCertificationDetails.current.OJTVerificationMethod === 'always-pass' ? EVALUATION_TYPE.AUTO : null;

        updateAssignment(
            evaluationType,
            currentCertificationDetails.current.courseNumber,
            status,
            feedback,
            user.userId,
            '',
            successInSharepointFileUpload.items[getFolderName()].folder,
            'sharepointDocumentFolderWA',
            fields.confirmationCheck
        );
        // eslint-disable-next-line
    }, [successInSharepointFileUpload]);

    // 2. FILES UPLOADED ERROR
    useEffect(() => {
        if (!errorInSharepointFileUpload)
            return;

        failedOperation(errorInSharepointFileUpload);
        // eslint-disable-next-line
    }, [errorInSharepointFileUpload]);

    // 3. WORK ASSIGNMENT UPDATED IN DB SUCCESS
    useEffect(() => {
        if (!assignmentUpdateResponse)
            return;

        console.log("Work Assignment successfully updated in DB!");

        if (submitStatus.current === STATUS.REJECTED) {
            console.log("Work Assignment state successfully updated to REJECT! No email will be sent.");
            operationSuccess("Work Assignment successfully Updated!");
            return;
        }

        if (!currentCertificationDetails.current)
            currentCertificationDetails.current = getCurrentSelectedCertificationDetails();

        const to = evaluationType.current === EVALUATION_TYPE.SME ? fields.currentAssignmentData.userEmail : user.email;

        var subject;
        if (evaluationType.current === EVALUATION_TYPE.SME)
            subject = "Work Assignment Evaluation";
        else if (evaluationType.current === EVALUATION_TYPE.AUTO)
            subject = "Work Assignment Auto-Evaluation";
        else
            subject = "Work Assignment Upload"
        
        var emailBody;
        if (evaluationType.current === EVALUATION_TYPE.SME) {
            emailBody = waEvaluationEmailBody(
                fields.currentAssignmentData.firstName + ' ' + fields.currentAssignmentData.lastName,
                fields.certification,
                fields.status,
                overlaypanelRef.current.getTextFields().feedback
            );
        }
        else if (evaluationType.current === EVALUATION_TYPE.AUTO) {
            emailBody = waAlwaysPassEmailBody(
                user.firstName + ' ' + user.lastName,
                fields.certification,
                currentCertificationDetails.current.OJTTemplate,
            );
        }
        else {
            emailBody = waEmailBody(
                user.firstName + ' ' + user.lastName,
                fields.certification,
                currentFilesUploaded.current,
                false
            );
        }

        const emailInstace = new EmailInstance(
            "do_not_reply@nokia.com",
            [to],
            [],
            ["I_NKC_KMCD_LOG@nokia.com", ...currentCertificationDetails.current.SMEs.split(',')],
            subject,
            emailBody
        );
        queueEmail(emailInstace);
        // eslint-disable-next-line
    }, [assignmentUpdateResponse]);

    // 3. WORK ASSIGNMENT UPDATED IN DB ERROR
    useEffect(() => {
        if (!assignmentUpdateError)
            return;

        failedOperation(assignmentUpdateError.data);
        // eslint-disable-next-line
    }, [assignmentUpdateError]);

    // 4. QUEUE NOTIFICATION SUCCESS
    useEffect(() => {
        if (!queueEmailSuccess)
            return;

        const successMessage = evaluationType.current === EVALUATION_TYPE.SME ?
            "Work Assignment has been successfully evaluated!" : "Work Assignment has been successfully submitted!";

        operationSuccess(successMessage);
        // eslint-disable-next-line
    }, [queueEmailSuccess]);

    // 4. QUEUE NOTIFICATION ERROR
    useEffect(() => {
        if (!queueEmailError)
            return;

        failedOperation(queueEmailError.data);
        // eslint-disable-next-line
    }, [queueEmailError]);

    return [uploadAssignment, updateAssignment];
}

export const useSMENotification = (fields) => {
    
    const [notifySMEResponse, setNotifySME, notifySMEError, , setNotifySMEBody] = useFetch(Env.BACKEND_SERVER_URL + 'Notifications/Email', false, {}, 'POST');

    const sendNotification = () => {
        setNotifySMEBody({
            from: "do_not_reply@nokia.com",
            to: [fields.currentAssignmentData.userEmail],
            cc: [],
            bcc: ["I_NKC_KMCD_LOG@nokia.com", ...fields.currentAssignmentData.SMEs.split(',')],
            subject: "Work Assignment Upload",
            body: waEmailBody(
                fields.currentAssignmentData.firstName + ' ' + fields.currentAssignmentData.lastName,
                fields.currentAssignmentData.courseDisplayName,
                null,
                true
            )
        });
        setNotifySME(true);
        fields.setShowProcessingDiag(true);
    }

    // NOTIFICATION TO SME SUCCESS
    useEffect(() => {
        if (!notifySMEResponse)
            return;

        console.log("Notification sent successfully to SME(s)!");
        fields.setShowProcessingDiag(false);
        fields.setInfoMessageVariant('success');
        fields.setInfoMessage("Notification sent successfully to SME(s)!");
        fields.setShowInfoMessage(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifySMEResponse]);

    // NOTIFICATION TO SME ERROR
    useEffect(() => {
        if (!notifySMEError)
            return;

        fields.setShowProcessingDiag(false);
        fields.setInfoMessageVariant('error');
        fields.setInfoMessage(notifySMEError);
        fields.setShowInfoMessage(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifySMEError]);

    return sendNotification;
}

export const useSMEs = () => {
    const user = useUser();

    const [SMEs] = useFetch(Env.BACKEND_SERVER_URL + 'WorkAssignments/SMEs', true);

    const userIsSME = useMemo(() => {
        if (!SMEs)
            return false;
        return SMEs.includes(user.email);
        // eslint-disable-next-line
    }, [SMEs]);

    return [SMEs, userIsSME];
}

export const useWAModes = (fields, textFields) => {
    const user = useUser();

    const getOverlayPanelUserName = () => {
        if (fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.EVALUATE)
            return `${fields.currentAssignmentData.firstName} ${fields.currentAssignmentData.lastName}`
        return `${user.firstName} ${user.lastName}`
    }

    const getActionButtonLabel = () => {
        if ((fields.mode === MODES.VIEW_AND_UPLOAD || fields.mode === MODES.UPLOAD) && fields.currentAssignmentData.status !== STATUS.PASSED)
            return 'Upload';
        else if (fields.mode === MODES.EVALUATE)
            return 'Update';
        else
            return 'Ok';
    }

    useEffect(() => {
        if (!fields.modeHasChanged)
            return;

        if (fields.mode === MODES.EVALUATE || fields.mode === MODES.VIEW_AND_UPLOAD) {
            fields.setRole(fields.currentAssignmentData.courseRole);
            fields.setCertification(fields.currentAssignmentData.courseDisplayName);
            fields.setStatus(fields.currentAssignmentData.status);
            textFields.setFeedback(fields.currentAssignmentData.feedback);
        }

        fields.setPanelVisible(true);
        fields.setModeHasChanged(false);
    // eslint-disable-next-line
    }, [fields.modeHasChanged]);

    return {
        getOverlayPanelUserName,
        getActionButtonLabel,
    };
}

export const useWASubmit = (fields, overlaypanelRef, refreshAssignments) => {
    const user = useUser();

    const sendSMENotification = useSMENotification(fields);
    const [uploadAssignment, updateAssignment] = useWAUpload(fields, overlaypanelRef, refreshAssignments);

    const onSubmitWA = () => {
        if (fields.submitType === SUBMIT_TYPE.NOTIFY_SME)
            sendSMENotification();
        else if (fields.submitType === SUBMIT_TYPE.UPLOAD_WA)
            uploadAssignment();
        else if (fields.submitType === SUBMIT_TYPE.UPDATE_WA)
            updateAssignment(
                EVALUATION_TYPE.SME,
                fields.currentAssignmentData.courseNumber,
                fields.status,
                overlaypanelRef.current.getTextFields().feedback,
                fields.currentAssignmentData.userId,
                user.userId,
                '',
                'sharepointDocumentFolderWA',
                fields.currentAssignmentData.confirmationCheck
            );

        fields.setShowAreYouSureMessage(false);
    }

    return onSubmitWA;
}