import { useState, useRef, useEffect, useMemo } from 'react';

import Env from "../CustomObjects/Environment";

import { useFetch, useFetchWithInfinite } from './Fetch';
import { useUser, useIsAdmin, useIsRole } from './Global';
import { EmailInstance, useEmailsNotifications } from './Emails';

import { uppercaseFirstLetter } from '../CustomObjects/Utils';

import CalendarScheduleIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarScheduleIcon';
import CalendarBlockIcon from '@nokia-csf-uxr/ccfk-assets/latest/CalendarBlockIcon';

import { dateCellRendererLocalizedDayOnly } from '../Renderers/DataTable';

export const MODES = {
    SCHEDULE: 'schedule',
    EDIT: 'edit',
    RELEASE: 'release',
    EXTEND: 'extend',
}

export const RESERVATION_STATUS = {
    BOOKED: 'BOOKED',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    INACTIVE: 'INACTIVE',
}

export const SLOT_TYPES = {
    USER: 'USER',
    TEST: 'TEST',
}

export const getValuePlaceholder = (v) => {
    if (v === '[[doc]]')
        return '[See documentation]';
    else
        return v;
}

export const useScheduleLabFields = () => {

    const messageRef = useRef(null);
    const datatableRef = useRef(null);

    const [showActive, setShowActive] = useState(true);
    const [reservationsToggleDisabled, setReservationsToggleDisabled] = useState(true);
    const [currentLabData, setCurrentLabData] = useState({});
    const [panelVisible, setPanelVisible] = useState(false);
    const [mode, setMode] = useState(MODES.SCHEDULE);
    const [role, setRole] = useState('');
    const [training, setTraining] = useState('');
    const [userSlots, setUserSlots] = useState([]);
    const [testSlots, setTestSlots] = useState([]);
    const [isLoadingSlots, setIsLoadingSlots] = useState(false);
    const [selectedUserSlot, setSelectedUserSlot] = useState({});
    const [selectedTestSlot, setSelectedTestSlot] = useState({});
    const [statusMessages, setStatusMessages] = useState([]);
    const [warningMessages, setWarningMessages] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const reset = () => {
        resetRole();
        resetTraining();
        resetUserSlots();
    }

    const resetRole = () => {
        setRole('');
    }

    const resetTraining = () => {
        setTraining('');
    }

    const resetUserSlots = () => {
        setUserSlots([]);
    }

    const resetTestSlots = () => {
        setTestSlots([]);
    }

    const resetSelectedUserSlot = () => {
        setSelectedUserSlot({});
    }

    const resetSelectedTestSlot = () => {
        setSelectedTestSlot({});
    }
    
    const resetMessages = () => {
        setStatusMessages([]);
        setWarningMessages([]);
        setErrorMessages([]);
    }

    const anySlotWasSelected = () => {
        return Object.keys(selectedUserSlot).length > 0 || Object.keys(selectedTestSlot).length > 0;
    }

    return {
        messageRef,
        datatableRef,
        showActive,
        setShowActive,
        reservationsToggleDisabled,
        setReservationsToggleDisabled,
        currentLabData,
        setCurrentLabData,
        panelVisible,
        setPanelVisible,
        mode,
        setMode,
        role,
        setRole,
        training,
        setTraining,
        userSlots,
        setUserSlots,
        testSlots,
        setTestSlots,
        isLoadingSlots,
        setIsLoadingSlots,
        selectedUserSlot,
        setSelectedUserSlot,
        selectedTestSlot,
        setSelectedTestSlot,
        statusMessages,
        setStatusMessages,
        warningMessages,
        setWarningMessages,
        errorMessages,
        setErrorMessages,
        reset,
        resetRole,
        resetTraining,
        resetUserSlots,
        resetTestSlots,
        resetSelectedUserSlot,
        resetSelectedTestSlot,
        resetMessages,
        anySlotWasSelected,
    }

}

export const useLabReservations = (fields) => {
    const user = useUser();
    const isAdmin = useIsAdmin();
    const isLabLead = useIsRole('labLead');

    const isFirstRequest = useRef(true);
    
    const getLabReservationsHeaders = () => {
        return (isAdmin || isLabLead) ? { showActive: fields.showActive } : { userEmail: user.email, showActive: fields.showActive };
    }

    const [
        userLabReservations,
        onGridReady,
        onKeywordChange,
        setFetchLabReservations,
        setLabReservationsHeaders,,
        fetchDataForCSV
    ] = useFetchWithInfinite(Env.BACKEND_SERVER_URL + 'LabScheduler', getLabReservationsHeaders(), 'GET', null, false, null, fields.datatableRef);

    const updateLabReservations = () => {
        fields.setReservationsToggleDisabled(true);

        setLabReservationsHeaders(getLabReservationsHeaders());
        setFetchLabReservations(true);
    };

    useEffect(() => {
        if (!isFirstRequest.current)
            updateLabReservations();
    // eslint-disable-next-line
    }, [fields.showActive]);

    useEffect(() => {
        if (!userLabReservations)
            return;
        fields.setReservationsToggleDisabled(false);
        isFirstRequest.current = false;
    // eslint-disable-next-line
    }, [userLabReservations]);

    return {
        userLabReservations,
        updateLabReservations,
        onGridReady,
        onKeywordChange,
        fetchDataForCSV
    };
}

export const useLabSingleReservation = () => {
    const [labReservation, setFetchLabReservation, , setLabReservationHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler', false);

    const getLabReservation = (id) => {
        setLabReservationHeaders({ reservationId: id });
        setFetchLabReservation(true);
    }

    return { labReservation, getLabReservation };
}

export const useLabRoles = (isALabOwner, isALabKPM) => {

    useEffect(() => {
        if (isALabOwner === null || isALabKPM === null)
            return;
        setLabRolesHeaders({
            isALabOwner: isALabOwner,
            isALabKPM: isALabKPM
        });
        setFetchLabRoles(true);
        // eslint-disable-next-line
    }, [isALabOwner, isALabKPM])
    
    const [labRoles, setFetchLabRoles,,setLabRolesHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/Roles');

    return { labRoles };
}

export const useLabTrainings = (isALabOwner, isALabKPM) => {
    const [labTrainings, setFetchLabTrainings, , setLabTrainingsHeaders, , setLabTrainings] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/Trainings', false);
    
    const resetLabTrainings = () => {
        setLabTrainings([]);
    }

    const updateLabTrainings = (role) => {
        setLabTrainingsHeaders({
            isALabOwner: isALabOwner,
            isALabKPM: isALabKPM,
            trainingRole: role
        });
        setFetchLabTrainings(true);
    }

    const getTrainingDetails = (displayName) => {
        const theTraining = labTrainings.filter(training => training.courseDisplayName === displayName);

        if (theTraining.length > 0)
            return theTraining[0];
        return null;
    }

    return { labTrainings, updateLabTrainings, getTrainingDetails, resetLabTrainings };
}

export const useLabOwners = () => {

    const user = useUser();

    const [labOwners] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/LabOwners', true);

    const isALabOwner = useMemo(() => {
        if (!labOwners)
            return null;
        return labOwners.some(labOwner => labOwner.includes(user.email));
        // eslint-disable-next-line
    }, [labOwners]);
    
    return { labOwners, isALabOwner };
}

export const useLabKPMs = () => {

    const user = useUser();

    const [labKPMs] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/LabKPMs', true);

    const isALabKPM = useMemo(() => {
        if (!labKPMs)
            return null;
        return labKPMs.some(labOwner => labOwner.includes(user.email));
        // eslint-disable-next-line
    }, [labKPMs]);

    return { labKPMs, isALabKPM };
}

export const useUserAvailableSlots = (getTrainingDetails, fields) => {
    const [slots, setFetchUserSlots, , setUserSlotsHeaders] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/Slots', false);
    
    const getUserSlots = (displayName) => {
        const theTraining = getTrainingDetails(displayName);

        if (!theTraining) {
            console.error('The display name selected does not exist in the trainings list.');
            return;
        }

        fields.setIsLoadingSlots(true);
        setUserSlotsHeaders({ courseNumber: theTraining.courseNumber });
        setFetchUserSlots(true);
    }

    const generateSlots = (slots) => {
        var newSlots = [];
        slots.forEach(slot => {
            newSlots.push({
                ...slot,
                icon: slot.numberOfAvailable <= 0 ? <CalendarBlockIcon /> : <CalendarScheduleIcon />,
                text: slot.text,
                subText: slot.subText,
                disabled: slot.numberOfAvailable <= 0 ? true : false
            });
        })

        return newSlots;
    }

    useEffect(() => {
        if (!slots)
            return;

        fields.setIsLoadingSlots(false);
        if (slots.statusMessages && slots.statusMessages.length > 0)
            fields.setStatusMessages(slots.statusMessages);
        if (slots.warningMessages && slots.warningMessages.length > 0)
            fields.setWarningMessages(slots.warningMessages);
        if (slots.errorMessages && slots.errorMessages.length > 0)
            fields.setErrorMessages(slots.errorMessages);

        if (slots.userSlots && slots.userSlots.length > 0)
            fields.setUserSlots(generateSlots(slots.userSlots));        
        if (slots.testSlots && slots.testSlots.length > 0)
            fields.setTestSlots(generateSlots(slots.testSlots));

    // eslint-disable-next-line
    }, [slots]);

    return { slots, getUserSlots };
}

export const useSechuleLabActions = (fields, updateLabReservations) => {
    const user = useUser();

    const [labScheduleSuccess, setScheduleLab, labScheduleError, , setLabScheduleBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler', false, {}, 'POST');
    const [labReleaseSuccess, setReleaseLab, labReleaseError, , setLabReleaseBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler', false, {}, 'PATCH');
    const [labExtendSuccess, setExtendLab, labExtendError, , setLabExtendBody] = useFetch(Env.BACKEND_SERVER_URL + 'LabScheduler/Extend', false, {}, 'POST');
    const { queueEmail, queueEmailSuccess, queueEmailError } = useEmailsNotifications();

    const scheduleLab = () => {
        var courseNumber;
        var startDate;
        var endDate;
        var type;
        
        if (Object.keys(fields.selectedUserSlot).length > 0) {
            courseNumber = fields.selectedUserSlot.courseNumber;
            startDate = fields.selectedUserSlot.startDate;
            endDate = fields.selectedUserSlot.endDate;
            type = SLOT_TYPES.USER;
        }
        else {
            courseNumber = fields.selectedTestSlot.courseNumber;
            startDate = fields.selectedTestSlot.startDate;
            endDate = fields.selectedTestSlot.endDate;
            type = SLOT_TYPES.TEST;
        }
        
        setLabScheduleBody({
            courseNumber: courseNumber,
            nokiaUserId: user.userId,
            userHandle: user.username,
            startDate: startDate,
            endDate: endDate,
            type: type,
        });
        setScheduleLab(true);
    }

    const releaseLab = () => {
        setLabReleaseBody({
            id: fields.currentLabData.id.toString(),
            isOpenAccess: fields.currentLabData.isOpenAccess
        });
        setReleaseLab(true);
    }

    const extendLab = () => {
        setLabExtendBody({
            userHandle: user.username,
            courseNumber: fields.currentLabData.courseNumber,
            labName: fields.currentLabData.labName,
            startDate: fields.currentLabData.startDate,
            waTemplate: fields.currentLabData.waTemplate
        });
        setExtendLab(true);
    }
    
    const onSubmitLabAction = () => {
        fields.messageRef.current.hideAreYouSureMessage();
        fields.messageRef.current.showProcessingDiag();

        if (fields.mode === MODES.SCHEDULE)
            scheduleLab();
        else if (fields.mode === MODES.RELEASE)
            releaseLab();
        else if (fields.mode === MODES.EXTEND)
            extendLab();
    }

    const sendSuccessScheduleEmail = (scheduleResponse) => {

        const startDate = dateCellRendererLocalizedDayOnly({ value: scheduleResponse.startDate });
        const endDate = dateCellRendererLocalizedDayOnly({ value: scheduleResponse.endDate });
        const credentials = scheduleResponse.labCredentials.length === 0 ? '' : 
            scheduleResponse.labCredentials.map(cred => (
                Object.entries(cred).map(([field, value]) => (
                    `<p><b>${uppercaseFirstLetter(field)}:</b> ${getValuePlaceholder(value)}</p>`
                )).join('')
            )).join('<br/><br/>');
            
        const emailInstance = new EmailInstance(
            'do_not_reply@nokia.com',
            [user.email],
            [],
            ['I_NKC_KMCD_LOG@nokia.com'],
            `Lab Reservation: ${scheduleResponse.courseDisplayName}`,
            `
                <h1>Lab Reservation: ${scheduleResponse.courseDisplayName}</h1>
                <p>Dear ${user.firstName} ${user.lastName},</p>
                <br/>
                <p>You have successfully reserved the lab with the following details:</p>
                <p><b>Lab:</b> ${scheduleResponse.labName}</p>
                <p><b>Type:</b> ${scheduleResponse.labType}</p>
                <p><b>Course:</b> ${scheduleResponse.courseDisplayName}</p>
                ${
                    scheduleResponse.isOpenAccess ?
                    `<p>This is an <b>'Open Access'</b> Lab.<br/><br/><b>NOTE: After your work is finished please release the lab.</b></p><br/>` :
                    `
                        <p><b>Start date:</b> ${startDate}</p>
                        <p><b>End date:</b> ${endDate}</p><br/>
                    `
                }
                ${
                    scheduleResponse.waTemplate &&
                    `<p><b>Lab Work Assignment Template:</b> <a href="${scheduleResponse.waTemplate}" target="_blank">Link</a></p><br/>`
                }
                <p><b>Please consider the following credentials:</b></p>
                ${credentials}
                <br/>
                <p>You can find more details of your reservation <a href="${Env.FRONTEND_SERVER_URL}labs/labbookings?id=${scheduleResponse.insertedId}" target="_blank">Here.</a></p>
            `
        );
        queueEmail(emailInstance);
    }

    const sendSuccessReleaseEmail = () => {
        const bccList = user.email === fields.currentLabData.userEmail ? [] : [user.email];

        const emailInstance = new EmailInstance(
            'do_not_reply@nokia.com',
            [fields.currentLabData.userEmail],
            bccList,
            ['I_NKC_KMCD_LOG@nokia.com'],
            `Lab Release: ${fields.currentLabData.courseDisplayName}`,
            `
                <h1>Lab Release: ${fields.currentLabData.courseDisplayName}</h1>
                <p>Dear ${fields.currentLabData.firstName} ${fields.currentLabData.lastName},</p>
                <br/>
                ${
                user.email === fields.currentLabData.userEmail ?
                    `<p>As per your request the following lab has been released:</p>` :
                    `<p>Your lab has been cancelled by <b>${user.email}</b>:</p>`
                }
                <p><b>Lab:</b> ${fields.currentLabData.labName}</p>
                <p><b>Type:</b> ${fields.currentLabData.labType}</p>
                <p><b>Course:</b> ${fields.currentLabData.courseDisplayName}</p>
                <br/>
                <p>If a new lab is needed you can <a href="${Env.FRONTEND_SERVER_URL}labs/labbookings?create" target="_blank">Book A Lab</a> in NKC.</p>
            `
        );
        queueEmail(emailInstance);
    }

    const successOperation = () => {
        var message = '';
        if (fields.mode === MODES.SCHEDULE)
            message = 'Lab successfully scheduled!';
        else if (fields.mode === MODES.RELEASE)
            message = 'Lab successfully released!';
        else if (fields.mode === MODES.EXTEND)
            message = 'Lab successfully extended!';
            
        fields.messageRef.current.hideProcessingDiag();
        fields.messageRef.current.successMessage(message);
        fields.setPanelVisible(false);
        fields.reset();
        updateLabReservations();
    }

    const failedOperation = (message) => {
        fields.messageRef.current.hideProcessingDiag();
        fields.messageRef.current.errorMessage(message);
    }
    
    // SCHEDULE SUCCESS => SEND EMAIL
    useEffect(() => {
        if (!labScheduleSuccess)
            return;
        sendSuccessScheduleEmail(labScheduleSuccess);
    // eslint-disable-next-line
    }, [labScheduleSuccess]);

    // RELEASE SUCCESS => SEND EMAIL
    useEffect(() => {
        if (!labReleaseSuccess)
            return;
        sendSuccessReleaseEmail();
    // eslint-disable-next-line
    }, [labReleaseSuccess]);

    // EXTEND SUCCESS => SEND EMAIL
    useEffect(() => {
        if (!labExtendSuccess)
            return;
        sendSuccessScheduleEmail(labExtendSuccess);
    // eslint-disable-next-line
    }, [labExtendSuccess]);

    // SCHEDULE EMAIL SUCCESS => MESSAGE WITH SUCCESS
    useEffect(() => {
        if (!queueEmailSuccess)
            return;
        successOperation();
    // eslint-disable-next-line
    }, [queueEmailSuccess]);
    
    // SCHEDULE ERROR => MESSAGE WITH ERROR
    useEffect(() => {
        if (!labScheduleError)
            return;
        failedOperation(labScheduleError.data);
    // eslint-disable-next-line
    }, [labScheduleError]);

    // RELEASE ERROR => MESSAGE WITH ERROR
    useEffect(() => {
        if (!labReleaseError)
            return;
        failedOperation(labReleaseError.data);
    // eslint-disable-next-line
    }, [labReleaseError]);

    // EXTEND ERROR => MESSAGE WITH ERROR
    useEffect(() => {
        if (!labExtendError)
            return;
        failedOperation(labExtendError.data);
    // eslint-disable-next-line
    }, [labExtendError]);

    // SCHEDULE EMAIL ERROR => MESSAGE WITH ERROR
    useEffect(() => {
        if (!queueEmailError)
            return;
        failedOperation(queueEmailError.data);
        // eslint-disable-next-line
    }, [queueEmailError]);
    
    return {
        onSubmitLabAction
    };
}
