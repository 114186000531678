
import React from 'react';

const About = () => {

    const style1 = { width: '65px', height: '37px', marginRight: '0px'};
    const style2 = { width: '53px', height: '44px' };
    const style3 = { width: '53px', height: '32px' };
    const style4 = { width: '52px', height: '52px' };
    const style5 = { width: '50px', height: '47px' };
    const style7 = { width: '53px', height: '38px' };
    const style9 = { height: '57px' };
    const style10 = { width: '53px', height: '53px' };
    const style11 = { width: '53px', height: '37px' };
    const style12 = { width: '37px', height: '37px' };
    const style13 = { width: '60px', height: '39px' };
    const style14 = { height: '60px' };
    const style15 = { width: '53px', height: '51px' };

    return (
        <div>
            <br />
            <div style={{width: '1050px', paddingLeft:'25px'}} >
                <table border="0" >
                    <tbody>
                        <tr>
                            <td valign="top">
                                <center><strong>KT Sessions</strong><br />
                                    <img alt="Knowledge Transfer" style={style3} src="assets/images/KT.png" />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top" >
                                Knowledge transfer is the process by which experienced employees share or distribute their knowledge, skills and behaviors to the employees replacing them.
                                Users can Schedule, Update Session, and provide Feedback for any session.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <center><strong>Certifications</strong><br />
                                    <img alt="Knowledge Transfer" style={style2} src="assets/images/Certifications.png" />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top" >
                                Enables teams to achieve professional certification qualification across  AAA, SBC, SDL, CSD, NTAS, NEF, CFX, and other Core
                                applications/products to assure the highest qualification for the team to perform their job or task.  Teams can view their
                                Certification Plan, their Planning KPIs, and their Planning KPIs per Delivery Team.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <center><strong>Labs</strong><br />
                                    <img alt="Labs" src="assets/images/labSchedule.png" style={style10} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top"  >
                                Schedule your Lab training to continue forward with your competence development and Submit your "Work Assignment" to notify the validation SMEs that you are ready for the next steps.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <center><strong>Planning</strong><br />
                                    <img alt="Planning" src="assets/images/kpiTarget.png" style={style11} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top"  >
                                Delivery Teams submit their certifications requests that are then planned for the calendar year.  KPIs are then set from that plan for each Delivery Team.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <center><strong>JIRA</strong><br />
                                    <img alt="JIRA" src="assets/images/jira.png" style={style12} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top"  >
                                JIRA tickets from Delivery Teams to R&D for tracking Closed, In Progress, Resolved, etc. ticket.&nbsp; Allows teams to search their tickets <span>associated </span>to the Delivery Team that created it and determine if the ticket was created because of an issue in the product or another reason.&nbsp; Reports&nbsp;
                            </td>

                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td valign="top" style={style14} >
                                <center><strong>Reports</strong><br />
                                    <img alt="Reports" style={style1} src="assets/images/reporting2.png" />
                                </center>
                            </td>
                            <td style={style14} ></td>
                            <td valign="top" style={style14} >
                                Allows team members to visualize data across multiple different domains and systems including KMCD Reports,
                                ACE Project Reports (special permissions needed), NokiaEDU Reports
                                , and Project Cube and others. Data is also federated across the different domains to gain further insights into the data.
                            </td>

                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <center><strong>AIM</strong><br />
                                    <img alt="img" src="assets/images/AIM_Logo.png" border="0" style={style4} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top" >
                                Advanced Integration Methods-Lite (AIM-Lite) is the delivery methodology used for Nokia customer projects.
                                <ul style={{ listStyleType:'disc' }}>
                                    <li>
                                        AIM-Lite defines
                                        <ol style={{ listStyleType:'circle' }}>
                                            <li>
                                                A set of gates that govern the transition between project execution steps
                                            </li>
                                            <li>
                                                A set of design deliverables that capture key project design information
                                            </li>
                                            <li>
                                                A set of project roles
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        AIM-Lite is based on the key principles of Systems Engineering.
                                    </li>
                                    <li>
                                        AIM-Lite draws on the cumulative knowledge and experience of expert practitioners globally.
                                    </li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={style9} >
                                <center><strong>PMAT</strong><br />
                                    <img alt="img" src="assets/images/PMAT_Logo.png" border="0" style={style5} />
                                </center>
                            </td>
                            <td style={style9} ></td>
                            <td valign="top" style={style9} >
                                Project Management (PMAT) provides standardized MS Project Online and SharePoint Online environment for the Market and Domain teams
                                to collaborate on deliverables, schedules, store project artifacts, and leverage project operational dashboards through Power BI.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={style9} >
                                <center><strong>Glossary</strong><br />
                                    <img alt="img" src="assets/images/glossary.png" border="0" style={style5} />
                                </center>
                            </td>
                            <td style={style9} ></td>
                            <td valign="top" style={style9} >
                                List of terms relating to a specific subject, text, or dialect, with explanations; a brief dictionary. NKC provides a particular domain of knowledge with the definitions for those terms.
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={style9} >
                                <center><strong>wetube</strong><br />
                                    <img alt="img" src="assets/images/wetube.png" border="0" style={style13} />
                                </center>
                            </td>
                            <td style={style9}></td>
                            <td valign="top" style={style9} >
                                Transfer of knowledge nuggets through short videos solve a practical problem. Like KT Sessions WeTUBE seeks to organize, create, capture or distribute knowledge through videos to ensure its availability for future users.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <center><strong>Contact Us</strong><br />
                                    <img alt="img" src="assets/images/email.png" border="0" style={style7} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top" >
                                Report an Issue within the Nokia Knowledge Center, Ask a Question to the team, propose a new feature, or just reach out to us for another reason. We are here for you.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <center><strong>Power Search</strong>
                                    <img alt="img" src="assets/images/magnifying.png" border="0" style={style15} />
                                </center>
                            </td>
                            <td>&nbsp;</td>
                            <td valign="top" >
                                The Knowledge Center Power Search (KCPS) brings together in a single pane of glass Nokia Yammer Channels, Confluence, Knowledge Transfer Sessions, Certified L3 and L4 professionals and PMAT.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div style={{ paddingLeft: '25px' }} >
                Version 23.1 Copyright &#169; 2023
            </div>
            <br />
        </div>
    );
}

export default About;