
import { useFetch } from "./Fetch";

import Env from "../CustomObjects/Environment";

export function EmailInstance(from, toList, ccList, bccList, subject, body, files = null) {
    this.from = from;
    this.to = toList;
    this.cc = ccList;
    this.bcc = bccList;
    this.subject = subject;
    this.body = body;
    this.files = files;
}

export const useEmailsNotifications = () => {

    const [queueEmailSuccess, setQueueEmail, queueEmailError, , setQueueEmailBody] = useFetch(Env.BACKEND_SERVER_URL + 'Notifications/QueueEmail', false, {}, 'POST', {}, false);
    const [queueEmailWithFileNamesSuccess, setQueueEmailWithFileNames, queueEmailWithFileNamesError, , setQueueEmailWithFileNamesBody] = useFetch(Env.BACKEND_SERVER_URL + 'Notifications/QueueEmailWithFileNames', false, {}, 'POST', {}, false);

    const queueEmail = (emailInstance) => {

        const formData = new FormData();

        formData.append('email', JSON.stringify(emailInstance));
        if (emailInstance.files && emailInstance.files.length > 0) {
            emailInstance.files.forEach(file => {
                formData.append('files', file.file);
            });
        }
        setQueueEmailBody(formData);
        setQueueEmail(true);
    }

    const queueEmailWithFileNames = (emailInstance) => {

        const formData = new FormData();

        formData.append('email', JSON.stringify(emailInstance));
        if (emailInstance.files && emailInstance.files.length > 0) {
            formData.append('fileNames', emailInstance.files);
        }
        setQueueEmailWithFileNamesBody(formData);
        setQueueEmailWithFileNames(true);
    }

    return {
        queueEmail,
        queueEmailSuccess,
        queueEmailError,
        queueEmailWithFileNames,
        queueEmailWithFileNamesSuccess,
        queueEmailWithFileNamesError
    };
};