import React, { forwardRef, useCallback, useEffect, useMemo, useState, useImperativeHandle } from 'react';
import ReactHtmlParser from 'html-react-parser';
import Coachmarks, {
    Coachmark,
    CoachmarkContent,
    CoachmarkHeader,
    CoachmarkFooter,
    CoachmarkCount,
    CoachmarkCloseButton
} from '@nokia-csf-uxr/ccfk/Coachmarks';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import Button, { ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';

import { CheckBox } from '../../Components/Common';

import { useSingleExecutionPerSession } from '../../CustomHooks/SingleExecution';

import '../../Styles/TipCoachmark.css';

const TipCoachmark = forwardRef((
    { identifier, steps, delay, condition },
    ref) => {

    const [currentStep, setCurrentStep] = useState(0);
    const [showCoachmarks, setShowCoachmarks] = useState(false);
    const [remaindMe, setRemaindMe] = useState(localStorage.getItem(identifier) === 'false' || localStorage.getItem(identifier) === null);

    useImperativeHandle(ref, () => ({
        showTips: () => {
            setShowCoachmarks(true);
        },
    }));

    useEffect(() => {
        const hideCoachmarks = localStorage.getItem(identifier);

        if (remaindMe && hideCoachmarks === 'true')
            localStorage.setItem(identifier, false);
        else if (!remaindMe && (hideCoachmarks === 'false' || hideCoachmarks === null))
            localStorage.setItem(identifier, true);
    }, [remaindMe, identifier]);

    useSingleExecutionPerSession(identifier, () => {
        const hideCoachmarks = localStorage.getItem(identifier);

        if (hideCoachmarks === 'true')
            setShowCoachmarks(false);
        else if (hideCoachmarks === null || hideCoachmarks === 'false')
            setShowCoachmarks(true);
    }, delay, condition);

    const navigateToStep = useCallback((index) => {
        const isInvalidIndex = index < 0 || index > steps.length;
        if (!isInvalidIndex) {
            const element = document.querySelector(steps[index].target);
            if (element)
                element.scrollIntoView();
            setCurrentStep(index);
        }
    }, [steps]);

    const onPreviousStep = useCallback(() => {
        if (currentStep - 1 < 0)
            setShowCoachmarks(false);
        else
            navigateToStep(currentStep - 1);
    }, [currentStep, navigateToStep]);

    const onNextStep = useCallback(() => {
        if (currentStep + 1 === steps.length)
            setShowCoachmarks(false);
        else
            navigateToStep(currentStep + 1);
    }, [currentStep, navigateToStep, steps]);

    const onClose = () => {
        setShowCoachmarks(false);
        setCurrentStep(0);
    };

    const coachmarkElements = useMemo(() => {
        if (!steps || steps.length === 0)
            return null;

        return steps.map((step, i) => (
            <Coachmark
                key={i}
                className='coachmark-item'
                positioningOptions={step.positioningOptions}
                target={step.target}
                show={showCoachmarks && i === currentStep}
                onClickOutside={onClose}
                balloon
                variant='related-custom'
            >
                <CoachmarkHeader>
                    <Typography variant="default" typography="TITLE_16">{step.title}</Typography>
                    <CoachmarkCloseButton>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </CoachmarkCloseButton>
                </CoachmarkHeader>
                <CoachmarkContent>
                    {ReactHtmlParser(step.content)}
                </CoachmarkContent>
                <CheckBox label='Keep showing?' value={remaindMe} setValue={setRemaindMe} />
                <CoachmarkFooter border='true'>
                    <CoachmarkCount>{currentStep + 1}/{steps.length}</CoachmarkCount>
                    {
                        currentStep >= 1 &&
                        <Button onClick={onPreviousStep} data-previous>
                            <ButtonText>Previous</ButtonText>
                        </Button>
                    }
                    <Button onClick={onNextStep} autoFocus data-next>
                        <ButtonText>{currentStep + 1 === steps.length ? 'Close' : 'Next'}</ButtonText>
                    </Button>
                </CoachmarkFooter>
            </Coachmark>
        ))
    }, [currentStep, onPreviousStep, onNextStep, showCoachmarks, steps, remaindMe]);

    return (
        <>
            {
                steps && steps.length > 0 &&
                <Coachmarks
                    className='coachmark-container'
                    show={showCoachmarks}
                    //style={{ display: showCoachmarks ? 'block' : 'hidden' }}
                    modalProps={{ onRequestClose: onClose }}
                >
                    {coachmarkElements}
                </Coachmarks>
            }
        </>
    );

});

TipCoachmark.defaultProps = {
    identifier: 'tip-coachmark',
    steps: [],
    delay: 0,
    condition: true,
}

export default TipCoachmark