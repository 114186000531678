import React, { useRef } from 'react';

import NotificationOutlineIcon from '@nokia-csf-uxr/ccfk-assets/legacy/NotificationOutlineIcon';
import EditIcon from '@nokia-csf-uxr/ccfk-assets/latest/EditIcon';
import CloseCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCircleFillIcon';
import StatusBusyIcon from '@nokia-csf-uxr/ccfk-assets/latest/StatusBusyIcon';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import VerifiedIcon from '@mui/icons-material/Verified';

import {
    statusBarRenderer,
    dateCellRendererLocalTime,
    link
} from '../../Renderers/DataTable';

import {
    ModuleContainer,
    ModuleHeader,
    ModuleContent,
    DataTable,
    Toggle,
    Message
} from "../../Components/Common";
import {
    UploadAssignmentButton,
    WorkAssignmentOverlayPanel
} from '../../Components/Labs';

import {
    useWAFields,
    useWorkAssignments,
    useSingleWorkAssignment,
    useWASubmit,
    STATUS
} from '../../CustomHooks/WorkAssignments';
import { useURLParamsAction } from '../../CustomHooks/URLParams';

const L3WorkAssignments = () => {

    const datatableRef = useRef(null);
    const overlaypanelRef = useRef(null);

    const fields = useWAFields();
    const { assignments, refreshAssignments, onToggleChange } = useWorkAssignments(fields, datatableRef);
    const [assignment, getWorkAssignment] = useSingleWorkAssignment();
    const onSubmitWA = useWASubmit(fields, overlaypanelRef, refreshAssignments);

    useURLParamsAction(['id'], assignment,
        (paramsObj) => {
            fields.setShowProcessingDiag(true);
            getWorkAssignment(paramsObj['id']);
        },
        () => {
            fields.setShowProcessingDiag(false);
            if (assignment.length > 0)
                fields.onEditAssignment({ data: assignment[0] });
        }
    );

    return (
        <ModuleContainer>
            <ModuleHeader
                backgroundPath={require('../../Images/BannerBackgrounds/banner-background-2.jpg')}
                backgroundPosition='50% 65%'
            >
                Work Assignments
            </ModuleHeader>
            <ModuleContent>
                <DataTable
                    ref={datatableRef}
                    data={assignments}
                    columnDefinitions={{
                        status: {
                            type: 'status',
                            headerName: '',
                            width: 44,
                            maxWidth: 44,
                            resizable: false,
                            cellRenderer: statusBarRenderer,
                            cellRendererParams: {
                                valueColorMap: {
                                    [STATUS.UPLOADED]: 'var(--ff-color-nokia-blue-400)',
                                    [STATUS.PASSED]: 'var(--ff-color-green-400)',
                                    [STATUS.FAILED]: 'var(--ff-color-red-500)',
                                    [STATUS.REJECTED]: 'var(--ff-color-red-700)',
                                },
                                valueIconMap: {
                                    [STATUS.UPLOADED]: CloudDoneIcon,
                                    [STATUS.PASSED]: VerifiedIcon,
                                    [STATUS.FAILED]: CloseCircleFillIcon,
                                    [STATUS.REJECTED]: StatusBusyIcon,
                                }
                            }
                        },
                        courseDisplayName: {
                            headerName: 'Certification',
                            flex: 1.2,
                        },
                        createDate: {
                            type: 'date',
                            cellRenderer: dateCellRendererLocalTime,
                            flex: 0.6,
                        },
                        modDate: {
                            headerName: 'Modification Date',
                            type: 'date',
                            cellRenderer: dateCellRendererLocalTime,
                            flex: 0.6,
                        },
                        courseRole: {
                            headerName: 'Role',
                            flex: 0.5
                        },
                        userEmail: {
                            headerName: 'Email',
                            flex: 0.6,
                        },
                        path: {
                            headerName: 'Assignments',
                            flex: 0.4,
                            cellRenderer: link,
                            cellRendererParams: {
                                text: 'Link...'
                            }
                        },
                        feedback: {
                            flex: 1.0,
                        },
                    }}
                    withTableOptions={{
                        onRefresh: refreshAssignments,
                        onFilterRemove: true,
                        onExcelFile: {
                            fileName: 'Assignments.csv'
                        },
                        onAdjustColumns: false
                    }}
                    withRowOptions={[
                        {
                            icon: <NotificationOutlineIcon />,
                            tooltip: 'Notify SME',
                            callback: ({ data }) => {
                                fields.setCurrentAssignmentData(data);
                                fields.showNotificationDialog();
                            },
                            renderCondition: ({ data }) => {
                                return data.status === STATUS.UPLOADED && fields.userIsAdminOrWALead()
                            }
                        },
                        {
                            icon: <EditIcon />,
                            tooltip: 'details',
                            callback: fields.onEditAssignment
                        },
                    ]}
                    columnsIgnore={fields.userIsSMEOrAdminOrWALead() ? fields.defaultColsToHide : [...fields.defaultColsToHide, 'path']}
                    withAdditionalComponents={{
                        toolbar: <Toggle
                            checked={fields.showEvaluated}
                            setChecked={fields.setShowEvaluated}
                            checkedText='Evaluation Complete'
                            uncheckedText='Pending Evaluation'
                            onChange={onToggleChange}
                            disabled={fields.toggleDisabled}
                        />,
                        searchbar: <UploadAssignmentButton onClick={fields.onUploadClick} />

                    }}
                    withRowClick={fields.onEditAssignment}
                    withSearch
                    withFilters
                    noRowsMessage={fields.noRowsMessage()}
                />
                <WorkAssignmentOverlayPanel
                    ref={overlaypanelRef}
                    fields={fields}
                />
                <Message
                    title="Work Assignment"
                    variant={fields.infoMessageVariant}
                    message={fields.infoMessage}
                    open={fields.showInfoMessage}
                    setOpen={fields.setShowInfoMessage}
                    actionButtonLabel="OK"
                    onSubmit={() => fields.setShowInfoMessage(false)}
                />
                <Message
                    title="Work Assignment"
                    message='Please wait while request is processed...'
                    open={fields.showProcessingDiag}
                    withLoading
                />
                <Message
                    title="Work Assignment"
                    variant='warning'
                    message={fields.areYouSureMessage}
                    open={fields.showAreYouSureMessage}
                    setOpen={fields.setShowAreYouSureMessage}
                    onSubmit={onSubmitWA}
                    actionButtonLabel='Yes'
                    cancelButtonLabel='No'
                />
            </ModuleContent>
        </ModuleContainer>
    );

}

export default L3WorkAssignments;
