import React, { forwardRef, useCallback, useEffect, useRef, useState, useImperativeHandle, useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import Pagination from './Pagination';

import DataTableToolBar from '../Datatable/DataTableToolBar';
import DateFilter from '../Datatable/DateFilter';
import { CircularLoading, TextWithChips } from '../../Components/Common';
import {
    usePaging,
    useClientSearch,
    useColumnDefinitions,
    useTableOptions,
    tableColumnTypes
} from '../../CustomHooks/Datatable';

import SearchCircleIcon from '@nokia-csf-uxr/ccfk-assets/latest/SearchCircleIcon';

import '../../Styles/DataTable.css';

const DataTable = forwardRef((
    {
        title, data, rowClassRules, columnDefinitions, columnsIgnore, withRowClick, withRowOptions,
        withTableOptions, withClientPagination, withServerInfiniteRow, withServerPagination, withAdditionalComponents,
        withSearch, withFilters, noRowsMessage
    }
    , ref) => {

    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const [searchChips, setSearchChips] = useState([]);
    const [isFetchingCSV, setIsFetchingCSV] = useState(false);
    const gridRef = useRef(null);
    const searchRef = useRef(null);

    const columnDefs = useColumnDefinitions(data, columnDefinitions, columnsIgnore, withRowOptions, withFilters);
    const [currentPage, totalPages, totalRows, onFirstPage, onPreviousPage, onNextPage, onLastPage, onGoToPage, onPaginationChanged] = usePaging(gridRef);
    const [isExternalFilterPresent, doesExternalFilterPass, onKeywordChange] = useClientSearch(gridApi, searchRef);
    const [onFilterRemove, onExcelFile, onAdjustColumns] = useTableOptions(gridApi, searchRef, withTableOptions, withServerInfiniteRow, withServerPagination);

    const isClientModel = useMemo(() =>
        !((withServerInfiniteRow && withServerInfiniteRow.onGridReady) || (withServerPagination && withServerPagination.onGridReady))
    , [withServerInfiniteRow, withServerPagination]);

    useEffect(() => {
        if (gridApi && isClientModel) {
            if (!data)
                gridApi.showLoadingOverlay();
            gridApi.setGridOption('rowData', data);
        }
    }, [data, gridApi, withServerInfiniteRow, withServerPagination, isClientModel]);

    useImperativeHandle(ref, () => ({
        setClientDataNullAndShowLoadingOverlay: () => {
            if (isClientModel && gridApi) {
                gridApi.setGridOption('rowData', null);
                gridApi.showLoadingOverlay();
            }
        },
        searchRef: () => searchRef,
        gridApi: () => gridApi,
        columnApi: () => columnApi,
        setIsFetchingCSV: (value) => setIsFetchingCSV(value)
    }));

    const onGridReadyDefault = useCallback(({ api, columnApi }) => {
        setGridApi(api);
        setColumnApi(columnApi);
    }, []);

    const onRowClicked = (data) => {
        if (data.eventPath === undefined || data.eventPath.find(e => e.classList !== undefined && e.classList.contains("stop-row-action")) === undefined) {
            withRowClick(data);
        }
    }

    const LoadingTableOverlay = () => {
        return (
            <CircularLoading overlay size={64} />
        );
    }

    if (withClientPagination) {
        return (
            <>
                {
                    withSearch &&
                        <div className="search-container">
                            <TextWithChips
                                ref={searchRef}
                                values={searchChips}
                                setValues={setSearchChips}
                                onChange={onKeywordChange}
                                inputIcon={<SearchCircleIcon color='var(--ff-color-tone-500)' />}
                                placeholder='Search...'
                                underlined
                            />
                            {
                                withAdditionalComponents && withAdditionalComponents.searchbar &&
                                withAdditionalComponents.searchbar
                            }
                        </div>
                }
                {
                    withTableOptions && Object.keys(withTableOptions).length > 0 &&
                    <DataTableToolBar
                        title={title}
                        onFilterRemove={withTableOptions.onFilterRemove ? onFilterRemove : null}
                        onRefresh={withTableOptions.onRefresh ? withTableOptions.onRefresh : null}
                        onExcelFile={withTableOptions.onExcelFile ? onExcelFile : null}
                        onAdjustColumns={withTableOptions.onAdjustColumns ? onAdjustColumns : null}
                        additionalComponents={withAdditionalComponents?.toolbar ?? null}
                        isFetchingCSV={isFetchingCSV}
                    />
                }
                <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        enableBrowserTooltips='true'
                        columnDefs={columnDefs}
                        rowClassRules={rowClassRules}
                        columnTypes={tableColumnTypes}
                        rowSelection="single"
                        onRowClicked={withRowClick ? onRowClicked: null}
                        rowClass="selected-row"
                        onGridReady={onGridReadyDefault}
                        pagination='true'
                        animateRows='true'
                        paginationAutoPageSize={true}
                        suppressPaginationPanel={true}
                        onPaginationChanged={onPaginationChanged}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        components={{
                            agLoadingOverlay: LoadingTableOverlay,
                            agDateInput: DateFilter
                        }}
                        overlayNoRowsTemplate={noRowsMessage}
                    />
                    <Pagination
                        onFirstPage={onFirstPage}
                        onPreviousPage={onPreviousPage}
                        onNextPage={onNextPage}
                        onLastPage={onLastPage}
                        onGoToPage={onGoToPage}
                        currentPage={currentPage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                    />
                </div>
            </>
        );
    }

    if (withServerInfiniteRow &&
        withServerInfiniteRow.onKeywordChange &&
        withServerInfiniteRow.onGridReady &&
        withServerInfiniteRow.onExcelFile
    ) {
        return (
            <>
                {
                    withSearch &&
                    <div className="search-container">
                        <TextWithChips
                            ref={searchRef}
                            values={searchChips}
                            setValues={setSearchChips}
                            onChange={withServerInfiniteRow.onKeywordChange}
                            inputIcon={<SearchCircleIcon color='var(--ff-color-tone-500)' />}
                            placeholder='Search...'
                            underlined
                        />
                        {
                            withAdditionalComponents && withAdditionalComponents.searchbar &&
                            withAdditionalComponents.searchbar
                        }
                    </div>
                }
                {
                    withTableOptions && Object.keys(withTableOptions).length > 0 &&
                    <DataTableToolBar
                        title={title}
                        onFilterRemove={withTableOptions.onFilterRemove ? onFilterRemove : null}
                        onRefresh={withTableOptions.onRefresh ? withTableOptions.onRefresh : null}
                        onExcelFile={withTableOptions.onExcelFile ? onExcelFile : null}
                        onAdjustColumns={withTableOptions.onAdjustColumns ? onAdjustColumns : null}
                        additionalComponents={withAdditionalComponents?.toolbar ?? null}
                        isFetchingCSV={isFetchingCSV}
                    />
                }
                <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                    <AgGridReact
                        rowModelType='infinite'
                        enableBrowserTooltips='true'
                        columnDefs={columnDefs}
                        rowClassRules={rowClassRules}
                        columnTypes={tableColumnTypes}
                        rowSelection="single"
                        onRowClicked={withRowClick ? onRowClicked : null}
                        rowClass="selected-row"
                        onGridReady={(props) => { withServerInfiniteRow.onGridReady(props); onGridReadyDefault(props); }}
                        cacheBlockSize={100}
                        cacheOverflowSize={2}
                        maxBlocksInCache={4}
                        infiniteInitialRowCount={100}
                        maxConcurrentDatasourceRequests={1}
                        components={{
                            agLoadingOverlay: LoadingTableOverlay,
                            agDateInput: DateFilter
                        }}
                        overlayNoRowsTemplate={noRowsMessage}
                    />
                </div>
            </>
        );
    }

    if (withServerPagination &&
        withServerPagination.onKeywordChange &&
        withServerPagination.onGridReady &&
        withServerPagination.onExcelFile
    ) {
        return (
            <>
                {
                    withSearch &&
                    <div className="search-container">
                        <TextWithChips
                            ref={searchRef}
                            values={searchChips}
                            setValues={setSearchChips}
                            onChange={withServerPagination.onKeywordChange}
                            inputIcon={<SearchCircleIcon color='var(--ff-color-tone-500)' />}
                            placeholder='Search...'
                            underlined
                        />
                        {
                            withAdditionalComponents && withAdditionalComponents.searchbar &&
                            withAdditionalComponents.searchbar
                        }
                    </div>
                }
                {
                    withTableOptions && Object.keys(withTableOptions).length > 0 &&
                    <DataTableToolBar
                        title={title}
                        onFilterRemove={withTableOptions.onFilterRemove ? onFilterRemove : null}
                        onRefresh={withTableOptions.onRefresh ? withTableOptions.onRefresh : null}
                        onExcelFile={withTableOptions.onExcelFile ? onExcelFile : null}
                        onAdjustColumns={withTableOptions.onAdjustColumns ? onAdjustColumns : null}
                        additionalComponents={withAdditionalComponents?.toolbar ?? null}
                        isFetchingCSV={isFetchingCSV}
                    />
                }
                <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowModelType='infinite'
                        enableBrowserTooltips='true'
                        columnDefs={columnDefs}
                        rowClassRules={rowClassRules}
                        columnTypes={tableColumnTypes}
                        rowSelection="single"
                        onRowClicked={withRowClick ? onRowClicked : null}
                        rowClass="selected-row"
                        onGridReady={(props) => { withServerPagination.onGridReady(props); onGridReadyDefault(props); }}
                        cacheBlockSize={100}
                        cacheOverflowSize={2}
                        maxBlocksInCache={4}
                        infiniteInitialRowCount={100}
                        maxConcurrentDatasourceRequests={1}
                        pagination={true}
                        paginationAutoPageSize={true}
                        suppressPaginationPanel={true}
                        onPaginationChanged={onPaginationChanged}
                        components={{
                            agLoadingOverlay: LoadingTableOverlay,
                            agDateInput: DateFilter
                        }}
                        overlayNoRowsTemplate={noRowsMessage}
                    />
                    <Pagination
                        onFirstPage={onFirstPage}
                        onPreviousPage={onPreviousPage}
                        onNextPage={onNextPage}
                        onLastPage={onLastPage}
                        onGoToPage={onGoToPage}
                        currentPage={currentPage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {
                withSearch &&
                <div className="search-container">
                    <TextWithChips
                        ref={searchRef}
                        values={searchChips}
                        setValues={setSearchChips}
                        onChange={onKeywordChange}
                        inputIcon={<SearchCircleIcon color='var(--ff-color-tone-500)' />}
                        placeholder='Search...'
                        underlined
                    />
                    {
                        withAdditionalComponents && withAdditionalComponents.searchbar &&
                        withAdditionalComponents.searchbar
                    }
                </div>
            }
            {
                withTableOptions && Object.keys(withTableOptions).length > 0 &&
                <DataTableToolBar
                    title={title}
                    onFilterRemove={withTableOptions.onFilterRemove ? onFilterRemove : null}
                    onRefresh={withTableOptions.onRefresh ? withTableOptions.onRefresh : null}
                    onExcelFile={withTableOptions.onExcelFile ? onExcelFile : null}
                    onAdjustColumns={withTableOptions.onAdjustColumns ? onAdjustColumns : null}
                    additionalComponents={withAdditionalComponents?.toolbar ?? null}
                    isFetchingCSV={isFetchingCSV}
                />
            }
            <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    enableBrowserTooltips='true'
                    columnDefs={columnDefs}
                    rowClassRules={rowClassRules}
                    columnTypes={tableColumnTypes}
                    rowSelection="single"
                    animateRows='true'
                    onRowClicked={withRowClick ? onRowClicked : null}
                    rowClass="selected-row"
                    onGridReady={onGridReadyDefault}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    components={{
                        agLoadingOverlay: LoadingTableOverlay,
                        agDateInput: DateFilter
                    }}
                    overlayNoRowsTemplate={noRowsMessage}
                />
            </div>
        </>
    );
});

DataTable.defaultProps = {
    columnDefinitions: {},
    rowClassRules: {},
    columnsIgnore: [],
    noRowsMessage: 'No data to display'
}

export default DataTable;