import React from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import { TextArea, FloatingPanel } from '../Components/Common';
import { ThinkingResponse } from '../Components/PowerSearch/LLMMessages';

import Button from '@nokia-csf-uxr/ccfk/Button';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import ArrowBoldDownCircleIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowBoldDownCircleIcon';
import AddIcon from '@nokia-csf-uxr/ccfk-assets/latest/AddIcon';

import logo from '../Images/Logo/psx_logo_blue_500.png';

import {
	usePowerSearchFields,
	usePowerSearchQuery,
	usePowerSearchMessages,
	usePowerSearchFeedback,
	usePowerSearchChatBoxActions,
	usePowerSearchSession
} from '../CustomHooks/PowerSearchX';

import '../Styles/PowerSearchX.css';

const PowerSearchX = () => {
	const fields = usePowerSearchFields();
	const { isAtBottom, scrollToBottomSmooth } = usePowerSearchChatBoxActions(fields);
	const { generatedMessages, updateMessage, updateMessages } = usePowerSearchMessages(fields, isAtBottom, scrollToBottomSmooth);
	const submitFeedback = usePowerSearchFeedback(fields, updateMessage);
	const submitPrompt = usePowerSearchQuery(fields, updateMessages);
	const { createNewSession } = usePowerSearchSession(fields);

	const onChangeHandler = (e) => {
		if (e.target.value !== "\n") {
			fields.setPrompt(e.target.value);
		}
	}

	const onKeyDownHandler = (e) => {
		if (e.keyCode === 13 && !e.shiftKey && !fields.isThinking) {
			submitPrompt();
		}
	}

	return (
		<div className='psllm-module-container'>
			<div className='psllm-top-section-container'>
				<img src={logo} alt='Power Search X' className='psllm-logo' />
				<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='New chat'>
					<IconButton disabled={fields.isThinking || fields.servicesAreDown} onClick={createNewSession}>
						<AddIcon size={20} color={'var(--ff-color-nokia-blue-500)'} />
					</IconButton>
				</Tooltip>
			</div>
			<div className='psllm-chat-container'>
				<div ref={fields.scrollDivRef} className='psllm-chat-box'>
					<div>
						{generatedMessages}
						{fields.isThinking && <ThinkingResponse/>}
					</div>
				</div>
				{
					fields.showGoDown &&
					<div className='psllm-chat-go-down' style={{ bottom: fields.goDownButtonBottom }}>
						<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Go to the bottom'>
							<IconButton onClick={scrollToBottomSmooth}>
								<ArrowBoldDownCircleIcon size={24} color={'var(--ff-color-nokia-blue-500)'} />
							</IconButton>
						</Tooltip>
					</div>
				}
				<div className='psllm-chat-input'>
					<TextField
						id="outlined-textarea"
						inputRef={fields.textFieldRef}
						placeholder="Ask me a question..."
						multiline
						fullWidth={true}
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									<Tooltip modifiers={[{ name: 'offset', options: { offset: [0, 2] } }]} placement="bottom" tooltip='Send'>
										<IconButton onClick={submitPrompt} disabled={fields.isThinking || fields.servicesAreDown}>
											<SendIcon />
										</IconButton>
									</Tooltip>
								</InputAdornment>,
						}}
						value={fields.prompt}
						onChange={onChangeHandler}
						onKeyDown={onKeyDownHandler}
						//disabled={fields.isThinking}
					/>
					<Typography className='psllm-disclaimer-text'>
						All content generated while using this tool is saved and may be subject to audit and analysis for the purpose of improving the tool's functionality and user experience. The generated content may be inaccurate or false.
					</Typography>
				</div>
			</div>
			<FloatingPanel
				title="Feedback"
				initialSize={{ width: 200, height: 300 }}
				startPosition={{ x: window.innerWidth - 800, y: (window.innerHeight / 2) - 300 }}
				visible={fields.feedback.show}
				setVisible={(s) => fields.setFeedback({ ...fields.feedback, show : s})}
			>
				<div className='psllm-floating-feedback'>
					<TextArea
						value={fields.feedback.text}
						setValue={(t) => fields.setFeedback({ ...fields.feedback, text: t })}
						placeholder={fields.feedback.placeholder}
					/>
					<div>
						<Button variant="secondary-bigger-font" onClick={() => fields.setFeedback({ ...fields.feedback, show: false })} autoFocus>Cancel</Button>
						<Button variant="call-to-action-bigger-font" onClick={submitFeedback}>Submit</Button>
					</div>
				</div>
			</FloatingPanel>
		</div>
    );
}

export default PowerSearchX;
