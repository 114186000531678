import Env from "../CustomObjects/Environment";

import { useEffect } from "react";
import { useFetch } from "./Fetch";

export const usePageHits = (url) => {

    const [, setUpdatePageHit,,, setPageHitBody] = useFetch(Env.BACKEND_SERVER_URL + 'Monitoring/PageHit', false, {}, 'POST');

    const updatePageHit = () => {
        console.log("Updating page hit for: ", url);
        setPageHitBody({ url : url });
        setUpdatePageHit(true);
    }

    useEffect(() => {
        updatePageHit();
        // eslint-disable-next-line
    }, []);;
}