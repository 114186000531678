import React from 'react';

import { RowSeparator, FileUpload } from "../Common";

const FilesUpload = ({ fields }) => {
	const fileUploadProps = {
		values: fields.filesUploaded,
		setValues: fields.setFilesUploaded,
		width: '100%',
		margin: '0rem',
		unit: 'MB'
	}

	return (
		<>
			<RowSeparator />
			<FileUpload {...fileUploadProps} />
			<RowSeparator />
		</>
	);
}

export default FilesUpload;