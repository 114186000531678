import _uniqueId from 'lodash/uniqueId';

import { useEffect, useMemo, useRef, useState } from "react";
import Env from "../CustomObjects/Environment";

import { LLMResponse, UserPrompt } from '../Components/PowerSearch/LLMMessages';

import { useFetch } from "./Fetch";
import { useUser } from "./Global";

const MESSAGE_TYPE = {
	PROMPT: 'prompt',
	RESPONSE: 'response',
}

const defaultResponse = {
	type: MESSAGE_TYPE.RESPONSE,
	text: 'Hello, how can I help you today?'
}

const defaultServicesFailureResponse = {
	type: MESSAGE_TYPE.RESPONSE,
	text: '**Oops! It seems like we\'re experiencing some technical difficulties right now. &#128679; Our team is already on it and working hard to get everything back up and running smoothly. Thanks for your understanding!**'
};

const defaultErrorResponse = {
	type: MESSAGE_TYPE.RESPONSE,
	text: 'Sorry, I am unable to process your request at this time.'
};

const defaultFeedback = {
	show: false,
    placeholder: 'Please provide feedback...',
    text: '',
    upDown: null,
    messageObj: null,
};

export const usePowerSearchFields = () => {
	const scrollDivRef = useRef(null);
	const textFieldRef = useRef(null);

	const [prompt, setPrompt] = useState("");
	const [isThinking, setIsThinking] = useState(false);
	const [servicesAreDown, setServicesAreDown] = useState(false);
	const [messages, setMessages] = useState([]);
	const [showGoDown, setShowGoDown] = useState(false);
	const [goDownButtonBottom, setGoDownButtonBottom] = useState('0');

	const [feedback, setFeedback] = useState(defaultFeedback);

	return {
		scrollDivRef,
		textFieldRef,

		prompt,
		setPrompt,

		isThinking,
		setIsThinking,

		servicesAreDown,
		setServicesAreDown,

		messages,
		setMessages,

		showGoDown,
		setShowGoDown,

		goDownButtonBottom,
		setGoDownButtonBottom,

		feedback,
		setFeedback,
	}
}

export const usePowerSearchMessages = (fields, isAtBottom, scrollToBottom) => {

	const updateMessages = (message) => {
		fields.setMessages(m => [...m, message]);
    }

	const updateMessage = (id, message) => {
		fields.setMessages(m => m.map(m => m.id === id ? message : m));
	}

	const getMessage = (id) => {
		return fields.messages.find(m => m.id === id);
    }

	// Scroll to the bottom when the messages change
	useEffect(() => {
		const lastMessage = fields.messages[fields.messages.length - 1];
		if (isAtBottom.current || (lastMessage !== undefined && lastMessage.type === MESSAGE_TYPE.PROMPT))
			scrollToBottom();
		// eslint-disable-next-line
	}, [fields.messages]);

	// Generate the messages
	const generatedMessages = useMemo(() => fields.messages.map(function (m, i) {
		if (m.type === MESSAGE_TYPE.RESPONSE) {
			return (
				<div className='psllm-message' key={i}>
					<LLMResponse
						fields={fields}
						messageObj={m}
						scrollToBottom={scrollToBottom}
						updateMessage={updateMessage}
						isAtBottom={isAtBottom}
					/>
				</div>
			);
		} else {
			return (
				<div className='psllm-message' key={i}>
					<UserPrompt
						messageObj={m}
						fields={fields}
						scrollToBottom={scrollToBottom}
					/>
				</div>
			);
		}
		// eslint-disable-next-line
	}), [fields.messages]);

	return {
		generatedMessages,
		updateMessages,
		getMessage,
		updateMessage
	};
}

export const usePowerSearchQuery = (fields, updateMessages) => {
    const [response, setGetResponse, errorResponse, , setRequestBody] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch', false, {}, 'POST', {}, true, true);
    const userInfo = useUser();

	const submitPrompt = () => {
		if (fields.prompt && !fields.servicesAreDown) {
			updateMessages({
				id: _uniqueId(),
				type: MESSAGE_TYPE.PROMPT,
				text: fields.prompt
			});
			setRequestBody({
				username: userInfo.username,
				messages: [{
					role: 'user',
					content: fields.prompt
				}]
			});
			setGetResponse(true);
			fields.setPrompt("");
			fields.setIsThinking(true);
		}
	}

	const formatResponse = (response) => {
		const data = response.data;

		return {
			id: _uniqueId(),
			type: MESSAGE_TYPE.RESPONSE,
			text: data.response,
			configurationId: data.configurationId,
			interactionId: data.interactionId,
			conversationId: data.conversationId,
			knowledge: data.knowledge,
			sources: data.sources,
			showKnowledgeFragments: false,
			positiveFeedback: null,
			feedbackText: null,
			clipboardTooltip: 'Copy',
		};
	}
	
	useEffect(() => {
		if (response) {
			if (response.data)
				updateMessages(formatResponse(response));
			else
				updateMessages(defaultErrorResponse);
			fields.setIsThinking(false);
		}
		if (errorResponse) {
			updateMessages(defaultErrorResponse);
			fields.setIsThinking(false);
		}
		// eslint-disable-next-line
	}, [setGetResponse, response, errorResponse, defaultErrorResponse]);

	return submitPrompt;
}

export const usePowerSearchFeedback = (fields, updateMessage) => {
	const [successFeedback, setSubmitFeedback, failedFeedback, , setFeedbackBody] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/Feedback', false, {}, 'POST');

	const submitFeedback = () => {
		fields.setFeedback({
			...fields.feedback,
			show: false
		});
		updateMessage(fields.feedback.messageObj.id, { ...fields.feedback.messageObj, positiveFeedback: fields.feedback.upDown, feedbackText: fields.feedback.text });
		setFeedbackBody({
			interactionId: fields.feedback.messageObj.interactionId,
			conversationId: fields.feedback.messageObj.conversationId,
			text: fields.feedback.text,
			upDown: fields.feedback.upDown
		});
		setSubmitFeedback(true);
	}

    useEffect(() => {
        if (successFeedback) {
			console.log("FEEDBACK SUBMITTED SUCCESSFULLY: ", successFeedback);
			fields.setFeedback(defaultFeedback);
        }
        if (failedFeedback) {
			console.log("ERROR SUBMITTING FEEDBACK: ", failedFeedback);
			fields.setFeedback(defaultFeedback);
        }
        // eslint-disable-next-line
    }, [successFeedback, failedFeedback]);

    return submitFeedback;
}

export const usePowerSearchChatBoxActions = (fields) => {

	const prevHeight = useRef(0);
	const isAtBottom = useRef(true);
	
	const updateIsAtBottom = () => {
		const offSet = 150;
		const div = fields.scrollDivRef.current;

		const newIsAtBottom = div.scrollHeight - div.scrollTop <= (div.clientHeight + offSet);

		if (newIsAtBottom !== isAtBottom.current) {
            isAtBottom.current = newIsAtBottom;
			fields.setShowGoDown(!newIsAtBottom);
        }
	}
	
	const scrollToBottom = () => {
        const div = fields.scrollDivRef.current;
        div.scrollTop = div.scrollHeight;
        fields.textFieldRef.current.focus();
	}

	const scrollToBottomSmooth = () => {
		const div = fields.scrollDivRef.current;
		div.scrollTo({ top: div.scrollHeight, behavior: 'smooth' });
		fields.textFieldRef.current.focus();
	}
	
	// Handle the scroll event
	useEffect(() => {
		const div = fields.scrollDivRef.current;
		const handleScroll = () => {
			if (!div)
				return;
			updateIsAtBottom();
		}

		div.addEventListener('scroll', handleScroll);
		
		return () => {
			div.removeEventListener('scroll', handleScroll);
		}
		// eslint-disable-next-line
	}, []);

	// Handle the chat box height change
	useEffect(() => {
		const div = fields.scrollDivRef.current;
		const handleHeightChange = () => {
			if (!div)
				return;

			const height = div.scrollHeight;
			if (height > prevHeight.current && isAtBottom.current)
				scrollToBottomSmooth();
			prevHeight.current = height;
		};

		const mutationObserver = new MutationObserver(handleHeightChange);
		mutationObserver.observe(div, { childList: true, subtree: true });

		return () => {
			mutationObserver.disconnect();
		};
		// eslint-disable-next-line
	}, []);

	// Handle the text field height change
	useEffect(() => {
		const div = fields.textFieldRef.current;

		const resizeObserver = new ResizeObserver(() => {
			if (!div)
				return;

			fields.setGoDownButtonBottom(div.offsetHeight + 100);
			updateIsAtBottom();
		});

		resizeObserver.observe(fields.textFieldRef.current);

		return () => {
			resizeObserver.disconnect();
		};

		// eslint-disable-next-line
	}, []);
	
	return {
		isAtBottom,
		scrollToBottom,
		scrollToBottomSmooth,
    };
}

export const usePowerSearchSession = (fields) => {

	const [successClearSession, setClearSession, failedClearSession] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/ClearSession', true, {}, 'POST', {}, true, true);
	const [servicesAreOk, setGetServicesStatus] = useFetch(Env.BACKEND_SERVER_URL + 'PowerSearch/ServicesStatus', true);

	const createNewSession = () => {
		fields.setPrompt('');
		fields.setIsThinking(false);
		fields.setShowGoDown(false);
		fields.setGoDownButtonBottom('0');
		fields.setMessages([]);
		setClearSession(true);
		setGetServicesStatus(true);
	};

	useEffect(() => {
        if (successClearSession) {
            console.log("Power search session cleared successfully!");
        }
        if (failedClearSession) {
            console.log("Error clearing power search session: ", failedClearSession.data);
        }
        // eslint-disable-next-line
	}, [successClearSession, failedClearSession]);

	useEffect(() => {
		if (servicesAreOk) {
			console.log("Power search X services are up and running!");
			fields.setMessages([defaultResponse]);
			fields.setServicesAreDown(false);
		}
		else if (servicesAreOk != null && !servicesAreOk) {
			console.error("Power search services are down!");
			fields.setMessages([defaultServicesFailureResponse]);
			fields.setServicesAreDown(true);
		}
    // eslint-disable-next-line
    }, [servicesAreOk]);

	return {
		createNewSession
	}
}